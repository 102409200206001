import { FinancingFrequency } from '../cashFlow.types';

export const FREQUENCY_OPTIONS: {
  value: FinancingFrequency;
  text: string;
}[] = [
  { value: 'daily', text: 'Daily' },
  { value: 'weekly', text: 'Weekly' },
  { value: 'biweekly', text: 'Biweekly' },
  { value: 'monthly', text: 'Monthly' },
  { value: 'loc', text: 'LOC' },
  { value: 'cc_split', text: 'CC Split' },
];
