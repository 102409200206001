import { useMemo, useState } from 'react';
import {
  Box,
  formatCurrency,
  Table,
  Text,
} from '@forward-financing/fast-forward';

import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';
import { formatDate } from 'helpers/string/dateUtils';
import { TradelineDetails } from './types';
import { TradelineOverview } from './TradelineOverview/TradelineOverview';
import { TradelineCurrentStatusCell } from './common/TradelineCurrentStatusCell';
import { TradelineCreditLoanTypeCell } from './common/TradelineCreditLoanTypeCell';

type TradelinesTableProps = {
  tradelinesDetails: TradelineDetails[];
  emptyMessage: string;
  isAllRowsExpanded: boolean;
};

const getRowColor = (tradeline: TradelineDetails): ColorVariant | undefined => {
  if (
    tradeline.currentStatus === '9' ||
    tradeline.accountStatusType
      .toLowerCase()
      .includes('unpaid balance reported as a loss')
  ) {
    return 'red-200';
  }

  return Number.isNaN(Number(tradeline.currentStatus)) ||
    tradeline.currentStatus === '0'
    ? undefined
    : 'yellow-200';
};

const formatDateToExpected = (
  openDate: string | undefined
): string | undefined => {
  if (!openDate) {
    return undefined;
  }

  const dateObj = new Date(openDate);

  return formatDate(dateObj, 'MM/dd/yyyy');
};

type SortOrder = 'ascending' | 'descending' | 'none';

type SingleSort = {
  key: 'openDate' | 'accountBalance';
  order: Exclude<SortOrder, 'none'>;
};

export const TradelinesTable = ({
  tradelinesDetails,
  emptyMessage,
  isAllRowsExpanded,
}: TradelinesTableProps): JSX.Element => {
  const [sortingForResponsibility, setSortingForResponsibility] =
    useState<SortOrder>('descending');
  const [secondarySort, setSecondarySort] = useState<SingleSort>({
    key: 'accountBalance',
    order: 'descending',
  });

  // Creates a copy of tradelinesDetails to ensure that if the user sets the sort back to "none", it will revert to the original order
  const sortedTradelinesDetails = useMemo(
    () =>
      [...tradelinesDetails].sort((a, b) => {
        // Sort first by Responsibility
        if (sortingForResponsibility !== 'none') {
          const aRanking = rankResponsibility(a.responsibility);
          const bRanking = rankResponsibility(b.responsibility);
          if (aRanking !== bRanking) {
            return sortingForResponsibility === 'ascending'
              ? aRanking - bRanking
              : bRanking - aRanking;
          }
        }

        if (secondarySort.key === 'openDate') {
          const aDate = new Date(a.openDate ?? 0);
          const bDate = new Date(b.openDate ?? 0);
          if (+aDate - +bDate !== 0) {
            return secondarySort.order === 'ascending'
              ? +aDate - +bDate
              : +bDate - +aDate;
          }
        } else if (secondarySort.key === 'accountBalance') {
          return secondarySort.order === 'ascending'
            ? a.accountBalance - b.accountBalance
            : b.accountBalance - a.accountBalance;
        }
        return 0;
      }),
    [tradelinesDetails, sortingForResponsibility, secondarySort]
  );

  if (!tradelinesDetails.length) {
    return <Text>{emptyMessage}</Text>;
  }

  const updateResponsibilitySort = (): void => {
    setSortingForResponsibility((curr) => {
      switch (curr) {
        case 'none':
          return 'descending';
        case 'descending':
          return 'ascending';
        default:
          return 'none';
      }
    });
  };

  const updateOpenDateSort = (): void => {
    setSecondarySort((curr) => {
      if (curr.key === 'openDate') {
        return {
          key: 'openDate',
          order: curr.order === 'ascending' ? 'descending' : 'ascending',
        };
      } else {
        return {
          key: 'openDate',
          order: 'descending',
        };
      }
    });
  };

  const updateAccountBalanceSort = (): void => {
    setSecondarySort((curr) => {
      if (curr.key === 'accountBalance') {
        return {
          key: 'accountBalance',
          order: curr.order === 'ascending' ? 'descending' : 'ascending',
        };
      } else {
        return {
          key: 'accountBalance',
          order: 'descending',
        };
      }
    });
  };

  return (
    <Table>
      <Table.Head>
        <Table.ColumnHeader />
        <Table.ColumnHeader>Name</Table.ColumnHeader>
        <Table.ColumnHeader>Credit Loan Type</Table.ColumnHeader>
        <Table.SortableColumnHeader
          label="Responsibility"
          order={sortingForResponsibility}
          onSort={updateResponsibilitySort}
        >
          Responsibility
        </Table.SortableColumnHeader>
        <Table.ColumnHeader>Rating Type</Table.ColumnHeader>
        <Table.SortableColumnHeader
          label="Open Date"
          order={
            secondarySort.key === 'openDate' ? secondarySort.order : 'none'
          }
          onSort={updateOpenDateSort}
        >
          Open Date
        </Table.SortableColumnHeader>
        <Table.SortableColumnHeader
          label="Account Balance"
          order={
            secondarySort.key === 'accountBalance'
              ? secondarySort.order
              : 'none'
          }
          onSort={updateAccountBalanceSort}
        >
          Account Balance
        </Table.SortableColumnHeader>
        <Table.ColumnHeader>Account Status Type</Table.ColumnHeader>
        <Table.ColumnHeader>Current Status</Table.ColumnHeader>
        <Table.ColumnHeader>Delinquency Count</Table.ColumnHeader>
      </Table.Head>

      <Table.Body>
        {sortedTradelinesDetails.map((tradeline) => (
          <>
            <Table.ExpandableRow
              key={`${tradeline.name}-${tradeline.openDate}`}
              content={
                <Box py={3}>
                  <TradelineOverview tradeline={tradeline} />
                </Box>
              }
              backgroundColor={getRowColor(tradeline)}
              isExpandedByParent={isAllRowsExpanded}
              isWholeRowClickable={true}
            >
              <Table.Cell>{tradeline.name}</Table.Cell>
              <TradelineCreditLoanTypeCell {...tradeline} />
              <Table.Cell>{tradeline.responsibility}</Table.Cell>
              <Table.Cell>{tradeline.ratingType}</Table.Cell>
              <Table.Cell>
                {formatDateToExpected(tradeline.openDate)}
              </Table.Cell>
              <Table.Cell>
                {formatCurrency(tradeline.accountBalance)}
              </Table.Cell>
              <Table.Cell>{tradeline.accountStatusType}</Table.Cell>
              <TradelineCurrentStatusCell {...tradeline} />
              <Table.Cell>{tradeline.delinquencyCount}</Table.Cell>
            </Table.ExpandableRow>

            {tradeline.originalCreditorName && (
              <Table.Row
                key={`${tradeline.name}-${tradeline.openDate}${tradeline.originalCreditorName}`}
              >
                <Table.Cell colSpan={10}>
                  {'Original Creditor: '}
                  <b>{tradeline.originalCreditorName}</b>
                </Table.Cell>
              </Table.Row>
            )}
          </>
        ))}
      </Table.Body>
    </Table>
  );
};

const rankResponsibility = (responsibility: string): number => {
  if (responsibility === 'Authorized User') return 1;
  else if (['Individual', 'Joint Account'].includes(responsibility)) return 2;
  return 0;
};
