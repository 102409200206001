import {
  Banner,
  Button,
  Flex,
  IconButton,
} from '@forward-financing/fast-forward';
import { Params, useParams } from 'react-router-dom';
import { useDeleteSheet } from '../cashFlowHooks';
import { useWorksheetsActiveTabContext } from '../Context/WorksheetsActiveTabContext';

type ActionButtonsProps = {
  sheetId: string;
  isOverview?: boolean;
};

export const ActionButtons = ({
  sheetId,
  isOverview = false,
}: ActionButtonsProps): JSX.Element => {
  const { submissionUuid } = useParams<keyof Params>();

  if (!submissionUuid) {
    throw new Error('Submission UUID is required');
  }

  const { setOverviewTab } = useWorksheetsActiveTabContext();

  const {
    mutateAsync: deleteSheet,
    error: errorFromDeletingSheet,
    isPending: isDeleting,
  } = useDeleteSheet(submissionUuid, sheetId);

  const handleDeleteSheet = async (): Promise<void> => {
    const { success } = await deleteSheet();

    success && setOverviewTab();
  };

  return (
    // TODO: OnClick for each one of these button will be handled on 2 different tickets
    <Flex flexDirection="column" gap={errorFromDeletingSheet ? 3 : 0}>
      {errorFromDeletingSheet?.message && (
        <Banner>{errorFromDeletingSheet.message}</Banner>
      )}

      <Flex gap={2} alignItems="center">
        {!isOverview && (
          <IconButton
            icon={isDeleting ? 'loader' : 'trash'}
            label="Delete"
            variant="danger"
            disabled={isDeleting}
            onClick={handleDeleteSheet}
          />
        )}

        <IconButton icon="square-plus" label="Create Closing Sheet" />
        <Button variant="primary">Save</Button>
      </Flex>
    </Flex>
  );
};
