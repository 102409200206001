import {
  Banner,
  Card,
  Flex,
  formatDateTimeString,
  Grid,
  Loading,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import { useEffect, useState } from 'react';
import { NetworkError } from 'api/networkError';
import {
  useAcceptedOffer,
  useIsoName,
  useLowerPrepayments,
  useResetAcceptedOffer,
} from './acceptedOfferHooks';
import { AcceptedOfferActionButtons } from './AcceptedOfferActionButtons';
import { DealTerms } from './DealTerms';

type AcceptedOfferContainerProps = {
  isoUuid?: string;
  submissionUuid: string;
  stage?: string;
  substage?: string;
};

export const AcceptedOfferContainer = ({
  isoUuid,
  submissionUuid,
  stage,
  substage,
}: AcceptedOfferContainerProps): JSX.Element | null => {
  const {
    data: acceptedOffer,
    loading: isAcceptedOfferLoading,
    error: acceptedOfferPotentialError,
    refetch: refetchAcceptedOffer,
  } = useAcceptedOffer(submissionUuid);

  const acceptedOfferError =
    acceptedOfferPotentialError instanceof NetworkError &&
    acceptedOfferPotentialError.statusCode === 404
      ? undefined
      : acceptedOfferPotentialError;

  const [
    lowerPrepaymentsFunc,
    {
      loading: isLowerPrepaymentsLoading,
      error: lowerPrepaymentsError,
      data: lowerPrepaymentsData,
    },
  ] = useLowerPrepayments({ submissionUuid });
  const [
    resetAcceptedOfferFunc,
    { loading: isResetAcceptedOfferLoading, error: resetAcceptedOfferError },
  ] = useResetAcceptedOffer({
    submissionUuid,
  });
  const {
    data: acceptedBy,
    loading: isIsoNameLoading,
    error: isoNameError,
  } = useIsoName(isoUuid);

  // Using a state allows for the banner to reappear if the button is clicked again after being dismissed
  const [showLowerPrepaymentsSuccess, setShowLowerPrepaymentsSuccess] =
    useState(false);

  useEffect(() => {
    if (lowerPrepaymentsData?.success) setShowLowerPrepaymentsSuccess(true);
  }, [lowerPrepaymentsData]);

  //Don't show a loading spinner on the initial load, as we don't want the card to show at all
  //if there is no accepted offer
  if (!acceptedOffer && !acceptedOfferError) return null;

  const isLoading =
    isAcceptedOfferLoading ||
    isLowerPrepaymentsLoading ||
    isResetAcceptedOfferLoading ||
    isIsoNameLoading;

  const handleLowerPrepayments = async (): Promise<void> => {
    setShowLowerPrepaymentsSuccess(false);
    await lowerPrepaymentsFunc();
    refetchAcceptedOffer();
  };

  const handleResetAcceptedOffer = async (offerId: string): Promise<void> => {
    await resetAcceptedOfferFunc(offerId);
    refetchAcceptedOffer();
  };

  return (
    <Grid.Item xs={12}>
      <Card title="Accepted Offer" collapsible>
        {showLowerPrepaymentsSuccess && (
          <Banner
            variant="success"
            dismissable
            onDismiss={() => setShowLowerPrepaymentsSuccess(false)}
          >
            Successfully offered lower prepayments.
          </Banner>
        )}

        {acceptedOfferError && (
          <Banner variant="error">{acceptedOfferError.message}</Banner>
        )}

        {lowerPrepaymentsError && (
          <Banner variant="error">{lowerPrepaymentsError.message}</Banner>
        )}

        {resetAcceptedOfferError && (
          <Banner variant="error">{resetAcceptedOfferError.message}</Banner>
        )}

        {isoNameError && (
          <Banner variant="error">{isoNameError.message}</Banner>
        )}

        {isLoading ? (
          <Loading size="medium" />
        ) : (
          acceptedOffer && (
            <>
              <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Flex alignItems={'center'} gap={2}>
                  <Subheading>Deal Terms</Subheading>
                  {acceptedBy && (
                    <Text>
                      Accepted by <Text bold>{acceptedBy}</Text> on{' '}
                      <Text bold>
                        {formatDateTimeString(acceptedOffer.acceptedOnDate)}
                      </Text>
                    </Text>
                  )}
                </Flex>
                <AcceptedOfferActionButtons
                  offerId={acceptedOffer.id}
                  lowerPrepayments={handleLowerPrepayments}
                  resetAcceptedOffer={handleResetAcceptedOffer}
                  stage={stage}
                  substage={substage}
                />
              </Flex>
              <DealTerms acceptedOffer={acceptedOffer} />
            </>
          )
        )}
      </Card>
    </Grid.Item>
  );
};
