import {
  Currency,
  Flex,
  Icon,
  Link,
  Subheading,
  Table,
} from '@forward-financing/fast-forward';
import { PublicRecordsOverview } from './types';

export interface PublicRecordsCountProps {
  publicRecord: PublicRecordsOverview;
  submissionUuid: string;
}
export const PublicRecordsCount = ({
  publicRecord,
  submissionUuid,
}: PublicRecordsCountProps): JSX.Element => {
  const {
    openBkNumber,
    openLiensNumber,
    openLiensAmount,
    openJudgmentNumber,
    openJudgmentAmount,
    closedBkNumber,
    closedLiensNumber,
    closedLiensAmount,
    closedJudgmentNumber,
    closedJudgmentAmount,
    totalBkNumber,
    totalLiensNumber,
    totalLiensAmount,
    totalJudgmentNumber,
    totalJudgmentAmount,
    ownerUuid,
  } = publicRecord;

  const isEmpty =
    Number(totalLiensNumber) === 0 &&
    Number(totalBkNumber) === 0 &&
    Number(totalJudgmentNumber) === 0;

  return (
    <>
      <Flex gap={3} alignItems="center">
        {isEmpty && (
          <Icon name="ban" data-testid="empty-icon" color="success" size="lg" />
        )}
        <Subheading variant="section">Public Records</Subheading>
        <Link
          newTab
          href={
            new URL(
              `/dashboard/submissions/${submissionUuid}/owners/${ownerUuid}/public_records`,
              window.location.origin
            )
          }
        >
          View Public Records
        </Link>
      </Flex>
      <Table caption="Public Records">
        <Table.Head>
          <Table.ColumnHeader />
          <Table.ColumnHeader textAlign="right">Open</Table.ColumnHeader>
          <Table.ColumnHeader textAlign="right">Closed</Table.ColumnHeader>
          <Table.ColumnHeader textAlign="right">Total</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.RowHeader>BK #</Table.RowHeader>
            <Table.Cell textAlign="right">{openBkNumber}</Table.Cell>
            <Table.Cell textAlign="right">{closedBkNumber}</Table.Cell>
            <Table.Cell textAlign="right">{totalBkNumber}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.RowHeader>Liens #</Table.RowHeader>
            <Table.Cell textAlign="right">{openLiensNumber}</Table.Cell>
            <Table.Cell textAlign="right">{closedLiensNumber}</Table.Cell>
            <Table.Cell textAlign="right">{totalLiensNumber}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.RowHeader>Liens $</Table.RowHeader>
            <Table.Cell textAlign="right">
              <Currency amount={parseInt(openLiensAmount) / 100} />
            </Table.Cell>
            <Table.Cell textAlign="right">
              <Currency amount={parseInt(closedLiensAmount) / 100} />
            </Table.Cell>
            <Table.Cell textAlign="right">
              <Currency amount={parseInt(totalLiensAmount) / 100} />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.RowHeader>Judgement #</Table.RowHeader>
            <Table.Cell textAlign="right">{openJudgmentNumber}</Table.Cell>
            <Table.Cell textAlign="right">{closedJudgmentNumber}</Table.Cell>
            <Table.Cell textAlign="right">{totalJudgmentNumber}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.RowHeader>Judgement $</Table.RowHeader>
            <Table.Cell textAlign="right">
              <Currency amount={parseInt(openJudgmentAmount) / 100} />
            </Table.Cell>
            <Table.Cell textAlign="right">
              <Currency amount={parseInt(closedJudgmentAmount) / 100} />
            </Table.Cell>
            <Table.Cell textAlign="right">
              <Currency amount={parseInt(totalJudgmentAmount) / 100} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};
