import {
  SheetMistakesCreatedBy,
  SheetMistakesType,
  SheetMistakesFeedback,
} from 'types/api/banking/types';

export const MISTAKES_BY_OPTIONS: {
  value: SheetMistakesCreatedBy;
  text: string;
}[] = [
  { value: 'prequal', text: 'Prequal' },
  { value: 'processing', text: 'Processing' },
  { value: 'closing', text: 'Closing' },
  { value: 'processing_ocr', text: 'Processing OCR' },
  { value: 'underwriting_ocr', text: 'UW OCR' },
  { value: 'closing_ocr', text: 'Closing OCR' },
];

export const SHEET_MISTAKES_TYPE_OPTIONS: {
  value: SheetMistakesType;
  text: string;
}[] = [
  { value: 'adb_calc_incorrect', text: 'Financial Data: ADB Calc incorrect' },
  {
    value: 'beginning_ending_balance_incorrect',
    text: 'Financial Data: Beginning/Ending Balance incorrect',
  },
  {
    value: 'deposit_amount_incorrect',
    text: 'Financial Data: Deposit Amount incorrect',
  },
  {
    value: 'deposit_count_incorrect',
    text: 'Financial Data: Deposit Count incorrect',
  },
  {
    value: 'funding_adjustment_incorrect',
    text: 'Financial Data: Funding Adjustment incorrect',
  },
  {
    value: 'minimum_balance_incorrect',
    text: 'Financial Data: Minimum Balance incorrect',
  },
  { value: 'missing_month', text: 'Financial Data: Missing Month' },
  {
    value: 'negative_days_count_incorrect',
    text: 'Financial Data: Negative Days count incorrect',
  },
  { value: 'nsf_count_incorrect', text: 'Financial Data: NSF count incorrect' },
  {
    value: 'other_adjustment_incorrect',
    text: 'Financial Data: Other Adjustment incorrect',
  },
  {
    value: 'transfer_adjustment_incorrect',
    text: 'Financial Data: Transfer Adjustment incorrect',
  },
  {
    value: 'savings_trust_account',
    text: 'Financial Data: Savings/Trust Acct',
  },
  { value: 'amount_incorrect', text: 'Financings: Amount incorrect' },
  {
    value: 'financing_company_incorrect',
    text: 'Financings: Financing Company incorrect',
  },
  { value: 'frequency_incorrect', text: 'Financings: Frequency incorrect' },
  {
    value: 'funded_amount_incorrect',
    text: 'Financings: Funded Amount incorrect',
  },
  { value: 'funded_date_incorrect', text: 'Financings: Funded Date incorrect' },
  { value: 'missing_financing', text: 'Financings: Missing Financing' },
];

export const PR_CL_FEEDBACK_OPTIONS: {
  value: SheetMistakesFeedback;
  text: string;
}[] = [
  { value: 'no_action', text: 'No Action Needed' },
  { value: 'feedback_provided', text: 'Feedback Provided' },
];
