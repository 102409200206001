import { useEffect, useState } from 'react';
import { Flex, Loading } from '@forward-financing/fast-forward';
import { defaultTo } from 'lodash';
import { featureFlags } from 'helpers/featureFlags';
import { useNewDealScoringContext } from '../NewDealScoring/NewDealScoringContext';
import {
  NewDealScoreComposite,
  RenewalScoreComposite,
} from '../DealScoring.types';
import { ScoresContainerBase } from './ScoresContainerBase';

type LedgerCompositeScoreProps = {
  compositeScoreLoading: boolean;
  compositeScore?: RenewalScoreComposite | NewDealScoreComposite;
  refreshedComppositoScore?: RenewalScoreComposite | NewDealScoreComposite;
};
export const LedgerCompositeScore = ({
  compositeScoreLoading,
  compositeScore,
  refreshedComppositoScore,
}: LedgerCompositeScoreProps): JSX.Element => {
  const [compositeScoreData, setCompositeScoreData] = useState(compositeScore);
  const { response: newDealScore } = useNewDealScoringContext();

  useEffect(() => {
    refreshedComppositoScore && setCompositeScoreData(refreshedComppositoScore);
  }, [refreshedComppositoScore]);
  return (
    <>
      {compositeScoreLoading && <Loading />}

      {!compositeScoreLoading &&
        compositeScoreData &&
        compositeScoreData.type === 'New Deal' && (
          <Flex gap={2}>
            <ScoresContainerBase
              title="Manual Score"
              currentScore={defaultTo(newDealScore?.averageScore, 0).toFixed(1)}
              maxScore="5.0"
              scores={[
                {
                  scoreName: 'Owner Risk',
                  maxScoreValue: 5,
                  scoreValue: defaultTo(newDealScore?.ownerRisk, 0),
                },
                {
                  scoreName: 'Business Risk',
                  maxScoreValue: 5,
                  scoreValue: defaultTo(newDealScore?.businessRisk, 0),
                },
                {
                  scoreName: 'Quality of Cash Flows',
                  maxScoreValue: 5,
                  scoreValue: defaultTo(newDealScore?.qualityOfCashFlows, 0),
                },
              ]}
            />

            {!compositeScoreLoading && (
              <ScoresContainerBase
                title="Composite Score"
                currentScore={defaultTo(
                  compositeScoreData.compositeScore?.score,
                  0
                ).toFixed(1)}
                maxScore={`Tier ${defaultTo(
                  compositeScoreData.compositeScore?.tier,
                  'N/A'
                )}`}
                scores={[
                  {
                    scoreName: 'Positions',
                    maxScoreValue: 5,
                    scoreValue: defaultTo(compositeScoreData.position.score, 0),
                  },
                  {
                    scoreName: 'Industry Risk',
                    maxScoreValue: 5,
                    scoreValue: defaultTo(
                      compositeScoreData.industryRisk.score,
                      0
                    ),
                  },
                  {
                    scoreName: 'FICO Score',
                    maxScoreValue: 3,
                    scoreValue: defaultTo(compositeScoreData.fico.score, 0),
                  },
                  {
                    scoreName: 'Time in Business',
                    maxScoreValue: 5,
                    scoreValue: defaultTo(
                      compositeScoreData.timeInBusiness.score,
                      0
                    ),
                  },
                  {
                    scoreName: 'Revenue Tier',
                    maxScoreValue: 5,
                    scoreValue: defaultTo(
                      compositeScoreData.trueRevenue.score,
                      0
                    ),
                  },
                  // Risk Score will be replacing Risk Decile end of October 2024.
                  // The fetched data will be returning either a `riskScore` for more recent submissions
                  // or a `riskDecile` for older submissions.
                  {
                    scoreName: compositeScoreData?.riskDecile
                      ? 'Risk Decile'
                      : 'Risk Score',
                    maxScoreValue: 5,
                    scoreValue: defaultTo(
                      compositeScoreData?.riskDecile
                        ? compositeScoreData?.riskDecile?.score
                        : compositeScoreData?.riskScore?.score,
                      0
                    ),
                  },
                ]}
              />
            )}
          </Flex>
        )}

      {!compositeScoreLoading &&
        compositeScore &&
        compositeScore.type === 'Renewal' && (
          <ScoresContainerBase
            title="Composite Score"
            currentScore={defaultTo(
              compositeScore.compositeScore?.score,
              0
            ).toFixed(1)}
            maxScore={`Tier ${defaultTo(
              compositeScore.compositeScore?.tier,
              'N/A'
            )}`}
            scores={[
              {
                scoreName: "Number of X's renewed",
                maxScoreValue: 7,
                scoreValue: compositeScore.timesFunded.score,
              },
              {
                scoreName: 'Industry Risk',
                maxScoreValue: 5,
                scoreValue: compositeScore.industryRisk.score,
              },
              {
                scoreName: 'Payment History',
                maxScoreValue: 5,
                scoreValue: compositeScore.paymentHistory.score,
              },
              {
                scoreName: 'Revenue Change',
                maxScoreValue: 3,
                scoreValue: compositeScore.revenueChange.score,
              },
              {
                scoreName: 'FICO Change',
                maxScoreValue: 3,
                scoreValue: compositeScore.ficoChange.score,
              },
              {
                scoreName: 'Stacking',
                maxScoreValue: 3,
                scoreValue: compositeScore.stacking.score,
              },
              ...(featureFlags.update_renewal_composite_score &&
              compositeScore.version === 'renewal_composite_score_v2'
                ? [
                    {
                      scoreName: 'BK Plus',
                      maxScoreValue: 5,
                      scoreValue: compositeScore.bkPlus.score,
                    },
                  ]
                : []),
            ]}
          />
        )}
    </>
  );
};
