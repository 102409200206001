import { useState } from 'react';
import { z } from 'zod';

import {
  Banner,
  Box,
  Button,
  Card,
  Flex,
  Form,
  Grid,
  IconButton,
  MaskedTextInput,
  Select,
  TextInput,
} from '@forward-financing/fast-forward';

import {
  validateSsn,
  validateZipCode,
} from '../../../helpers/validations/FieldValidator';
import { useGetStates } from '../../SubmissionEditing/SubmissionEditingHooks';
import {
  OwnerManualSearchFetcher,
  OwnerManualSearchQueryParams,
} from './ownerManualSearch.types';
import { useOwner } from './ownerManualSearchHooks';

const formSchema = z
  .object({
    phone: z.string().regex(/^\d*$/, 'Phone must be numeric').optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    ssn: z
      .string()
      .optional()
      .refine((ssn) => (ssn ? validateSsn(ssn).isValid : true), {
        message: 'Invalid SSN',
      }),
    street1: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    zip: z
      .string()
      .optional()
      .refine((zip) => (zip ? validateZipCode(zip).isValid : true), {
        message: 'Invalid Zip Code.',
      }),
  })
  .refine((data) => Object.values(data).some((value) => value !== ''), {
    message: 'At least one field must be filled',
  });

type FormSchemaError = Partial<z.inferFormattedError<typeof formSchema>>;

type OwnerManualSearchFormProps = {
  fetcher: OwnerManualSearchFetcher;
  submissionUuid?: string;
  ownerUuid?: string;
};

export const OwnerManualSearchForm = ({
  fetcher,
  ownerUuid,
  submissionUuid,
}: OwnerManualSearchFormProps): JSX.Element => {
  const [formValues, setFormValues] = useState<OwnerManualSearchQueryParams>({
    ssn: '',
    firstName: '',
    lastName: '',
    phone: '',
    street1: '',
    city: '',
    state: '',
    zip: '',
  });
  const [errors, setErrors] = useState<FormSchemaError>({});
  const { data: states, error: statesError } = useGetStates();

  const { data: owner, error: ownerError } = useOwner(ownerUuid);

  const handleChange = (
    name: keyof NonNullable<OwnerManualSearchQueryParams>,
    value: string
  ): void => {
    setFormValues({ ...formValues, [name]: value });
  };

  const isValidForm = (): boolean => {
    const validationResult = formSchema.safeParse(formValues);
    if (validationResult.success) {
      setErrors({});
    } else {
      setErrors(validationResult.error.format());
    }
    return validationResult.success;
  };

  const isSubmitEnabled = Object.entries(formValues).some(([, value]) => value);

  const handleSubmit = async (): Promise<void> => {
    if (isValidForm() && !!ownerUuid && !!submissionUuid) {
      await fetcher(ownerUuid, submissionUuid, formValues);
    }
  };

  return (
    <Card collapsible title={'Owner Report Search Page'}>
      <Form
        onSubmit={handleSubmit}
        accessibleName="Owner report search page"
        allowImplicitSubmission={true}
      >
        {({ fireSubmit }) => (
          <>
            {statesError && <Banner dismissable>{statesError?.message}</Banner>}
            {ownerError && <Banner dismissable>{ownerError?.message}</Banner>}
            <Grid gutter>
              <Grid.Item s={12} l={3}>
                <MaskedTextInput
                  label="SSN"
                  name="ssn"
                  mask="#"
                  format="###-##-####"
                  value={formValues.ssn}
                  onValueChange={(newVal) => {
                    handleChange('ssn', newVal.value);
                  }}
                  errorMessage={errors?.ssn?._errors.join(' ')}
                />
                <IconButton
                  icon="copy"
                  onClick={() => handleChange('ssn', owner?.ssn || '')}
                  label="Copy SSN"
                />
              </Grid.Item>

              <Grid.Item s={12} l={3}>
                <TextInput
                  name="firstName"
                  label="First Name"
                  value={formValues.firstName}
                  onValueChange={(newVal) => handleChange('firstName', newVal)}
                  errorMessage={errors?.firstName?._errors.join(' ')}
                />
                <IconButton
                  icon="copy"
                  onClick={() =>
                    handleChange('firstName', owner?.firstName || '')
                  }
                  label="Copy First Name"
                />
              </Grid.Item>

              <Grid.Item s={12} l={3}>
                <TextInput
                  name="lastName"
                  label="Last Name"
                  value={formValues.lastName}
                  onValueChange={(newVal) => handleChange('lastName', newVal)}
                  errorMessage={errors?.lastName?._errors.join(' ')}
                />
                <IconButton
                  icon="copy"
                  onClick={() =>
                    handleChange('lastName', owner?.lastName || '')
                  }
                  label="Copy Last Name"
                />
              </Grid.Item>

              <Grid.Item s={12} l={3}>
                <MaskedTextInput
                  name="phone"
                  label="Phone"
                  value={formValues.phone}
                  onValueChange={(newVal) =>
                    handleChange('phone', newVal.value)
                  }
                  errorMessage={errors?.phone?._errors.join(' ')}
                  format={'(###) ###-####'}
                />
                <Flex>
                  <IconButton
                    icon="copy"
                    onClick={() =>
                      handleChange('phone', owner?.cellPhone || '')
                    }
                    label="Copy Cell Phone"
                  />
                  <IconButton
                    icon="copy"
                    onClick={() =>
                      handleChange('phone', owner?.homePhone || '')
                    }
                    label="Copy Home Phone"
                  />
                </Flex>
              </Grid.Item>

              <Grid.Item s={12} l={3}>
                <TextInput
                  name="street1"
                  label="Street 1"
                  value={formValues.street1}
                  onValueChange={(newVal) => handleChange('street1', newVal)}
                  errorMessage={errors?.street1?._errors.join(' ')}
                />
                <IconButton
                  icon="copy"
                  onClick={() => handleChange('street1', owner?.street1 || '')}
                  label="Copy Street 1"
                />
              </Grid.Item>

              <Grid.Item s={12} l={3}>
                <TextInput
                  name="city"
                  label="City"
                  value={formValues.city}
                  onValueChange={(newVal) => handleChange('city', newVal)}
                  errorMessage={errors?.city?._errors.join(' ')}
                />
                <IconButton
                  icon="copy"
                  onClick={() => handleChange('city', owner?.city || '')}
                  label="Copy City"
                />
              </Grid.Item>

              <Grid.Item s={12} l={3}>
                <Select
                  name="state"
                  label="State"
                  options={states || []}
                  value={formValues.state ?? ''}
                  onValueChange={(newVal) => handleChange('state', newVal)}
                  errorMessage={errors?.state?._errors.join(' ')}
                />
                <IconButton
                  icon="copy"
                  onClick={() => handleChange('state', owner?.state || '')}
                  label="Copy State"
                />
              </Grid.Item>

              <Grid.Item s={12} l={3}>
                <MaskedTextInput
                  name="zip"
                  type="number"
                  label="Zip"
                  value={formValues.zip}
                  onValueChange={(newVal) => handleChange('zip', newVal.value)}
                  format={'##### ####'}
                  mask={''}
                  errorMessage={errors?.zip?._errors.join(' ')}
                />
                <IconButton
                  icon="copy"
                  onClick={() => handleChange('zip', owner?.zip || '')}
                  label="Copy Zip"
                />
              </Grid.Item>
            </Grid>
            <Box mt={3} textAlign="right">
              <Button onClick={fireSubmit} disabled={!isSubmitEnabled}>
                Search
              </Button>
            </Box>
          </>
        )}
      </Form>
    </Card>
  );
};
