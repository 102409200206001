import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Banner, Loading } from '@forward-financing/fast-forward';

import { featureFlags } from 'helpers/featureFlags';
import { isAfterUnderwriting } from 'helpers/utils';
import { NewDealScoringContainer } from './NewDealScoring/NewDealScoringContainer';
import { NewDealScoring } from './NewDealScoring/NewDealScoring';
import { RenewalScoringContainer } from './RenewalScoring/RenewalScoringContainer';
import { useScoringComposite, useSubmission } from './DealScoringFetchHooks';
import {
  NewDealScoreComposite,
  RenewalScoreComposite,
} from './DealScoring.types';
import { useLedgers } from './Ledger/ledgerHooks';

export type DealScoringContainerProps = {
  submissionUuid: string;
  currentSubmissionUuid: string;
  setCardTitle: Dispatch<SetStateAction<string>>;
  currentTabValue?: string;
  setCurrentTabValue: Dispatch<SetStateAction<string | undefined>>;
  defaultTabValue?: string;
  setDefaultTabValue: Dispatch<SetStateAction<string | undefined>>;
};

export const DealScoringContainer = ({
  submissionUuid,
  setCardTitle,
  currentSubmissionUuid,
  currentTabValue,
  setCurrentTabValue,
  defaultTabValue,
  setDefaultTabValue,
}: DealScoringContainerProps): JSX.Element => {
  const [previousFundedSubmissionUuid, setPreviousFundedSubmissionUuid] =
    useState<string>();

  const {
    data: submission,
    error: submissionError,
    loading: submissionLoading,
  } = useSubmission(submissionUuid);

  const {
    data: compositeScore,
    error: compositeScoreError,
    loading: compositeScoreLoading,
  } = useScoringComposite(submissionUuid);

  const {
    data: ledgersData,
    error: ledgersError,
    refetch: refetchLedgers,
  } = useLedgers(submissionUuid);

  const ledgerPrevLength = useRef(ledgersData?.length);

  const shouldShowV6Score =
    featureFlags.show_v6_score_display &&
    // if submission category is new sales or business dev
    submission?.category !== undefined &&
    ['new_sales', 'business_development_program'].includes(
      submission.category
    ) &&
    (submission.stage === 'Underwriting' ||
      isAfterUnderwriting(submission.stage));

  useEffect(() => {
    if (submission) {
      setPreviousFundedSubmissionUuid(
        submission.previousFundedSubmissionUuidFromCustomer ||
          submission.previousFundedSubmissionUuid
      );
    }
  }, [submission]);

  if (
    submissionError ||
    (compositeScoreError && compositeScoreError.statusCode !== 404) ||
    ledgersError
  ) {
    return (
      <>
        {submissionError && (
          <Banner dismissable={false}>{submissionError.message}</Banner>
        )}
        {compositeScoreError && compositeScoreError.statusCode !== 404 && (
          <Banner dismissable={false}>{compositeScoreError.message}</Banner>
        )}
        {ledgersError && (
          <Banner dismissable={false}>{ledgersError.message}</Banner>
        )}
      </>
    );
  }

  if (submissionLoading || compositeScoreLoading) {
    // return nothing while loading
    return <Loading />;
  }

  const showNewDealScoring: boolean =
    featureFlags.render_scoring_section_based_on_composite_score
      ? (compositeScore && compositeScore.type === 'New Deal') ||
        (!compositeScore && submission?.type === 'New Deal')
      : submission?.type === 'New Deal';

  if (!submission) {
    return <></>;
  }

  // Note that the type coersion below is necessary to support the above feature flag.
  // When the feature flag is no longer needed, the types will be inferred correctly
  // via the type (or absence) of the composite score.

  return (
    <>
      {showNewDealScoring ? (
        <NewDealScoringContainer
          submission={submission}
          setCardTitle={setCardTitle}
          currentSubmissionUuid={currentSubmissionUuid}
          shouldShowV6Score={shouldShowV6Score}
          compositeScore={compositeScore as NewDealScoreComposite | undefined}
          ledgersData={ledgersData}
          refetchLedgers={refetchLedgers}
          currentTabValue={currentTabValue}
          setCurrentTabValue={setCurrentTabValue}
          defaultTabValue={defaultTabValue}
          setDefaultTabValue={setDefaultTabValue}
          ledgerPrevLength={ledgerPrevLength}
        >
          <NewDealScoring submissionUuid={submission.uuid} />
        </NewDealScoringContainer>
      ) : (
        <RenewalScoringContainer
          setCardTitle={setCardTitle}
          previousFundedSubmissionUuid={previousFundedSubmissionUuid}
          compositeScore={compositeScore as RenewalScoreComposite | undefined}
          shouldShowV6Score={shouldShowV6Score}
          currentSubmissionUuid={currentSubmissionUuid}
          ledgersData={ledgersData}
          submission={submission}
          refetchLedgers={refetchLedgers}
          currentTabValue={currentTabValue}
          setCurrentTabValue={setCurrentTabValue}
          defaultTabValue={defaultTabValue}
          setDefaultTabValue={setDefaultTabValue}
          ledgerPrevLength={ledgerPrevLength}
        />
      )}
    </>
  );
};
