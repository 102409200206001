import { Card, Grid, Loading } from '@forward-financing/fast-forward';

import { ExceptionRequestDetails } from './ExceptionRequestDetails';
import { useExceptionRequestContext } from './Context/ExceptionRequestContext';

type ExceptionRequestContainerProps = {
  isNewDeal: boolean;
  submissionUuid: string;
};

export const ExceptionRequestContainer = ({
  isNewDeal,
  submissionUuid,
}: ExceptionRequestContainerProps): JSX.Element | null => {
  const { requests, areRequestsLoading, refetchExceptionRequests } =
    useExceptionRequestContext();

  if (areRequestsLoading) {
    return (
      <Grid.Item>
        <Loading size="large" />
      </Grid.Item>
    );
  }

  const showExceptionRequest = !!requests?.length;

  if (!requests?.length) {
    return null;
  }

  return (
    <Grid.Item>
      <Card title={'Exception Request(s)'} collapsible>
        {showExceptionRequest &&
          requests.map((exceptionRequest) => (
            <ExceptionRequestDetails
              exceptionRequest={exceptionRequest}
              key={exceptionRequest.id}
              isNewDeal={isNewDeal}
              refetchExceptionRequests={refetchExceptionRequests}
              requests={requests}
              submissionUuid={submissionUuid}
            />
          ))}
      </Card>
    </Grid.Item>
  );
};
