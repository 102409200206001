import {
  Banner,
  Box,
  Flex,
  Icon,
  IconButton,
  Loading,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { LastPulledOnDate } from '../LastPulledOnDate/LastPulledOnDate';
import { PaynetSearchResult } from './businessOverview.types';
import { usePullPaynetReport } from './businessOverviewHooks';

export interface PaynetResultsTableProps {
  searchResults: PaynetSearchResult[];
  submissionUuid: string;
}

export const PaynetSearchResultsTable = (
  props: PaynetResultsTableProps
): JSX.Element => {
  const [addReportToApplication, { data, loading, error }] =
    usePullPaynetReport(props.submissionUuid);

  return (
    <Box>
      {loading && <Loading size="small" text="Attempting to queue job" />}
      {data?.success && (
        <Banner dismissable={false} variant="success">
          <Flex gap={2}>
            <Icon name="circle-check" size="2x" />
            <Text>
              Paynet was pulled successfully! It takes anywhere from 5 seconds
              to 4 minutes to process the data, so please allow a little bit of
              time before refreshing the page. If you are still not able to view
              the report after 4 minutes, please contact the tech support.
            </Text>
          </Flex>
        </Banner>
      )}
      {error && (
        <Banner dismissable={false} variant="error">
          {error.message}
        </Banner>
      )}
      <Table caption="Paynet Search Results">
        <Table.Head>
          <Table.ColumnHeader>Name</Table.ColumnHeader>
          <Table.ColumnHeader>City</Table.ColumnHeader>
          <Table.ColumnHeader>State</Table.ColumnHeader>
          <Table.ColumnHeader>Tax ID</Table.ColumnHeader>
          <Table.ColumnHeader>Actions</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {props.searchResults.map((result) => (
            <Table.Row key={result.paynetId}>
              <Table.Cell>{result.name}</Table.Cell>
              <Table.Cell>{result.city}</Table.Cell>
              <Table.Cell>{result.stateCode}</Table.Cell>
              <Table.Cell>{result.taxId}</Table.Cell>
              <Table.Cell>
                <IconButton
                  label="Add to Customer"
                  icon="plus-circle"
                  disabled={loading}
                  onClick={() =>
                    addReportToApplication({
                      reportId: result.paynetId,
                    })
                  }
                />
                <LastPulledOnDate lastPulledOn={result.lastPulledOnDate} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};
