import { Box, Flex } from '@forward-financing/fast-forward';
import { useState } from 'react';
import { Sheet } from './cashFlow.types';
import { OverviewDetails } from './OverviewDetails';
import { FinancialDataContainer } from './FinancialData/FinancialDataContainer';
import { FinancingContainer } from './FinancingContainer';
import { AccountDetails } from './AccountDetails/AccountDetails';
import { MistakesContainer } from './Mistakes/MistakesContainer';
import { WorksheetHeader } from './WokksheetHeader/WorksheetHeader';

type WorksheetContainerProps = {
  sheetData: Sheet;
  isOverview?: boolean;
  submissionUuid: string;
};

export const WorksheetContainer = ({
  sheetData,
  isOverview = false,
  submissionUuid,
}: WorksheetContainerProps): JSX.Element => {
  const [sheetToUpdate, setSheetToUpdate] = useState(sheetData);

  return (
    <Flex flexDirection={'column'} gap={3}>
      <WorksheetHeader sheetId={sheetToUpdate.id} isOverview={isOverview} />

      {isOverview ? (
        <OverviewDetails
          sheetToUpdate={sheetToUpdate}
          setSheetToUpdate={setSheetToUpdate}
        />
      ) : (
        <AccountDetails
          sheetToUpdate={sheetToUpdate}
          setSheetToUpdate={setSheetToUpdate}
        />
      )}

      <Box>
        <FinancialDataContainer
          sheetData={sheetData}
          submissionUuid={submissionUuid}
          isOverview={isOverview}
        />
      </Box>
      <Box backgroundColor="gray-200" p={3} borderRadius={2}>
        <FinancingContainer
          sheetId={sheetData.id}
          netDepositOverrideCents={sheetData.netDepositOverrideCents}
          isOverview={isOverview}
        />
      </Box>

      <Box backgroundColor="gray-200" p={3} borderRadius={2}>
        <MistakesContainer sheetId={sheetData.id} isReadonly={isOverview} />
      </Box>
    </Flex>
  );
};
