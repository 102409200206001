import { createContext, useContext, useState } from 'react';
import * as React from 'react';

type WorksheetsActiveTabContextData = {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setOverviewTab: () => void;
};

const WorksheetsActiveTabContext = createContext<
  WorksheetsActiveTabContextData | undefined
>(undefined);

export const useWorksheetsActiveTabContext =
  (): WorksheetsActiveTabContextData => {
    const context = useContext(WorksheetsActiveTabContext);

    /**
     * Added this check to improve DevEx.
     * In case if someone accidentally uses this hook outside of the provider.
     */
    if (context === undefined) {
      throw new Error(
        'useWorksheetsActiveTabContext must be used within a LedgerContextProvider'
      );
    }

    return context;
  };

export const OVERVIEW_TAB_ID = 'overview-sheet';

export const WorksheetsActiveTabContextProvider = ({
  children,
}: React.PropsWithChildren): JSX.Element => {
  const [activeTab, setActiveTab] = useState<string>(OVERVIEW_TAB_ID);

  const setOverviewTab = (): void => {
    setActiveTab(OVERVIEW_TAB_ID);
  };

  return (
    <WorksheetsActiveTabContext.Provider
      value={{ activeTab, setActiveTab, setOverviewTab }}
    >
      {children}
    </WorksheetsActiveTabContext.Provider>
  );
};
