import { useState } from 'react';
import {
  Box,
  Flex,
  Grid,
  Icon,
  Subheading,
  Tabs,
} from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';
import { usePaymentScheduleContext } from '../PaymentScheduleContext';
import { CalculatorForm } from './CalculatorForm';
import { ConfirmAdjustment } from './ConfirmAdjustment';
import { CompareAndSelectLayout } from './CompareAndSelectLayout';
import { SameDayAchForm } from './SameDayAchForm';

export const Calculator = (): JSX.Element => {
  const { formState } = usePaymentScheduleContext();
  const [isSameDayPaymentDisabled, setIsSameDayPaymentDisabled] =
    useState<boolean>(false);

  if (formState.selectedAdjustment) {
    return <ConfirmAdjustment />;
  }

  const currentHour = new Date(
    new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York',
      hourCycle: 'h23',
    })
  ).getHours();

  const afterTwoPM = currentHour >= 14;

  const toggleSameDayPaymentBasedOnPaymentProvider = (
    provider: string
  ): void => {
    const isManualStripeProvider = provider === 'manual_stripe';

    setIsSameDayPaymentDisabled(isManualStripeProvider ? false : afterTwoPM);
  };

  return (
    <Tabs defaultValue="calculator">
      <Tabs.List>
        <Tabs.Trigger value="calculator">
          Payment Schedule Calculator
        </Tabs.Trigger>
        {featureFlags.same_day_ach_form && (
          <Tabs.Trigger value="same-day-ach">
            <Flex gap={2} alignItems="center">
              Same Day Payment
              {isSameDayPaymentDisabled && (
                <Icon name="warning" color="danger" />
              )}
            </Flex>
          </Tabs.Trigger>
        )}
      </Tabs.List>
      <Tabs.Content value="calculator">
        <Grid gutter>
          <Grid.Item xs={12} m={6}>
            <CalculatorForm />
          </Grid.Item>
          <Grid.Item xs={12} m={6}>
            <Box>
              <Subheading>Compare &amp; Select</Subheading>
              <CompareAndSelectLayout />
            </Box>
          </Grid.Item>
        </Grid>
      </Tabs.Content>
      <Tabs.Content value="same-day-ach">
        <Grid>
          <Grid.Item l={12} xl={9}>
            <SameDayAchForm
              isSameDayPaymentDisabled={isSameDayPaymentDisabled}
              onPaymentProviderChange={
                toggleSameDayPaymentBasedOnPaymentProvider
              }
            />
          </Grid.Item>
        </Grid>
      </Tabs.Content>
    </Tabs>
  );
};
