import { useEffect, useState } from 'react';

import { ExistingFinancing } from '../cashFlow.types';
import { useExistingFinancings } from '../cashFlowHooks';

const selectCurrentFinancings = (
  financings: ExistingFinancing[]
): ExistingFinancing[] =>
  financings.filter((item) => item.attributes.financingType === 'current');

type UseExistingFinancingsState = {
  existingFinancings: ExistingFinancing[];
  setExistingFinancings: React.Dispatch<
    React.SetStateAction<ExistingFinancing[]>
  >;
};

export const useExistingFinancingsState = (
  sheetId: string
): UseExistingFinancingsState => {
  const [existingFinancings, setExistingFinancings] = useState<
    ExistingFinancing[]
  >([]);

  const { data: existingFinancingsServerData } = useExistingFinancings(
    sheetId,
    selectCurrentFinancings
  );

  useEffect(() => {
    if (existingFinancingsServerData) {
      setExistingFinancings(existingFinancingsServerData);
    }
  }, [existingFinancingsServerData]);

  return { existingFinancings, setExistingFinancings };
};
