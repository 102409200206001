import {
  Flex,
  Subheading,
  Button,
  Sheet,
  Loading,
  Banner,
  IconButton,
  Combobox,
} from '@forward-financing/fast-forward';
import { noop } from 'lodash';
import { useMistakes } from '../cashFlowHooks';
import { getSelectedOption } from '../cashFlowUtils';
import {
  MISTAKES_BY_OPTIONS,
  SHEET_MISTAKES_TYPE_OPTIONS,
  PR_CL_FEEDBACK_OPTIONS,
} from './mistakesConstants';

type MistakesContainerProps = {
  sheetId: string;
  // We are going to use that prop when implementing the edit mode.
  // On the overview we will show readonly version.
  // eslint-disable-next-line react/no-unused-prop-types
  isReadonly: boolean;
};

export const MistakesContainer = ({
  sheetId,
}: MistakesContainerProps): JSX.Element => {
  const { data, isLoading, error } = useMistakes(sheetId);

  if (isLoading) {
    return <Loading size="medium" />;
  }

  return (
    <Flex flexDirection={'column'} gap={3}>
      <Flex alignItems={'center'} gap={3}>
        <Subheading>Mistakes</Subheading>

        <Button variant={'primary'} startIcon={'add'}>
          Add mistake
        </Button>
      </Flex>

      {error?.message && <Banner>{error.message}</Banner>}

      <Sheet>
        <Sheet.Head>
          <Sheet.ColumnHeader backgroundColor={'gray-100'}>
            <></>
          </Sheet.ColumnHeader>

          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth="large"
          >
            Mistake By
          </Sheet.ColumnHeader>

          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth="large"
          >
            Mistake Category
          </Sheet.ColumnHeader>

          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth="large"
          >
            Additional Note
          </Sheet.ColumnHeader>

          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth="large"
          >
            PR/CL Feedback
          </Sheet.ColumnHeader>
        </Sheet.Head>

        <Sheet.Body>
          {data?.map((mistake) => (
            <Sheet.Row key={mistake.id}>
              <Sheet.TextCell>
                <Flex justifyContent={'center'}>
                  <IconButton
                    hiddenLabel={true}
                    icon={'trash-can'}
                    label={'Delete mistake'}
                  />
                </Flex>
              </Sheet.TextCell>

              <Sheet.TextCell>
                <Flex px={1}>
                  <Combobox
                    label=""
                    isFixed={true}
                    onValueChange={noop}
                    disabled={true} //Disabled for now, for view only purposes
                    options={MISTAKES_BY_OPTIONS}
                    values={getSelectedOption(
                      MISTAKES_BY_OPTIONS,
                      mistake.attributes.createdBy
                    )}
                  />
                </Flex>
              </Sheet.TextCell>

              <Sheet.TextCell>
                <Flex px={1}>
                  <Combobox
                    label=""
                    isFixed={true}
                    onValueChange={noop}
                    disabled={true} //Disabled for now, for view only purposes
                    options={SHEET_MISTAKES_TYPE_OPTIONS}
                    values={getSelectedOption(
                      SHEET_MISTAKES_TYPE_OPTIONS,
                      mistake.attributes.mistakeType
                    )}
                  />
                </Flex>
              </Sheet.TextCell>

              <Sheet.TextCell value={mistake.attributes.note} />

              <Sheet.TextCell>
                <Flex px={1}>
                  <Combobox
                    label=""
                    isFixed={true}
                    onValueChange={noop}
                    disabled={true} //Disabled for now, for view only purposes
                    options={PR_CL_FEEDBACK_OPTIONS}
                    values={getSelectedOption(
                      PR_CL_FEEDBACK_OPTIONS,
                      mistake.attributes.feedback
                    )}
                  />
                </Flex>
              </Sheet.TextCell>
            </Sheet.Row>
          ))}
        </Sheet.Body>
      </Sheet>
    </Flex>
  );
};
