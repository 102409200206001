import { UseQueryOptions } from '@tanstack/react-query';
import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { AUTH_BASE_URL } from 'constants/globals';
import {
  AuthenticatedUserResponse,
  UserByRoleResponse,
} from 'types/api/auth/types';

// The happy path return value of this does not need tests. It already gets 100% coverage
// thanks to components/Submissions/SubmissionEditingHooks.test.ts.

// This enpoint accepts some sub role as well. In addition to fetch by role like processing, admin, etc,
// we are able to fetch by sub roles like prequal, team_lead, underwriter, processor.
export const fetchUsersByRole = async (
  role: string
): Promise<UserByRoleResponse[]> => {
  const url = new URL(`${AUTH_BASE_URL()}api/v1/roles/${role}/users`);

  const response = await makeInternalAPIRequest<UserByRoleResponse[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, `Failed to fetch ${role} users`);
  }

  return response.json();
};

export const fetchUsersByRoleQueryOptions = (
  role: string
): UseQueryOptions<UserByRoleResponse[]> => {
  return {
    queryKey: ['fetchUsersByRole', role],
    queryFn: () => fetchUsersByRole(role),
  };
};

export const fetchUser = async (
  id: string | number
): Promise<AuthenticatedUserResponse> => {
  const url = new URL(`${AUTH_BASE_URL()}api/v1/users/${id}`);

  const response = await makeInternalAPIRequest<AuthenticatedUserResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, `Failed to fetch User`);
  }

  return response.json();
};
