import {
  Flex,
  Grid,
  Icon,
  RadioGrid,
  RadioGridProps,
  Subheading,
  Text,
  TextArea,
  TextAreaProps,
} from '@forward-financing/fast-forward';
import { useEffect, useState } from 'react';
import { useDebounce } from 'ahooks';
import { featureFlags } from 'helpers/featureFlags';
import { UnderwriterScoreForm } from '../NewDealScoring.types';
import { useNewDealScoringContext } from '../NewDealScoringContext';

export const INITIAL_NOTES_TEXT = `Industry: 
Owner Risk: 
Business Risk: 
Cash Flow: 
Pricing Strategy Deviation Reason: `;

export const getAverageScore = (score: UnderwriterScoreForm): number => {
  const attributes = [
    score.businessRisk,
    score.ownerRisk,
    score.qualityOfCashFlows,
    score.dealSpecificFactors,
    score.onlinePresence,
  ];

  const scoredAttributes = attributes.filter(
    (n): n is number => n !== null && n !== 0
  );

  const sum = scoredAttributes.reduce((acc, num) => acc + num, 0);

  return scoredAttributes.length === 0 ? 0 : sum / scoredAttributes.length;
};

type UnderwriterScoringProps = {
  submissionUuid: string;
};

export const UnderwriterScoring = ({
  submissionUuid,
}: UnderwriterScoringProps): JSX.Element => {
  const {
    hasUnsavedUnderwriterChanges,
    isPartialSave,
    score,
    handleScoreUpdate,
    handleUnderwriterTextAreaChange,
    onSubmit,
  } = useNewDealScoringContext();

  const [noteContent, setNoteContent] = useState(score.underwriterNote);

  const questions: RadioGridProps['questions'] = [
    {
      name: 'ownerRisk',
      value: score.ownerRisk.toString(),
      label: 'Owner Risk',
    },
    {
      name: 'businessRisk',
      value: score.businessRisk.toString(),
      label: 'Business Risk',
    },
    {
      name: 'qualityOfCashFlows',
      value: score.qualityOfCashFlows.toString(),
      label: 'Quality of Cash Flows',
    },
  ];

  const handleRadioGridChange: RadioGridProps['onValueChange'] = (
    name,
    newValue
  ) => {
    handleScoreUpdate({
      ...score,
      [name]: Number(newValue),
    });
  };

  const handleTextAreaChange: TextAreaProps['onChange'] = (e) => {
    setNoteContent(e.target.value || INITIAL_NOTES_TEXT);
    handleUnderwriterTextAreaChange({
      ...score,
      underwriterNote: e.target.value || INITIAL_NOTES_TEXT,
    });
  };

  const isLegacyScore = !!(score.dealSpecificFactors || score.onlinePresence);

  const debouncedNoteContent = useDebounce(noteContent, {
    wait: 1000,
    leading: false,
    trailing: true,
  });

  useEffect(() => {
    const handleSaveNote = async (): Promise<void> => {
      if (featureFlags.use_debounced_autosaving_note) {
        await onSubmit();
      }
    };

    void handleSaveNote();

    // We only want to trigger this useEffect when
    // debouncedNoteContent gets updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedNoteContent]);

  return (
    <>
      <Grid gutter>
        <Grid.Item xs={12} s={5} m={7}>
          <Flex gap={2} alignItems="center">
            {isPartialSave && (
              <Icon
                data-testid="underwriter-score-partial-save-icon"
                color="warning"
                name="exclamation-triangle"
              />
            )}

            {hasUnsavedUnderwriterChanges && (
              <Icon
                data-testid="underwriter-score-unsaved-changes-icon"
                color="danger"
                name="exclamation-circle"
              />
            )}

            <Subheading>
              New Deal Score: {getAverageScore(score).toFixed(1)} / 5.0
            </Subheading>
          </Flex>

          <Grid gutter>
            <Grid.Item xl={7}>
              <Subheading variant="subsection">Select Scores</Subheading>
              <RadioGrid
                options={[
                  { value: '1' },
                  { value: '2' },
                  { value: '3' },
                  { value: '4' },
                  { value: '5' },
                ]}
                questions={questions}
                onValueChange={handleRadioGridChange}
              />
              {isLegacyScore && (
                <Text>
                  * This deal was scored based on a previous, now deprecated
                  version of the guidelines.
                </Text>
              )}
            </Grid.Item>
          </Grid>
        </Grid.Item>
        <Grid.Item xs={12} s={7} m={5}>
          <Subheading>Notes</Subheading>
          <TextArea
            label="Record Notes Below"
            rows={7}
            value={noteContent}
            onChange={handleTextAreaChange}
            onBlur={() => {
              hasUnsavedUnderwriterChanges && onSubmit();
            }}
          />
        </Grid.Item>
      </Grid>
    </>
  );
};
