import { SelectableOption } from '@forward-financing/fast-forward';

/**
 * Returns the selected option, or if not found it returns an empty array
 */
export const getSelectedOption = <T extends string>(
  optionsList: { value: T; text: string }[],
  value: T | undefined
): SelectableOption[] => {
  const item = optionsList.find((option) => option.value === value);

  return item ? [item] : [];
};
