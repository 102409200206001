import React, { MutableRefObject, useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Divider,
  Flex,
  formatDateString,
  Icon,
  IconButton,
  Loading,
  Tabs,
} from '@forward-financing/fast-forward';

import { usePreventNavigation } from 'hooks/usePreventNavigation.hook';
import { toError } from 'helpers/errorUtils';
import { useExceptionRequestContext } from 'components/SubmissionUnderwriting/ExceptionRequest/Context/ExceptionRequestContext';
import { NewDealScoreComposite } from '../DealScoring.types';
import { V6ScoreDisplay } from '../V6ScoreDisplay/V6ScoreDisplay';
import { Ledger } from '../Ledger/ledger.types';
import { LedgerContainer } from '../Ledger/LedgerContainer';
import { useCreateLedger } from '../Ledger/ledgerHooks';
import { Submission } from '../DealScoringContainer.types';
import { LedgerContextProvider } from '../Ledger/LedgerContext/LedgerContext';
import {
  isPriorStageUnderwriting,
  shouldSelectUWTabForNewDeals,
} from '../DealScoring.utils';
import {
  ManagerScoreForm,
  NewDealScore,
  ScoreForm,
  UnderwriterScoreForm,
} from './NewDealScoring.types';
import { NewDealScoringProvider } from './NewDealScoringContext';
import {
  getAverageScore,
  INITIAL_NOTES_TEXT,
} from './UnderwriterScoring/UnderwriterScoring';
import {
  createScore,
  fetchNewDealScore,
  updateScore,
} from './NewDealScoringFetchUtils';

export interface NewDealScoringContainerProps {
  children: React.ReactNode;
  compositeScore?: NewDealScoreComposite;
  ledgersData?: Ledger[];
  currentSubmissionUuid: string;
  shouldShowV6Score?: boolean;
  refetchLedgers: () => void;
  submission: Submission;
  setCardTitle: React.Dispatch<React.SetStateAction<string>>;
  currentTabValue?: string;
  setCurrentTabValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  defaultTabValue?: string;
  setDefaultTabValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  ledgerPrevLength: MutableRefObject<number | undefined>;
}

export const NewDealScoringContainer = ({
  children,
  compositeScore,
  submission,
  ledgersData,
  shouldShowV6Score,
  refetchLedgers,
  setCardTitle,
  currentSubmissionUuid,
  currentTabValue,
  setCurrentTabValue,
  defaultTabValue,
  setDefaultTabValue,
  ledgerPrevLength,
}: NewDealScoringContainerProps): JSX.Element => {
  const [scoreIsLoading, setScoreIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [createLedger, { loading, error: createLedgerError }] =
    useCreateLedger();

  const { isLedgerOpen, areAnyExceptionRequestsOpen } =
    useExceptionRequestContext();

  const handleAddNewLedger = async (): Promise<void> => {
    const response = await createLedger({
      submissionUuid: submission.uuid,
    });

    if (response.success) {
      refetchLedgers?.();
    }
  };

  const scoreTab = 'uwScore';
  const mostRecentLedgerId =
    ledgersData && ledgersData?.length > 0
      ? `${ledgersData?.at(0)?.id}-ledger`
      : scoreTab;

  const shouldMakeScoreTabDefault =
    isPriorStageUnderwriting(submission) ||
    shouldSelectUWTabForNewDeals(submission);

  useEffect(() => {
    if (isLedgerOpen) {
      setDefaultTabValue(mostRecentLedgerId);

      return;
    }

    const tabValue = shouldMakeScoreTabDefault ? scoreTab : mostRecentLedgerId;

    setDefaultTabValue(tabValue);
  }, [
    mostRecentLedgerId,
    scoreTab,
    setDefaultTabValue,
    shouldMakeScoreTabDefault,
    isLedgerOpen,
  ]);

  // ledgers are being sorted descending in the hook
  useEffect(() => {
    if (
      ledgersData &&
      ledgersData.length > 0 &&
      !shouldMakeScoreTabDefault &&
      ledgerPrevLength.current &&
      ledgersData?.length !== ledgerPrevLength.current
    ) {
      ledgerPrevLength.current = ledgersData?.length;
      setCurrentTabValue(`${ledgersData?.at(0)?.id}-ledger`);
    }
  }, [
    ledgersData,
    setCurrentTabValue,
    shouldMakeScoreTabDefault,
    ledgerPrevLength,
  ]);

  const [hasUnsavedUnderwriterChanges, setHasUnsavedUnderwriterChanges] =
    useState(false);
  const [hasUnsavedManagerChanges, setHasUnsavedManagerChanges] =
    useState(false);
  const [isUnscored, setIsUnscored] = useState(true);

  const [response, setResponse] = useState<NewDealScore | null>(null);
  const [score, setScore] = useState<ScoreForm>({
    ownerRisk: 0,
    businessRisk: 0,
    qualityOfCashFlows: 0,
    onlinePresence: null,
    dealSpecificFactors: null,
    underwriterNote: '',
    pricingRating: 0,
    processRating: 0,
    managerNote: '',
    checklistResponses: [],
  });
  const [isAutosaving, setIsAutosaving] = useState(false);

  useEffect(() => {
    // This is to ensure the card title isn't changed when users change tabs to another submission
    currentSubmissionUuid === submission.uuid &&
      setCardTitle(`UW Decision | ${getAverageScore(score).toFixed(1)}`);
  }, [score, setCardTitle, currentSubmissionUuid, submission.uuid]);

  usePreventNavigation(
    hasUnsavedUnderwriterChanges || hasUnsavedManagerChanges
  );

  useEffect(() => {
    const fetchScore = async (): Promise<void> => {
      try {
        const newDealScoreResponse = await fetchNewDealScore(submission.uuid);

        setResponse(newDealScoreResponse);
        resetForm(newDealScoreResponse);
        setIsUnscored(newDealScoreResponse.lastUpdated === null);
      } catch (e: unknown) {
        const error = toError(e);
        setErrorMessage(error.message);
      } finally {
        setScoreIsLoading(false);
      }
    };

    void fetchScore();
  }, [submission.uuid]);

  const handleScoreChange = (newScore: ScoreForm): void => {
    setScore({
      ...score,
      ...newScore,
    });
    // null out the error message on any change
    setErrorMessage(null);
  };

  const handleScoreUpdate = async (inputScore: ScoreForm): Promise<void> => {
    setIsAutosaving(true);
    try {
      const newScore = await (isUnscored
        ? createScore(submission.uuid, { ...score, ...inputScore })
        : updateScore(submission.uuid, { ...score, ...inputScore }));

      resetForm(newScore);
      setResponse(newScore);
      setIsUnscored(false);
    } catch (e: unknown) {
      const error = toError(e);
      setErrorMessage(error.message);
    }
    setIsAutosaving(false);
  };

  const handleUnderwriterTextAreaChange = (
    newScore: UnderwriterScoreForm
  ): void => {
    handleScoreChange({
      ...score,
      ...newScore,
    });
    setHasUnsavedUnderwriterChanges(true);
  };

  const handleManagerTextAreaChange = (newScore: ManagerScoreForm): void => {
    handleScoreChange({
      ...score,
      ...newScore,
    });
    setHasUnsavedManagerChanges(true);
  };

  // We have to pass the response here because we're using it in
  // the .then above, which is called before `response` is set in state
  const resetForm = (responseData: NewDealScore): void => {
    setScore({
      ownerRisk: responseData.ownerRisk || 0,
      businessRisk: responseData.businessRisk || 0,
      qualityOfCashFlows: responseData.qualityOfCashFlows || 0,
      onlinePresence: responseData.onlinePresence,
      dealSpecificFactors: responseData.dealSpecificFactors,
      underwriterNote: responseData.underwriterNote || INITIAL_NOTES_TEXT,
      pricingRating: responseData.pricingRating || 0,
      processRating: responseData.processRating || 0,
      managerNote: responseData.managerNote || '',
      checklistResponses: responseData.checklistResponses || [],
    });

    setHasUnsavedUnderwriterChanges(false);
    setHasUnsavedManagerChanges(false);
    setErrorMessage(null);
  };

  const onSubmit = async (): Promise<void> => {
    setIsAutosaving(true);
    try {
      const newScore = await (isUnscored
        ? createScore(submission.uuid, score)
        : updateScore(submission.uuid, score));

      resetForm(newScore);
      setResponse(newScore);
      setIsUnscored(false);
    } catch (e: unknown) {
      const error = toError(e);
      setErrorMessage(error.message);
    }
    setIsAutosaving(false);
  };

  const underwriterScoreAttributes = [
    score.ownerRisk,
    score.businessRisk,
    score.qualityOfCashFlows,
  ];

  const isPartialSave =
    !isUnscored &&
    !hasUnsavedUnderwriterChanges &&
    underwriterScoreAttributes.some((scoreValue) => scoreValue === 0);

  if (scoreIsLoading) {
    return <Loading />;
  }

  const isSubmissionInUnderwritingStage =
    submission && submission.stage === 'Underwriting';

  const shouldDisableCreateLedger =
    loading || !isSubmissionInUnderwritingStage || areAnyExceptionRequestsOpen;

  return (
    <NewDealScoringProvider
      value={{
        response,
        score,
        handleScoreUpdate,
        handleUnderwriterTextAreaChange,
        handleManagerTextAreaChange,
        isUnscored,
        isPartialSave,
        hasUnsavedUnderwriterChanges,
        hasUnsavedManagerChanges,
        resetForm,
        onSubmit,
        isAutosaving,
      }}
    >
      <Box m={3}>
        {errorMessage && <Banner dismissable={false}>{errorMessage}</Banner>}
        {createLedgerError && (
          <Banner dismissable={false}>{createLedgerError.message}</Banner>
        )}
        <Tabs
          defaultValue={defaultTabValue}
          value={currentTabValue}
          onValueChange={(value) => {
            setCurrentTabValue(value);
          }}
        >
          <Tabs.List maximumDisplayTabs={6} modalTitle="Ledgers">
            <Tabs.Trigger value="uwScore">
              <Flex gap={2} alignItems="center">
                UW Score
              </Flex>
            </Tabs.Trigger>

            {shouldShowV6Score && (
              <Tabs.Trigger value="v6Score">V6 Score</Tabs.Trigger>
            )}

            <IconButton
              icon={'plus-circle'}
              label="New Ledger"
              disabled={shouldDisableCreateLedger}
              onClick={handleAddNewLedger}
            />

            {ledgersData &&
              ledgersData.map((ledger, index) => (
                <Tabs.Trigger
                  value={`${ledger.id}-ledger`}
                  key={ledger.id.toString()}
                >
                  {formatDateString(ledger.createdAt)} Ledger #
                  {ledgersData.length - index}{' '}
                  {ledger.lock && (
                    <>
                      <Divider orientation="vertical" margin={2} />{' '}
                      <Icon name="lock" />
                    </>
                  )}
                </Tabs.Trigger>
              ))}
          </Tabs.List>

          {shouldShowV6Score && (
            <Tabs.Content value="v6Score">
              <V6ScoreDisplay submissionUuid={submission.uuid} />
            </Tabs.Content>
          )}

          <Tabs.Content value="uwScore">{children}</Tabs.Content>

          {ledgersData &&
            ledgersData.map((ledger) => (
              <Tabs.Content
                value={`${ledger.id}-ledger`}
                key={ledger.id.toString()}
              >
                <LedgerContextProvider
                  key={ledger.id.toString()}
                  ledger={ledger}
                  submissionType={submission.type}
                  shouldShowBanners={!!currentTabValue?.includes('ledger')}
                  submissionUuid={submission.uuid}
                  refetchLedgers={refetchLedgers}
                >
                  <LedgerContainer
                    submission={submission}
                    currentSubmissionUuid={currentSubmissionUuid}
                    refetchLedgers={refetchLedgers}
                    uwNotes={response?.underwriterNote || undefined}
                  />
                </LedgerContextProvider>
              </Tabs.Content>
            ))}
        </Tabs>
      </Box>
    </NewDealScoringProvider>
  );
};
