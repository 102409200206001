import {
  Flex,
  formatCurrency,
  Sheet,
  Text,
} from '@forward-financing/fast-forward';
import { displayPercentage } from 'helpers/utils';
import { ExistingFinancing } from '../../cashFlow.types';

type CashFlowExistingFinancingTotalRowProps = {
  existingFinancings?: ExistingFinancing[];
  getMonthlyPaymentValue: (amount: number, frequency: string) => number;
  getGrossPercentValue: (monthlyPayment: number) => number;
};
export const ExistingFinancingTotalRow = ({
  existingFinancings,
  getMonthlyPaymentValue,
  getGrossPercentValue,
}: CashFlowExistingFinancingTotalRowProps): JSX.Element => {
  const getTotalValueByKey = (key: string): number => {
    let total = 0;

    existingFinancings?.forEach((item) => {
      const attributes = item.attributes;
      if (key === 'monthlyPayment') {
        total += getMonthlyPaymentValue(
          attributes.dailyAmountCents / 100,
          attributes.frequency
        );
      } else if (key === 'grossPercentage') {
        total += getGrossPercentValue(
          getMonthlyPaymentValue(
            attributes.dailyAmountCents / 100,
            attributes.frequency
          )
        );
      }
    });

    return total;
  };

  return (
    <Sheet.Row backgroundColor={'gray-800'}>
      <Sheet.TextCell colSpan={1} />
      <Sheet.TextCell colSpan={1} />
      <Sheet.TextCell colSpan={1} />
      <Sheet.TextCell>
        <Flex justifyContent={'end'} px={2}>
          <Text color={'white'} bold={true}>
            Totals
          </Text>
        </Flex>
      </Sheet.TextCell>
      <Sheet.TextCell>
        <Flex justifyContent={'end'} px={2}>
          <Text color={'white'}>
            {formatCurrency(getTotalValueByKey('monthlyPayment'))}
          </Text>
        </Flex>
      </Sheet.TextCell>
      <Sheet.TextCell>
        <Flex justifyContent={'end'} px={2}>
          <Text color={'white'}>
            {displayPercentage(getTotalValueByKey('grossPercentage'))}
          </Text>
        </Flex>
      </Sheet.TextCell>
      <Sheet.TextCell colSpan={1} />
      <Sheet.TextCell colSpan={1} />
      <Sheet.TextCell colSpan={1} />
      <Sheet.TextCell colSpan={1} />
      <Sheet.TextCell colSpan={1} />
      <Sheet.TextCell colSpan={1} />
      <Sheet.TextCell colSpan={1} />
    </Sheet.Row>
  );
};
