import {
  MutationResponse,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { DeclineDriversResponse } from 'types/api/underwriting/types';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { useApiUpdateApplication } from 'apiHooks/waitingRoom/applicationFetchHooks';
import { ApplicationResponseOnboardingStatus } from 'types/api/waitingRoom/types';
import { useGetApiDeclineDrivers } from 'apiHooks/underwriting/declineDriversFetchHooks';
import { IsoResponse } from 'types/api/funding/types';
import { useGetApiIso } from 'apiHooks/funding/isoFetchHooks';

export type UpdateApplicationBody = {
  applicationUuid: string;
  onboardingAnalystUuid?: string;
  onBoardingStatus?: ApplicationResponseOnboardingStatus;
  submissionUuid?: string;
};

type UseUpdateApplicationResult = [
  (input: UpdateApplicationBody) => Promise<MutationResponse>,
  { error?: Error; loading: boolean }
];

export const useUpdateApplication = (): UseUpdateApplicationResult => {
  const [updateApplication, { error, loading }] = useApiUpdateApplication();

  const updateFunction = (
    args: UpdateApplicationBody
  ): Promise<MutationResponse> => {
    return updateApplication({
      applicationUuid: args.applicationUuid,
      updateBody: {
        onboarding_analyst_uuid: args.onboardingAnalystUuid,
        onboarding_status: args.onBoardingStatus,
        uw_application_uuid: args.applicationUuid,
        submission_uuid: args.submissionUuid,
      },
      source: 'underwriting',
    });
  };

  return [
    updateFunction,
    {
      error,
      loading,
    },
  ];
};

const toDeclineDriversArray = (
  declineDrivers?: DeclineDriversResponse
): string[] => {
  if (!declineDrivers) {
    return [];
  }

  return declineDrivers?.data.map((declineDriver) => declineDriver.value);
};

export const useDeclineDrivers = (): UseGenericQueryResponse<string[]> =>
  useGenericFeatureQuery(useGetApiDeclineDrivers, toDeclineDriversArray);

export type IndependentSalesOrganization = {
  salesRepRule: string;
};

const toIso = (iso?: IsoResponse): IndependentSalesOrganization | undefined => {
  if (!iso) {
    return undefined;
  }

  return {
    salesRepRule: iso.partner.sales_rep_rule,
  };
};

export const useGetIso = (
  isoUuid?: string
): UseGenericQueryResponse<IndependentSalesOrganization> => {
  return useGenericFeatureQuery(useGetApiIso, toIso, isoUuid);
};
