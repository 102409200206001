import React, { MutableRefObject, useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Divider,
  formatDateString,
  Icon,
  IconButton,
  Loading,
  Tabs,
} from '@forward-financing/fast-forward';
import { usePreventNavigation } from 'hooks/usePreventNavigation.hook';
import { toError } from 'helpers/errorUtils';
import { useExceptionRequestContext } from 'components/SubmissionUnderwriting/ExceptionRequest/Context/ExceptionRequestContext';
import { useScoringComposite } from '../DealScoringFetchHooks';
import { RenewalScoreComposite } from '../DealScoring.types';
import { V6ScoreDisplay } from '../V6ScoreDisplay/V6ScoreDisplay';
import { Ledger } from '../Ledger/ledger.types';
import { Submission } from '../DealScoringContainer.types';
import { useCreateLedger } from '../Ledger/ledgerHooks';
import { LedgerContainer } from '../Ledger/LedgerContainer';
import { LedgerContextProvider } from '../Ledger/LedgerContext/LedgerContext';
import {
  isPriorStageUnderwriting,
  shouldSelectRenewalTabForRenewals,
} from '../DealScoring.utils';
import { PastAdvances } from './PastAdvances/PastAdvances';
import { fetchRenewalScores } from './RenewalScoringFetchUtils';
import { RenewalScoresData } from './RenewalScoring.types';
import { RenewalScoringComposite } from './RenewalScoringComposite';
import {
  useRefreshRenewalCompositeScore,
  useSubmissionFundedDate,
} from './RenewalScoringFetchHooks';
import { compositeScoreToText } from './renewalScoringUtils';

export type RenewalScoringContainerProps = {
  previousFundedSubmissionUuid?: string;
  compositeScore?: RenewalScoreComposite;
  submission: Submission;
  shouldShowV6Score?: boolean;
  refetchLedgers: () => void;
  ledgersData?: Ledger[];
  currentSubmissionUuid: string;
  setCardTitle: React.Dispatch<React.SetStateAction<string>>;
  currentTabValue?: string;
  setCurrentTabValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  defaultTabValue?: string;
  setDefaultTabValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  ledgerPrevLength: MutableRefObject<number | undefined>;
};

export const RenewalScoringContainer = ({
  previousFundedSubmissionUuid,
  compositeScore,
  shouldShowV6Score,
  refetchLedgers,
  submission,
  ledgersData,
  setCardTitle,
  currentSubmissionUuid,
  currentTabValue,
  setCurrentTabValue,
  defaultTabValue,
  setDefaultTabValue,
  ledgerPrevLength,
}: RenewalScoringContainerProps): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [response, setResponse] = useState<RenewalScoresData | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [createLedger, { error: createLedgerError }] = useCreateLedger();

  const { areAnyExceptionRequestsOpen, isLedgerOpen } =
    useExceptionRequestContext();

  const handleAddNewLedger = async (): Promise<void> => {
    const createLedgerResponse = await createLedger({
      submissionUuid: submission.uuid,
    });

    if (createLedgerResponse.success) {
      refetchLedgers?.();
    }
  };

  usePreventNavigation(hasUnsavedChanges);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const scoreResponse = await fetchRenewalScores(submission.uuid);
        setResponse(scoreResponse);
        resetForm();
      } catch (e: unknown) {
        const error = toError(e);
        setErrorMessage(`Failed to load Renewal Scores: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    void fetchData();
  }, [submission.uuid]);

  // Fetches the previous composite score for Past Advances tab.
  const { data: previousScoreComposite } = useScoringComposite(
    previousFundedSubmissionUuid
  );

  // Fetches the previous funded submission's funded date.
  const { data: previousFundedDate } = useSubmissionFundedDate(
    previousFundedSubmissionUuid
  );

  // Refetches only when the user clicks the refresh button.
  const [
    refreshCompositeScore,
    {
      renewalScoreCompositeRefreshed,
      error: errorRefreshRenewalCompositeScore,
      loading: loadingRefreshRenewalCompositeScore,
    },
  ] = useRefreshRenewalCompositeScore(submission.uuid);

  const scoreData =
    renewalScoreCompositeRefreshed || compositeScore || undefined;

  const resetForm = (): void => {
    setHasUnsavedChanges(false);
    setErrorMessage(null);
  };

  const uwScoreText =
    scoreData?.compositeScore?.score && scoreData?.compositeScore?.tier
      ? `${scoreData.compositeScore.score.toFixed(1)} / Tier ${
          scoreData.compositeScore.tier
        }`
      : '';

  const scoreTab = 'renewalCompositeScore';

  const mostRecentLedgerId =
    ledgersData && ledgersData?.length > 0
      ? `${ledgersData?.at(0)?.id}-ledger`
      : scoreTab;

  const shouldMakeScoreTabDefault =
    isPriorStageUnderwriting(submission) ||
    shouldSelectRenewalTabForRenewals(submission);

  useEffect(() => {
    if (isLedgerOpen) {
      setDefaultTabValue(mostRecentLedgerId);

      return;
    }

    const tabValue = shouldMakeScoreTabDefault ? scoreTab : mostRecentLedgerId;

    setDefaultTabValue(tabValue);
  }, [
    mostRecentLedgerId,
    scoreTab,
    setDefaultTabValue,
    shouldMakeScoreTabDefault,
    isLedgerOpen,
  ]);

  // ledgers are being sorted descending in the hook
  useEffect(() => {
    if (
      ledgersData &&
      ledgersData.length > 0 &&
      !shouldMakeScoreTabDefault &&
      ledgerPrevLength.current &&
      ledgersData?.length !== ledgerPrevLength.current
    ) {
      ledgerPrevLength.current = ledgersData?.length;
      setCurrentTabValue(`${ledgersData?.at(0)?.id}-ledger`);
    }
  }, [
    ledgersData,
    setCurrentTabValue,
    shouldMakeScoreTabDefault,
    ledgerPrevLength,
  ]);

  useEffect(() => {
    currentSubmissionUuid === submission.uuid &&
      scoreData &&
      setCardTitle(
        `UW Decision: ${compositeScoreToText(scoreData?.compositeScore)}`
      );
  }, [
    scoreData,
    setCardTitle,
    uwScoreText,
    currentSubmissionUuid,
    submission.uuid,
  ]);

  const isSubmissionInUnderwritingStage =
    submission && submission.stage === 'Underwriting';

  const shouldDisableCreateLedger =
    loading || !isSubmissionInUnderwritingStage || areAnyExceptionRequestsOpen;

  if (loading) {
    return <Loading />;
  }

  return (
    <Box m={3}>
      {errorMessage && <Banner dismissable={false}>{errorMessage}</Banner>}
      {createLedgerError && (
        <Banner dismissable={false}>Failed to create a ledger</Banner>
      )}

      {
        <Tabs
          defaultValue={defaultTabValue}
          value={currentTabValue}
          onValueChange={(value) => setCurrentTabValue(value)}
        >
          <Tabs.List maximumDisplayTabs={6} modalTitle="Ledgers">
            <Tabs.Trigger value="renewalCompositeScore">
              Renewal Composite Score
            </Tabs.Trigger>

            {shouldShowV6Score && (
              <Tabs.Trigger value="v6Score">V6 Score</Tabs.Trigger>
            )}

            <Tabs.Trigger value="pastAdvances">Past Advance(s)</Tabs.Trigger>

            <IconButton
              icon={'plus-circle'}
              label="New Ledger"
              disabled={shouldDisableCreateLedger}
              onClick={handleAddNewLedger}
            />

            {ledgersData &&
              ledgersData.map((ledger, index) => (
                <Tabs.Trigger
                  value={`${ledger.id}-ledger`}
                  key={ledger.id.toString()}
                >
                  {formatDateString(ledger.createdAt)} Ledger #{index + 1}
                  {ledger.lock && (
                    <>
                      <Divider orientation="vertical" margin={2} />{' '}
                      <Icon name="lock" />
                    </>
                  )}
                </Tabs.Trigger>
              ))}
          </Tabs.List>

          {shouldShowV6Score && (
            <Tabs.Content value="v6Score">
              <V6ScoreDisplay submissionUuid={submission.uuid} />
            </Tabs.Content>
          )}

          <Tabs.Content value="pastAdvances">
            {!response && <Banner> There is no data to show</Banner>}
            {response && (
              <PastAdvances
                originalDealScore={response?.originalDealScore}
                previousRenewalScores={response?.previousRenewalScores}
                previousCompositeScore={
                  previousScoreComposite?.type === 'Renewal'
                    ? previousScoreComposite
                    : undefined
                }
                previousFundedDate={previousFundedDate}
              />
            )}
          </Tabs.Content>

          <Tabs.Content value="renewalCompositeScore">
            <RenewalScoringComposite
              submissionUuid={submission.uuid}
              scoreData={scoreData}
              refreshScoreData={refreshCompositeScore}
              loading={loadingRefreshRenewalCompositeScore}
              errorRefresh={errorRefreshRenewalCompositeScore}
            />
          </Tabs.Content>

          {ledgersData &&
            ledgersData.map((ledger) => (
              <Tabs.Content
                value={`${ledger.id}-ledger`}
                key={ledger.id.toString()}
              >
                <LedgerContextProvider
                  key={ledger.id.toString()}
                  ledger={ledger}
                  submissionType={submission.type}
                  shouldShowBanners={!!currentTabValue?.includes('ledger')}
                  submissionUuid={submission.uuid}
                  refetchLedgers={refetchLedgers}
                >
                  <LedgerContainer
                    submission={submission}
                    currentSubmissionUuid={currentSubmissionUuid}
                    refetchLedgers={refetchLedgers}
                    isRenewal
                  />
                </LedgerContextProvider>
              </Tabs.Content>
            ))}
        </Tabs>
      }
    </Box>
  );
};
