import React from 'react';
import { Banner, Box, PageContainer } from '@forward-financing/fast-forward';
import { PaymentsIndex } from './PaymentsIndex';

const necessaryParams = ['status', 'date', 'provider', 'sameDay'] as const;
export const PaymentsIndexContainer = (): JSX.Element => {
  const urlSearch = new URLSearchParams(window.location.search);
  const entries = necessaryParams.map((param) => [param, urlSearch.get(param)]);
  const missingParams = entries.filter(([_, v]) => v === null).map(([k]) => k);

  const errors: string[] = [];

  if (missingParams.length) {
    errors.push(
      `The following URL parameters are missing: ${missingParams.join(', ')}.`
    );
  }
  const { status, date, provider, sameDay } = Object.fromEntries(entries) as {
    [s in typeof necessaryParams[number]]: string | null;
  };

  if (date !== null && isNaN(new Date(date).getTime())) {
    errors.push('The date provided is invalid.');
  }

  return (
    <PageContainer>
      <Box paddingY={3}>
        {status === null ||
        date === null ||
        provider === null ||
        errors.length ? (
          <Banner dismissable={false}>
            Something went wrong accessing the payment index page.{' '}
            {errors.join(' ')} Try returning to the payments dashboard.
          </Banner>
        ) : (
          <PaymentsIndex
            status={status}
            date={date}
            provider={provider}
            sameDay={sameDay === 'true'}
          />
        )}
      </Box>
    </PageContainer>
  );
};
