import { Box } from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import { LastUpdatedMessage } from '../LastUpdatedMessage';
import { useNewDealScoringContext } from './NewDealScoringContext';
import { NewDealScoringReadOnly } from './NewDealScoringReadOnly';
import { NewDealScoringEditor } from './NewDealScoringEditor';

type NewDealScoringProps = {
  submissionUuid: string;
};

export const NewDealScoring = ({
  submissionUuid,
}: NewDealScoringProps): JSX.Element => {
  const { role: userRole } = useUserContext();
  const { response } = useNewDealScoringContext();

  return (
    <>
      {response && (
        <Box>
          {userRole === 'admin' ? (
            <NewDealScoringEditor submissionUuid={submissionUuid} />
          ) : (
            <NewDealScoringReadOnly />
          )}
          {response.lastUpdated && (
            <LastUpdatedMessage
              time={response.lastUpdated}
              name={response.lastUpdatedBy ?? 'Unknown User'}
            />
          )}
        </Box>
      )}
    </>
  );
};
