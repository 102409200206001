import { useEffect, useState } from 'react';
import {
  Banner,
  Card,
  Divider,
  formatCurrency,
  formatDateString,
  Icon,
  Loading,
  PageContainer,
  StickyContainer,
  Tabs,
  Tag,
} from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';
import { useExceptionRequestContext } from '../SubmissionUnderwriting/ExceptionRequest/Context/ExceptionRequestContext';
import {
  useCustomerSubmissions,
  useRenewalComparisonSubmission,
  useScoringComposite,
  useSubmission,
} from './DealScoringFetchHooks';
import { DealScoringContainer } from './DealScoringContainer';

export interface DealScoringCardContainerProps {
  submissionUuid: string;
  customerUuid?: string;
}

export const DealScoringCardContainer = ({
  submissionUuid,
  customerUuid,
}: DealScoringCardContainerProps): JSX.Element => {
  const { isLedgerOpen } = useExceptionRequestContext();

  const cardTitleText = 'UW Decision';
  const [cardTitle, setCardTitle] = useState(cardTitleText);

  const [currentTabValue, setCurrentTabValue] = useState<string | undefined>();
  const [defaultTabValue, setDefaultTabValue] = useState<string | undefined>();

  const [activeTabSubmission, setActiveTabSubmission] =
    useState<string>(submissionUuid);

  const {
    data: customerSubmissions,
    error: customerSubmissionsError,
    loading: customerSubmissionLoading,
  } = useCustomerSubmissions(customerUuid);

  const { data: submission, error: submissionError } =
    useSubmission(submissionUuid);

  const { data: renewalComparison, error: renewalComparisonError } =
    useRenewalComparisonSubmission(
      submission?.previousFundedSubmissionUuidFromCustomer
    );

  const sortedCustomerSubmissions = customerSubmissions?.sort((a, b) => {
    if (a.dateSubReceived && b.dateSubReceived) {
      return b.dateSubReceived.localeCompare(a.dateSubReceived);
    }
    return 0;
  });

  const { data: compositeScore } = useScoringComposite(submissionUuid);

  const compositeScoreText =
    compositeScore &&
    compositeScore.compositeScore?.score &&
    compositeScore.compositeScore.score;
  const compositeScoreTierText =
    compositeScore &&
    compositeScore.compositeScore?.tier &&
    compositeScore.compositeScore.tier;

  // Setting card title in this level because UW want to see the score before having to open the card
  useEffect(() => {
    if (
      compositeScore?.type === 'Renewal' &&
      compositeScoreText &&
      compositeScoreTierText
    ) {
      setCardTitle(
        `${cardTitleText} ${compositeScoreText.toFixed(
          1
        )} / Tier ${compositeScoreTierText}`
      );
    }
  }, [
    compositeScore,
    compositeScoreText,
    compositeScoreTierText,
    cardTitleText,
  ]);

  const isLedgerSelected = currentTabValue
    ? currentTabValue.includes('ledger')
    : defaultTabValue?.includes('ledger');

  const percentRepaidTagColor = (
    percentageRepaid: number
  ): 'success' | 'caution' | 'danger' => {
    if (percentageRepaid >= 60) {
      return 'success';
    } else if (percentageRepaid >= 50) {
      return 'caution';
    } else {
      return 'danger';
    }
  };

  const shouldShowRenewalComparisonTags =
    featureFlags.previous_funded_data_in_uw_decision && renewalComparison;

  return (
    <StickyContainer stickTo="bottom">
      <PageContainer>
        <Card
          collapsible
          title={cardTitle}
          initialOpenState={false}
          showFullScreenButton={isLedgerSelected}
          showHalfScreen={isLedgerSelected}
          showFullScreen={isLedgerSelected}
          isCardOpen={isLedgerOpen}
          actions={
            shouldShowRenewalComparisonTags && (
              <>
                <Tag
                  bgColor={percentRepaidTagColor(
                    +renewalComparison.percentageRepaid
                  )}
                >{`Balance outstanding: ${formatCurrency(
                  +renewalComparison.balance
                )}`}</Tag>
                <Tag
                  bgColor={percentRepaidTagColor(
                    +renewalComparison.percentageRepaid
                  )}
                >{`${(+renewalComparison.percentageRepaid).toFixed(
                  2
                )}% repaid`}</Tag>
              </>
            )
          }
        >
          <>
            {customerSubmissionsError && (
              <Banner dismissable={false}>
                {customerSubmissionsError.message}
              </Banner>
            )}

            {submissionError && (
              <Banner dismissable={false}>{submissionError.message}</Banner>
            )}

            {renewalComparisonError && (
              <Banner dismissable={false}>
                {renewalComparisonError.message}
              </Banner>
            )}

            {customerSubmissions?.length === 0 && (
              <Banner dismissable={false}>
                There are no submissions tied to this customer.
              </Banner>
            )}

            {customerSubmissionLoading && <Loading />}
            {customerSubmissions && (
              <Tabs
                defaultValue={submissionUuid}
                value={activeTabSubmission}
                onValueChange={(value) => setActiveTabSubmission(value)}
              >
                <Tabs.List maximumDisplayTabs={5} modalTitle="Submissions">
                  {sortedCustomerSubmissions?.map((sub, index) => (
                    <Tabs.Trigger value={sub.uuid} key={sub.uuid}>
                      {sub.uuid === submissionUuid && (
                        <>
                          <Icon name="star" />
                          <Divider orientation="vertical" margin={2} />
                        </>
                      )}
                      Submission {index + 1} -{' '}
                      {formatDateString(sub.dateSubReceived)}{' '}
                      {sub.type === 'Renewal' && (
                        <>
                          <Divider orientation="vertical" margin={2} />{' '}
                          <Icon name="repeat" />
                        </>
                      )}
                    </Tabs.Trigger>
                  ))}
                </Tabs.List>

                {sortedCustomerSubmissions?.map((sub) => {
                  return (
                    <Tabs.Content value={sub.uuid} key={sub.uuid}>
                      <DealScoringContainer
                        submissionUuid={sub.uuid}
                        currentSubmissionUuid={submissionUuid}
                        setCardTitle={setCardTitle}
                        currentTabValue={currentTabValue}
                        setCurrentTabValue={setCurrentTabValue}
                        defaultTabValue={defaultTabValue}
                        setDefaultTabValue={setDefaultTabValue}
                      />
                    </Tabs.Content>
                  );
                })}
              </Tabs>
            )}
          </>
        </Card>
      </PageContainer>
    </StickyContainer>
  );
};
