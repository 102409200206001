import {
  Box,
  DescriptionList,
  formatCurrency,
  Grid,
  Text,
} from '@forward-financing/fast-forward';
import { AcceptedOffer } from './types';

type DealTermsProps = {
  acceptedOffer: AcceptedOffer;
};

const formatTerm = (
  value?: number,
  formatFunc = (n: number) => String(n)
): string => {
  if (value === undefined) return 'Invalid';
  return formatFunc(value);
};

export const DealTerms = ({
  acceptedOffer: {
    purchasePrice,
    factorRate,
    upsell,
    totalFactorRate,
    term,
    dailyPayment,
    amountSold,
    commission,
    defaultProcessingFee,
    processingFee,
    holdbackPercent,
  },
}: DealTermsProps): JSX.Element => {
  return (
    <Grid>
      <Grid.Item xs={4}>
        <Box paddingRight={12} marginRight={12}>
          <DescriptionList divider="normal" variant="space-between">
            <DescriptionList.Item>
              <DescriptionList.Term>Purchase Price</DescriptionList.Term>
              <DescriptionList.Details>
                <Text bold>{formatTerm(purchasePrice, formatCurrency)}</Text>
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>Factor Rate</DescriptionList.Term>
              <DescriptionList.Details>
                <Text bold>{formatTerm(factorRate)}</Text>
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>Upsell</DescriptionList.Term>
              <DescriptionList.Details>
                <Text bold>{formatTerm(upsell)}</Text>
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>Total Factor Rate</DescriptionList.Term>
              <DescriptionList.Details>
                <Text bold>{formatTerm(totalFactorRate)}</Text>
              </DescriptionList.Details>
            </DescriptionList.Item>
          </DescriptionList>
        </Box>
      </Grid.Item>

      <Grid.Item xs={4}>
        <Box paddingX={12}>
          <DescriptionList divider="normal" variant="space-between">
            <DescriptionList.Item>
              <DescriptionList.Term>Est. # of Payments</DescriptionList.Term>
              <DescriptionList.Details>
                <Text bold>{formatTerm(term)}</Text>
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>Daily Payment</DescriptionList.Term>
              <DescriptionList.Details>
                <Text bold>{formatTerm(dailyPayment, formatCurrency)}</Text>
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>Amount Sold</DescriptionList.Term>
              <DescriptionList.Details>
                <Text bold>{formatTerm(amountSold, formatCurrency)}</Text>
              </DescriptionList.Details>
            </DescriptionList.Item>
          </DescriptionList>
        </Box>
      </Grid.Item>

      <Grid.Item xs={4}>
        <Box paddingLeft={12} marginLeft={12}>
          <DescriptionList divider="normal" variant="space-between">
            <DescriptionList.Item>
              <DescriptionList.Term>Commission ($)</DescriptionList.Term>
              <DescriptionList.Details>
                <Text bold>{formatTerm(commission, formatCurrency)}</Text>
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>
                Default Processing Fee
              </DescriptionList.Term>
              <DescriptionList.Details>
                <Text bold>
                  {formatTerm(defaultProcessingFee, formatCurrency)}
                </Text>
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>Actual Processing Fee</DescriptionList.Term>
              <DescriptionList.Details>
                <Text bold>{formatTerm(processingFee, formatCurrency)}</Text>
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>Holdback Percent</DescriptionList.Term>
              <DescriptionList.Details>
                <Text bold>{formatTerm(holdbackPercent, (n) => `${n}%`)}</Text>
              </DescriptionList.Details>
            </DescriptionList.Item>
          </DescriptionList>
        </Box>
      </Grid.Item>
    </Grid>
  );
};
