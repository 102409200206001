import {
  Banner,
  Divider,
  Flex,
  Loading,
} from '@forward-financing/fast-forward';
import { upperFirst } from 'lodash';
import { PreviousFinancingContainer } from './PreviousFinancing/PreviousFinancingContainer';
import { useExistingFinancings } from './cashFlowHooks';
import { ExistingFinancingContainer } from './ExistingFinancing/ExistingFinancingContainer';

type FinancingContainerProps = {
  sheetId: string;
  netDepositOverrideCents: number;
  isOverview?: boolean;
};

export const FinancingContainer = ({
  sheetId,
  netDepositOverrideCents,
  isOverview = false,
}: FinancingContainerProps): JSX.Element => {
  const {
    data: existingFinancings,
    error: existingFinancingsError,
    isLoading,
  } = useExistingFinancings(sheetId);

  if (isLoading) {
    return <Loading />;
  }

  if (existingFinancingsError) {
    return <Banner>{existingFinancingsError.message}</Banner>;
  }

  const getFundingCompanyValues = (
    val: string
  ): { value: string; text: string } => {
    return {
      value: val,
      text: upperFirst(val),
    };
  };

  const getMonthlyPaymentValue = (
    amount: number,
    frequency: string
  ): number => {
    switch (frequency) {
      case 'cc_split':
      case 'daily':
        return amount * 21;
      case 'weekly':
        return amount * 4;
      case 'biweekly':
        return amount * 2;
      case 'monthly':
        return amount;
      case 'loc':
      default:
        return 0;
    }
  };

  return (
    <Flex flexDirection={'column'} gap={2}>
      <ExistingFinancingContainer
        sheetId={sheetId}
        isReadOnly={isOverview}
        netDepositOverride={netDepositOverrideCents}
      />

      <Divider />

      <PreviousFinancingContainer
        previousFinancings={existingFinancings?.filter(
          (item) => item.attributes.financingType === 'historic'
        )}
        getFundingCompanyValues={getFundingCompanyValues}
        getMonthlyPaymentValue={getMonthlyPaymentValue}
      />
    </Flex>
  );
};
