import { useCallback } from 'react';
import {
  resetAcceptedOffer,
  lowerPrepayments,
  fetchAcceptedOffer,
} from 'api/underwriting/acceptedOfferFetchUtils';
import {
  UseGenericMutationResponse,
  UseGenericQueryResponse,
  useGenericMutation,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import { AcceptedOfferResponse } from 'types/api/underwriting/types';

export type UseResetAcceptedOfferArgs = {
  submissionUuid: string;
  offerId: string;
};
export type UseResetAcceptedOfferResponse = UseGenericMutationResponse<
  { success: boolean },
  UseResetAcceptedOfferArgs
>;

const resetAcceptedOfferWithArgs = async ({
  submissionUuid,
  offerId,
}: UseResetAcceptedOfferArgs): Promise<{ success: boolean }> =>
  resetAcceptedOffer(submissionUuid, offerId);

export const useApiResetAcceptedOffer = (): UseResetAcceptedOfferResponse => {
  return useGenericMutation(resetAcceptedOfferWithArgs);
};

export type UseLowerPrepaymentsArgs = {
  submissionUuid: string;
};
export type UseLowerPrepaymensResponse = UseGenericMutationResponse<
  { success: boolean },
  UseLowerPrepaymentsArgs
>;

const lowerPrepaymentsWithArgs = async ({
  submissionUuid,
}: UseLowerPrepaymentsArgs): Promise<{ success: boolean }> =>
  lowerPrepayments(submissionUuid);

export const useApiLowerPrepayments = (): UseLowerPrepaymensResponse => {
  return useGenericMutation(lowerPrepaymentsWithArgs);
};

export const useApiAcceptedOffer = (
  submissionUuid?: string
): UseGenericQueryResponse<AcceptedOfferResponse> => {
  const loadData = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchAcceptedOffer(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(loadData);
};
