import { Flex, Button } from '@forward-financing/fast-forward';

type AcceptedOfferActionButtonsProps = {
  offerId: string;
  lowerPrepayments: () => Promise<void>;
  resetAcceptedOffer: (offerId: string) => Promise<void>;
  stage?: string;
  substage?: string;
};

const canResetAcceptedOffer = (stage?: string, substage?: string): boolean => {
  if (!stage) return false;
  if (['Processing', 'Underwriting', 'Approved', 'Contracting'].includes(stage))
    return true;
  if (stage === 'Digital Closing' && substage === 'Support Required')
    return true;
  return false;
};

export const AcceptedOfferActionButtons = ({
  offerId,
  lowerPrepayments,
  resetAcceptedOffer,
  stage,
  substage,
}: AcceptedOfferActionButtonsProps): JSX.Element => {
  const handleResetAcceptedOffer = (): Promise<void> =>
    resetAcceptedOffer(offerId);

  return (
    <Flex gap={3}>
      <Button onClick={lowerPrepayments} variant="secondary">
        Offer lower prepayments
      </Button>
      <Button
        onClick={handleResetAcceptedOffer}
        variant="primary"
        disabled={!canResetAcceptedOffer(stage, substage)}
      >
        Reset accepted offer
      </Button>
    </Flex>
  );
};
