import {
  Address,
  Banner,
  Box,
  Card,
  Currency,
  DescriptionList,
  Flex,
  Grid,
  Icon,
  Link,
  Loading,
  Table,
  Text,
  formatPhoneNumber,
} from '@forward-financing/fast-forward';

import { featureFlags } from 'helpers/featureFlags';
import { addressToString } from '../../../helpers/addressUtils';

import {
  Customer,
  Owner,
  ApplicationSummarySubmission,
  Address as AddressType,
} from './applicationSummary.types';

export interface ApplicationSummaryProps {
  submission?: ApplicationSummarySubmission;
  customer?: Customer;
  owners?: Owner[];
  errorMessages: string[];
  loading: boolean;
}

const formatOwnershipPercentage = (percentage?: number): string =>
  typeof percentage === 'number' ? `${percentage.toFixed(0)}%` : 'N/A';

const addressToUrlGoogleMaps = (accountAddress: AddressType): URL =>
  new URL(
    `/maps/place/${addressToString(accountAddress).replace(/\s/g, '+')}`,
    'https://www.google.com'
  );

export const ApplicationSummary = (
  props: ApplicationSummaryProps
): JSX.Element => {
  const { errorMessages, loading, submission, customer, owners } = props;

  const hasOwners = owners && Array.isArray(owners) && owners.length > 0;
  const hasErrors = errorMessages.length > 0;

  const renewalComparisonUrl = featureFlags.show_new_renewal_comparison_page
    ? new URL(
        `${window.location.origin}/admin/submissions/${submission?.uuid}/renewal-comparison/`
      )
    : new URL(
        `${window.location.origin}/admin/applications/${submission?.uuid}/renewal-comparison/${submission?.uuid}`
      );

  return (
    <Card
      title="Application Summary"
      collapsible={true}
      initialOpenState={false}
      actions={
        <>
          {submission?.type === 'Renewal' && (
            <Link newTab href={renewalComparisonUrl} title="Renewal Comparison">
              <Icon size="2x" name="repeat" />
            </Link>
          )}
        </>
      }
    >
      {loading ? (
        <Loading />
      ) : (
        <Box>
          {hasErrors && (
            <Banner dismissable={true} variant="error">
              {errorMessages.map((message) => (
                <Text key={message}>{message}</Text>
              ))}
            </Banner>
          )}

          <Grid gutter>
            <Grid.Item l={3} s={12}>
              <Flex justifyContent="center" marginBottom={2}>
                <Text bold>Business Summary</Text>
              </Flex>

              {customer && (
                <DescriptionList>
                  <DescriptionList.Item>
                    <DescriptionList.Term>DBA</DescriptionList.Term>
                    <DescriptionList.Details>
                      {customer.doingBusinessAsName}
                    </DescriptionList.Details>
                  </DescriptionList.Item>
                  <DescriptionList.Item>
                    <DescriptionList.Term>Location</DescriptionList.Term>
                    <DescriptionList.Details>
                      {customer.accountAddress && (
                        <Link
                          newTab
                          href={addressToUrlGoogleMaps(customer.accountAddress)}
                        >
                          <Address
                            streetAddress={[
                              customer.accountAddress.street1,
                              customer?.accountAddress.street2,
                            ]}
                            city={customer.accountAddress.city || ''}
                            state={customer.accountAddress.state || ''}
                            zipCode={customer.accountAddress.zip || ''}
                            isSingleLine
                          />
                        </Link>
                      )}
                    </DescriptionList.Details>
                  </DescriptionList.Item>

                  <DescriptionList.Item>
                    <DescriptionList.Term>Business Type</DescriptionList.Term>
                    <DescriptionList.Details>
                      {customer.industryName}
                    </DescriptionList.Details>
                  </DescriptionList.Item>

                  <DescriptionList.Item>
                    <DescriptionList.Term>Year Est.</DescriptionList.Term>
                    <DescriptionList.Details>
                      {customer.startedOnDate?.substring(0, 4)}
                    </DescriptionList.Details>
                  </DescriptionList.Item>
                </DescriptionList>
              )}
            </Grid.Item>

            <Grid.Item l={6} s={12}>
              <Flex justifyContent="center" marginBottom={2}>
                <Text bold>Owner Summary</Text>
              </Flex>

              <Table>
                <Table.Head>
                  <Table.ColumnHeader>Name</Table.ColumnHeader>
                  <Table.ColumnHeader>Ownership %</Table.ColumnHeader>
                  <Table.ColumnHeader>
                    <Flex justifyContent="left" alignItems="center" gap={2}>
                      <Text>Work Phone</Text>
                      <Icon name="store-alt" />
                    </Flex>
                  </Table.ColumnHeader>
                  <Table.ColumnHeader>
                    <Flex justifyContent="left" alignItems="center" gap={2}>
                      <Text>Home</Text>
                      <Icon name="home" />
                    </Flex>
                  </Table.ColumnHeader>
                  <Table.ColumnHeader>
                    <Flex justifyContent="left" alignItems="center" gap={2}>
                      <Text>Mobile</Text>
                      <Icon name="mobile-alt" />
                    </Flex>
                  </Table.ColumnHeader>
                </Table.Head>
                {hasOwners && (
                  <Table.Body>
                    {(owners || []).map((owner) => (
                      <Table.Row key={owner?.uuid}>
                        <Table.Cell>{owner?.name}</Table.Cell>

                        <Table.Cell>
                          {formatOwnershipPercentage(
                            owner?.ownershipPercentage
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {customer?.businessPhone &&
                            formatPhoneNumber(customer.businessPhone)}
                        </Table.Cell>
                        <Table.Cell>
                          {owner?.homePhone &&
                            formatPhoneNumber(owner.homePhone)}
                        </Table.Cell>
                        <Table.Cell>
                          {owner?.cellPhone &&
                            formatPhoneNumber(owner.cellPhone)}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                )}
              </Table>
            </Grid.Item>

            <Grid.Item l={3} s={12}>
              <Flex justifyContent="center" marginBottom={2}>
                <Text bold>Cashflow Summary</Text>
              </Flex>

              {submission && customer && (
                <DescriptionList>
                  <DescriptionList.Item>
                    <DescriptionList.Term>
                      Amount Requested
                    </DescriptionList.Term>
                    <DescriptionList.Details>
                      {typeof submission.amountRequested === 'number' && (
                        <Currency amount={submission.amountRequested} />
                      )}
                    </DescriptionList.Details>
                  </DescriptionList.Item>

                  <DescriptionList.Item>
                    <DescriptionList.Term>Monthly Revenue</DescriptionList.Term>
                    <DescriptionList.Details>
                      {customer.monthlyRevenue}
                    </DescriptionList.Details>
                  </DescriptionList.Item>

                  <DescriptionList.Item>
                    <DescriptionList.Term>Use of Funds</DescriptionList.Term>
                    <DescriptionList.Details>
                      {submission.useOfFunds}
                    </DescriptionList.Details>
                  </DescriptionList.Item>
                </DescriptionList>
              )}
            </Grid.Item>
            {/* This is temporary sending submissions uuid instead of application record_id because we don't have application record_id in submissions payload. We can't refactor this route until project phoenix is done. */}
            {submission?.type === 'Renewal' && (
              <Grid.Item l={12} s={12}>
                <Link newTab href={renewalComparisonUrl}>
                  Renewal Comparison
                </Link>
              </Grid.Item>
            )}
          </Grid>
        </Box>
      )}
    </Card>
  );
};
