import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';

import {
  ExistingFinancingRequestBody,
  ExistingFinancingsResponse,
  FinancialDataRowRequestBody,
  SheetMistakeRequestBody,
  SheetMistakesResponse,
  SheetRequestBody,
  SheetSnapshotResponse,
  SheetsResponse,
} from 'types/api/banking/types';
import {
  createFinancialDataRowMutationOptions,
  createSheetsMutationOptions,
  deleteSheetQueryOptions,
  fetchExistingFinancingsQueryOptions,
  fetchMistakesQueryOptions,
  fetchSheetsQueryOptions,
  patchSheet,
  patchSnapshot,
  updateExistingFinancingMutationOptions,
  updateMistakeQueryOptions,
} from 'api/banking/cashFlowFetchUtils';
import { UserByRoleResponse } from 'types/api/auth/types';
import { fetchUsersByRoleQueryOptions } from 'api/auth/usersFetchUtils';

import { QueryKeys } from 'api/queryKeys';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { NetworkError } from 'api/networkError';
import {
  ExistingFinancing,
  Mistake,
  Sheet,
  SheetSnapshot,
  User,
} from './cashFlow.types';

const toSheetSnapshot = (data: SheetSnapshotResponse): SheetSnapshot => {
  return {
    id: data.id,
    sheetId: data.sheet_id,
    position: data.position,
    month: data.month,
    depositCountString: data.deposit_count_string,
    negativeDaysCountString: data.negative_days_count_string,
    nsfCountString: data.nsf_count_string,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    beginningBalanceCents: data.beginning_balance_cents,
    beginningBalanceCurrency: data.beginning_balance_currency,
    endingBalanceCents: data.ending_balance_cents,
    endingBalanceCurrency: data.ending_balance_currency,
    averageDailyBalanceCents: data.average_daily_balance_cents,
    averageDailyBalanceCurrency: data.average_daily_balance_currency,
    deposits: data.deposits,
    fundingAdjustment: data.funding_adjustment,
    transferAdjustment: data.transfer_adjustment,
    otherAdjustment: data.other_adjustment,
    includeInAutocalculate: data.include_in_autocalculate,
    minimumBalanceCents: data.minimum_balance_cents,
    periodDays: data.period_days ?? undefined,
    suspiciousActivity: data.suspicious_activity ?? undefined,
    depositCountNumeric: data.deposit_count_numeric,
    negativeDaysCountNumeric: data.negative_days_count_numeric,
    nsfCountNumeric: data.nsf_count_numeric,
    depositsNumeric: data.deposits_numeric,
    fundingAdjustmentNumeric: data.funding_adjustment_numeric,
    transferAdjustmentNumeric: data.transfer_adjustment_numeric,
    otherAdjustmentNumeric: data.other_adjustment_numeric,
    mtdHash: data.mtd_hash ?? undefined,
  };
};

export const toSheet = ({ data }: SheetsResponse): Sheet[] =>
  data.map((sheet) => {
    return {
      id: sheet.id,
      type: sheet.type,
      createdAt: sheet.attributes.created_at,
      updatedAt: sheet.attributes.updated_at,
      netDepositOverrideCents: sheet.attributes.net_deposit_override_cents,
      netDepositOverrideCurrency:
        sheet.attributes.net_deposit_override_currency,
      autocalculateNetDepositOverride:
        sheet.attributes.autocalculate_net_deposit_override,
      name: sheet.attributes.name,
      isLocked: sheet.attributes.is_locked,
      partnerId: sheet.attributes.partner_id,
      processingNotes: sheet.attributes.processing_notes,
      averageDailyBalanceOverrideCents:
        sheet.attributes.average_daily_balance_override_cents,
      averageDailyBalanceOverrideCurrency:
        sheet.attributes.average_daily_balance_override_currency,
      autocalculateAverageDailyBalanceOverride:
        sheet.attributes.autocalculate_average_daily_balance_override,
      isRenewal: sheet.attributes.is_renewal,
      dataValidationErrorMessage:
        sheet.attributes.data_validation_error_message ?? undefined,
      accountNumber: sheet.attributes.account_number ?? undefined,
      includeInOverview: sheet.attributes.include_in_overview ?? undefined,
      primary: sheet.attributes.primary ?? undefined,
      processingAnalystName:
        sheet.attributes.processing_analyst_name ?? undefined,
      parentSheetId: sheet.attributes.parent_sheet_id ?? undefined,
      verificationClosingAnalystName:
        sheet.attributes.verification_closing_analyst_name ?? undefined,
      availableBalance: sheet.attributes.available_balance ?? undefined,
      processingAnalystId: sheet.attributes.processing_analyst_id ?? undefined,
      bankSummaryFetchRequestId:
        sheet.attributes.bank_summary_fetch_request_id ?? undefined,
      snapshots: sheet.attributes.snapshots.map(toSheetSnapshot),
    };
  });

export const toExistingFinancings = (
  data: ExistingFinancingsResponse
): ExistingFinancing[] =>
  data.data.map((financing) => {
    return {
      id: financing.id,
      type: financing.type,
      attributes: {
        sheetId: financing.attributes.sheet_id,
        position: financing.attributes.position,
        financingType: financing.attributes.financing_type,
        company: financing.attributes.company,
        frequency: financing.attributes.frequency,
        createdAt: financing.attributes.created_at,
        updatedAt: financing.attributes.updated_at,
        dailyAmountCents: financing.attributes.daily_amount_cents,
        dailyAmountCurrency: financing.attributes.daily_amount_currency,
        lastPaymentDate: financing.attributes.last_payment_date ?? undefined,
        term: financing.attributes.term ?? undefined,
        factorRate: financing.attributes.factor_rate ?? undefined,
        isExcluded: financing.attributes.is_excluded,
        fundedDate: financing.attributes.funded_date ?? undefined,
        fundedAmountCents: financing.attributes.funded_amount_cents,
        paymentStartDate: financing.attributes.payment_start_date ?? undefined,
        isRenewal: financing.attributes.is_renewal ?? undefined,
      },
    };
  });

export const useFetchSheets = (
  submissionUuid: string
): UseQueryResult<Sheet[] | undefined> => {
  return useQuery({
    ...fetchSheetsQueryOptions(submissionUuid),
    select: (data) => toSheet(data),
    enabled: !!submissionUuid,
  });
};

export const usePatchSheets = (
  submissionUuid: string
): UseMutationResult<
  Sheet[] | undefined,
  null,
  { body: SheetRequestBody; sheetId: string }
> => {
  return useMutation({
    mutationKey: ['patchSheet', submissionUuid],
    mutationFn: async ({ body, sheetId }) => {
      const response = await patchSheet(submissionUuid, sheetId, body);
      return toSheet(response);
    },
  });
};

export const usePatchSnapshot = (
  snapshotId: string
): UseMutationResult<Sheet[], null, Partial<SheetSnapshotResponse>> => {
  return useMutation({
    mutationKey: ['patchSheet', snapshotId],
    mutationFn: async (body: Partial<SheetSnapshotResponse>) => {
      const response = await patchSnapshot(snapshotId, body);
      return toSheet(response);
    },
  });
};

const toUser = (user: UserByRoleResponse): User => {
  return {
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    id: user.id,
    role: user.role,
    subRole: user.sub_role,
  };
};

export const useFetchUsersByRole = (
  role: 'vca' | 'processor' | 'processing_team_lead'
): UseQueryResult<User[]> => {
  return useQuery({
    ...fetchUsersByRoleQueryOptions(role),
    select: (data) => data.map(toUser),
    enabled: !!role,
  });
};

// we are concatenating vca analysts because this team sometimes help the processing team,
// so they need to be able to assign themselves to deals
export const useFetchProcessingAnalystUsers = (): {
  data: User[];
  isLoading: boolean;
} => {
  const processingUsers = useFetchUsersByRole('processor');
  const processingTeamLeadUsers = useFetchUsersByRole('processing_team_lead');
  const vcaUsers = useFetchUsersByRole('vca');

  return {
    data: (processingUsers.data ?? [])
      .concat(processingTeamLeadUsers.data ?? [])
      .concat(vcaUsers.data ?? []),
    isLoading:
      processingUsers.isLoading ||
      vcaUsers.isLoading ||
      processingTeamLeadUsers.isLoading,
  };
};

export const useFetchVcaUsers = (): {
  data: User[];
  isLoading: boolean;
} => {
  const vcaUsers = useFetchUsersByRole('vca');
  const processingTeamLeadUsers = useFetchUsersByRole('processing_team_lead');

  return {
    data: (vcaUsers.data ?? []).concat(processingTeamLeadUsers.data ?? []),
    isLoading: vcaUsers.isLoading || processingTeamLeadUsers.isLoading,
  };
};

export const useUpdateExistingFinancing = (
  sheetId: string,
  financingId: string
): UseMutationResult<
  MutationResponse,
  unknown,
  ExistingFinancingRequestBody
> => {
  const queryClient = useQueryClient();

  return useMutation({
    ...updateExistingFinancingMutationOptions(sheetId, financingId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.FETCH_SHEET_EXISTING_FINANCINGS, sheetId],
      });
    },
  });
};

export const useExistingFinancings = (
  sheetId: string,
  customSelect?: (data: ExistingFinancing[]) => ExistingFinancing[]
): UseQueryResult<ExistingFinancing[] | undefined> => {
  return useQuery({
    ...fetchExistingFinancingsQueryOptions(sheetId),
    select: (data) => {
      const formattedData = toExistingFinancings(data);

      return customSelect ? customSelect(formattedData) : formattedData;
    },
    enabled: !!sheetId,
  });
};

export const useCreateSheet = (
  submissionUuid: string
): UseMutationResult<MutationResponse, NetworkError, SheetRequestBody> => {
  const queryClient = useQueryClient();

  return useMutation({
    ...createSheetsMutationOptions(submissionUuid),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.FETCH_SHEETS, submissionUuid],
      });
    },
  });
};

const toMistakes = ({ data }: SheetMistakesResponse): Mistake[] => {
  return data.map((mistake) => ({
    id: mistake.id,
    type: mistake.type,
    attributes: {
      sheetId: mistake.attributes.sheet_id,
      note: mistake.attributes.note,
      createdBy: mistake.attributes.created_by ?? undefined,
      createdAtDate: mistake.attributes.created_at,
      updatedAtDate: mistake.attributes.updated_at,
      section: mistake.attributes.section ?? undefined,
      mistakeType: mistake.attributes.mistake_type ?? undefined,
      feedback: mistake.attributes.feedback ?? undefined,
      updatedBy: mistake.attributes.updated_by ?? undefined,
    },
  }));
};

export const useMistakes = (sheetId: string): UseQueryResult<Mistake[]> => {
  return useQuery({
    ...fetchMistakesQueryOptions(sheetId),
    select: (data) => toMistakes(data),
  });
};

export const useUpdateMistake = (
  sheetId: string,
  mistakeId: string
): UseMutationResult<
  MutationResponse,
  NetworkError,
  SheetMistakeRequestBody
> => {
  const queryClient = useQueryClient();

  return useMutation({
    ...updateMistakeQueryOptions(sheetId, mistakeId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.FETCH_MISTAKES, sheetId],
      });
    },
  });
};

export const useCreateFinancialDataRowMutationOptions = (
  sheetId: string,
  submissionUuid?: string
): UseMutationResult<
  MutationResponse,
  NetworkError,
  FinancialDataRowRequestBody
> => {
  const queryClient = useQueryClient();

  return useMutation({
    ...createFinancialDataRowMutationOptions(sheetId, submissionUuid),
    onSuccess: async (data) => {
      if (!data.response) return;

      const formattedSnapshotResponse = toSheetSnapshot({
        ...data.response.attributes,
        id: data.response.id,
      });

      queryClient.setQueryData<Sheet[]>(
        [QueryKeys.FETCH_SHEETS, submissionUuid],
        (oldData) => {
          if (!oldData) return oldData;

          const sheetIndex = oldData.findIndex((sheet) => sheet.id === sheetId);
          if (sheetIndex === -1) return oldData;
          const updatedSheet = {
            ...oldData[sheetIndex],
            snapshots: [
              ...oldData[sheetIndex].snapshots,
              formattedSnapshotResponse,
            ],
          };

          const updatedSheets = [...oldData];
          updatedSheets[sheetIndex] = updatedSheet;
          return updatedSheets;
        }
      );

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.FETCH_SHEETS, submissionUuid],
      });
    },
  });
};

export const useDeleteSheet = (
  submissionUuid: string,
  sheetId: string
): UseMutationResult<MutationResponse, NetworkError, void> => {
  const queryClient = useQueryClient();

  return useMutation({
    ...deleteSheetQueryOptions(submissionUuid, sheetId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.FETCH_SHEETS, submissionUuid],
      });
    },
  });
};
