import * as React from 'react';
import { memo, MutableRefObject, useMemo, useState } from 'react';
import { Box, Button, Sheet } from '@forward-financing/fast-forward';
import { defaultTo, isEqual } from 'lodash';

import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { Ledger } from '../ledger.types';
import { LEDGER_TABLES_MONTHS } from '../constants';
import { useLedgerContext } from '../LedgerContext/LedgerContext';
import {
  EMPTY_OFFERS_LEDGER_DATA,
  OFFERS_TABLE_FIELD_NAME_LABEL_MAP,
} from './offers.constants';
import {
  formatLedgerOfferField,
  generateOffersTableColumnHeaders,
  geOffersTabletInitialState,
  getRowColor,
  getUpdatedOffer,
  getUpdatedOffersTableStateWithCellValue,
  getUpdatedOffersTableStateWithResponse,
  isLedgerOffer,
} from './offers.utils';
import { LedgerOffer, OffersTableState } from './offers.types';
import { OffersTableCell } from './OffersTableCell';

type OffersTableProps = {
  ledgerCreationDate: string;
  fetchedOffers: LedgerOffer[];
  handleChangeLedger: (updates: Partial<Ledger>) => void;
  handleOfferCalculation: (
    offer: Partial<LedgerOffer> | undefined,
    months: string
  ) => Promise<MutationResponse>;
  readOnly?: boolean;
  eligibleForWeekly?: boolean;
  offerRef: MutableRefObject<HTMLDivElement | null>;
  handleScrollOffer: (event: React.UIEvent<HTMLDivElement>) => void;
};

const OffersTableComponent = ({
  ledgerCreationDate,
  fetchedOffers,
  handleChangeLedger,
  handleOfferCalculation,
  readOnly,
  eligibleForWeekly,
  offerRef,
  handleScrollOffer,
}: OffersTableProps): JSX.Element => {
  /**
   * State contains "fetchedOffers" - which synced with the BE
   * and "cellsState" - which contains the state of each cell in the table.
   */
  const [tableState, setTableState] = useState<OffersTableState>(
    geOffersTabletInitialState(fetchedOffers)
  );
  const { updateOfferValidationState } = useLedgerContext();

  /** Normally will only contain 0 or 1 number, but depending
   * on order of focus/blur events dispatching, the array ensures
   * that the focusedMonth is properly updated
   */
  const [focusedMonth, setFocusedMonth] = useState<number[]>([]);

  const columnNames = useMemo(
    () => generateOffersTableColumnHeaders(ledgerCreationDate, true),
    [ledgerCreationDate]
  );

  const updateOffers = (
    fieldName: keyof LedgerOffer,
    months: number,
    value: string
  ): Partial<LedgerOffer>[] => {
    const isOfferExists = tableState.fetchedOffers.some(
      (offer) => offer.months === months
    );

    /**
     * Updates the `offers` array. If an offer with the same `months` exists, it updates the offer.
     * Otherwise, it adds a new offer with the specified `months` and `fieldName`.
     */
    const updatedOffers = isOfferExists
      ? tableState.fetchedOffers.map((offer) =>
          offer.months === months
            ? getUpdatedOffer(offer, fieldName, value)
            : offer
        )
      : [...tableState.fetchedOffers, { months, [fieldName]: value }];

    setTableState((prev) => ({ ...prev, fetchedOffers: updatedOffers }));

    return updatedOffers;
  };

  const onBlurHandler = async (
    months: number,
    fieldName: keyof LedgerOffer,
    value: string,
    valid: boolean
  ): Promise<void> => {
    setFocusedMonth((focused) => {
      const index = focused.findIndex((v) => v === months);
      return focused.filter((_, i) => i !== index);
    });

    if (!valid) return;

    const updatedOffers = updateOffers(fieldName, months, value);

    const changedOffer = updatedOffers.find((offer) => offer.months === months);
    const initialOffer = fetchedOffers?.find(
      (offer) => offer.months === months
    );

    handleChangeLedger({
      [`dollar${months}Months`]: changedOffer?.dollarOverride ?? '',
      [`gross${months}Months`]: changedOffer?.gross ?? '',
    });

    if (changedOffer && !isEqual(changedOffer, initialOffer)) {
      await calculateOffer(
        defaultTo(changedOffer.months?.toString(), months.toString()),
        changedOffer
      );
    }
  };

  /**
   * Sends an API request to calculate the offer based on the provided months and changed offer.
   * If the calculation is successful, it updates the table state with the new offer.
   */
  const calculateOffer = async (
    months: string,
    changedOffer: Partial<LedgerOffer>
  ): Promise<void> => {
    const { success, response } = await handleOfferCalculation(
      changedOffer,
      months
    );

    if (success && isLedgerOffer(response)) {
      setTableState((prevState) =>
        getUpdatedOffersTableStateWithResponse(prevState, response)
      );
    }
  };

  /**
   * Resets the offers table state by clearing the cells state and fetched offers.
   * Also triggers a change in the ledger with empty offers ledger data.
   */
  const resetOffers = (): void => {
    setTableState({
      cellsState: {},
      fetchedOffers: [],
    });

    handleChangeLedger(EMPTY_OFFERS_LEDGER_DATA);
  };

  const shouldShowResetButton = (): boolean =>
    !readOnly && !!tableState.fetchedOffers.length;

  /**
   * Sets the value of a cell in the offers table state.
   * It updates the cell state and triggers the validation state update.
   */
  const setCellValue =
    (month: number, fieldName: string) =>
    (arg: string | ((prev: string) => string)): void => {
      setTableState((prevState) =>
        getUpdatedOffersTableStateWithCellValue(
          prevState,
          month,
          fieldName,
          arg,
          updateOfferValidationState
        )
      );
    };

  const getCellValue = (fieldName: string, month: number): string => {
    if (fieldName === 'dailyPayment' && eligibleForWeekly) {
      const value = defaultTo(
        tableState.cellsState[month]?.[fieldName]?.value,
        ''
      );

      const calculatedValue = value
        ? (parseFloat(value.replace(/%|\$|,/g, '')) * 5).toString()
        : '';

      return calculatedValue
        ? formatLedgerOfferField({ [fieldName]: calculatedValue }, fieldName)
        : '';
    }

    return defaultTo(tableState.cellsState[month]?.[fieldName]?.value, '');
  };

  const focusedMonthIndex = LEDGER_TABLES_MONTHS.findIndex(
    (v) => v === focusedMonth[0]
  );

  return (
    <div id="ledgers-sheet-table">
      {shouldShowResetButton() && (
        <Box mb={2}>
          <Button onClick={() => resetOffers()} endIcon={'arrow-left-rotate'}>
            Reset Offers
          </Button>
        </Box>
      )}

      <Sheet ref={offerRef} onScroll={handleScrollOffer}>
        <Sheet.Head>
          <Sheet.ColumnHeader minColumnWidth="medium" columnWidth="medium">
            Term length (Months)
          </Sheet.ColumnHeader>
          {columnNames.map((header, index) => (
            <Sheet.ColumnHeader
              minColumnWidth="small"
              columnWidth="small"
              backgroundColor={
                index === focusedMonthIndex ? 'green-200' : undefined
              }
              key={header}
            >
              {header}
            </Sheet.ColumnHeader>
          ))}
        </Sheet.Head>
        <Sheet.Body>
          {Object.keys(OFFERS_TABLE_FIELD_NAME_LABEL_MAP()).map((fieldName) => (
            <Sheet.Row key={fieldName} backgroundColor={getRowColor(fieldName)}>
              <Sheet.RowHeader>
                {
                  OFFERS_TABLE_FIELD_NAME_LABEL_MAP(eligibleForWeekly)[
                    fieldName
                  ]
                }
              </Sheet.RowHeader>
              {LEDGER_TABLES_MONTHS.map((months) => (
                <OffersTableCell
                  isValid={defaultTo(
                    tableState.cellsState[months]?.[fieldName]?.isValid,
                    true
                  )}
                  value={getCellValue(fieldName, months)}
                  setValue={setCellValue(months, fieldName)}
                  fieldName={fieldName}
                  months={months}
                  key={months}
                  isEditable={
                    ['dollarOverride', 'gross'].includes(fieldName) && !readOnly
                  }
                  handleBlur={onBlurHandler}
                  isHighlighted={focusedMonth.includes(months)}
                  handleFocus={() => setFocusedMonth((m) => [...m, months])}
                />
              ))}
            </Sheet.Row>
          ))}
        </Sheet.Body>
      </Sheet>
    </div>
  );
};

export const OffersTable = memo(OffersTableComponent);
