import {
  Checkbox,
  Flex,
  formatCurrency,
  Sheet,
  Text,
} from '@forward-financing/fast-forward';
import { Sheet as SheetType, SheetSnapshot } from '../cashFlow.types';
import { displayPercentage } from '../../../../helpers/utils';

type CashFlowFinancialDataContainerProps = {
  sheetData: SheetType;
  snapShotData: SheetSnapshot[];
  getRevenue: (
    deposits: string,
    fundingAdj: string,
    transferAdj: string,
    otherAdj: string
  ) => number;
  getAdbCalc: (beginningBalance: number, endingBalance: number) => number;
  getAdbPercentCalc: (adb: number, adbCalc: number, deposits: number) => number;
};

export const FinancialDataAverageSection = ({
  sheetData,
  snapShotData,
  getRevenue,
  getAdbCalc,
  getAdbPercentCalc,
}: CashFlowFinancialDataContainerProps): JSX.Element => {
  const getAveragesWithKey = (
    key?: keyof SheetSnapshot,
    funcKey?: string
  ): number => {
    if (snapShotData.length === 0) return 0;

    let total = 0;

    snapShotData.forEach((item) => {
      //We use funcKeys for calculation that doesn't exist in the snapShotData object.
      if (funcKey) {
        if (funcKey === 'revenue') {
          // Here we calculate total of revenues from revenue column
          total += getRevenue(
            item.deposits,
            item.fundingAdjustment,
            item.transferAdjustment,
            item.otherAdjustment
          );
        } else if (funcKey === 'adbCalc') {
          // Here we calculate total of ADB Calc from ADB Calc column
          total += getAdbCalc(
            item.beginningBalanceCents,
            item.endingBalanceCents
          );
        } else if (funcKey === 'adbPercent') {
          // Here we calculate total of ADB % from ADB % column
          total += getAdbPercentCalc(
            item.averageDailyBalanceCents,
            getAdbCalc(item.beginningBalanceCents, item.endingBalanceCents),
            getRevenue(
              item.deposits,
              item.fundingAdjustment,
              item.transferAdjustment,
              item.otherAdjustment
            )
          );
        }
      } else {
        if (key) {
          const value = parseFloat(item[key] as string);
          if (!isNaN(value)) {
            total += value;
          }
        }
      }
    });

    return total / snapShotData.length;
  };

  const GetFlexLayout = (value: string, isBold?: boolean): JSX.Element => {
    return (
      <Flex justifyContent={'center'}>
        <Text color={'white'} bold={isBold}>
          {value}
        </Text>
      </Flex>
    );
  };

  return (
    <>
      <Sheet.Row backgroundColor={'gray-800'}>
        <Sheet.TextCell>{GetFlexLayout('Averages', true)}</Sheet.TextCell>
        <Sheet.TextCell>
          {GetFlexLayout(formatCurrency(getAveragesWithKey('deposits')))}
        </Sheet.TextCell>
        <Sheet.TextCell colSpan={3} />
        <Sheet.TextCell>
          {GetFlexLayout(
            formatCurrency(getAveragesWithKey(undefined, 'revenue'))
          )}
        </Sheet.TextCell>
        <Sheet.TextCell colSpan={1} />
        <Sheet.TextCell>
          {GetFlexLayout(getAveragesWithKey('depositCountString').toString())}
        </Sheet.TextCell>
        <Sheet.TextCell>
          {GetFlexLayout(
            getAveragesWithKey('negativeDaysCountString').toString()
          )}
        </Sheet.TextCell>

        <Sheet.TextCell>
          {GetFlexLayout(getAveragesWithKey('nsfCountString').toString())}
        </Sheet.TextCell>
        <Sheet.TextCell colSpan={2} />
        <Sheet.TextCell>
          {GetFlexLayout(
            formatCurrency(getAveragesWithKey(undefined, 'adbCalc') / 100)
          )}
        </Sheet.TextCell>
        <Sheet.TextCell>
          {GetFlexLayout(
            formatCurrency(getAveragesWithKey('averageDailyBalanceCents') / 100)
          )}
        </Sheet.TextCell>
        <Sheet.TextCell>
          {GetFlexLayout(
            displayPercentage(getAveragesWithKey(undefined, 'adbPercent'))
          )}
        </Sheet.TextCell>
        <Sheet.TextCell colSpan={1} />
      </Sheet.Row>
      <Sheet.Row backgroundColor={'gray-800'}>
        <Sheet.TextCell colSpan={4} />
        <Sheet.TextCell>{GetFlexLayout('Override', true)}</Sheet.TextCell>
        <Sheet.TextCell
          value={formatCurrency(sheetData.netDepositOverrideCents / 100)}
          isEditable={true}
          //TODO onValueChange should be added its part of another ticket.
          //Inline editable cells won't be able to render text and edit them we have to pass color to them in order to change it/
        />
        <Sheet.TextCell colSpan={2}>
          {
            <Checkbox
              label={'Autocalculate'}
              checked={sheetData.autocalculateNetDepositOverride}
            />
          }
        </Sheet.TextCell>
        <Sheet.TextCell colSpan={5} />
        <Sheet.TextCell
          value={formatCurrency(
            sheetData.averageDailyBalanceOverrideCents / 100
          )}
          isEditable={true}
          //TODO onValueChange should be added its part of another ticket.
        />
        <Sheet.TextCell colSpan={2}>
          {
            <Checkbox
              label={'Autocalculate'}
              checked={sheetData.autocalculateNetDepositOverride}
            />
          }
        </Sheet.TextCell>
      </Sheet.Row>
      <Sheet.Row backgroundColor={'gray-800'}>
        <Sheet.TextCell colSpan={4} />
        <Sheet.TextCell>{GetFlexLayout('Annual Revenue', true)}</Sheet.TextCell>
        <Sheet.TextCell>
          {GetFlexLayout(
            formatCurrency((sheetData.netDepositOverrideCents / 100) * 12)
          )}
        </Sheet.TextCell>
        <Sheet.TextCell colSpan={6} />
        <Sheet.TextCell>
          {GetFlexLayout('Average Daily Balance', true)}
        </Sheet.TextCell>
        <Sheet.TextCell>
          {GetFlexLayout(
            formatCurrency(
              (sheetData.averageDailyBalanceOverrideCents / 100) * 12
            )
          )}
        </Sheet.TextCell>
        <Sheet.TextCell colSpan={2} />
      </Sheet.Row>
    </>
  );
};
