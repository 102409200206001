import { formatDateTimeString, Text } from '@forward-financing/fast-forward';

export const LastPulledOnDate = ({
  lastPulledOn,
}: {
  lastPulledOn?: string;
}): JSX.Element => {
  return (
    <>
      {lastPulledOn && (
        <Text italic>Last pulled on {formatDateTimeString(lastPulledOn)}</Text>
      )}
    </>
  );
};
