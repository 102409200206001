import {
  Tooltip,
  Text,
  Icon,
  formatCurrency,
  Flex,
} from '@forward-financing/fast-forward';
import { ExceptionRequestSuggestedPricing } from '../exceptionRequest.types';

type ExceptionSuggestedPricingProps = {
  suggestedPricingData?: ExceptionRequestSuggestedPricing;
};
const TOOLTIP_POSITION = 'bottom';

export const ExceptionSuggestedPricing = ({
  suggestedPricingData,
}: ExceptionSuggestedPricingProps): JSX.Element => {
  return (
    <Tooltip
      content={
        suggestedPricingData ? (
          <>
            <Flex gap={2}>
              <Text bold color="white">
                Gross:
              </Text>
              <Text color="white">
                {suggestedPricingData.buyRate}% /{' '}
                {formatCurrency(
                  parseFloat(String(suggestedPricingData.fundingAmount))
                )}
              </Text>
            </Flex>
            <Flex gap={2}>
              <Text bold color="white">
                Term Length:
              </Text>
              <Text color="white">
                {suggestedPricingData.termLength} Months
              </Text>
            </Flex>
            <Flex gap={2}>
              <Text bold color="white">
                Program:
              </Text>
              <Text color="white">{suggestedPricingData.programType}</Text>
            </Flex>
          </>
        ) : (
          <Text color="white">No Suggested Pricing Available</Text>
        )
      }
      position={TOOLTIP_POSITION}
      trigger={
        <span>
          <Icon name="info-circle" data-testid="suggested-pricing-icon" />
        </span>
      }
      maxWidth={500}
    />
  );
};
