import { useExceptionRequestContext } from '../ExceptionRequest/Context/ExceptionRequestContext';
import { ApplicationSnapshotSubmission } from './applicationSnapshot.types';
import { ApplicationSnapshotContainer } from './ApplicationSnapshotContainer';

type SubmissionEditingApplicationSnapshotContainerProps = {
  submission: ApplicationSnapshotSubmission;
};
export const SubmissionEditingApplicationSnapshotContainer = ({
  submission,
}: SubmissionEditingApplicationSnapshotContainerProps): JSX.Element => {
  const { refetchExceptionRequests } = useExceptionRequestContext();

  return (
    <ApplicationSnapshotContainer
      submission={submission}
      refetchExceptionRequests={refetchExceptionRequests}
    />
  );
};
