import {
  Button,
  DescriptionList,
  Grid,
  OptionShape,
  Select,
  Switch,
  Table,
  Tag,
  TextArea,
} from '@forward-financing/fast-forward';
import { Params, useParams } from 'react-router-dom';
import { Sheet, User } from './cashFlow.types';
import {
  useFetchProcessingAnalystUsers,
  useFetchSheets,
  useFetchVcaUsers,
} from './cashFlowHooks';
import { useWorksheetsActiveTabContext } from './Context/WorksheetsActiveTabContext';

type OverviewDetailsProps = {
  sheetToUpdate: Sheet;
  setSheetToUpdate: React.Dispatch<React.SetStateAction<Sheet>>;
};

export const OverviewDetails = ({
  sheetToUpdate,
  setSheetToUpdate,
}: OverviewDetailsProps): JSX.Element => {
  const { submissionUuid } = useParams<Params>();

  if (!submissionUuid) {
    throw new Error('Submission UUID is required');
  }

  const { setActiveTab } = useWorksheetsActiveTabContext();
  const { data: sheets } = useFetchSheets(submissionUuid);
  const includedSheets = sheets?.filter((s) => s.includeInOverview);

  const renderTagIfNecessary = (isPrimary?: boolean): JSX.Element | null =>
    isPrimary ? <Tag bgColor="success">Primary</Tag> : null;

  const userToOptionShape = (
    useNameAsValue: boolean,
    users?: User[]
  ): OptionShape[] => {
    if (!users) {
      return [];
    }

    return users
      ?.map((u) => {
        const fullName = `${u.firstName} ${u.lastName}`;
        return { value: `${useNameAsValue ? fullName : u.id}`, text: fullName };
      })
      .sort((userA, userB) => userA.text.localeCompare(userB.text));
  };

  const { data: processingAnalystsUsers } = useFetchProcessingAnalystUsers();
  const { data: verificationClosingUsers } = useFetchVcaUsers();

  const onChangeHandler = (fieldToUpdate: Partial<Sheet>): void => {
    setSheetToUpdate((prev) => {
      return { ...prev, ...fieldToUpdate };
    });
  };

  return (
    <Grid gutter>
      <Grid.Item xs={2}>
        <Table>
          <Table.Head>
            <Table.ColumnHeader>Included Accounts</Table.ColumnHeader>
          </Table.Head>
          <Table.Body>
            {!includedSheets?.length && (
              <Table.Row>
                <Table.Cell>There are no included accounts</Table.Cell>
              </Table.Row>
            )}

            {includedSheets?.length &&
              includedSheets.map((sheet) => (
                <Table.Row key={sheet.id}>
                  <Table.Cell backgroundColor="white">
                    <Button
                      onClick={() => setActiveTab(sheet.id)}
                      variant="text"
                    >
                      {sheet.accountNumber}
                    </Button>
                    {renderTagIfNecessary(sheet.primary)}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Grid.Item>
      <Grid.Item xs={5}>
        <DescriptionList divider="compact">
          <DescriptionList.Item>
            <DescriptionList.Term>Processing Analyst</DescriptionList.Term>
            <DescriptionList.Details>
              <Select
                label="Processing Analyst"
                hiddenLabel
                options={userToOptionShape(false, processingAnalystsUsers)}
                value={sheetToUpdate.processingAnalystId?.toString() || ''}
                onValueChange={(newVal) =>
                  onChangeHandler({ processingAnalystId: newVal })
                }
              />
            </DescriptionList.Details>
          </DescriptionList.Item>

          <DescriptionList.Item>
            <DescriptionList.Term>Processing Notes</DescriptionList.Term>
            <DescriptionList.Details>
              <TextArea
                label={''}
                value={sheetToUpdate.processingNotes}
                onChange={(newVal) =>
                  onChangeHandler({ processingNotes: newVal.target.value })
                }
              />
            </DescriptionList.Details>
          </DescriptionList.Item>
        </DescriptionList>
      </Grid.Item>
      <Grid.Item xs={5}>
        <DescriptionList divider="compact">
          <DescriptionList.Item>
            <DescriptionList.Term>
              Verification Closing Analyst
            </DescriptionList.Term>
            <DescriptionList.Details>
              <Select
                label="Verification Closing Analyst"
                hiddenLabel
                options={userToOptionShape(true, verificationClosingUsers)}
                value={sheetToUpdate.verificationClosingAnalystName || ''}
                onValueChange={(newVal) =>
                  onChangeHandler({ verificationClosingAnalystName: newVal })
                }
              />
            </DescriptionList.Details>
          </DescriptionList.Item>

          <DescriptionList.Item>
            <DescriptionList.Term>Renewal</DescriptionList.Term>
            <DescriptionList.Details>
              <Switch
                id="is_renewal-switch"
                label=""
                checked={sheetToUpdate.isRenewal || false}
                onCheckedChange={(newVal) =>
                  onChangeHandler({ isRenewal: newVal })
                }
              />
            </DescriptionList.Details>
          </DescriptionList.Item>
        </DescriptionList>
      </Grid.Item>
    </Grid>
  );
};
