import { SubmissionResponseCategories } from 'types/api/underwriting/types';
import { Submission } from './DealScoringContainer.types';

const NEW_DEAL_CATEGORIES: SubmissionResponseCategories[] = [
  SubmissionResponseCategories.BUSINESS_DEVELOPMENT_PROGRAM,
  SubmissionResponseCategories.NEW_SALES,
];

export const shouldSelectUWTabForNewDeals = (submission: Submission): boolean =>
  submission?.category
    ? NEW_DEAL_CATEGORIES.includes(submission?.category)
    : false;

const RENEWAL_DEAL_CATEGORIES: SubmissionResponseCategories[] = [
  SubmissionResponseCategories.OTHER_ISO_RENEWAL,
  SubmissionResponseCategories.RENEWAL,
  SubmissionResponseCategories.PIF_EXISTING_ISO,
  SubmissionResponseCategories.PIF_NEW_ISO,
  SubmissionResponseCategories.PIF_ISO_EXCLUSIVITY,
];

export const shouldSelectRenewalTabForRenewals = (
  submission: Submission
): boolean =>
  submission?.category
    ? RENEWAL_DEAL_CATEGORIES.includes(submission?.category)
    : false;

const BEFORE_UW_SUBMISSION_STAGES = ['Processing', 'Declined'];

export const isPriorStageUnderwriting = (submission: Submission): boolean =>
  BEFORE_UW_SUBMISSION_STAGES.includes(submission.stage);
