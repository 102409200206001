import {
  Card,
  Grid,
  Flex,
  formatDateTimeString,
  Text,
} from '@forward-financing/fast-forward';
import { OverriddenValidations, User } from '../ledger.types';

type OverrideValidationCardProps = {
  latestOverriddenValidation?: OverriddenValidations;
  underwriter?: User;
};

export const OverrideValidationCard = ({
  latestOverriddenValidation,
  underwriter,
}: OverrideValidationCardProps): JSX.Element | null => {
  if (!latestOverriddenValidation) {
    return null;
  }

  return (
    <Card
      title={`Validations overridden in ${latestOverriddenValidation.stage}`}
      collapsible
      initialOpenState={false}
    >
      <Grid>
        <Grid.Item xl={6}>
          <Flex flexDirection="column">
            {latestOverriddenValidation.missingAttributes?.map((item) => {
              return <Text key={item}>- {item}</Text>;
            })}
          </Flex>
        </Grid.Item>

        <Grid.Item xl={6}>
          <Flex flexDirection="column">
            <Flex gap={1}>
              <Text bold>Note:</Text>
              <Text>{latestOverriddenValidation.note}</Text>
            </Flex>

            <Flex gap={1}>
              <Text bold>Underwriter:</Text>
              <Text>
                {underwriter?.firstName} {underwriter?.lastName}
              </Text>
            </Flex>

            <Flex gap={1}>
              <Text bold>Date overridden:</Text>
              <Text>
                {latestOverriddenValidation.createdAt &&
                  formatDateTimeString(latestOverriddenValidation.createdAt)}
              </Text>
            </Flex>

            <Flex gap={1}>
              <Text bold>Stage:</Text>
              <Text>{latestOverriddenValidation.stage}</Text>
            </Flex>
          </Flex>
        </Grid.Item>
      </Grid>
    </Card>
  );
};
