import { fetchLivePipelineSubmissions } from 'api/underwriting/livePipelineFetchUtils';
import {
  UseLazyGenericQueryFetcher,
  UseLazyGenericQueryResult,
  useLazyGenericQuery,
} from 'apiHooks/genericFetchHooks';
import {
  LivePipelineSearchParams,
  LivePipelineSubmissionSearchResultResponse,
} from 'types/api/underwriting/types';

const fetchSubmissionsWithArgs = (
  args: LivePipelineSearchParams
): Promise<LivePipelineSubmissionSearchResultResponse> =>
  fetchLivePipelineSubmissions(args);

export const useApiLazyGetLivePipelineSubmissions = (): [
  UseLazyGenericQueryFetcher<LivePipelineSearchParams>,
  UseLazyGenericQueryResult<LivePipelineSubmissionSearchResultResponse>
] => {
  return useLazyGenericQuery(fetchSubmissionsWithArgs);
};
