export interface WebPresenceResponse {
  business_website: string;
  facebook: string;
  instagram: string;
  yelp: string;
  other: string;
}

export enum WebPresenceType {
  Website = 'business_website',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Yelp = 'yelp',
  Other = 'other',
}

export interface Decline {
  decline_driver_notes: string;
  decline_drivers: string[];
  previous_decline?: boolean;
}

export interface Ownership {
  contact_uuid: string;
  ownership_percentage: number;
}

export interface PrequalState {
  prequal_started_at: Date | null;
  prequal_completed_at: Date | null;
  account_completed: boolean;
  customer_search_results_reviewed: Date | null;
  owners_completed: boolean;
  bank_accounts_completed: boolean;
  other_info_completed: boolean;
  sent_to_processing: boolean;
  selected_customer: string | null;
}

export interface Application {
  uuid?: string;
  record_id: number;
  loan_use: string; // use of funds
  capital_needed: string; // Amount needed
  capitalNeededNotPresent?: boolean;
  customer_uuid?: string;
  account_uuid?: string;
  partner_id: number;
  partner_uuid: string;
  prequal_analyst_name?: string;
  prequal_state_attributes: PrequalState;
  submission_source: string;
  notes: string;
  sales_rep_email: string;
  salesforce_opportunity_sfid?: string;
  opportunity_uuid?: string;
  available_renewal_url: string | null;
}

export interface Submission extends Application {
  account: Account;
  contacts: Contact[];
  decline: Decline;
}

export interface Account {
  uuid?: string;
  entity_type?: string;
  started_on?: string; // business start date
  industry_id?: number;
  industry_uuid?: string;
  name?: string;
  legal_hold?: boolean;
  legal_name?: string;
  fein?: string;
  phone?: string;
  created_at?: Date;
  updated_at?: Date;
  dbaSameAsLegalName?: boolean;
  address: Address;
  feinNotPresent?: boolean;
  web_presences?: WebPresences;
  phoneNotPresent?: boolean;
  bank_account: BankInfo | null;
  active_submissions?: ActiveSubmission[];
}

export interface ActiveSubmission {
  iso_uuid: string;
  url: string;
  date: string;
  uuid: string;
}

export interface Contact {
  id?: number;
  uuid?: string;
  first_name: string;
  last_name: string;
  born_on: string | null;
  email: string;
  home_phone: string | null;
  cell_phone: string | null;
  ssn: string | null;
  title: string;
  ownership_percentage?: number;
  address: Address;
  _destroy?: boolean; //this refers to already removed contacts
  ssnNotPresent?: boolean;
  bornOnNotPresent?: boolean;
  key?: string;
  owner_id?: number;
  deleted_at?: Date;
}

export interface WebPresences {
  business_website?: WebPresence;
  facebook?: WebPresence;
  instagram?: WebPresence;
  yelp?: WebPresence;
  other?: WebPresence;
}

export interface WebPresence {
  web_presence_type: WebPresenceType;
  url: string;
  urlChanged?: boolean;
}

export interface BankInfo {
  id: number | null;
  account_holder_full_name: string | null;
  bank_name: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  account_number: string | null;
  routing_number: string | null;
  bank_id: string | null;
}

export interface Address {
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zip: string;
}

export interface Opportunity {
  application_uuid: string;
  decline_driver_notes: string;
  decline_drivers: string;
  name: string;
  prequal_analyst_name: string;
  renewal_submitter_email: string | null;
  stage_name: string;
  sub_stage: string;
  submission_source: string;
  type: string;
  uuid: string;
  account: Account;
  underwriter_sign_off_timestamp: Date | null;
  underwriter_sign_off_id: number | null;
  underwriter_sign_off_name: string | null;
  x2dc_underwriter__c?: string;
  decision_analyst_id: number | null;
  processing_analyst_id: number | null;
}

export interface Owner {
  id?: number;
  uuid?: string;
  first_name: string;
  last_name: string;
  born_on: string | null;
  email: string;
  home_phone: string | null;
  cell_phone: string | null;
  ssn: string | null;
  title: string;
  ownership_percentage?: number | undefined;
  address: Address;
  ssnNotPresent?: boolean;
  bornOnNotPresent?: boolean;
  key?: string;
  deleted_at?: Date;
}
