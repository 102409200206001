import {
  Banner,
  Card,
  formatDateString,
  IconButton,
  Tabs,
} from '@forward-financing/fast-forward';
import { useCreateSheet, useFetchSheets } from './cashFlowHooks';
import { WorksheetContainer } from './WorksheetContainer';
import { SecondarySheetsContainer } from './SecondarySheetsContainer';
import { Sheet } from './cashFlow.types';
import {
  OVERVIEW_TAB_ID,
  useWorksheetsActiveTabContext,
} from './Context/WorksheetsActiveTabContext';

type CashFlowCardContainerProps = {
  submissionUuid: string;
};

export const CashFlowCardContainer = ({
  submissionUuid,
}: CashFlowCardContainerProps): JSX.Element => {
  const { data: sheets, error: sheetsError } = useFetchSheets(submissionUuid);
  const { activeTab, setActiveTab } = useWorksheetsActiveTabContext();

  const {
    mutate: createSheet,
    error: createSheetError,
    isError: isCreateSheetError,
    reset: resetCreateMutationState,
  } = useCreateSheet(submissionUuid);

  const overviewSheet = sheets?.find((s) => s.type === 'sheet_overviews');
  const overviewClosingSheet = sheets?.find(
    (s) => s.type === 'sheet_closing_overviews'
  );

  const createNewSheetHandler = (): void => {
    // name is the only required field and it is not used
    createSheet({ sheet: { name: '0000' } });
  };

  const mainSheets = sheets?.filter((s) => s.type === 'sheet_currents');
  const secondarySheets = sheets?.filter((s) => s.type === 'sheet_closings');

  const findClosingSheets = (sheetId: number): Sheet | undefined =>
    secondarySheets?.find((s) => s.parentSheetId === sheetId);

  return (
    <Card title="Cash Flow" collapsible>
      {sheetsError && <Banner>{sheetsError.message}</Banner>}

      {isCreateSheetError && (
        <Banner onDismiss={() => resetCreateMutationState()}>
          {createSheetError.message}
        </Banner>
      )}

      <Tabs
        defaultValue={OVERVIEW_TAB_ID}
        value={activeTab}
        onValueChange={(value) => setActiveTab(value)}
      >
        <Tabs.List>
          {overviewSheet && (
            <Tabs.Trigger value={OVERVIEW_TAB_ID}>Overview</Tabs.Trigger>
          )}

          {mainSheets?.map((sheet) => (
            <Tabs.Trigger value={sheet.id} key={sheet.id}>
              {sheet.accountNumber
                ? sheet.accountNumber?.toString().substring(-4)
                : formatDateString(sheet.createdAt)}
            </Tabs.Trigger>
          ))}

          <IconButton
            icon={'plus'}
            label="Add new Sheet"
            onClick={createNewSheetHandler}
          />
        </Tabs.List>

        {overviewSheet && (
          <Tabs.Content value={OVERVIEW_TAB_ID}>
            {overviewClosingSheet ? (
              <SecondarySheetsContainer
                underwritingSheetData={overviewSheet}
                closingSheetData={overviewClosingSheet}
                isOverview
                submissionUuid={submissionUuid}
              />
            ) : (
              <WorksheetContainer
                sheetData={overviewSheet}
                isOverview
                submissionUuid={submissionUuid}
              />
            )}
          </Tabs.Content>
        )}

        {mainSheets?.map((sheet) => {
          const closingSheets = findClosingSheets(+sheet.id);

          return (
            <Tabs.Content value={sheet.id} key={sheet.id}>
              {closingSheets ? (
                <SecondarySheetsContainer
                  underwritingSheetData={sheet}
                  closingSheetData={closingSheets}
                  tabNamePrefix={
                    sheet.accountNumber || formatDateString(sheet.createdAt)
                  }
                  submissionUuid={submissionUuid}
                />
              ) : (
                <WorksheetContainer
                  sheetData={sheet}
                  submissionUuid={submissionUuid}
                />
              )}
            </Tabs.Content>
          );
        })}
      </Tabs>
    </Card>
  );
};
