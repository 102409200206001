import { useCallback } from 'react';
import {
  useGetApiLexisNexisOwnerManualSearch,
  useGetApiOwner,
} from 'apiHooks/underwriting/ownerFetchHooks';
import {
  OwnerLexisNexisSearchResultResponse,
  OwnerResponse,
} from 'types/api/underwriting/types';
import {
  MutationResponse,
  UseGenericMutationResult,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { toAddressString } from '../helpers';
import { LatestLexisNexisOwnerReports } from '../LastPulledOnDate/lastPulledOnDateHooks';
import {
  Owner,
  OwnerLexisNexisManualSearch,
  OwnerManualSearchFetcher,
  OwnerManualSearchQueryParams,
} from './ownerManualSearch.types';

const toOwnerLexisNexisManualSearch = (
  result: OwnerLexisNexisSearchResultResponse,
  latestLexisNexisOwnerReports: LatestLexisNexisOwnerReports
): OwnerLexisNexisManualSearch => {
  return {
    name: result.full_name,
    ssn: result.ssn,
    dob: result.date_of_birth?.map((date) => `${date.month}/${date.year}`),
    addresses: result.addresses?.map(toAddressString),
    reportId: result.report_identifier,
    createdAt: result.created_at ?? undefined,
    lastPulledOnDate:
      latestLexisNexisOwnerReports[result.report_identifier]?.created_at,
  };
};

const toManualSearchQueryParams = (
  params: OwnerManualSearchQueryParams
): string => {
  const queryParams = [
    params.ssn && `ssn=${params.ssn}`,
    params.firstName && `first_name=${params.firstName}`,
    params.lastName && `last_name=${params.lastName}`,
    params.state && `state=${params.state}`,
    params.street1 && `street1=${params.street1}`,
    params.phone && `phone=${params.phone}`,
    params.city && `city=${params.city}`,
    params.zip && `zip=${params.zip}`,
  ];

  return queryParams.filter(Boolean).join('&');
};

type UseLexisNexisManualSearchResult = [
  OwnerManualSearchFetcher,
  UseGenericMutationResult<OwnerLexisNexisManualSearch[]>
];

export const useGetLexisNexisManualSearchResult = (
  latestLexisNexisOwnerReports: LatestLexisNexisOwnerReports
): UseLexisNexisManualSearchResult => {
  const [fetcher, { data, loading, error, responseReady }] =
    useGetApiLexisNexisOwnerManualSearch();

  const fetchSearchResults = useCallback(
    (
      ownerUuid: string,
      submissionUuid: string,
      queryParams: OwnerManualSearchQueryParams
    ): Promise<MutationResponse> => {
      const params = toManualSearchQueryParams(queryParams);
      return fetcher({ ownerUuid, submissionUuid, queryParams: params });
    },
    [fetcher]
  );

  return [
    fetchSearchResults,
    {
      data: data?.results?.map((result) =>
        toOwnerLexisNexisManualSearch(result, latestLexisNexisOwnerReports)
      ),
      loading,
      error,
      responseReady,
    },
  ];
};

const toOwner = (response?: OwnerResponse): Owner => {
  return {
    ssn: response?.ssn ?? undefined,
    firstName: response?.first_name,
    lastName: response?.last_name,
    street1: response?.address?.street1 ?? undefined,
    cellPhone: response?.cell_phone ?? undefined,
    homePhone: response?.home_phone ?? undefined,
    city: response?.address?.city ?? undefined,
    state: response?.address?.state ?? undefined,
    zip: response?.address?.zip ?? undefined,
  };
};

export const useOwner = (ownerUuid?: string): UseGenericQueryResponse<Owner> =>
  useGenericFeatureQuery(useGetApiOwner, toOwner, ownerUuid);
