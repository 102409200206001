import { FinancingFrequency } from '../cashFlow.types';

export const getAdvancedAmountValue = (
  monthlyPayment: number,
  term?: number,
  factorRate?: number
): number => {
  if (!term || !factorRate) return 0;

  return (monthlyPayment * term) / factorRate;
};

export const getGrossPercentValue =
  (netDepositOverride: number) =>
  (monthlyPayment: number): number => {
    const overrideValue = netDepositOverride / 100;
    return (monthlyPayment / overrideValue) * 100;
  };

export const getMonthlyPaymentValue = (
  amount: number,
  frequency: string
): number => {
  switch (frequency) {
    case 'cc_split':
    case 'daily':
      return amount * 21;
    case 'weekly':
      return amount * 4;
    case 'biweekly':
      return amount * 2;
    case 'monthly':
      return amount;
    case 'loc':
    default:
      return 0;
  }
};

export const isFinancingFrequency = (
  value: unknown
): value is FinancingFrequency => {
  return (
    typeof value === 'string' &&
    (value === 'daily' ||
      value === 'weekly' ||
      value === 'biweekly' ||
      value === 'monthly' ||
      value === 'loc' ||
      value === 'cc_split')
  );
};

export const parseCurrency = (value: string | number): number =>
  parseFloat(String(value).replace(/[^0-9.-]+/g, '')) || 0;
export const toCents = (dollars: number): number => Math.round(dollars * 100);
export const toDollars = (cents: number): number => cents / 100;
