import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { GenericLedgerApiParams } from 'components/DealScoring/Ledger/ledger.types';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { BANK_BASE_URL, UNDERWRITING_BASE_URL } from 'constants/globals';
import {
  BuyRatesResponse,
  LedgerBankingPricingSuggestionsRaw,
  LedgerOfferRaw,
  LedgerResponse,
  OverrideByRatesPayload,
  PatchLedgerRequestBody,
  UpdateLedgerBody,
} from 'types/api/banking/types';
import {
  CLONE_LEDGER_URL,
  CREATE_LEDGER_URL,
  DELETE_LEDGER_URL,
  FETCH_BANKING_SUGGESTED_PRICING_URL,
  FETCH_BUY_RATES_URL,
  FETCH_LEDGERS_URL,
  FETCH_OFFERS_URL,
  OVERRIDES_BUY_RATES_URL,
  SAVE_LEDGER_URL,
  SEND_LEDGER_TO_CREDIT_COMMITTEE_URL,
  VALIDATIONS_OVERRIDE_URL,
} from 'api/constants';
import {
  OverriddenValidationsResponse,
  ValidationOverridePayload,
} from 'types/api/underwriting/types';

export const updateLedger = async (
  submissionUuid: string,
  updateBody: UpdateLedgerBody
): Promise<MutationResponse> => {
  const url = new URL(`/api/v2/ledgers/${submissionUuid}`, BANK_BASE_URL());

  const response = await makeInternalAPIRequest<void, UpdateLedgerBody>(
    url,
    'PATCH',
    updateBody
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to update ledger');
  }

  return { success: true };
};

export const fetchLedgers = async (
  submissionUuid: string
): Promise<LedgerResponse[]> => {
  const url = new URL(FETCH_LEDGERS_URL(submissionUuid), BANK_BASE_URL());

  const response = await makeInternalAPIRequest<LedgerResponse[]>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Ledgers');
  }

  return await response.json();
};

export const overrideBuyRates = async ({
  submissionUuid,
  ledgerId,
  requestBody,
}: GenericLedgerApiParams & {
  requestBody: OverrideByRatesPayload;
}): Promise<BuyRatesResponse> => {
  const url = new URL(
    OVERRIDES_BUY_RATES_URL(submissionUuid, ledgerId),
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    BuyRatesResponse,
    OverrideByRatesPayload
  >(url, 'POST', requestBody);

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to override buy rates');
  }

  return response.json();
};

export const patchLedger = async ({
  ledgerId,
  requestBody,
  submissionUuid,
}: GenericLedgerApiParams & {
  requestBody: PatchLedgerRequestBody;
}): Promise<LedgerResponse | undefined> => {
  const url = new URL(
    SAVE_LEDGER_URL(submissionUuid, ledgerId),
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<LedgerResponse>(
    url,
    'PATCH',
    requestBody
  );

  if (!response.ok) {
    if (response.status === 422) {
      const responseBody = (await response.json()) as { error: string };

      // BE returns {error: "Validation failure"} when there is validation override needed and
      // returns {error: string} for other validation failures
      const errorMessage =
        responseBody.error === 'Validation failure'
          ? 'Validation override required'
          : responseBody.error;

      throw new NetworkError(response.status, errorMessage);
    }

    throw new NetworkError(response.status, 'Failed to update Ledger');
  }

  let parsedResponse;

  try {
    parsedResponse = await response.json();
  } catch {
    parsedResponse = undefined;
  }

  return parsedResponse;
};

export const sentToCreditCommittee = async ({
  submissionUuid,
  ledgerId,
}: GenericLedgerApiParams): Promise<MutationResponse> => {
  const url = new URL(
    SEND_LEDGER_TO_CREDIT_COMMITTEE_URL(submissionUuid, ledgerId),
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<null, Record<string, never>>(
    url,
    'PATCH',
    {}
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to send To Credit Committee'
    );
  }

  return { success: true };
};

export const cloneLedger = async ({
  ledgerId,
  submissionUuid,
}: GenericLedgerApiParams): Promise<MutationResponse> => {
  const url = new URL(
    CLONE_LEDGER_URL(submissionUuid, ledgerId),
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<MutationResponse>(url, 'POST');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to clone Ledger');
  }

  return { success: true };
};

export const fetchBuyRates = async ({
  submissionUuid,
  ledgerId,
  params,
}: GenericLedgerApiParams): Promise<BuyRatesResponse> => {
  const url = new URL(
    `${FETCH_BUY_RATES_URL(submissionUuid, ledgerId)}?${params}`,
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<BuyRatesResponse>(url, 'GET');

  if (!response.ok) {
    let errorText = await response.text();

    errorText = errorText?.length ? errorText : 'Failed to fetch Buy Rates';

    throw new NetworkError(response.status, errorText);
  }

  return response.json();
};

export const deleteLedger = async ({
  ledgerId,
  submissionUuid,
}: GenericLedgerApiParams): Promise<MutationResponse> => {
  const url = new URL(
    DELETE_LEDGER_URL(submissionUuid, ledgerId),
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<void>(url, 'DELETE');

  if (response.ok) {
    return { success: true };
  }

  throw new NetworkError(response.status, 'Failed to delete ledger');
};

export const createLedger = async ({
  submissionUuid,
}: Omit<GenericLedgerApiParams, 'ledgerId'>): Promise<MutationResponse> => {
  const url = new URL(CREATE_LEDGER_URL(submissionUuid), BANK_BASE_URL());

  const response = await makeInternalAPIRequest<void>(url, 'POST');

  if (response.ok) {
    return { success: true };
  }

  throw new NetworkError(response.status, 'Failed to create ledger');
};

export const fetchLedgerOffers = async ({
  ledgerId,
  submissionUuid,
}: GenericLedgerApiParams): Promise<LedgerOfferRaw[]> => {
  const url = new URL(
    FETCH_OFFERS_URL(submissionUuid, ledgerId),
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<LedgerOfferRaw[]>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Offers');
  }

  return response.json();
};

export const fetchLedgerBankingSuggestedPricing = async (
  ledgerId: number
): Promise<LedgerBankingPricingSuggestionsRaw> => {
  const url = new URL(
    FETCH_BANKING_SUGGESTED_PRICING_URL(ledgerId),
    BANK_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<LedgerBankingPricingSuggestionsRaw>(
      url,
      'GET'
    );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Banking Suggested Pricing'
    );
  }

  const json = await response.json();

  // [FE-2066]: Hotfix will be removed after endpoint is updated
  if (json && json.errors) {
    throw new NetworkError(
      response.status,
      `We are unable to suggest pricing because ${json.errors}`
    );
  }

  return json;
};

export const overriddenValidations = async (
  submissionUuid: string
): Promise<OverriddenValidationsResponse[]> => {
  const url = new URL(
    VALIDATIONS_OVERRIDE_URL(submissionUuid),
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    OverriddenValidationsResponse[]
  >(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to get Overridden Validations'
    );
  }

  return response.json();
};

export const validationOverride = async (
  submissionUuid: string,
  updateBody: ValidationOverridePayload
): Promise<MutationResponse> => {
  const url = new URL(
    VALIDATIONS_OVERRIDE_URL(submissionUuid),
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    void,
    ValidationOverridePayload
  >(url, 'POST', updateBody);

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to Override Validation');
  }

  return { success: true };
};

export const fetchLedger = async (
  ledgerId: number
): Promise<LedgerResponse> => {
  const url = new URL(`/api/v3/ledgers/${ledgerId}`, BANK_BASE_URL());

  const response = await makeInternalAPIRequest<LedgerResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Ledger');
  }

  return response.json();
};
