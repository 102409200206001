import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as React from 'react';

import { isEqual } from 'lodash';
import { Banner, Box, Flex, Icon, Text } from '@forward-financing/fast-forward';
import { useDeepCompareMemoize } from 'hooks/useDeepCompareMemoize';
import { Ledger } from '../ledger.types';
import { LEDGER_PROGRAM_OPTIONS_MAPPING } from '../constants';
import { usePatchLedger } from '../ledgerHooks';
import {
  LedgerContextData,
  OffersValidationState,
  UseOffersValidationReturnType,
  UseProgramValidationReturnType,
} from './LedgerContext.types';
import {
  LEDGER_VALIDATION_DEFAULT_OFFERS_STATE,
  RISK_ASSIGNMENTS,
} from './LedgerContext.constants';

const LedgerContext = createContext<LedgerContextData | undefined>(undefined);

export const useLedgerContext = (): LedgerContextData => {
  const context = useContext(LedgerContext);

  /**
   * Added this check to improve DevEx.
   * In case if someone accidentally uses this hook outside of the provider.
   */
  if (context === undefined) {
    throw new Error(
      'useLedgerContext must be used within a LedgerContextProvider'
    );
  }

  return context;
};

const useOffersValidation = (): UseOffersValidationReturnType => {
  const [areOffersInvalid, setAreOffersInvalid] = useState(false);

  const [offersValidationState, setOffersValidationState] =
    useState<OffersValidationState>(LEDGER_VALIDATION_DEFAULT_OFFERS_STATE);

  /**
   * Memoizing state in order to reduce amount of useEffect calls.
   * We want to trigger useEffect only when
   * offersValidationState values REALLY changes.
   */
  const memoizedOffersValidationState = useDeepCompareMemoize(
    offersValidationState
  );

  useEffect(() => {
    const isInvalid = Object.values(memoizedOffersValidationState).some(
      (valid) => !valid
    );

    setAreOffersInvalid(isInvalid);
  }, [memoizedOffersValidationState]);

  const updateOfferValidationState = useCallback(
    (month: number, isValid: boolean) => {
      setOffersValidationState((prevState) => {
        return {
          ...prevState,
          [month]: isValid,
        };
      });
    },
    []
  );

  return { areOffersInvalid, updateOfferValidationState };
};

const useProgramValidation = (
  programExplanation: string,
  selectedProgram: number,
  submissionType: string
): UseProgramValidationReturnType => {
  const [suggestedProgram, setSuggestedProgram] = useState<string>('');

  const isProgramExplanationRequired = useMemo(() => {
    const isNewDeal = submissionType === 'New Deal';

    // Id 34 corresponds to program 'Starter', id 1 corresponds to program 'High Risk',
    const isRequiredProgram = [34, 1].includes(selectedProgram);
    const doesNotMatchSuggestedPricingProgram =
      suggestedProgram !== LEDGER_PROGRAM_OPTIONS_MAPPING[selectedProgram];

    return (
      isNewDeal && (isRequiredProgram || doesNotMatchSuggestedPricingProgram)
    );
  }, [suggestedProgram, selectedProgram, submissionType]);

  const isProgramExplanationInvalid = useMemo(() => {
    return isProgramExplanationRequired ? !programExplanation.length : false;
  }, [isProgramExplanationRequired, programExplanation]);

  return {
    isProgramExplanationRequired,
    setSuggestedProgram,
    isProgramExplanationInvalid,
  };
};

export const LedgerContextProvider = ({
  children,
  ledger,
  submissionType,
  uaSuggestedProgram,
  shouldShowBanners,
  submissionUuid,
  refetchLedgers,
}: React.PropsWithChildren<{
  ledger: Ledger;
  submissionType: string;
  shouldShowBanners: boolean;
  uaSuggestedProgram?: string;
  submissionUuid: string;
  refetchLedgers: () => void;
}>): JSX.Element => {
  // This state is to record the program returned by UA suggested pricing which is behind a FT.
  // When the FeatureFlag is ON for everyone, we could use the original "suggestedProgram" state
  // for recording program type, since right now that state could house BA suggested pricing or UA
  // suggested pricing depending on the FT.
  const [underwritingSuggestedProgram, setUnderwritingSuggestedProgram] =
    useState<string | undefined>(uaSuggestedProgram);
  /**
   * Memoizing the ledger in order to prevent unnecessary re-renders.
   */
  const memoizedLedger = useDeepCompareMemoize(ledger);
  /**
   * State to track all changes made by users.
   */
  const [ledgerToUpdate, setLedgerToUpdate] = useState<Ledger>(memoizedLedger);

  useEffect(() => {
    if (!isEqual(memoizedLedger, ledgerToUpdate)) {
      setLedgerToUpdate(memoizedLedger);
    }

    // We don't want to react to changes in ledgerToUpdate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedLedger]);

  const hasUnsavedChanges = useMemo(
    () => !isEqual(memoizedLedger, ledgerToUpdate),
    [memoizedLedger, ledgerToUpdate]
  );

  const handleChangeLedger = useCallback(
    (toUpdate: Partial<Ledger>): void => {
      setLedgerToUpdate((prevLedger) => {
        const updatedLedger = { ...prevLedger, ...toUpdate };

        return updatedLedger;
      });
    },
    [setLedgerToUpdate]
  );

  const [
    patchLedger,
    { error: errorFromPatchLedger, loading: isPatchLedgerLoading },
  ] = usePatchLedger();

  const saveLedger = async (
    shouldLock?: boolean
  ): Promise<{ success: boolean }> => {
    // Do not send the request if there are no changes.
    if (!shouldLock && isEqual(memoizedLedger, ledgerToUpdate)) {
      return { success: true };
    }

    const { success, response } = await patchLedger({
      ledger: { ...ledgerToUpdate, ...(shouldLock && { lock: true }) },
      ledgerId: ledgerToUpdate.id,
      submissionUuid,
    });

    if (success && response) {
      setLedgerToUpdate(response);

      refetchLedgers();

      return { success: true };
    }

    return { success: false };
  };

  const { areOffersInvalid, updateOfferValidationState } =
    useOffersValidation();

  const {
    isProgramExplanationInvalid,
    setSuggestedProgram,
    isProgramExplanationRequired,
  } = useProgramValidation(
    ledgerToUpdate.programExplanation,
    ledgerToUpdate.program,
    submissionType
  );

  const getRiskAssignment =
    underwritingSuggestedProgram &&
    RISK_ASSIGNMENTS[underwritingSuggestedProgram];

  const showOverrideBuyRatesReasonWarning =
    ledgerToUpdate.overrideBuyRates &&
    ledgerToUpdate.overrideBuyRatesReason.trim().length === 0;

  /**
   * Save button should be disabled if next conditions are met:
   *  - any of the offers are invalid
   *  - selected program type does not match suggested and explanation is empty
   *
   * BE will not allow us save ledgers if any of problems above appears.
   * So we're preventing unnecessary requests to BE.
   */
  const isSaveDisabled = useMemo(
    () => areOffersInvalid || isProgramExplanationInvalid,
    [areOffersInvalid, isProgramExplanationInvalid]
  );

  const showWarningBanner =
    hasUnsavedChanges ||
    showOverrideBuyRatesReasonWarning ||
    isProgramExplanationInvalid;

  return (
    <LedgerContext.Provider
      value={{
        isSaveDisabled,
        updateOfferValidationState,
        ledgerToUpdate,
        setLedgerToUpdate,
        isProgramExplanationRequired,
        setSuggestedProgram,
        isProgramExplanationInvalid,
        handleChangeLedger,
        hasUnsavedChanges,
        setUnderwritingSuggestedProgram,
        getRiskAssignment,
        ledger,
        saveLedger,
        isPatchLedgerLoading,
        errorFromPatchLedger,
      }}
    >
      {showWarningBanner && shouldShowBanners && (
        <Box mt={2}>
          <Banner dismissable={false} variant="warning">
            <Flex flexDirection="column">
              {hasUnsavedChanges && (
                <Flex flexDirection="row" gap={2}>
                  <Icon name="triangle-exclamation" />
                  <Text>This section has unsaved changes.</Text>
                </Flex>
              )}
              {showOverrideBuyRatesReasonWarning && (
                <Flex flexDirection="row" gap={2}>
                  <Icon name="triangle-exclamation" />
                  <Text>Override Explanation is required.</Text>
                </Flex>
              )}
              {isProgramExplanationInvalid && (
                <Flex flexDirection="row" gap={2}>
                  <Icon name="triangle-exclamation" />
                  <Text>
                    Program Explanation Required because the program is
                    Starter/Core or does not match with suggested pricing.
                  </Text>
                </Flex>
              )}
            </Flex>
          </Banner>
        </Box>
      )}
      {children}
    </LedgerContext.Provider>
  );
};
