import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { NewDealScore, ScoreForm } from './NewDealScoring.types';

export type NewDealScoreInput = {
  owner_risk: number | null;
  business_risk: number | null;
  quality_of_cash_flows: number | null;
  underwriter_score_note_attributes: {
    note: string | null;
  };
  underwriter_score_review_attributes: {
    process_rating: number | null;
    pricing_rating: number | null;
    note: string | null;
    underwriter_score_review_responses_attributes: NewDealScoreResponseChecklistItem[];
  };
};

export type NewDealScoreResponseChecklistItem = {
  underwriter_score_review_question_id: string;
  id: string | null;
  question: string;
  question_type: 'deal' | 'process';
  descriptors: string[];
  response: boolean | null;
};

export type NewDealScoreResponse = {
  owner_risk: number | null;
  business_risk: number | null;
  quality_of_cash_flows: number | null;
  underwriter_note: string | null;
  process_rating: number | null;
  underwriter_score_review_responses: NewDealScoreResponseChecklistItem[];
  pricing_rating: number | null;
  manager_note: string | null;
  online_presence: number | null;
  deal_specific_factors: number | null;
  deal_funded_on: string | null;
  average_score: number | null;
  last_updated: string | null;
  last_updated_by: string | null;
};

export type NewDealAutomatedScoreResponse = {
  business_risk: number;
  owner_risk: number;
  average_score: number;
};

const responseToScore = (data: NewDealScoreResponse): NewDealScore => ({
  onlinePresence: data.online_presence || null,
  ownerRisk: data.owner_risk || null,
  businessRisk: data.business_risk || null,
  qualityOfCashFlows: data.quality_of_cash_flows || null,
  dealSpecificFactors: data.deal_specific_factors || null,
  underwriterNote: data.underwriter_note || null,
  dealFundedOn: data.deal_funded_on || null,
  averageScore: data.average_score || null,
  pricingRating: data.pricing_rating || null,
  checklistResponses: data.underwriter_score_review_responses.map(
    (response) => {
      return {
        underwriterScoreReviewQuestionId:
          response.underwriter_score_review_question_id,
        id: response.id,
        questionType: response.question_type,
        question: response.question,
        descriptors: response.descriptors,
        response: response.response,
      };
    }
  ),
  processRating: data.process_rating || null,
  managerNote: data.manager_note || null,
  lastUpdated: data.last_updated || null,
  lastUpdatedBy: data.last_updated_by || null,
});

const scoreToInput = (score: ScoreForm): NewDealScoreInput => ({
  owner_risk: score.ownerRisk === 0 ? null : score.ownerRisk,
  business_risk: score.businessRisk === 0 ? null : score.businessRisk,
  quality_of_cash_flows:
    score.qualityOfCashFlows === 0 ? null : score.qualityOfCashFlows,
  underwriter_score_note_attributes: {
    // not bothering to null check this one because we'll always have at least the initial text
    note: score.underwriterNote,
  },
  underwriter_score_review_attributes: {
    pricing_rating: score.pricingRating === 0 ? null : score.pricingRating,
    process_rating: score.processRating === 0 ? null : score.processRating,
    note: score.managerNote === '' ? null : score.managerNote,
    underwriter_score_review_responses_attributes: score.checklistResponses.map(
      (response) => {
        return {
          underwriter_score_review_question_id:
            response.underwriterScoreReviewQuestionId,
          id: response.id,
          question_type: response.questionType,
          question: response.question,
          descriptors: response.descriptors,
          response: response.response === null ? true : response.response,
        };
      }
    ),
  },
});

export const fetchNewDealScore = async (
  submissionUuid: string
): Promise<NewDealScore> => {
  const response = await makeInternalAPIRequest<NewDealScoreResponse>(
    new URL(
      `/api/v2/submissions/${submissionUuid}/underwriter_scores`,
      UNDERWRITING_BASE_URL()
    ),
    'GET'
  );

  if (!response.ok) {
    if (response.status === 404) {
      throw new NetworkError(response.status, 'New Deal Score not found');
    } else if (response.status === 403) {
      throw new NetworkError(response.status, 'You are not authorized');
    }
    throw new Error(
      `${response.status} Error fetching New Deal Score data. Please reload the page and try again.`
    );
  }

  const parsedResponse = await response.json();
  return responseToScore(parsedResponse);
};

export const createScore = async (
  submissionUuid: string,
  score: ScoreForm
): Promise<NewDealScore> => {
  const response = await makeInternalAPIRequest<
    NewDealScoreResponse,
    NewDealScoreInput
  >(
    new URL(
      `/api/v2/submissions/${submissionUuid}/underwriter_scores`,
      UNDERWRITING_BASE_URL()
    ),
    'POST',
    scoreToInput(score)
  );

  if (!response.ok) {
    if (response.status === 404) {
      throw new Error(`New Deal Score not found`);
    } else if (response.status === 403) {
      throw new NetworkError(response.status, 'You are not authorized');
    }

    throw new Error(
      `${response.status} Error creating New Deal Score. Please reload the page and try again.`
    );
  }

  const parsedResponse = await response.json();
  return responseToScore(parsedResponse);
};

export const updateScore = async (
  submissionUuid: string,
  score: ScoreForm
): Promise<NewDealScore> => {
  const response = await makeInternalAPIRequest<
    NewDealScoreResponse,
    NewDealScoreInput
  >(
    new URL(
      `/api/v2/submissions/${submissionUuid}/underwriter_scores`,
      UNDERWRITING_BASE_URL()
    ),
    'PATCH',
    scoreToInput(score)
  );

  if (!response.ok) {
    if (response.status === 404) {
      throw new Error(`New Deal Score not found`);
    } else if (response.status === 403) {
      throw new NetworkError(response.status, 'You are not authorized');
    }

    throw new Error(
      `${response.status} Error updating New Deal Score. Please reload the page and try again.`
    );
  }

  const parsedResponse = await response.json();
  return responseToScore(parsedResponse);
};
