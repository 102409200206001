import { formatCurrency, Table } from '@forward-financing/fast-forward';
import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';
import { ExceptionRequest } from '../../ExceptionsRequest/exceptionsRequest.types';

interface TableOfferProp {
  type: string;
  buyRate?: number;
  fundingAmount?: number;
  program?: string;
  termLength?: number;
}

interface ExceptionRequestOfferTableProps {
  exceptionRequest: ExceptionRequest;
}

const HIGH_RISK_PROGRAMS = ['Core', 'High Risk'];

export const ExceptionRequestOfferTable = ({
  exceptionRequest,
}: ExceptionRequestOfferTableProps): JSX.Element => {
  const getExceptionOfferValues = (req: ExceptionRequest): TableOfferProp[] => {
    const FFOffer = req.offer;
    const underwriterDecision = req.underwriterDecision;
    const arr = [
      {
        type: 'FF Offer',
        buyRate: FFOffer?.buyRate,
        fundingAmount: FFOffer?.fundingAmount,
        program: FFOffer?.program,
        termLength: FFOffer?.termLength,
      },
      {
        type: 'Exception Request',
        buyRate: req.buyRate,
        fundingAmount: req.fundingAmount,
        program: req.program,
        termLength: req.termLength,
      },
    ];
    const obj = {
      type: 'Modified Offer',
      buyRate: underwriterDecision?.buyRate,
      fundingAmount: underwriterDecision?.fundingAmount,
      program: underwriterDecision?.program,
      termLength: underwriterDecision?.termLength,
    };
    if (underwriterDecision) {
      arr.push(obj);
    }
    return arr;
  };

  const getBGColor = (
    item: TableOfferProp,
    condition: boolean
  ): ColorVariant | undefined => {
    const highlightRow = exceptionRequest.underwriterDecision
      ? 'Modified Offer'
      : 'Exception Request';
    return item.type === highlightRow && condition ? 'yellow-200' : undefined;
  };

  const getProgramHighLightCondition = (
    offerProgram = '',
    exceptionProgram = ''
  ): boolean => {
    if (
      HIGH_RISK_PROGRAMS.includes(offerProgram) &&
      HIGH_RISK_PROGRAMS.includes(exceptionProgram)
    )
      return false;

    return offerProgram !== exceptionProgram;
  };

  return (
    <Table>
      <Table.Head>
        <Table.ColumnHeader>Offer</Table.ColumnHeader>
        <Table.ColumnHeader>Funding Amount</Table.ColumnHeader>
        <Table.ColumnHeader>Term Length</Table.ColumnHeader>
        <Table.ColumnHeader>Buy Rate</Table.ColumnHeader>
        <Table.ColumnHeader>Program</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        {getExceptionOfferValues(exceptionRequest).map((item) => {
          return (
            <Table.Row key={exceptionRequest.id.toString()}>
              <Table.Cell>{item.type}</Table.Cell>
              <Table.Cell
                backgroundColor={getBGColor(
                  item,
                  item.fundingAmount !== exceptionRequest.offer?.fundingAmount
                )}
              >
                {item.fundingAmount && formatCurrency(item.fundingAmount)}
              </Table.Cell>
              <Table.Cell
                backgroundColor={getBGColor(
                  item,
                  item.termLength !== exceptionRequest.offer?.termLength
                )}
              >
                {item.termLength}
              </Table.Cell>
              <Table.Cell
                backgroundColor={getBGColor(
                  item,
                  item.buyRate !== exceptionRequest.offer?.buyRate
                )}
              >
                {item.buyRate}
              </Table.Cell>
              <Table.Cell
                backgroundColor={getBGColor(
                  item,
                  getProgramHighLightCondition(
                    exceptionRequest.offer?.program,
                    item.program
                  )
                )}
              >
                {item.program}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
