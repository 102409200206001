import { useMemo } from 'react';
import {
  Flex,
  formatCurrency,
  formatDateString,
  Icon,
  Link,
  Loading,
  QualityIcon,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { capitalize, defaultTo } from 'lodash';
import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';
import { FUNDING_URL } from 'constants/globals';
import { displayPercentage } from 'helpers/utils';
import { featureFlags } from 'helpers/featureFlags';
import {
  useAcceptedOffer,
  useAcceptedOffers,
  useMultipleRenewalComparisonSubmission,
  useRenewalComparisonSubmission,
  useSubmissionCreditDecision,
  useSubmissionCreditDecisions,
  useSubmissionUnderwriterName,
} from '../renewalComparisonHooks';
import {
  PreviousFundedSubmission,
  RenewalComparisonSubmission,
} from '../renewalComparison.types';

const calculatePaymentAmount = (
  amount: string | number,
  isDaily: boolean
): number => {
  const numericAmount = Number(amount);

  if (isNaN(numericAmount)) {
    return 0;
  }

  if (isDaily) {
    return numericAmount;
  }

  // Multiply by 5 as this is a weekly payment
  return numericAmount * 5;
};

const convertPaceToQualityIconColor = (
  pace: number
): 'good' | 'bad' | 'neutral' => {
  if (pace <= 33) {
    return 'bad';
  }

  if (pace > 67) {
    return 'good';
  }

  return 'neutral';
};

const getFundedAmountComparisonColor = (comparison: number): ColorVariant => {
  if (comparison < 0) return 'danger';
  if (comparison > 0) return 'success';
  return 'primary';
};

type PaymentHistoryLinkProps = {
  recordId: number;
};

const PaymentHistoryLink = ({
  recordId,
}: PaymentHistoryLinkProps): JSX.Element => {
  const url = new URL(
    `admin/advances/${recordId}?tab=payback-schedule`,
    FUNDING_URL()
  );
  return (
    <Link href={url} newTab>
      View Payment Calendar <Icon name="arrow-up-right-from-square" />
    </Link>
  );
};

export type AdvanceComparisonFundingInfoTableProps = {
  currentSubmissionUuid: string;
  previousFundedSubmissions: PreviousFundedSubmission[] | undefined;
};

const shouldShowPercentageRepaidAtRenewal = (
  submission?: RenewalComparisonSubmission
): submission is RenewalComparisonSubmission => {
  return (
    (submission &&
      !submission.isAddOn &&
      submission.percentageRepaidAtRenewal !== undefined &&
      !isNaN(Number(submission.percentageRepaidAtRenewal))) ??
    false
  );
};

export const AdvanceComparisonFundingInfoTable = ({
  currentSubmissionUuid,
  previousFundedSubmissions,
}: AdvanceComparisonFundingInfoTableProps): JSX.Element => {
  // Current Submission data
  const { data: currentSubmissionUnderwriterName } =
    useSubmissionUnderwriterName(currentSubmissionUuid);

  const { data: currentSubmissionData, loading: loadingCurrentSubmission } =
    useRenewalComparisonSubmission(currentSubmissionUuid);

  const {
    data: currentSubmissionAcceptedOffer,
    loading: loadingCurrentSubmissionAcceptedOffer,
  } = useAcceptedOffer(currentSubmissionUuid);

  const {
    data: currentSubmissionCreditDecision,
    loading: loadingCurrentSubmissionCreditDecision,
  } = useSubmissionCreditDecision(currentSubmissionUuid);

  // Previous Funded Submissions data
  const memoizedSubmissionUuids = useMemo(
    () =>
      previousFundedSubmissions?.map((submission) => submission.submissionUuid),
    [previousFundedSubmissions]
  );

  const { data: advanceComparisons, loading: loadingAdvanceComparisons } =
    useMultipleRenewalComparisonSubmission(memoizedSubmissionUuids);

  const { data: acceptedOffers, loading: loadingAcceptedOffers } =
    useAcceptedOffers(memoizedSubmissionUuids);

  const { data: creditDecisions, loading: loadingCreditDecisions } =
    useSubmissionCreditDecisions(memoizedSubmissionUuids);

  if (
    loadingCurrentSubmission ||
    loadingCurrentSubmissionAcceptedOffer ||
    loadingCurrentSubmissionCreditDecision ||
    loadingAdvanceComparisons ||
    loadingAcceptedOffers ||
    loadingCreditDecisions
  ) {
    return <Loading />;
  }

  // RowHeaders + Current Submission + # of previousFundedSubmissions
  const NUMBER_OF_COLUMNS = 1 + 1 + (memoizedSubmissionUuids?.length || 0);

  const parsedFundedAmountComparison = Number(
    currentSubmissionData?.fundedAmountComparison
  );

  return (
    <Table caption="Advance Comparison Table">
      {/* 
          This is the only colgroup (so far) that has a dynamic number of columns 
          as it is partially based on the length of memoizedSubmissionUuids 
          (equivalent to the previousFundedSubmissions prop), so we need to 
          first determine the number of columns and divide that by 100 in 
          order to get equal widths for each column.
      */}
      <Table.ColGroup>
        {Array.from({ length: NUMBER_OF_COLUMNS }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Table.Col key={index} />
        ))}
      </Table.ColGroup>

      <Table.Head>
        <Table.ColumnHeader>Advance Comparison</Table.ColumnHeader>

        <Table.ColumnHeader>Current Submission</Table.ColumnHeader>

        {advanceComparisons?.map((submission) => (
          <Table.ColumnHeader key={submission.advanceNumber}>
            {submission.isAddOn ? 'Add-On' : 'Advance'}{' '}
            {submission.advanceNumber}
          </Table.ColumnHeader>
        ))}
      </Table.Head>

      <Table.Body>
        <Table.Row>
          <Table.RowHeader>UW</Table.RowHeader>
          <Table.Cell>
            {defaultTo(currentSubmissionUnderwriterName, 'Unassigned')}
          </Table.Cell>

          {previousFundedSubmissions?.map((submission) => (
            <Table.Cell key={submission.submissionUuid}>
              {submission.underwriterName || 'Unassigned'}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Max Approved Amount</Table.RowHeader>
          <Table.Cell>
            {typeof currentSubmissionCreditDecision?.maxApprovalAmount ===
            'number'
              ? formatCurrency(
                  currentSubmissionCreditDecision.maxApprovalAmount
                )
              : 'N/A'}
          </Table.Cell>

          {creditDecisions?.map((submission, index) => (
            <Table.Cell
              key={`${submission.maxApprovalAmount}-${memoizedSubmissionUuids?.[index]}`}
            >
              {typeof submission.maxApprovalAmount === 'number'
                ? formatCurrency(submission.maxApprovalAmount)
                : 'N/A'}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Max Approved Term</Table.RowHeader>
          <Table.Cell>
            {typeof currentSubmissionCreditDecision?.maxApprovalTerm ===
            'number'
              ? `${currentSubmissionCreditDecision.maxApprovalTerm} months`
              : 'N/A'}
          </Table.Cell>

          {creditDecisions?.map((submission, index) => (
            <Table.Cell
              key={`${submission.maxApprovalAmount}-${memoizedSubmissionUuids?.[index]}`}
            >
              {typeof submission?.maxApprovalTerm === 'number'
                ? `${submission.maxApprovalTerm} months`
                : 'N/A'}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Funded Date</Table.RowHeader>
          <Table.Cell>
            {typeof currentSubmissionData?.dateOfAdvance === 'string'
              ? formatDateString(currentSubmissionData?.dateOfAdvance)
              : 'N/A'}
          </Table.Cell>

          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {formatDateString(submission.dateOfAdvance)}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Funded Amount</Table.RowHeader>

          <Table.Cell>
            <Text bold>
              {typeof currentSubmissionData?.amount === 'number'
                ? formatCurrency(Number(currentSubmissionData?.amount))
                : 'N/A'}{' '}
              {featureFlags.show_funded_amount_percentage &&
                !isNaN(parsedFundedAmountComparison) && (
                  <Text
                    size="s"
                    color={getFundedAmountComparisonColor(
                      parsedFundedAmountComparison
                    )}
                  >
                    ({parsedFundedAmountComparison.toFixed(2)}%)
                  </Text>
                )}
            </Text>
          </Table.Cell>

          {advanceComparisons?.map(
            ({ advanceNumber, amount, fundedAmountComparison }) => {
              const oldParsedFundedAmountComparison = Number(
                fundedAmountComparison
              );
              return (
                <Table.Cell key={advanceNumber}>
                  <Text bold>
                    {formatCurrency(Number(amount))}{' '}
                    {featureFlags.show_funded_amount_percentage &&
                      !isNaN(oldParsedFundedAmountComparison) && (
                        <Text
                          size="s"
                          color={getFundedAmountComparisonColor(
                            oldParsedFundedAmountComparison
                          )}
                        >
                          ({oldParsedFundedAmountComparison.toFixed(2)}%)
                        </Text>
                      )}
                  </Text>
                </Table.Cell>
              );
            }
          )}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Net Funded Amount</Table.RowHeader>

          <Table.Cell>
            {typeof currentSubmissionData?.netFundedAmount === 'string'
              ? formatCurrency(Number(currentSubmissionData?.netFundedAmount))
              : 'N/A'}
          </Table.Cell>

          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {formatCurrency(Number(submission.netFundedAmount))}
            </Table.Cell>
          ))}
        </Table.Row>

        {featureFlags.show_percent_repaid_at_funding && (
          <Table.Row>
            <Table.RowHeader>% Repaid at Time of Funding</Table.RowHeader>
            <Table.Cell>
              {shouldShowPercentageRepaidAtRenewal(currentSubmissionData)
                ? `${Number(
                    currentSubmissionData.percentageRepaidAtRenewal
                  ).toFixed(2)}%`
                : 'N/A'}
            </Table.Cell>

            {advanceComparisons?.map((submission) => (
              <Table.Cell key={submission.advanceNumber}>
                {shouldShowPercentageRepaidAtRenewal(submission)
                  ? `${Number(submission.percentageRepaidAtRenewal).toFixed(
                      2
                    )}%`
                  : 'N/A'}
              </Table.Cell>
            ))}
          </Table.Row>
        )}

        <Table.Row>
          <Table.RowHeader>Funded Term</Table.RowHeader>

          <Table.Cell>
            <Text bold>
              {typeof currentSubmissionData?.targetTerm === 'number'
                ? `${currentSubmissionData?.targetTerm} months`
                : 'N/A'}
            </Text>
          </Table.Cell>

          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              <Text bold>{submission.targetTerm} months</Text>
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Program</Table.RowHeader>

          <Table.Cell>
            {defaultTo(currentSubmissionAcceptedOffer?.program, 'N/A')}
          </Table.Cell>

          {acceptedOffers?.map((submission) => (
            <Table.Cell key={submission.id}>
              {defaultTo(submission.program, 'N/A')}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Buy Rate</Table.RowHeader>

          <Table.Cell>
            {defaultTo(currentSubmissionAcceptedOffer?.buyRate, 'N/A')}
          </Table.Cell>

          {acceptedOffers?.map((submission) => (
            <Table.Cell key={submission.id}>
              {defaultTo(submission.buyRate, 'N/A')}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Factor Rate</Table.RowHeader>

          <Table.Cell>
            {defaultTo(currentSubmissionAcceptedOffer?.factorRate, 'N/A')}
          </Table.Cell>

          {acceptedOffers?.map((submission) => (
            <Table.Cell key={submission.id}>
              {defaultTo(submission.factorRate, 'N/A')}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Commission</Table.RowHeader>

          <Table.Cell>
            {typeof currentSubmissionData?.commission === 'string'
              ? displayPercentage(Number(currentSubmissionData?.commission))
              : 'N/A'}
          </Table.Cell>

          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {displayPercentage(Number(submission.commission))}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Payment Frequency</Table.RowHeader>

          <Table.Cell>
            {typeof currentSubmissionData?.contractualPaybackFrequency ===
            'string'
              ? capitalize(currentSubmissionData?.contractualPaybackFrequency)
              : 'N/A'}
          </Table.Cell>

          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {capitalize(submission.contractualPaybackFrequency)}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Payment Amount</Table.RowHeader>

          <Table.Cell>
            {currentSubmissionData?.dailyPayment
              ? formatCurrency(
                  calculatePaymentAmount(
                    currentSubmissionData?.dailyPayment,
                    currentSubmissionData?.contractualPaybackFrequency.toLowerCase() ===
                      'daily'
                  )
                )
              : 'N/A'}
          </Table.Cell>

          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {formatCurrency(
                calculatePaymentAmount(
                  submission.dailyPayment,
                  submission.contractualPaybackFrequency.toLowerCase() ===
                    'daily'
                )
              )}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>30 Day Pace</Table.RowHeader>

          <Table.Cell>
            {typeof currentSubmissionData?.thirtyDayDollarPace === 'number' ? (
              <Flex
                gap={1}
                data-testid={`quality-icon-${convertPaceToQualityIconColor(
                  currentSubmissionData.thirtyDayDollarPace
                )}`}
              >
                {displayPercentage(currentSubmissionData.thirtyDayDollarPace)}
                <QualityIcon
                  quality={convertPaceToQualityIconColor(
                    currentSubmissionData.thirtyDayDollarPace
                  )}
                />
              </Flex>
            ) : (
              'N/A'
            )}
          </Table.Cell>

          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {typeof submission.thirtyDayDollarPace === 'number' ? (
                <Flex
                  gap={1}
                  data-testid={`quality-icon-${convertPaceToQualityIconColor(
                    submission.thirtyDayDollarPace
                  )}`}
                >
                  {displayPercentage(submission.thirtyDayDollarPace)}
                  <QualityIcon
                    quality={convertPaceToQualityIconColor(
                      submission.thirtyDayDollarPace
                    )}
                  />
                </Flex>
              ) : (
                'N/A'
              )}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>60 Day Pace</Table.RowHeader>

          <Table.Cell>
            {typeof currentSubmissionData?.sixtyDayDollarPace === 'number' ? (
              <Flex
                gap={1}
                data-testid={`quality-icon-${convertPaceToQualityIconColor(
                  currentSubmissionData.sixtyDayDollarPace
                )}`}
              >
                {displayPercentage(currentSubmissionData.sixtyDayDollarPace)}
                <QualityIcon
                  quality={convertPaceToQualityIconColor(
                    currentSubmissionData.sixtyDayDollarPace
                  )}
                />
              </Flex>
            ) : (
              'N/A'
            )}
          </Table.Cell>

          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {typeof submission.sixtyDayDollarPace === 'number' ? (
                <Flex
                  gap={1}
                  data-testid={`quality-icon-${convertPaceToQualityIconColor(
                    submission.sixtyDayDollarPace
                  )}`}
                >
                  {displayPercentage(submission.sixtyDayDollarPace)}
                  <QualityIcon
                    quality={convertPaceToQualityIconColor(
                      submission.sixtyDayDollarPace
                    )}
                  />
                </Flex>
              ) : (
                'N/A'
              )}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Overall Pace</Table.RowHeader>

          <Table.Cell>
            {typeof currentSubmissionData?.overallDollarPace === 'number' ? (
              <Flex
                gap={1}
                data-testid={`quality-icon-${convertPaceToQualityIconColor(
                  currentSubmissionData.overallDollarPace
                )}`}
              >
                {displayPercentage(currentSubmissionData.overallDollarPace)}
                <QualityIcon
                  quality={convertPaceToQualityIconColor(
                    currentSubmissionData.overallDollarPace
                  )}
                />
              </Flex>
            ) : (
              'N/A'
            )}
          </Table.Cell>

          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {typeof submission.overallDollarPace === 'number' ? (
                <Flex
                  gap={1}
                  data-testid={`quality-icon-${convertPaceToQualityIconColor(
                    submission.overallDollarPace
                  )}`}
                >
                  {displayPercentage(submission.overallDollarPace)}
                  <QualityIcon
                    quality={convertPaceToQualityIconColor(
                      submission.overallDollarPace
                    )}
                  />
                </Flex>
              ) : (
                'N/A'
              )}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Overall Payment Count Pace</Table.RowHeader>

          <Table.Cell>
            {typeof currentSubmissionData?.overallPaymentCountPace ===
            'number' ? (
              <Flex
                gap={1}
                data-testid={`quality-icon-${convertPaceToQualityIconColor(
                  currentSubmissionData.overallPaymentCountPace
                )}`}
              >
                {displayPercentage(
                  currentSubmissionData.overallPaymentCountPace
                )}
                <QualityIcon
                  quality={convertPaceToQualityIconColor(
                    currentSubmissionData.overallPaymentCountPace
                  )}
                />
              </Flex>
            ) : (
              'N/A'
            )}
          </Table.Cell>

          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {typeof submission.overallPaymentCountPace === 'number' ? (
                <Flex
                  gap={1}
                  data-testid={`quality-icon-${convertPaceToQualityIconColor(
                    submission.overallPaymentCountPace
                  )}`}
                >
                  {displayPercentage(submission.overallPaymentCountPace)}
                  <QualityIcon
                    quality={convertPaceToQualityIconColor(
                      submission.overallPaymentCountPace
                    )}
                  />
                </Flex>
              ) : (
                'N/A'
              )}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader># Missed Payments</Table.RowHeader>

          <Table.Cell>
            {defaultTo(currentSubmissionData?.missedPaymentCount, 'N/A')}
          </Table.Cell>

          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {submission.missedPaymentCount}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader># Reduced Payments</Table.RowHeader>

          <Table.Cell>
            {defaultTo(currentSubmissionData?.reducedPaymentCount, 'N/A')}
          </Table.Cell>

          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              {submission.reducedPaymentCount}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Payment History</Table.RowHeader>

          <Table.Cell>
            {typeof currentSubmissionData?.recordId === 'number' ? (
              <PaymentHistoryLink recordId={currentSubmissionData?.recordId} />
            ) : (
              'N/A'
            )}
          </Table.Cell>

          {advanceComparisons?.map((submission) => (
            <Table.Cell key={submission.advanceNumber}>
              <PaymentHistoryLink recordId={submission.recordId} />
            </Table.Cell>
          ))}
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
