import { PlainDate } from '@forward-financing/fast-forward';
import { PaymentFrequency as ApiPaymentFrequency } from 'types/api/funding/types';

export interface HolidayDay {
  kind: 'HolidayDay';
  date: PlainDate;
  holiday: string;
}

export interface IndefinitePauseDay {
  kind: 'IndefinitePauseDay';
  date: PlainDate;
  status: 'indefinite_pause';
  notes?: string;
  createdOn?: string;
  createdBy?: string;
}

export type PaymentStatus =
  | 'settled'
  | 'rejected'
  | 'skipped'
  | 'scheduled'
  | 'pending';
export type PaymentFrequency = ApiPaymentFrequency | 'biweekly';
export type ExistingPayments =
  | SettledPayment
  | RejectedPayment
  | SkippedPayment
  | ScheduledPayment
  | PendingPayment;

export const paymentFrequencies: PaymentFrequency[] = [
  'daily',
  'weekly',
  'biweekly',
  'monthly',
];

export interface Payment {
  amount: number;
  provider: string;
  description?: string;
  sameDayAch: boolean;
  batchPath?: string | null;
}

export interface RejectedPayment extends Payment {
  code: string;
  reason: string;
  description: string;
  status: 'rejected';
}

export interface SettledPayment extends Payment {
  status: 'settled';
}

export interface PendingPayment extends Payment {
  status: 'pending';
}

export interface SkippedPayment extends Payment {
  status: 'skipped';
}

export interface ScheduledPayment extends Payment {
  status: 'scheduled';
}

export interface ScheduleDay {
  kind: 'ScheduleDay';
  date: PlainDate;
  // these top level "amount" and "status" should be the
  // aggregate of the payments in the paybacks field
  amount: number;
  status: PaymentStatus;
  payments: ExistingPayments[];
  reductionPercentageBucket: string | null;
  manual: boolean;
  rollover: boolean;
  provider: string;
  balanceOutstanding: number;
  percentageRepaid?: number;
  stopped: boolean | null;
  notes?: string;
  createdOn?: string;
  createdBy?: string;
  frequency: PaymentFrequency | null;
  recurrency: number | null;
  adjustmentReason?: AdjustmentReason | null;
  scheduledSameDayAch?: {
    amount: number;
  };
}

export interface EmptyDay {
  kind: 'EmptyDay';
  date: PlainDate;
}

export type PaymentDay =
  | HolidayDay
  | IndefinitePauseDay
  | ScheduleDay
  | EmptyDay;

export type NoSchedule = {
  isNotAvailable: true;
};

export type PaymentSchedule = PaymentDay[] | NoSchedule;

export interface AdvanceProfile {
  amount: number;
  dailyPayment: number;
  dateOfAdvance: string;
  dateOfLastPayment?: string;
  manualActive: boolean;
  outstandingBalance: number;
  totalAmountIncludingFees: number;
  paymentFrequency: PaymentFrequency;
  percentageRepaid: number;
  purchasedAmount: number;
  recordId: number;
  rejectionsCount: number;
  totalPayment: number;
  writeOff?: string;
  dateAdvanceLeavesBorrowingBase?: string;
}

export interface ScheduleAdjustmentComparison {
  amount: number;
  /**
   * A date string in YYYY-MM-DD format
   * This could be null in the case of a custom plan
   */
  estimatedPayoffDate: string | null;
  label: string;
  errors: string[] | null;
}

export interface BankInfo {
  achRoutingNumber: string;
  accountNumber: string;
  achProvider: string;
}

export type AchProcessors = string[];

export interface BankInfoResponseShape {
  bankInfo: BankInfo;
  achProcessors: AchProcessors;
}

export interface UpdateBankInfoInput {
  achProvider: string;
}

export interface UpdateBankInfoResponse {
  success: boolean;
}

export type AdjustmentReason = 'contractual' | 'courtesy' | 'no_engagement';
export interface CalculatorFormState {
  startDate?: PlainDate;
  endDate?: PlainDate;
  isOpenEnded?: boolean;
  frequency?: PaymentFrequency;
  recurrency?: number;
  lastMonthRevenue?: number;
  customAmount?: number;
  paymentProcessor?: string;
  adjustmentNote?: string;
  selectedAdjustment?: ScheduleAdjustmentComparison;
  adjustmentReason?: AdjustmentReason;
}

export interface SameDayAchFormState {
  paymentDate?: PlainDate;
  customAmount?: number;
  adjustmentNote?: string;
  achProvider?: string;
}

export interface PaymentScheduleContextData {
  advanceRecordId: number;
  paymentSchedule?: PaymentDay[];
  advanceProfile?: AdvanceProfile;
  bankInfo?: BankInfo;
  achProcessors?: AchProcessors;
  calculateError?: string;
  adjustmentProjections?: ScheduleAdjustmentComparison[];
  isLoadingAdjustmentProjections?: boolean;
  createAdjustmentError?: string;
  refetchBankInfo?: () => Promise<void>;

  formState: CalculatorFormState;
  sameDayAchFormState: SameDayAchFormState;
  updateFormState: (newValue: CalculatorFormState) => void;
  updateSameDayAchFormState: (newValue: SameDayAchFormState) => void;
  getAdjustmentProjection: () => Promise<void>;
  resetForm: () => void;
  resetSameDayAchForm: () => void;
  createPaymentScheduleAdjustment: () => Promise<void>;
  /**
   * @param dateString - a date string in the form YYYY-MM-DD
   */
  resumePaymentsOn: (dateString: string) => Promise<void>;
  refetchPaymentSchedule: () => Promise<void>;
}
