import { useCallback, useState } from 'react';
import { capitalize } from 'lodash';
import { Card } from '@forward-financing/fast-forward';
import { PaymentsSummaryTable } from './PaymentsSummaryTable';

type PaymentsSummaryProps = {
  status: string;
  isOpenedDefault?: boolean;
};

export const PaymentsSummary = ({
  status,
  isOpenedDefault,
}: PaymentsSummaryProps): JSX.Element => {
  //Store table page here to avoid resetting if collapsed/uncollapsed
  const [tablePage, setTablePage] = useState(1);

  const handleTablePageChange = useCallback((newPage: number) => {
    setTablePage(newPage);
  }, []);

  return (
    <Card
      title={capitalize(status)}
      collapsible
      initialOpenState={!!isOpenedDefault}
    >
      <PaymentsSummaryTable
        status={status}
        page={tablePage}
        handlePageChange={handleTablePageChange}
      />
    </Card>
  );
};
