import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      /**
       * Stale time is set to 1 second to prevent unnecessary re-fetching of data.
       * GH issue -https://github.com/TanStack/query/discussions/6542
       */
      staleTime: 1000,
      /**
       * TODO: We need to figure out if we want to run refetch on window focus.
       */
      refetchOnWindowFocus: false,
    },
  },
});

interface ReactQueryContextProps {
  children: React.ReactNode;
}

export const ReactQueryContext = (
  props: ReactQueryContextProps
): JSX.Element => {
  return (
    <QueryClientProvider client={client}>{props.children}</QueryClientProvider>
  );
};
