import { useState } from 'react';
import {
  Banner,
  Currency,
  DescriptionList,
  Grid,
  Select,
  Switch,
} from '@forward-financing/fast-forward';
import { Ledger } from 'components/DealScoring/Ledger/ledger.types';
import { useGetIso } from './ledgerHooks';
import { useLedgerContext } from './LedgerContext/LedgerContext';
import { LEDGER_PROGRAM_OPTIONS_RISK_ASSIGNMENT_MAPPING } from './constants';

export type RevenueSummaryTableProps = {
  ledger: Ledger;
  amountRequested?: number;
  isoUuid?: string;
  onChange: (toUpdate: Partial<Ledger>) => void;
  readOnly?: boolean;
  isNewSaleDealCategory?: boolean;
};

export const RevenueSummaryTable = (
  props: RevenueSummaryTableProps
): JSX.Element => {
  const { data: iso, error: isoError } = useGetIso(props.isoUuid);

  const { getRiskAssignment } = useLedgerContext();

  const [program, setProgram] = useState(props.ledger.program);
  const handleProgramChange = (value: string): void => {
    setProgram(Number(value));
    props.onChange({
      program: Number(value),
    });
  };

  const [isAddOn, setIsAddOn] = useState(props.ledger.addOn);
  const handleIsAddOnSwitch = (checked: boolean): void => {
    setIsAddOn(checked);
    props.onChange({
      addOn: checked,
    });
  };

  const [isBuyOut, setIsBuyOut] = useState(props.ledger.buyOut);
  const handleIsBuyOutSwitch = (checked: boolean): void => {
    setIsBuyOut(checked);
    props.onChange({
      buyOut: checked,
    });
  };

  const [isPrime, setIsPrime] = useState(props.ledger.prime);
  const handleIsPrimeSwitch = (checked: boolean): void => {
    setIsPrime(checked);
    props.onChange({
      prime: checked,
    });
  };

  const [hasPreviousReceived, setHasPreviousReceived] = useState(
    props.ledger.previousReceived
  );
  const handleHasPreviousReceivedSwitch = (checked: boolean): void => {
    setHasPreviousReceived(checked);
    props.onChange({
      previousReceived: checked,
    });
  };

  const [isEligibleForWeekly, setIsEligibleForWeekly] = useState(
    props.ledger.eligibleForWeekly
  );
  const handleIsEligibleForWeeklySwitch = (checked: boolean): void => {
    setIsEligibleForWeekly(checked);
    props.onChange({
      eligibleForWeekly: checked,
    });
    if (!checked) {
      setIsPresentedAsWeekly(false);
      props.onChange({
        presentedAsWeekly: '',
      });
    }
  };

  const [isPresentedAsWeekly, setIsPresentedAsWeekly] = useState(
    props.ledger.presentedAsWeekly === 'weekly'
  );
  const handleIsPresentedAsWeeklySwitch = (checked: boolean): void => {
    setIsPresentedAsWeekly(checked);
    props.onChange({
      presentedAsWeekly: checked ? 'weekly' : '',
    });
  };

  const handleChangeEdit = (value: boolean): void => {
    props.onChange({
      overrideBuyRates: value,
    });
  };
  //Should show the banner when selected program is Starter/Core and Eligible for Weekly set to yes
  const shouldShowWarning =
    isEligibleForWeekly && (program === 34 || program === 1);

  const baseProgramOptions = [
    { text: 'Starter', value: '34' },
    { text: 'Core', value: '1' },
    { text: 'Standard', value: '2' },
    { text: 'Low Risk', value: '3' },
    { text: 'Premium', value: '4' },
  ];

  const riskAssignmentProgramOptions = getRiskAssignment
    ? LEDGER_PROGRAM_OPTIONS_RISK_ASSIGNMENT_MAPPING[getRiskAssignment]
    : baseProgramOptions;

  const programOptions =
    props.isNewSaleDealCategory && !props.readOnly
      ? riskAssignmentProgramOptions
      : baseProgramOptions;

  return (
    <>
      {props.isNewSaleDealCategory && !getRiskAssignment && (
        <Banner dismissable={false} variant="info">
          Risk Score not found; all Program types available
        </Banner>
      )}
      {isoError && (
        <Banner dismissable={false} variant="error">
          {isoError.message}
        </Banner>
      )}
      {shouldShowWarning && (
        <Banner dismissable={false} variant="warning">
          {
            'Based on the selected Program, it is suggested that Eligible for Weekly be set to no.'
          }
        </Banner>
      )}
      {program === 0 && (
        <Banner dismissable={false} variant="error">
          Select program type before saving
        </Banner>
      )}
      <Grid gutter>
        <Grid.Item xl={3}>
          <DescriptionList divider="compact">
            <DescriptionList.Item>
              <DescriptionList.Term>Override</DescriptionList.Term>
              <DescriptionList.Details>
                <Currency amount={props.ledger.override} />
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>ADB</DescriptionList.Term>
              <DescriptionList.Details>
                <Currency amount={props.ledger.adb} />
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>Amount Requested</DescriptionList.Term>
              <DescriptionList.Details>
                {props.amountRequested && (
                  <Currency amount={props.amountRequested} />
                )}
              </DescriptionList.Details>
            </DescriptionList.Item>
          </DescriptionList>
        </Grid.Item>

        <Grid.Item xl={3}>
          <DescriptionList divider="compact">
            <DescriptionList.Item>
              <DescriptionList.Term>Program</DescriptionList.Term>
              <DescriptionList.Details>
                <Select
                  label="Program"
                  options={programOptions}
                  value={program === 0 ? '' : program.toString()}
                  onValueChange={handleProgramChange}
                  disabled={props.readOnly}
                  hiddenLabel
                  required
                />
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>ISO</DescriptionList.Term>
              <DescriptionList.Details>{iso?.name}</DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              {/*Forward’s Position (UW App Ledger) should be equal to Current Positions in the Banking App, plus one*/}
              <DescriptionList.Term>
                {"Forward's Position"}
              </DescriptionList.Term>
              <DescriptionList.Details>
                {props.ledger.forwardPosition + 1}
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>Total Gross</DescriptionList.Term>
              <DescriptionList.Details>
                {Number(props.ledger.totalGross).toFixed(1)}%
              </DescriptionList.Details>
            </DescriptionList.Item>
          </DescriptionList>
        </Grid.Item>

        <Grid.Item xl={3}>
          <DescriptionList divider="compact" variant="space-between">
            <DescriptionList.Item>
              <DescriptionList.Term>Add-On?</DescriptionList.Term>
              <DescriptionList.Details>
                <Switch
                  id="add-on-switch"
                  label=""
                  checked={isAddOn || false}
                  disabled={props.readOnly}
                  onCheckedChange={(newVal) => {
                    handleIsAddOnSwitch(newVal);
                  }}
                />
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>Buy Out?</DescriptionList.Term>
              <DescriptionList.Details>
                <Switch
                  id="buy-out-switch"
                  label=""
                  checked={isBuyOut || false}
                  disabled={props.readOnly}
                  onCheckedChange={(newVal) => {
                    handleIsBuyOutSwitch(newVal);
                  }}
                />
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>Prime?</DescriptionList.Term>
              <DescriptionList.Details>
                <Switch
                  id="prime-switch"
                  label=""
                  checked={isPrime || false}
                  disabled={props.readOnly}
                  onCheckedChange={(newVal) => {
                    handleIsPrimeSwitch(newVal);
                  }}
                />
              </DescriptionList.Details>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Term>Previously Received?</DescriptionList.Term>
              <DescriptionList.Details>
                <Switch
                  id="previous-received-switch"
                  label=""
                  checked={hasPreviousReceived || false}
                  disabled={props.readOnly}
                  onCheckedChange={(newVal) => {
                    handleHasPreviousReceivedSwitch(newVal);
                  }}
                />
              </DescriptionList.Details>
            </DescriptionList.Item>
          </DescriptionList>
        </Grid.Item>

        <Grid.Item xl={3}>
          <DescriptionList divider="compact" variant="space-between">
            <DescriptionList.Item>
              <DescriptionList.Term>Eligible for Weekly</DescriptionList.Term>
              <DescriptionList.Details>
                <Switch
                  id="eligible-for-weekly-switch"
                  label=""
                  checked={isEligibleForWeekly || false}
                  disabled={props.readOnly}
                  onCheckedChange={(newVal) => {
                    handleIsEligibleForWeeklySwitch(newVal);
                  }}
                />
              </DescriptionList.Details>
            </DescriptionList.Item>

            {isEligibleForWeekly && (
              <>
                <DescriptionList.Item>
                  <DescriptionList.Term>
                    Presented as Weekly
                  </DescriptionList.Term>
                  <DescriptionList.Details>
                    <Switch
                      id="presented-as-weekly-switch"
                      label=""
                      checked={isPresentedAsWeekly || false}
                      disabled={props.readOnly}
                      onCheckedChange={(newVal) => {
                        handleIsPresentedAsWeeklySwitch(newVal);
                      }}
                    />
                  </DescriptionList.Details>
                </DescriptionList.Item>
              </>
            )}

            <DescriptionList.Item>
              <DescriptionList.Term>Override Buy Rates</DescriptionList.Term>
              <DescriptionList.Details>
                <Switch
                  id="override-buy-rates-switch"
                  label=""
                  checked={props.ledger.overrideBuyRates}
                  onCheckedChange={handleChangeEdit}
                  disabled={props.ledger.program === 0 || props.readOnly}
                />
              </DescriptionList.Details>
            </DescriptionList.Item>
          </DescriptionList>
        </Grid.Item>
      </Grid>
    </>
  );
};
