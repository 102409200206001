import {
  Banner,
  Currency,
  Flex,
  formatDateString,
  Loading,
  Pagination,
  Table,
} from '@forward-financing/fast-forward';
import { useGetSummaries } from './PaymentsDashboardHooks';

type PaymentsSummaryTableProps = {
  status: string;
  page: number;
  handlePageChange: (page: number) => void;
};

export const PaymentsSummaryTable = ({
  status,
  page,
  handlePageChange,
}: PaymentsSummaryTableProps): JSX.Element => {
  const {
    data: paymentSummaryData,
    loading: paymentSummaryLoading,
    error: paymentSummaryError,
  } = useGetSummaries(status, page);

  const getIndexLink = (
    date: string,
    provider: string,
    sameDay: boolean
  ): URL => {
    const params = new URLSearchParams({
      date,
      provider,
      status,
      sameDay: sameDay.toString(),
    });
    return new URL(
      `/dashboard/payments/index?${params}`,
      window.location.origin
    );
  };

  return (
    <>
      {paymentSummaryError && <Banner>{paymentSummaryError.message}</Banner>}
      {paymentSummaryLoading && <Loading size="medium" />}
      {!paymentSummaryLoading && paymentSummaryData && (
        <>
          <Table>
            <Table.ColGroup>
              <Table.Col />
              <Table.Col />
              <Table.Col />
              <Table.Col />
              <Table.Col />
              <Table.Col />
            </Table.ColGroup>
            <Table.Head>
              <Table.ColumnHeader>Date</Table.ColumnHeader>
              <Table.ColumnHeader>Provider</Table.ColumnHeader>
              <Table.ColumnHeader>Same-day?</Table.ColumnHeader>
              <Table.ColumnHeader>Count</Table.ColumnHeader>
              <Table.ColumnHeader>Amount</Table.ColumnHeader>
              <Table.ColumnHeader>Details</Table.ColumnHeader>
            </Table.Head>
            <Table.Body>
              {paymentSummaryData?.summaries.map(
                ({ date, provider, sameDay, count, amount }) => (
                  <Table.Row key={`${status}-${date}-${provider}-${sameDay}`}>
                    <Table.Cell>{formatDateString(date)}</Table.Cell>
                    <Table.Cell>{provider ?? 'Unknown'}</Table.Cell>
                    <Table.Cell>{sameDay ? 'Yes' : 'No'}</Table.Cell>
                    <Table.Cell>{count}</Table.Cell>
                    <Table.Cell>
                      {!isNaN(amount) && <Currency amount={amount} />}
                    </Table.Cell>
                    <Table.LinkCell
                      href={getIndexLink(date, provider ?? 'null', sameDay)}
                    >
                      Index
                    </Table.LinkCell>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
          <Flex flexDirection="row-reverse">
            <Pagination
              numPages={paymentSummaryData.totalPages}
              currentPage={paymentSummaryData.currentPage}
              onPageClick={handlePageChange}
            />
          </Flex>
        </>
      )}
    </>
  );
};
