import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import {
  LivePipelineSearchParams,
  LivePipelineSubmissionSearchResultResponse,
} from 'types/api/underwriting/types';

export const fetchLivePipelineSubmissions = async (
  searchParams: LivePipelineSearchParams
): Promise<LivePipelineSubmissionSearchResultResponse> => {
  const url = new URL(`api/v2/live_pipeline/search`, UNDERWRITING_BASE_URL());

  Object.entries(searchParams).forEach(
    ([key, value]: [string, string | number]) => {
      url.searchParams.append(key, value.toString());
    }
  );

  const response =
    await makeInternalAPIRequest<LivePipelineSubmissionSearchResultResponse>(
      url,
      'GET'
    );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Submissions');
  }

  return response.json();
};
