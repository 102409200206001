import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { useExceptionRequests } from 'components/ExceptionsRequest/exceptionsRequestHooks';
import { ExceptionRequest } from 'components/ExceptionsRequest/exceptionsRequest.types';
import { featureFlags } from 'helpers/featureFlags';
import {
  useCloneLedger,
  useCreateLedger,
  useLedgers,
} from 'components/DealScoring/Ledger/ledgerHooks';
import { useDeepCompareMemoize } from 'hooks/useDeepCompareMemoize';

export type ExceptionRequestContextData = {
  isLedgerOpen: boolean;
  setIsLedgerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModifyButtonDisabled: boolean;
  modifyOfferHandler: () => void;
  requests: ExceptionRequest[] | undefined;
  areRequestsLoading: boolean;
  refetchExceptionRequests: () => void;
  areAnyExceptionRequestsOpen: boolean | undefined;
};

export const ExceptionRequestContext = createContext<
  ExceptionRequestContextData | undefined
>(undefined);

export const useExceptionRequestContext = (): ExceptionRequestContextData => {
  const context = useContext(ExceptionRequestContext);

  /**
   * Added this check to improve DevEx.
   * In case if someone accidentally uses this hook outside of the provider.
   */
  if (context === undefined) {
    throw new Error(
      'useExceptionRequestContext must be used within a ExceptionRequestContextProvider'
    );
  }

  return context;
};

type Params = {
  submissionUuid: string;
};

export const ExceptionRequestContextProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const { submissionUuid } = useParams<keyof Params>() as Params;
  const [isModifyButtonDisabled, setIsModifyButtonDisabled] = useState(false);
  const [isLedgerOpen, setIsLedgerOpen] = useState(false);
  const { data: ledgersData } = useLedgers(submissionUuid);
  const [cloneLedger] = useCloneLedger();
  const [createLedger] = useCreateLedger();

  const memoizedLedgersData = useDeepCompareMemoize(ledgersData);

  const mostRecentLockedLedger = useMemo(
    () => memoizedLedgersData?.find((ledger) => ledger.lock),
    [memoizedLedgersData]
  );

  const modifyOfferHandler = async (): Promise<void> => {
    setIsModifyButtonDisabled(true);

    if (mostRecentLockedLedger) {
      await cloneLedger({
        submissionUuid,
        ledgerId: mostRecentLockedLedger.id,
      });
    } else {
      await createLedger({ submissionUuid });
    }

    setIsModifyButtonDisabled(false);

    setIsLedgerOpen(true);
  };

  const {
    requests,
    loading: requestsLoading,
    refetch: refetchExceptionRequests,
  } = useExceptionRequests(submissionUuid);

  const areAnyExceptionRequestsOpen = useMemo(
    () =>
      featureFlags.exception_request_rewrite &&
      requests?.some((request) =>
        ['pending_review', 'in_progress'].includes(request.status)
      ),
    [requests]
  );

  return (
    <ExceptionRequestContext.Provider
      value={{
        isLedgerOpen,
        setIsLedgerOpen,
        isModifyButtonDisabled,
        modifyOfferHandler,
        requests,
        areRequestsLoading: requestsLoading,
        refetchExceptionRequests,
        areAnyExceptionRequestsOpen,
      }}
    >
      {children}
    </ExceptionRequestContext.Provider>
  );
};
