import {
  Box,
  formatCurrency,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';
import { featureFlags } from 'helpers/featureFlags';
import { noDataMessage } from '../../../helpers/string/errorMessageUtils';
import { PaymentDetails } from './businessPaynet.types';

type BusinessPaynetDetailsTableProps = {
  paymentDetails?: PaymentDetails[];
};

const getFrequencyColor = (frequency: string): ColorVariant | undefined => {
  switch (frequency) {
    case 'WKDY':
    case 'WEEK':
      return 'caution';
  }
  return undefined;
};

const getStatusColor = (status?: string): ColorVariant | undefined => {
  switch (status) {
    case 'WOFF':
      return 'danger';
  }
  return undefined;
};

export const BusinessPaynetDetailsTable = ({
  paymentDetails,
}: BusinessPaynetDetailsTableProps): JSX.Element => {
  const hasPaymentDetails = paymentDetails && paymentDetails?.length > 0;
  return (
    <Table caption="Paynet Table Details">
      <Table.Head>
        <Table.ColumnHeader />
        <Table.ColumnHeader>Lender</Table.ColumnHeader>
        <Table.ColumnHeader>Industry</Table.ColumnHeader>
        <Table.ColumnHeader>Collateral</Table.ColumnHeader>
        <Table.ColumnHeader>Contract</Table.ColumnHeader>
        <Table.ColumnHeader>Start Date</Table.ColumnHeader>
        <Table.ColumnHeader>As Of Date</Table.ColumnHeader>
        <Table.ColumnHeader>Org. Amt.</Table.ColumnHeader>
        <Table.ColumnHeader>Bal. Amt.</Table.ColumnHeader>
        <Table.ColumnHeader>Pay Amt.</Table.ColumnHeader>
        <Table.ColumnHeader>Term</Table.ColumnHeader>
        <Table.ColumnHeader>Frequency</Table.ColumnHeader>
        <Table.ColumnHeader colSpan={4}>Delinquencies</Table.ColumnHeader>
        <Table.ColumnHeader>Status</Table.ColumnHeader>
        <Table.ColumnHeader>Loss</Table.ColumnHeader>
      </Table.Head>

      <Table.Head>
        <Table.ColumnHeader />
        <Table.ColumnHeader />
        <Table.ColumnHeader />
        <Table.ColumnHeader />
        <Table.ColumnHeader />
        <Table.ColumnHeader />
        <Table.ColumnHeader />
        <Table.ColumnHeader />
        <Table.ColumnHeader />
        <Table.ColumnHeader />
        <Table.ColumnHeader />
        <Table.ColumnHeader />
        <Table.ColumnHeader>1+</Table.ColumnHeader>
        <Table.ColumnHeader>31+</Table.ColumnHeader>
        <Table.ColumnHeader>61+</Table.ColumnHeader>
        <Table.ColumnHeader>91+</Table.ColumnHeader>
        <Table.ColumnHeader />
        <Table.ColumnHeader />
      </Table.Head>

      <Table.Body>
        {hasPaymentDetails ? (
          paymentDetails?.map(
            (paymentDetail: PaymentDetails, index: number) => (
              <Table.Row key={`paynetTableDetails.${paymentDetail.lender}`}>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>{paymentDetail.lender}</Table.Cell>
                <Table.Cell>{paymentDetail.industry}</Table.Cell>
                <Table.Cell>{paymentDetail.collateral}</Table.Cell>
                <Table.Cell>{paymentDetail.contract}</Table.Cell>
                <Table.Cell>{paymentDetail.startDate}</Table.Cell>
                <Table.Cell>{paymentDetail.asOfDate}</Table.Cell>
                <Table.Cell>
                  {paymentDetail.originalAmount &&
                    formatCurrency(+paymentDetail.originalAmount)}
                </Table.Cell>
                <Table.Cell>
                  {paymentDetail.balanceAmount &&
                    formatCurrency(+paymentDetail.balanceAmount)}
                </Table.Cell>
                <Table.Cell>
                  {paymentDetail.paymentAmount &&
                    formatCurrency(+paymentDetail.paymentAmount)}
                </Table.Cell>
                <Table.Cell>{paymentDetail.term}</Table.Cell>
                <Table.Cell
                  backgroundColor={
                    featureFlags.highlight_paynet_details_table
                      ? getFrequencyColor(paymentDetail.frequency)
                      : undefined
                  }
                >
                  {paymentDetail.frequency}
                </Table.Cell>
                <Table.Cell>{paymentDetail.delinquency01 || '-'}</Table.Cell>
                <Table.Cell>{paymentDetail.delinquency31 || '-'}</Table.Cell>
                <Table.Cell>{paymentDetail.delinquency61 || '-'}</Table.Cell>
                <Table.Cell>{paymentDetail.delinquency91 || '-'}</Table.Cell>
                <Table.Cell
                  backgroundColor={
                    featureFlags.highlight_paynet_details_table
                      ? getStatusColor(paymentDetail.status)
                      : undefined
                  }
                >
                  {paymentDetail.status}
                </Table.Cell>
                <Table.Cell>{paymentDetail.loss}</Table.Cell>
              </Table.Row>
            )
          )
        ) : (
          <Box mb={4}>
            <Text>{noDataMessage('Paynet details')}</Text>
          </Box>
        )}
      </Table.Body>
    </Table>
  );
};
