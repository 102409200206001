/* istanbul ignore file */
/**
 * We're not testing this file for the same reason as
 * discussed in https://github.com/ForwardFinancing/internal-frontend/pull/573#issuecomment-1148041693
 * and all of the other components used in this file ARE tested.
 */
import { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyles } from '@forward-financing/fast-forward';
import { ApolloContext } from 'contexts/ApolloContext';
import { ReactQueryContext } from 'contexts/ReactQueryContext';
import { toError } from 'helpers/errorUtils';
import { Routes } from './components/Routes';
import { featureFlags } from './helpers/featureFlags';
import { UnderwritingClient } from './api/UnderwritingClient';
import { FFLogger } from './api/LogClient';
import { UserContextContainer } from './contexts/UserContext';
import { ViewHistoryProvider } from './contexts/ViewHistoryProvider';
import { LocalStorageViewHistoryStore } from './contexts/stores/ViewHistoryStore';
import { ErrorBoundary } from './components/shared/generic/ErrorBoundary';

export const Application = (): JSX.Element => {
  const [featureFlagsAreReady, setFeatureFlagsAreReady] = useState(false);

  useEffect(() => {
    featureFlags
      .loadBy(UnderwritingClient.fetchFeatureFlags)
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch((e: unknown) => {
        const error = toError(e);
        FFLogger.error(error);
      })
      // eslint-disable-next-line promise/prefer-await-to-then
      .finally(() => {
        setFeatureFlagsAreReady(true);
      });
  }, []);

  return (
    <>
      <GlobalStyles />
      <ErrorBoundary>
        <ReactQueryContext>
          <ApolloContext>
            <UserContextContainer>
              {
                // Showing too many loading spinners can make applications feel
                // sluggish, especially if they only display for a short amount of time.
                // The feature flag request should be relatively fast, so instead of
                // rendering a short-lived spinner, we're avoiding rendering any routes
                // until the request has settled.
                //
                // This will increase our time to first meaningful paint, but it should
                // make the overall experience nicer for users, especially if one of the
                // routes also displays a loading spinner.
                featureFlagsAreReady && (
                  <BrowserRouter>
                    <ViewHistoryProvider
                      store={new LocalStorageViewHistoryStore()}
                    >
                      <Routes />
                    </ViewHistoryProvider>
                  </BrowserRouter>
                )
              }
            </UserContextContainer>
          </ApolloContext>
        </ReactQueryContext>
      </ErrorBoundary>
    </>
  );
};
