import {
  Button,
  Checkbox,
  Combobox,
  Flex,
  formatCurrency,
  formatDateString,
  IconButton,
  Sheet,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import { FREQUENCY_OPTIONS } from '../ExistingFinancing/existingFinancingConstants';
import { ExistingFinancing } from '../cashFlow.types';

type ExistingFinancingContainerProps = {
  previousFinancings?: ExistingFinancing[];
  getFundingCompanyValues: (val: string) => { value: string; text: string };
  getMonthlyPaymentValue: (amount: number, frequency: string) => number;
};

export const PreviousFinancingContainer = ({
  previousFinancings,
  getFundingCompanyValues,
  getMonthlyPaymentValue,
}: ExistingFinancingContainerProps): JSX.Element => {
  return (
    <Flex flexDirection={'column'}>
      <Flex alignItems={'center'} gap={3}>
        <Subheading>Previous Financing</Subheading>
        <Button variant={'primary'} startIcon={'add'}>
          Add financing
        </Button>
      </Flex>
      <Sheet>
        <Sheet.Head>
          <Sheet.ColumnHeader backgroundColor={'gray-100'}>
            <></>
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Funding Company
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Payment Start Date
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Last Payment Date
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Frequency
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'small'}
          >
            Amount
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Monthly Payment
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Funded Amount
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader backgroundColor={'gray-100'}>
            Renewal?
          </Sheet.ColumnHeader>
        </Sheet.Head>

        <Sheet.Body>
          {previousFinancings?.map((item) => {
            const attributes = item.attributes;

            return (
              <Sheet.Row key={item.id} backgroundColor={'white'}>
                <Sheet.TextCell>
                  <Flex justifyContent={'center'}>
                    <IconButton
                      hiddenLabel={true}
                      icon={'trash-can'}
                      label={'Delete Previous Financing'}
                    />
                  </Flex>
                </Sheet.TextCell>
                <Sheet.TextCell>
                  <Flex px={1}>
                    <Combobox
                      label=""
                      isFixed={true}
                      onValueChange={() => {
                        //TODO should be done in separate task FE-2479
                      }}
                      disabled={true} //Disabled for now, for view only purposes
                      options={[]}
                      values={[
                        getFundingCompanyValues(attributes.financingType),
                      ]}
                    />
                  </Flex>
                </Sheet.TextCell>
                <Sheet.TextCell
                  value={formatDateString(attributes.paymentStartDate)}
                />
                <Sheet.TextCell
                  value={formatDateString(attributes.lastPaymentDate)}
                />
                <Sheet.TextCell>
                  <Flex px={1}>
                    <Combobox
                      label=""
                      isFixed={true}
                      onValueChange={() => {
                        //TODO should be done in separate task FE-2479
                      }}
                      disabled={true} //Disabled for now, for view only purposes
                      options={FREQUENCY_OPTIONS}
                      values={[getFundingCompanyValues(attributes.frequency)]}
                    />
                  </Flex>
                </Sheet.TextCell>
                <Sheet.TextCell
                  value={formatCurrency(attributes.dailyAmountCents / 100)}
                />
                <Sheet.TextCell backgroundColor={'gray-800'}>
                  <Flex justifyContent={'center'}>
                    <Text color={'white'}>
                      {formatCurrency(
                        getMonthlyPaymentValue(
                          attributes.dailyAmountCents / 100,
                          attributes.frequency
                        )
                      )}
                    </Text>
                  </Flex>
                </Sheet.TextCell>
                <Sheet.TextCell
                  value={formatCurrency(attributes.fundedAmountCents / 2)}
                />
                <Sheet.TextCell>
                  <Flex justifyContent={'center'}>
                    <Checkbox label={''} checked={attributes.isRenewal} />
                  </Flex>
                </Sheet.TextCell>
              </Sheet.Row>
            );
          })}
        </Sheet.Body>
      </Sheet>
    </Flex>
  );
};
