import { Tabs } from '@forward-financing/fast-forward';
import { Sheet } from './cashFlow.types';
import { WorksheetContainer } from './WorksheetContainer';

type SecondarySheetsContainerProps = {
  underwritingSheetData: Sheet;
  closingSheetData: Sheet;
  tabNamePrefix?: string;
  isOverview?: boolean;
  submissionUuid: string;
};

export const SecondarySheetsContainer = ({
  closingSheetData,
  underwritingSheetData,
  tabNamePrefix,
  isOverview,
  submissionUuid,
}: SecondarySheetsContainerProps): JSX.Element => {
  return (
    <Tabs defaultValue="undewriting-sheet">
      <Tabs.List>
        <Tabs.Trigger value={'undewriting-sheet'}>
          {tabNamePrefix && `${tabNamePrefix} - `}Underwriting
        </Tabs.Trigger>
        <Tabs.Trigger value={'closing-sheet'}>
          {tabNamePrefix && `${tabNamePrefix} - `}Closing
        </Tabs.Trigger>
      </Tabs.List>

      <Tabs.Content value={'closing-sheet'}>
        <WorksheetContainer
          sheetData={closingSheetData}
          isOverview={isOverview}
          submissionUuid={submissionUuid}
        />
      </Tabs.Content>
      <Tabs.Content value={'undewriting-sheet'}>
        <WorksheetContainer
          sheetData={underwritingSheetData}
          isOverview={isOverview}
          submissionUuid={submissionUuid}
        />
      </Tabs.Content>
    </Tabs>
  );
};
