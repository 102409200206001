import {
  Button,
  Checkbox,
  Flex,
  Sheet,
  Subheading,
} from '@forward-financing/fast-forward';
import { ExistingFinancing } from '../cashFlow.types';

import {
  getGrossPercentValue,
  getMonthlyPaymentValue,
} from './existingFinancingUtils';
import { useExistingFinancingsState } from './existingFinancingHooks';
import { ExistingFinancingRow } from './Row/ExistingFinancingRow';
import { ExistingFinancingTotalRow } from './TotalRow/ExistingFinancingTotalRow';

type CashFlowExistingFinancingContainerProps = {
  sheetId: string;
  netDepositOverride: number;
  isReadOnly?: boolean;
};

export const ExistingFinancingContainer = ({
  sheetId,
  netDepositOverride,
  isReadOnly = false,
}: CashFlowExistingFinancingContainerProps): JSX.Element => {
  const { existingFinancings, setExistingFinancings } =
    useExistingFinancingsState(sheetId);

  const updateFinancingAttribute = (
    financingId: string,
    attributeUpdater: (
      attrs: ExistingFinancing['attributes']
    ) => Partial<ExistingFinancing['attributes']>
  ): void => {
    setExistingFinancings((prevState) =>
      prevState.map((financing) => {
        if (financing.id === financingId) {
          return {
            ...financing,
            attributes: {
              ...financing.attributes,
              ...attributeUpdater(financing.attributes),
            },
          };
        }
        return financing;
      })
    );
  };

  return (
    <Flex flexDirection={'column'}>
      <Flex alignItems={'center'} gap={3}>
        <Subheading>Existing Financing</Subheading>
        <Button variant={'primary'} startIcon={'add'}>
          Add financing
        </Button>
      </Flex>

      <Sheet>
        <Sheet.Head>
          <Sheet.ColumnHeader backgroundColor={'gray-100'}>
            <></>
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Funding Company
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Frequency
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Amount
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Monthly Payment
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'small'}
          >
            % Gross
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'small'}
          >
            <Flex
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <div>Include?</div>
              <Flex justifyContent={'center'}>
                <Checkbox label={''} />
              </Flex>
            </Flex>
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Funded Date
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Funded Amount
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader backgroundColor={'gray-100'}>
            Term
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'small'}
          >
            Factor Rate
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Advanced Amount
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader backgroundColor={'gray-100'}>
            Renewal?
          </Sheet.ColumnHeader>
        </Sheet.Head>

        <Sheet.Body>
          {existingFinancings?.map((item) => (
            <ExistingFinancingRow
              key={item.id}
              financing={item}
              updateFinancingAttribute={updateFinancingAttribute}
              netDepositOverride={netDepositOverride}
              isReadOnly={isReadOnly}
            />
          ))}

          <ExistingFinancingTotalRow
            existingFinancings={existingFinancings}
            getMonthlyPaymentValue={getMonthlyPaymentValue}
            getGrossPercentValue={getGrossPercentValue(netDepositOverride)}
          />
        </Sheet.Body>
      </Sheet>
    </Flex>
  );
};
