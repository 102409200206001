import { Grid, Radio } from '@forward-financing/fast-forward';

type DeclineReasonsProps = {
  selectedReason: string;
  setSelectedReason: (reason: string) => void;
};

const RadioItem = ({ reason }: { reason: string }): JSX.Element => (
  <Radio.Item
    removeBackgroundColor
    reduceControlMargin
    id={reason}
    value={reason}
  >
    {reason}
  </Radio.Item>
);

export const allDeclineReasons = [
  'Business Risk',
  'Capped due to Industry',
  'Cash Flow/Revenue',
  'Excessive Funding Concerns',
  'Excessive Recent Submissions',
  'Missing Competing Offer Information',
  'Negative Days/Bounced Payments',
  'No new information provided',
  'Offer Stands',
  'Open offer with another ISO',
  'Original concerns noted not addressed',
  'Outstanding Balance on Advance',
  'Outstanding Stips not addressed',
  'Owner Risk',
  'Payment Issues (Internal)',
  'Total Gross',
  'Other',
];

export const DeclineReasons = ({
  selectedReason,
  setSelectedReason,
}: DeclineReasonsProps): JSX.Element => (
  <Radio
    name="Reason for declining"
    value={selectedReason}
    onChange={setSelectedReason}
    orientation="horizontal"
  >
    {/* 
      These items are to be ordered alphabetically (excluding 'Other')
      across the rows, rather than by columns. As such, we
      are splitting the main array into three separate arrays that
      filter by every third element, and then mapping over
      each of these arrays to render the radio items alphabetically by row.
    */}
    <Grid>
      <Grid.Item s={4}>
        {allDeclineReasons
          .filter((_, index) => index % 3 === 0)
          .map((reason) => (
            <RadioItem reason={reason} key={reason} />
          ))}
      </Grid.Item>

      <Grid.Item s={4}>
        {allDeclineReasons
          .filter((_, index) => index % 3 === 1)
          .map((reason) => (
            <RadioItem reason={reason} key={reason} />
          ))}
      </Grid.Item>

      <Grid.Item s={4}>
        {allDeclineReasons
          .filter((_, index) => index % 3 === 2)
          .map((reason) => (
            <RadioItem reason={reason} key={reason} />
          ))}
      </Grid.Item>
    </Grid>
  </Radio>
);
