import { defaultTo } from 'lodash';
import { useMemo } from 'react';
import {
  RenewalScoreResponse,
  RenewalScoresFetchResponse,
  SubmissionCompositeScoreResponse,
  SubmissionNotesResponse,
  SubmissionNoteType,
  SubmissionRenewalCompositeScoreResponse,
  SubmissionResponse,
} from 'types/api/underwriting/types';

import {
  useApiRefreshCompositeScore,
  useCreateApiRenewalScore,
  useGetApiSubmission,
  useGetRenewalScore,
  useGetSubmissionCompositeScore,
  UseGetSubmissionCompositeScoreResponse,
  useGetSubmissionNotes,
  useUpdateApiRenewalScore,
  useUpdateApiSubmissionNote,
} from 'apiHooks/underwriting/submissionFetchHooks';

import {
  MutationResponse,
  UseGenericMutationResult,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { FFLogger } from 'api/LogClient';
import { useGetCustomerSubmissions } from 'apiHooks/underwriting/customerFetchHooks';
import { RenewalComparisonsResponse } from 'types/api/funding/types';
import { useApiRenewalComparisons } from 'apiHooks/funding/renewalComparisonsFetchHooks';
import {
  DealScoringNoteType,
  ManagerDealScoring,
  NewDealScoreComposite,
  RenewalComparisonSubmission,
  RenewalScoreComposite,
  SubmissionNotes,
} from './DealScoring.types';
import { Submission } from './DealScoringContainer.types';
import { ErrorWithStatusCode } from './RenewalScoring/RenewalScoringFetchHooks';

export const toSubmission = (submission: SubmissionResponse): Submission => {
  return {
    type: submission.type,
    uuid: submission.uuid,
    previousFundedSubmissionUuid:
      submission.previous_funded_opportunity_uuid ?? undefined,
    previousFundedSubmissionUuidFromCustomer:
      submission.previous_funded_submission_uuid_from_customer ?? undefined,
    customerUuid: submission.customer_uuid,
    amountRequested: defaultTo(submission.capital_needed, undefined),
    isoUuid: defaultTo(submission.partner_uuid, undefined),
    category: submission.category ?? undefined,
    subStage: submission.sub_stage ?? undefined,
    stage: submission.stage_name,
    dateSubReceived: submission.date_app_received__c ?? undefined,
    isNewDealCategory: defaultTo(submission.is_new_deal_category, undefined),
  };
};

export const useSubmission = (
  submissionUuid: string
): UseGenericQueryResponse<Submission> =>
  useGenericFeatureQuery(
    useGetApiSubmission,
    (data) => (data ? toSubmission(data) : undefined),
    submissionUuid
  );

const apiNoteTypeToFeatureNoteType = (
  submissionNoteType: SubmissionNoteType
): DealScoringNoteType =>
  ({
    underwriter_scoring: 'UNDERWRITER',
    manager_scoring: 'MANAGER',
  }[submissionNoteType] as DealScoringNoteType);

const featureNoteTypeToApiNoteType = (
  featureNoteType: DealScoringNoteType
): SubmissionNoteType =>
  ({
    UNDERWRITER: 'underwriter_scoring',
    MANAGER: 'manager_scoring',
  }[featureNoteType] as SubmissionNoteType);

const toSubmissionNotes = (
  submissionNotesResponse: SubmissionNotesResponse
): SubmissionNotes => {
  return {
    content: submissionNotesResponse.content,
    noteType: apiNoteTypeToFeatureNoteType(submissionNotesResponse.note_type),
  };
};

export const useDealScoringNotes = (
  submissionUuid?: string
): UseGenericQueryResponse<SubmissionNotes[]> =>
  useGenericFeatureQuery(
    useGetSubmissionNotes,
    (data) => data?.map(toSubmissionNotes),
    submissionUuid
  );

export type UpdateSubmissionNoteBody = {
  noteContent: string;
};

type UseUpdateSubmissionNotesResult = [
  (input: UpdateSubmissionNoteBody) => Promise<MutationResponse>,
  UseGenericMutationResult<SubmissionNotes>
];

export const useUpdateSubmissionNote = (
  submissionUuid: string,
  noteType: DealScoringNoteType
): UseUpdateSubmissionNotesResult => {
  const [updateSubmissionNote, { data, error, ...rest }] =
    useUpdateApiSubmissionNote();

  const updateFunction = (
    args: UpdateSubmissionNoteBody
  ): Promise<MutationResponse> => {
    return updateSubmissionNote({
      submissionUuid,
      noteType: featureNoteTypeToApiNoteType(noteType),
      updateBody: {
        content: args.noteContent,
      },
    });
  };

  return [
    updateFunction,
    {
      data: data && toSubmissionNotes(data),
      error,
      ...rest,
    },
  ];
};

const toManagerScoring = (
  renewalScoreResponse?: RenewalScoreResponse
): ManagerDealScoring | undefined => {
  if (!renewalScoreResponse) {
    return undefined;
  }

  return {
    managerFeedbackScore:
      renewalScoreResponse.manager_feedback_score ?? undefined,
    lastUpdatedAt: renewalScoreResponse.last_updated_at ?? undefined,
  };
};

const toRenewalDealScoring = (
  renewalScoreResponse?: RenewalScoresFetchResponse
): ManagerDealScoring | undefined => {
  if (!renewalScoreResponse) {
    return undefined;
  }

  return toManagerScoring(renewalScoreResponse.renewal_score);
};

export const useManagerDealScore = (
  submissionUuid?: string
): UseGenericQueryResponse<ManagerDealScoring> =>
  useGenericFeatureQuery(
    useGetRenewalScore,
    toRenewalDealScoring,
    submissionUuid
  );

export type UpdateManagerScoreBody = {
  managerFeedbackScore: boolean;
};

type UseUpdateManagerScoreResult = [
  (input: UpdateManagerScoreBody) => Promise<MutationResponse>,
  UseGenericMutationResult<ManagerDealScoring>
];

export const useUpdateManagerScore = (
  submissionUuid: string
): UseUpdateManagerScoreResult => {
  const [updateRenewalScore, { data, error, ...rest }] =
    useUpdateApiRenewalScore();

  const updateFunction = ({
    managerFeedbackScore,
  }: UpdateManagerScoreBody): Promise<MutationResponse> => {
    return updateRenewalScore({
      submissionUuid,
      updateBody: {
        manager_feedback_score: managerFeedbackScore,
      },
    });
  };

  return [
    updateFunction,
    {
      data: toManagerScoring(data),
      error,
      ...rest,
    },
  ];
};

type UseCreateManagerScoreResult = [
  () => Promise<MutationResponse>,
  UseGenericMutationResult<ManagerDealScoring>
];

export const useCreateManagerScore = (
  submissionUuid: string
): UseCreateManagerScoreResult => {
  const [createRenewalScore, { data, error, ...rest }] =
    useCreateApiRenewalScore();

  const createFunction = (): Promise<MutationResponse> => {
    return createRenewalScore({
      submissionUuid,
    });
  };

  return [
    createFunction,
    {
      data: toManagerScoring(data),
      error,
      ...rest,
    },
  ];
};

export const toRenewalScoreComposite = (
  renewalCompositeScore: SubmissionRenewalCompositeScoreResponse
): RenewalScoreComposite | undefined => {
  if (renewalCompositeScore.artifact_case === 'renewal_composite_score_v2') {
    return {
      type: 'Renewal',
      industryRisk: {
        score: renewalCompositeScore.industry_risk?.score,
        value: renewalCompositeScore.industry_risk?.value,
        industry: renewalCompositeScore.industry_risk?.industry,
      },
      timesFunded: {
        score: renewalCompositeScore.times_funded?.score,
        value: renewalCompositeScore.times_funded?.value,
      },
      ficoChange: {
        score: renewalCompositeScore.fico_change?.score,
        value: renewalCompositeScore.fico_change?.value,
      },
      revenueChange: {
        score: renewalCompositeScore.revenue_change?.score,
        value: renewalCompositeScore.revenue_change?.value,
      },
      paymentHistory: {
        score: renewalCompositeScore.payment_history?.score,
        value: renewalCompositeScore.payment_history?.value,
      },
      stacking: {
        score: renewalCompositeScore.stacking?.score,
        preFfGross: renewalCompositeScore.stacking?.pre_ff_gross,
      },
      ...(renewalCompositeScore.composite_score && {
        compositeScore: {
          score: renewalCompositeScore.composite_score?.score,
          tier: renewalCompositeScore.composite_score?.tier,
        },
      }),
      updatedAt: renewalCompositeScore.updated_at,
      version: renewalCompositeScore.artifact_case,
      bkPlus: {
        score: renewalCompositeScore.bk_plus?.score,
        value: renewalCompositeScore.bk_plus?.value,
      },
    };
  } else {
    return {
      type: 'Renewal',
      industryRisk: {
        score: renewalCompositeScore.industry_risk?.score,
        value: renewalCompositeScore.industry_risk?.value,
        industry: renewalCompositeScore.industry_risk?.industry,
      },
      timesFunded: {
        score: renewalCompositeScore.times_funded?.score,
        value: renewalCompositeScore.times_funded?.value,
      },
      ficoChange: {
        score: renewalCompositeScore.fico_change?.score,
        value: renewalCompositeScore.fico_change?.value,
      },
      revenueChange: {
        score: renewalCompositeScore.revenue_change?.score,
        value: renewalCompositeScore.revenue_change?.value,
      },
      paymentHistory: {
        score: renewalCompositeScore.payment_history?.score,
        value: renewalCompositeScore.payment_history?.value,
      },
      stacking: {
        score: renewalCompositeScore.stacking?.score,
        preFfGross: renewalCompositeScore.stacking?.pre_ff_gross,
      },
      ...(renewalCompositeScore.composite_score && {
        compositeScore: {
          score: renewalCompositeScore.composite_score?.score,
          tier: renewalCompositeScore.composite_score?.tier,
        },
      }),
      updatedAt: renewalCompositeScore.updated_at,
      repaidPercentage: {
        score: renewalCompositeScore.repaid_percentage?.score,
        value: renewalCompositeScore.repaid_percentage?.value,
      },
      version: renewalCompositeScore.artifact_case,
    };
  }
};

const toCompositeScore = (
  compositeScore?: SubmissionCompositeScoreResponse
): NewDealScoreComposite | RenewalScoreComposite | undefined => {
  if (!compositeScore || compositeScore.artifact_case === 'composite_score') {
    return undefined;
  }

  if (/^renewal_composite_score.*/.test(compositeScore.artifact_case)) {
    return toRenewalScoreComposite(compositeScore);
  } else {
    FFLogger.warn('Unknown composite score type', compositeScore);
    return undefined;
  }
};

interface UseScoringCompositeResult
  extends Omit<UseGetSubmissionCompositeScoreResponse, 'data'> {
  data?: NewDealScoreComposite | RenewalScoreComposite;
  error?: ErrorWithStatusCode;
}

/**
 * Note: This is currently untested because it is not implemented anywhere.
 *
 * We are using a generic feature hook, so once we implement this hook in
 * an upcoming ticket, the feature tests will cover this hook, and our
 * coverage hit should be restored. Once this is verified we can remove
 * this comment.
 */
export const useScoringComposite = (
  submissionUuid?: string
): UseScoringCompositeResult => {
  const useToCompositeScoreMomized = (
    response?: SubmissionCompositeScoreResponse
  ): NewDealScoreComposite | RenewalScoreComposite | undefined => {
    return useMemo(() => toCompositeScore(response), [response]);
  };

  return useGenericFeatureQuery(
    useGetSubmissionCompositeScore,
    useToCompositeScoreMomized,
    submissionUuid
  );
};

export const useCustomerSubmissions = (
  customerUuid?: string
): UseGenericQueryResponse<Submission[]> => {
  return useGenericFeatureQuery(
    useGetCustomerSubmissions,
    (data) => (data ? data?.map(toSubmission) : []),
    customerUuid
  );
};

type UseRefreshCompositeScoreResult = [
  () => Promise<MutationResponse>,
  UseGenericMutationResult<NewDealScoreComposite | RenewalScoreComposite>
];

export const useRefreshCompositeScore = (
  submissionUuid: string
): UseRefreshCompositeScoreResult => {
  const [
    refreshCompositeScoreFunction,
    { data, error, loading, responseReady },
  ] = useApiRefreshCompositeScore();

  const refreshCompositeScore = (): Promise<MutationResponse> => {
    return refreshCompositeScoreFunction(submissionUuid);
  };

  const score = useMemo(() => toCompositeScore(data), [data]);
  return [
    refreshCompositeScore,
    { data: score, error, loading, responseReady },
  ];
};

const toRenewalComparisonSubmission = (
  data: RenewalComparisonsResponse
): RenewalComparisonSubmission => {
  const { advance } = data;

  return {
    balance: defaultTo(advance.balance, ''),
    percentageRepaid: defaultTo(advance.percentage_repaid, ''),
  };
};

export const useRenewalComparisonSubmission = (
  submissionUuid?: string
): UseGenericQueryResponse<RenewalComparisonSubmission> => {
  return useGenericFeatureQuery(
    useApiRenewalComparisons,
    (data) => data && toRenewalComparisonSubmission(data),
    submissionUuid
  );
};
