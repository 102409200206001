import { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';

import { BANK_BASE_URL } from 'constants/globals';
import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import {
  ExistingFinancingRequestBody,
  ExistingFinancingsResponse,
  FinancialDataRowRequestBody,
  SheetMistakeRequestBody,
  SheetMistakesResponse,
  SheetRequestBody,
  SheetSnapshotResponse,
  SheetsResponse,
  WorksheetSnapshotResponse,
} from 'types/api/banking/types';
import { MutationKeys } from 'api/mutationKeys';
import { QueryKeys } from 'api/queryKeys';
import { DELETE_WORKSHEET_URL } from 'api/constants';

export const fetchSheets = async (
  submissionUuid: string
): Promise<SheetsResponse> => {
  const url = new URL(
    `api/v3/submissions/${submissionUuid}/sheets`,
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<SheetsResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Something went wrong fetching Sheets'
    );
  }

  return response.json();
};

export const fetchSheetsQueryOptions = (
  submissionUuid: string
): UseQueryOptions<SheetsResponse> => {
  return {
    queryKey: [QueryKeys.FETCH_SHEETS, submissionUuid],
    queryFn: () => fetchSheets(submissionUuid),
  };
};

export const updateExistingFinancing = async (
  sheetId: string,
  financingId: string,
  requestBody: ExistingFinancingRequestBody
): Promise<MutationResponse> => {
  const url = new URL(
    `/api/v3/sheets/${sheetId}/financings/${financingId}`,
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    void,
    ExistingFinancingRequestBody
  >(url, 'PATCH', requestBody);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Something went wrong updating Existing Financing'
    );
  }

  return { success: true };
};

export const updateExistingFinancingMutationOptions = (
  sheetId: string,
  financingId: string
): UseMutationOptions<
  MutationResponse,
  unknown,
  ExistingFinancingRequestBody
> => {
  return {
    mutationKey: [MutationKeys.UPDATE_EXISTING_FINANCING, sheetId, financingId],
    mutationFn: (requestBody) =>
      updateExistingFinancing(sheetId, financingId, requestBody),
  };
};

export const createSheet = async (
  submissionUuid: string,
  body: SheetRequestBody
): Promise<MutationResponse> => {
  const url = new URL(
    `api/v3/submissions/${submissionUuid}/sheets`,
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    MutationResponse,
    SheetRequestBody
  >(url, 'POST', body);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Something went wrong creating the new sheet'
    );
  }

  return { success: true };
};

export const createSheetsMutationOptions = (
  submissionUuid: string
): UseMutationOptions<MutationResponse, NetworkError, SheetRequestBody> => {
  return {
    mutationKey: [MutationKeys.CREATE_SHEET, submissionUuid],
    mutationFn: (requestBody) => createSheet(submissionUuid, requestBody),
  };
};

export const fetchExistingFinancings = async (
  sheetUuid: string
): Promise<ExistingFinancingsResponse> => {
  const url = new URL(`api/v3/sheets/${sheetUuid}/financings`, BANK_BASE_URL());

  const response = await makeInternalAPIRequest<ExistingFinancingsResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Something went wrong fetching Existing Financings'
    );
  }

  return response.json();
};

export const fetchExistingFinancingsQueryOptions = (
  sheetId: string
): UseQueryOptions<ExistingFinancingsResponse> => {
  return {
    queryKey: ['fetchSheetExistingFinancings', sheetId],
    queryFn: () => fetchExistingFinancings(sheetId),
  };
};

export const patchSheet = async (
  submissionUuid: string,
  sheetId: string,
  body: SheetRequestBody
): Promise<SheetsResponse> => {
  const url = new URL(
    `api/v3/submissions/${submissionUuid}/sheets/${sheetId}`,
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    SheetsResponse,
    SheetRequestBody
  >(url, 'PATCH', body);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Something went wrong updating Sheets'
    );
  }

  return response.json();
};

export const patchSnapshot = async (
  snapshotId: string,
  body: Partial<SheetSnapshotResponse>
): Promise<SheetsResponse> => {
  const url = new URL(`api/v3/snapshots/${snapshotId}`, BANK_BASE_URL());

  const response = await makeInternalAPIRequest<
    SheetsResponse,
    Partial<SheetSnapshotResponse>
  >(url, 'PATCH', body);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Something went wrong updating Snapshot'
    );
  }

  return response.json();
};

export const fetchMistakes = async (
  sheetId: string
): Promise<SheetMistakesResponse> => {
  const url = new URL(`api/v3/sheets/${sheetId}/mistakes`, BANK_BASE_URL());

  const response = await makeInternalAPIRequest<SheetMistakesResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Something went wrong fetching Mistakes'
    );
  }

  return response.json();
};

export const fetchMistakesQueryOptions = (
  sheetId: string
): UseQueryOptions<SheetMistakesResponse> => {
  return {
    queryKey: [QueryKeys.FETCH_MISTAKES, sheetId],
    queryFn: () => fetchMistakes(sheetId),
  };
};

export const updateMistake = async (
  sheetId: string,
  mistakeId: string,
  body: SheetMistakeRequestBody
): Promise<MutationResponse> => {
  const url = new URL(
    `/api/v3/sheets/${sheetId}/mistakes/${mistakeId}`,
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<void, SheetMistakeRequestBody>(
    url,
    'PATCH',
    body
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Something went wrong updating Mistake'
    );
  }

  return { success: true };
};

export const updateMistakeQueryOptions = (
  sheetId: string,
  mistakeId: string
): UseMutationOptions<
  MutationResponse,
  NetworkError,
  SheetMistakeRequestBody
> => {
  return {
    mutationKey: [MutationKeys.UPDATE_MISTAKE, sheetId, mistakeId],
    mutationFn: (body) => updateMistake(sheetId, mistakeId, body),
  };
};

export const createFinancialDataRow = async (
  sheetId: string,
  body: FinancialDataRowRequestBody,
  submissionUuid?: string
): Promise<MutationResponse<WorksheetSnapshotResponse>> => {
  const url = new URL(
    `api/v3/submissions/${submissionUuid}/sheets/${sheetId}/snapshots`,
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    WorksheetSnapshotResponse,
    FinancialDataRowRequestBody
  >(url, 'POST', body);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Something went wrong creating the new financial data row'
    );
  }
  const data = await response.json();

  return { success: true, response: data };
};

export const createFinancialDataRowMutationOptions = (
  sheetId: string,
  submissionUuid?: string
): UseMutationOptions<
  MutationResponse<WorksheetSnapshotResponse>,
  NetworkError,
  FinancialDataRowRequestBody
> => {
  return {
    mutationKey: [MutationKeys.ADD_NEW_FINANCIAL_DATA_ROW, submissionUuid],
    mutationFn: (requestBody: FinancialDataRowRequestBody) =>
      createFinancialDataRow(sheetId, requestBody, submissionUuid),
  };
};

export const deleteSheet = async ({
  sheetId,
  submissionUuid,
}: {
  submissionUuid: string;
  sheetId: string;
}): Promise<MutationResponse> => {
  const url = new URL(
    DELETE_WORKSHEET_URL(submissionUuid, sheetId),
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<void>(url, 'DELETE');

  if (response.ok) {
    return { success: true };
  }

  throw new NetworkError(response.status, 'Failed to delete worksheet');
};

export const deleteSheetQueryOptions = (
  submissionUuid: string,
  sheetId: string
): UseMutationOptions<MutationResponse, NetworkError> => {
  return {
    mutationKey: [MutationKeys.DELETE_SHEET, submissionUuid, sheetId],
    mutationFn: () => deleteSheet({ sheetId, submissionUuid }),
  };
};
