import { Flex, Subheading } from '@forward-financing/fast-forward';
import { ActionButtons } from '../ActionButtons/ActionButtons';

type WorksheetHeaderProps = {
  sheetId: string;
  isOverview?: boolean;
};

export const WorksheetHeader = ({
  isOverview = false,
  sheetId,
}: WorksheetHeaderProps): JSX.Element => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Subheading>
        {isOverview ? 'Overview Details' : 'Account Details'}
      </Subheading>

      <ActionButtons sheetId={sheetId} isOverview={isOverview} />
    </Flex>
  );
};
