import { useUserContext } from 'contexts/UserContext';
import { InternalNavbarLink } from './InternalNavbarLink';

export const NavbarLivePipelineLink = (): JSX.Element => {
  const { sub_role: subRole } = useUserContext();

  const livePipelineLink = subRole?.includes('underwriter')
    ? '/dashboard/live-underwriting-pipeline'
    : '/dashboard/live-pipeline';

  const livePipelineText = subRole?.includes('underwriter')
    ? 'UW Live Pipeline'
    : 'Live Pipeline';

  return (
    <InternalNavbarLink icon="bars-staggered" to={livePipelineLink}>
      {livePipelineText}
    </InternalNavbarLink>
  );
};
