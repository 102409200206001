import {
  Currency,
  formatDateString,
  Table,
} from '@forward-financing/fast-forward';
import { capitalize } from 'lodash';
import { FUNDING_BASE_URL } from 'constants/globals';
import { DetailedPayment } from './PaymentsDashboard.types';

type PaymentsIndexTableProps = {
  data: DetailedPayment[];
};

export const PaymentsIndexTable = ({
  data,
}: PaymentsIndexTableProps): JSX.Element => {
  const getDetailsLink = (id: number): URL => {
    return new URL(`/admin/paybacks/${id}`, FUNDING_BASE_URL());
  };

  const getAdvanceLink = (advance: number): URL => {
    return new URL(`/admin/advances/${advance}`, FUNDING_BASE_URL());
  };

  return (
    <Table>
      <Table.ColGroup>
        <Table.Col />
        <Table.Col />
        <Table.Col />
        <Table.Col />
        <Table.Col proportionalWidth={1.5} />
        <Table.Col />
        <Table.Col />
        <Table.Col />
      </Table.ColGroup>
      <Table.Head>
        <Table.ColumnHeader>Date</Table.ColumnHeader>
        <Table.ColumnHeader>Provider</Table.ColumnHeader>
        <Table.ColumnHeader>Same-day?</Table.ColumnHeader>
        <Table.ColumnHeader>Status</Table.ColumnHeader>
        <Table.ColumnHeader>Description</Table.ColumnHeader>
        <Table.ColumnHeader>Amount</Table.ColumnHeader>
        <Table.ColumnHeader>Advance</Table.ColumnHeader>
        <Table.ColumnHeader>Details</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        {data.map(
          ({
            id,
            date,
            provider,
            sameDay,
            status,
            description,
            amount,
            advance,
          }) => (
            <Table.Row key={id}>
              <Table.Cell>{formatDateString(date)}</Table.Cell>
              <Table.Cell>{provider ?? 'Unknown'}</Table.Cell>
              <Table.Cell>{sameDay ? 'Yes' : 'No'}</Table.Cell>
              <Table.Cell>{capitalize(status)}</Table.Cell>
              <Table.Cell>{description}</Table.Cell>
              <Table.Cell>
                {!isNaN(amount) && <Currency amount={amount} />}
              </Table.Cell>
              <Table.LinkCell href={getAdvanceLink(advance)} newTab>
                {advance}
              </Table.LinkCell>
              <Table.LinkCell href={getDetailsLink(id)} newTab>
                Link
              </Table.LinkCell>
            </Table.Row>
          )
        )}
      </Table.Body>
    </Table>
  );
};
