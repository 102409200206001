import { Box, Text } from '@forward-financing/fast-forward';
import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';

import { ExceptionRequestStatus } from 'components/ExceptionsRequest/exceptionsRequest.types';

type ExceptionRequestStatusBadgeProps = {
  status: ExceptionRequestStatus;
  declineNotes?: string;
  declineReason?: string;
};

const STATUS_READABLE_NAMES: Record<ExceptionRequestStatus, string> = {
  pending_review: 'Pending Assignment',
  in_progress: 'In Progress',
  reviewed: 'Reviewed',
};

const STATUS_BADGE_COLORS: Record<ExceptionRequestStatus, ColorVariant> = {
  pending_review: 'yellow-400',
  in_progress: 'yellow-400',
  reviewed: 'success-400',
};

const TEXT_COLORS: Record<ExceptionRequestStatus, ColorVariant> = {
  pending_review: 'gray-1000',
  in_progress: 'gray-1000',
  reviewed: 'white',
};

const isDeclined = (props: ExceptionRequestStatusBadgeProps): boolean =>
  props.status === 'reviewed' &&
  (!!props.declineNotes || !!props.declineReason);

const getBackgroundColor = (
  status: ExceptionRequestStatus,
  isRequestDeclined?: boolean
): ColorVariant => {
  if (isRequestDeclined) {
    return 'red-600';
  }

  return STATUS_BADGE_COLORS[status];
};

const getText = (
  status: ExceptionRequestStatus,
  isRequestDeclined?: boolean
): string => {
  const text = STATUS_READABLE_NAMES[status];

  if (isRequestDeclined) {
    return `${text} / Decline`;
  }

  if (status === 'reviewed') {
    return `${text} / Approval`;
  }

  return text;
};

export const getTextColor = (
  status: ExceptionRequestStatus,
  isRequestDeclined?: boolean
): ColorVariant => {
  if (isRequestDeclined) {
    return 'white';
  }

  return TEXT_COLORS[status];
};

export const ExceptionRequestStatusBadge = ({
  status,
  declineNotes,
  declineReason,
}: ExceptionRequestStatusBadgeProps): JSX.Element => {
  const isReqDeclined = isDeclined({ status, declineNotes, declineReason });

  const backgroundColor = getBackgroundColor(status, isReqDeclined);

  const textColor = getTextColor(status, isReqDeclined);

  const text = getText(status, isReqDeclined);

  return (
    <Box backgroundColor={backgroundColor} px={1} borderRadius={3}>
      <Text bold size="s" color={textColor}>
        {text}
      </Text>
    </Box>
  );
};
