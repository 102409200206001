import { useCallback } from 'react';
import {
  UseGenericQueryResponse,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import { AcceptedOfferResponse } from 'types/api/banking/types';
import { fetchAcceptedOffer } from 'api/banking/acceptedOfferFetchUtils';
import { AcceptedOfferErrorResponse } from 'components/NewRenewalComparison/renewalComparison.types';
import { NetworkError } from 'api/networkError';

export const useApiAcceptedOffer = (
  submissionUuid?: string
): UseGenericQueryResponse<AcceptedOfferResponse> => {
  const loadData = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchAcceptedOffer(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(loadData);
};

export const useApiAcceptedOffers = (
  submissionUuids?: string[]
): UseGenericQueryResponse<
  (AcceptedOfferResponse | AcceptedOfferErrorResponse)[] | undefined
> => {
  const loadData = useCallback(async () => {
    if (!submissionUuids) {
      return undefined;
    }

    const results = await Promise.allSettled(
      submissionUuids.map((uuid) => fetchAcceptedOffer(uuid))
    );

    // We need to include all the results, even if some of them failed
    return results.map((result) =>
      result.status === 'fulfilled'
        ? result.value
        : // Any error will return the fetch function's NetworkError message
          { error: (result.reason as NetworkError)?.message }
    );
  }, [submissionUuids]);

  return useGenericQuery(loadData);
};
