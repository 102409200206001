import {
  useApiAcceptedOffer,
  useApiLowerPrepayments,
  useApiResetAcceptedOffer,
} from 'apiHooks/underwriting/acceptedOfferFetchHooks';
import {
  MutationResponse,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { AcceptedOfferResponse } from 'types/api/underwriting/types';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { useGetApiIso } from 'apiHooks/funding/isoFetchHooks';
import { IsoResponse } from 'types/api/funding/types';
import { AcceptedOffer } from './types';

export type UseResetAcceptedOfferHookArgs = {
  submissionUuid: string;
};

export type UseResetAcceptedOfferResponse = [
  (offerId: string) => Promise<MutationResponse>,
  { data?: { success: boolean }; error?: Error; loading: boolean }
];

export const useResetAcceptedOffer = ({
  submissionUuid,
}: UseResetAcceptedOfferHookArgs): UseResetAcceptedOfferResponse => {
  const [postFn, { error, ...rest }] = useApiResetAcceptedOffer();

  const postFunction = async (offerId: string): Promise<MutationResponse> =>
    postFn({ submissionUuid, offerId });
  return [
    postFunction,
    {
      error,
      ...rest,
    },
  ];
};

export type UseLowerPrepaymentsArgs = {
  submissionUuid: string;
};

export type UseLowerPrepaymentsResponse = [
  () => Promise<MutationResponse>,
  { data?: { success: boolean }; error?: Error; loading: boolean }
];

export const useLowerPrepayments = ({
  submissionUuid,
}: UseLowerPrepaymentsArgs): UseLowerPrepaymentsResponse => {
  const [postFn, { error, ...rest }] = useApiLowerPrepayments();

  const postFunction = async (): Promise<MutationResponse> =>
    postFn({ submissionUuid });
  return [
    postFunction,
    {
      error,
      ...rest,
    },
  ];
};

const toAcceptedOffer = (response: AcceptedOfferResponse): AcceptedOffer => {
  return {
    id: response.id,
    purchasePrice: response.amount,
    factorRate: response.buy_rate ?? undefined,
    upsell: response.upsell ?? undefined,
    totalFactorRate: response.total_factor_rate ?? undefined,
    term: response.term ?? undefined,
    dailyPayment: response.daily_payment ?? undefined,
    amountSold: response.payback_amount ?? undefined,
    commission: response.commission ?? undefined,
    defaultProcessingFee: response.default_processing_fee,
    processingFee: response.processing_fee,
    holdbackPercent: response.rounded_holdback_percent ?? undefined,
    acceptedOnDate: response.created_at,
  };
};

const acceptedOfferExists = ({
  term,
  upsell,
  buy_rate,
}: AcceptedOfferResponse): boolean => {
  return term !== null && upsell !== null && buy_rate !== null; // This is the same logic the BE uses
};

export const useAcceptedOffer = (
  submissionUuid?: string
): UseGenericQueryResponse<AcceptedOffer> => {
  return useGenericFeatureQuery(
    useApiAcceptedOffer,
    (data) =>
      (data && acceptedOfferExists(data) && toAcceptedOffer(data)) || undefined,
    submissionUuid
  );
};

const toIsoName = (response?: IsoResponse): string | undefined => {
  return response?.partner.name;
};

export const useIsoName = (
  isoUuid?: string
): UseGenericQueryResponse<string> => {
  return useGenericFeatureQuery(useGetApiIso, toIsoName, isoUuid);
};

// TODO: Ensure full coverage when hooks are used in components
