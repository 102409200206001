import {
  Checkbox,
  DescriptionList,
  Flex,
  Grid,
  Sheet,
  TextArea,
} from '@forward-financing/fast-forward';
import { useCallback } from 'react';
import { Sheet as SheetType } from '../cashFlow.types';

type AccountDetailsProps = {
  sheetToUpdate: SheetType;
  setSheetToUpdate: React.Dispatch<React.SetStateAction<SheetType>>;
};

export const AccountDetails = ({
  sheetToUpdate,
  setSheetToUpdate,
}: AccountDetailsProps): JSX.Element => {
  const onChangeHandler = useCallback(
    (fieldToUpdate: Partial<SheetType>): void => {
      setSheetToUpdate((prev) => ({ ...prev, ...fieldToUpdate }));
    },
    [setSheetToUpdate]
  );

  return (
    <Grid gutter>
      <Grid.Item xs={6}>
        <Sheet>
          <Sheet.Head>
            <Sheet.ColumnHeader backgroundColor="gray-100" columnWidth="medium">
              Account Number
            </Sheet.ColumnHeader>

            <Sheet.ColumnHeader backgroundColor="gray-100" columnWidth="medium">
              Include in Overview?
            </Sheet.ColumnHeader>

            <Sheet.ColumnHeader backgroundColor="gray-100" columnWidth="medium">
              Primary?
            </Sheet.ColumnHeader>
          </Sheet.Head>

          <Sheet.Body>
            <Sheet.Row backgroundColor="white">
              <Sheet.TextCell
                value={sheetToUpdate.accountNumber}
                onValueChange={(newValue) =>
                  onChangeHandler({ accountNumber: newValue })
                }
              />

              <Sheet.TextCell>
                <Flex justifyContent={'center'}>
                  <Checkbox
                    label={''}
                    checked={sheetToUpdate.includeInOverview}
                    onCheckboxChange={(newValue) =>
                      onChangeHandler({ includeInOverview: newValue })
                    }
                  />
                </Flex>
              </Sheet.TextCell>

              <Sheet.TextCell>
                <Flex justifyContent={'center'}>
                  <Checkbox
                    label={''}
                    checked={sheetToUpdate.primary}
                    onCheckboxChange={(newValue) =>
                      onChangeHandler({ primary: newValue })
                    }
                  />
                </Flex>
              </Sheet.TextCell>
            </Sheet.Row>
          </Sheet.Body>
        </Sheet>
      </Grid.Item>

      <Grid.Item xs={6}>
        <DescriptionList divider="compact">
          <DescriptionList.Item>
            <DescriptionList.Term>Processing Notes</DescriptionList.Term>

            <DescriptionList.Details>
              <TextArea
                label={'Processing notes'}
                hiddenLabel
                value={sheetToUpdate.processingNotes}
                onChange={(newVal) =>
                  onChangeHandler({ processingNotes: newVal.target.value })
                }
              />
            </DescriptionList.Details>
          </DescriptionList.Item>
        </DescriptionList>
      </Grid.Item>
    </Grid>
  );
};
