import { useState } from 'react';
import { Flex, Banner, Button } from '@forward-financing/fast-forward';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { AnalyticsGatewayClient } from 'api/AnalyticsGatewayClient';
import { useUserContext } from 'contexts/UserContext';
import { EditAttachmentsModal } from './EditAttachmentsModal';
import { Attachment, AttachmentSource } from './attachmentManager.types';
import { AttachmentsTable } from './AttachmentsTable';
import { DeleteAttachmentModal } from './DeleteAttachmentModal';
import { ReadOnlyAttachmentsTable } from './ReadOnlyAttachmentsTable';
import { DownloadAllAttachmentsButton } from './DownloadAllAttachmentsButton';
import { BulkEditAttachmentModal } from './BulkEditAttachmentModal';
import { useAttachmentManagerContext } from './AttachmentManagerContext';

export interface AttachmentGroupProps {
  attachments: Attachment[];
  source: AttachmentSource;
  shouldShowDownload: boolean;
}

export const AttachmentGroup = ({
  attachments,
  source,
  shouldShowDownload,
}: AttachmentGroupProps): JSX.Element => {
  const { isPrequalContext, primaryId } = useAttachmentManagerContext();
  const user = useUserContext();

  const [selectedAttachmentIds, setSelectedAttachmentIds] = useState<string[]>(
    []
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { isSalesforce } = useAttachmentManagerContext();

  const deletedAttachments = attachments.filter((att) => att.fileDeletedAt);
  const currentAttachments = attachments.filter((att) => !att.fileDeletedAt);

  const onAttachmentRowChecked = (
    attachmentId: string,
    checked: boolean
  ): void => {
    if (checked) {
      setSelectedAttachmentIds((prev) => [...prev, attachmentId]);
    } else {
      setSelectedAttachmentIds((prev) =>
        prev.filter((a) => a !== attachmentId)
      );
    }
  };

  const toggleAllChecked = (allChecked: boolean): void => {
    if (allChecked) {
      setSelectedAttachmentIds(currentAttachments.map((att) => att.uuid));
    } else {
      setSelectedAttachmentIds([]);
    }
  };

  const selectedAttachments = currentAttachments.filter((att) =>
    selectedAttachmentIds.includes(att.uuid)
  );

  const sendLogsToAnalyticsGateway = async (): Promise<void> => {
    try {
      const logData = {
        field_name: 'Open all bank statements',
        object_uuid: primaryId,
        name: 'Open All Bank Statements',
        action: 'Open All Bank Statements',
        object_type: 'SalesforceOpportunity',
      };

      return await AnalyticsGatewayClient.createLogSender(
        user,
        'Attachments Manager'
      )(logData);
    } catch {
      // return a promise to make TS happy.
      // don't bubble error any further because
      // we still want user to be able to do the download
      return Promise.resolve();
    }
  };

  const bankStatementFiles = attachments.filter((att) =>
    att.documentTags.includes('bank_statement')
  );

  const handleOpenAllBankStatements = async (): Promise<void> => {
    await sendLogsToAnalyticsGateway();

    const urls = bankStatementFiles.map(
      (att) =>
        new URL(
          isPrequalContext
            ? `/api/v1/applications/${primaryId}/attachments/${att.uuid}`
            : `/api/v2/submissions/${primaryId}/attachments/${att.uuid}`,
          UNDERWRITING_BASE_URL()
        )
    );

    urls.forEach((url) => {
      window.open(url.toString(), '_blank');
    });
  };

  return (
    <>
      {errorMessage && (
        <Banner dismissable={false} variant="error">
          {errorMessage}
        </Banner>
      )}
      <Flex
        justifyContent={'flex-end'}
        marginBottom={3}
        gap={2}
        flexWrap="wrap"
      >
        {shouldShowDownload && bankStatementFiles.length > 0 && (
          <Button
            startIcon="money-check-dollar"
            onClick={handleOpenAllBankStatements}
          >
            Open All Bank Statements
          </Button>
        )}

        <DeleteAttachmentModal selectedAttachments={selectedAttachments} />
        {!isSalesforce && (
          <EditAttachmentsModal
            selectedAttachments={selectedAttachments}
            onSaveComplete={() => setSelectedAttachmentIds([])}
          />
        )}
        {!isSalesforce && (
          <BulkEditAttachmentModal
            selectedAttachments={selectedAttachments}
            onSaveComplete={() => setSelectedAttachmentIds([])}
          />
        )}
        {shouldShowDownload && (
          <DownloadAllAttachmentsButton
            source={source}
            setErrorMessage={setErrorMessage}
            disabledForDocumentRetention={attachments.every(
              (att) => !!att.fileDeletedAt
            )}
          />
        )}
      </Flex>
      <Flex flexDirection="column" gap={3}>
        {currentAttachments.length > 0 && (
          <AttachmentsTable
            attachments={currentAttachments}
            selectedAttachmentIds={selectedAttachmentIds}
            onRowSelect={onAttachmentRowChecked}
            toggleAllChecked={toggleAllChecked}
          />
        )}
        {deletedAttachments.length > 0 && (
          <ReadOnlyAttachmentsTable attachments={deletedAttachments} />
        )}
      </Flex>
    </>
  );
};
