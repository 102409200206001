import { useEffect, useMemo } from 'react';
import {
  Banner,
  Flex,
  formatCurrency,
  Loading,
  Text,
} from '@forward-financing/fast-forward';
import { defaultTo } from 'lodash';

import { useGetApiFeatureFlagWithIdentifier } from 'apiHooks/underwriting/featureFlagFetchHooks';
import { CamelizeKeys } from 'types/camelize';
import { PricingSuggestionResponse } from 'types/api/underwriting/types';
import {
  useLedgerBankingSuggestedPricing,
  useLedgerUnderwritingSuggestedPricing,
} from './ledgerHooks';
import { Ledger, LedgerBankingPricingSuggestions } from './ledger.types';
import { useLedgerContext } from './LedgerContext/LedgerContext';

export type SuggestedPricingContainerProps = {
  submissionUuid: string;
  customerUuid?: string;
  ledger: Ledger;
};

const BankingSuggestedPricing = ({
  ledgerId,
}: {
  ledgerId: number;
}): JSX.Element | null => {
  const { data, loading, error } = useLedgerBankingSuggestedPricing(ledgerId);
  const { setSuggestedProgram } = useLedgerContext();

  useEffect(() => {
    if (data?.programType) {
      setSuggestedProgram(data.programType);
    }
  }, [data?.programType, setSuggestedProgram]);

  const renderDeclineMessage = useMemo(() => {
    return !!data?.declineMessage;
  }, [data?.declineMessage]);

  const getGrossBasisText = (
    suggestion: LedgerBankingPricingSuggestions
  ): string =>
    `${suggestion.grossBasis.amount}${
      suggestion.grossBasis.unit
    } / ${formatCurrency(parseInt(suggestion.gross.displayedAmount, 10))}`;

  return (
    <>
      {loading && <Loading />}
      {error && <Banner>{error.message}</Banner>}
      {data && renderDeclineMessage && (
        <Banner variant="caution">{data.declineMessage}</Banner>
      )}
      {data && !renderDeclineMessage && (
        <Flex
          justifyContent="flex-start"
          gap={3}
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          mb={3}
        >
          <Text bold>System Suggested Pricing: </Text>
          <Flex gap={1}>
            <Text bold>UW Score: </Text>
            <Text>{data.underwritingAverageScore?.toFixed(1)}</Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Industry Risk: </Text>
            <Text>{data.industryRisk}</Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Program: </Text>
            <Text>
              {data.programType === 'High Risk' ? 'Core' : data.programType}
            </Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Estimated Term: </Text>
            <Text>
              {data.estimatedTerm === 1
                ? `${data.estimatedTerm} month`
                : `${data.estimatedTerm} months`}
            </Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Gross: </Text>
            <Text>{getGrossBasisText(data)}</Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};

type UnderwritingSuggestedPricingProps = {
  data?: CamelizeKeys<PricingSuggestionResponse>;
  loading: boolean;
  error?: Error;
};
const UnderwritingSuggestedPricing = ({
  data,
  error,
  loading,
}: UnderwritingSuggestedPricingProps): JSX.Element | null => {
  const { setSuggestedProgram } = useLedgerContext();

  useEffect(() => {
    if (data?.programType) {
      setSuggestedProgram(data.programType);
    }
  }, [data?.programType, setSuggestedProgram]);

  return (
    <>
      {loading && <Loading />}
      {error && <Banner>{error.message}</Banner>}
      {data && (
        <Flex
          justifyContent="flex-start"
          gap={3}
          alignItems="center"
          flexWrap="wrap"
          mb={3}
        >
          <Text bold>System Suggested Pricing: </Text>
          <Flex gap={1}>
            <Text bold>Risk Score: </Text>
            <Text>{data.inputs.riskScore}</Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Industry Risk: </Text>
            <Text>{data.inputs.industryRisk}</Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Program: </Text>
            <Text>{data.programType}</Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Pre-Forward Gross: </Text>
            <Text>{data.inputs.financingsGross}%</Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Recommended Term: </Text>
            <Text>
              {data.maxTerm === 1
                ? `${data.maxTerm} month`
                : `${data.maxTerm} months`}
            </Text>
          </Flex>

          <Flex gap={1}>
            <Text bold>Recommended Advance Amount: </Text>
            <Text>{formatCurrency(data.roundedAmount)}</Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export const SuggestedPricingContainer = ({
  submissionUuid,
  customerUuid,
  ledger,
}: SuggestedPricingContainerProps): JSX.Element => {
  const pricingSuggestionsAbTestFlagEnabled =
    useGetApiFeatureFlagWithIdentifier(
      'ua_ba_pricing_suggestion_a_b_test',
      customerUuid
    );

  const { setUnderwritingSuggestedProgram } = useLedgerContext();

  const { data, error, loading } = useLedgerUnderwritingSuggestedPricing(
    submissionUuid,
    {
      sourceKey: ledger.id.toString(),
      // Hardcode the sourceType to Underwriting::Ledger for now
      sourceType: 'Underwriting::Ledger',
      revenue: defaultTo(ledger.revenue, ''),
      financingsCount: defaultTo(ledger.financingsCount?.toString(), ''),
      financingsGross: defaultTo(ledger.financingsGross, ''),
    }
  );

  // this is to make sure program type FROM UA is always tracked because it is used to find the risk assignment
  // since UA suggested pricing is behind a FT we need to make sure this call always happens regardless of the FT
  useEffect(() => {
    if (data?.programType) {
      setUnderwritingSuggestedProgram(data.programType);
    }
  }, [data?.programType, setUnderwritingSuggestedProgram]);

  return (
    <>
      {pricingSuggestionsAbTestFlagEnabled ? (
        <UnderwritingSuggestedPricing
          data={data}
          error={error}
          loading={loading}
        />
      ) : (
        <BankingSuggestedPricing ledgerId={ledger.id} />
      )}
    </>
  );
};
