import {
  Banner,
  Button,
  Checkbox,
  Flex,
  formatCurrency,
  formatDateString,
  Sheet,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import { Sheet as SheetType } from '../cashFlow.types';
import { displayPercentage } from '../../../../helpers/utils';
import { useCreateFinancialDataRowMutationOptions } from '../cashFlowHooks';
import { FinancialDataAverageSection } from './FinancialDataAverageSection';

type CashFlowFinancialDataContainerProps = {
  sheetData: SheetType;
  submissionUuid?: string;
  isOverview?: boolean;
};

export const FinancialDataContainer = ({
  sheetData,
  submissionUuid,
  isOverview = false,
}: CashFlowFinancialDataContainerProps): JSX.Element => {
  const {
    mutate: createFinancialDataRow,
    error: createFinancialDataError,
    isError: isCreateFinancialDataError,
    reset: resetCreateMutationState,
  } = useCreateFinancialDataRowMutationOptions(sheetData.id, submissionUuid);

  const getRevenue = (
    deposits: string,
    fundingAdj: string,
    transferAdj: string,
    otherAdj: string
  ): number => {
    const depositNum = parseFloat(deposits);
    const fundingAdjNum = parseFloat(fundingAdj);
    const transferAdjNum = parseFloat(transferAdj);
    const otherAdjNum = parseFloat(otherAdj);

    return depositNum - (fundingAdjNum + transferAdjNum + otherAdjNum);
  };

  const getAdbCalc = (
    beginningBalance: number,
    endingBalance: number
  ): number => {
    return (beginningBalance + endingBalance) / 2;
  };

  const getAdbPercentCalc = (
    adb: number,
    adbCalc: number,
    deposits: number
  ): number => {
    if (isNaN(deposits) || deposits === 0) {
      return 0;
    }

    const adbValue = adb || adbCalc;
    return (adbValue / deposits) * 100;
  };

  const createNewFinancialDataRow = (): void => {
    const array = sheetData.snapshots;
    const last = array[array.length - 1];
    const lastDate = new Date(last.month);

    // Subtract one month
    lastDate.setMonth(lastDate.getMonth() - 1);

    // Format to YYYY-MM-DD
    const newMonth = lastDate.toISOString().split('T')[0];

    createFinancialDataRow({ month: newMonth });
  };

  return (
    <Flex flexDirection={'column'}>
      {isCreateFinancialDataError && (
        <Banner onDismiss={() => resetCreateMutationState()}>
          {createFinancialDataError.message}
        </Banner>
      )}

      <Flex alignItems={'center'} gap={3}>
        <Subheading>Financial Data</Subheading>
        {!isOverview && (
          <Button
            variant={'primary'}
            startIcon={'add'}
            onClick={createNewFinancialDataRow}
          >
            Add financing
          </Button>
        )}
      </Flex>
      <Sheet>
        <Sheet.Head>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Month
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Deposits
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Funding Adjustment
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Transfer Adjustment
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Other Adjustment
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Revenue
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'small'}
          >
            <Flex
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <div>Include?</div>
              <Flex justifyContent={'center'}>
                <Checkbox label={''} />
              </Flex>
            </Flex>
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'small'}
          >
            Deposit Count
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'small'}
          >
            Neg. Days
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'small'}
          >
            NSFs
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Beg. Balance
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            End. Balance
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            ADB Calc
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            ADB
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'small'}
          >
            ADB %
          </Sheet.ColumnHeader>
          <Sheet.ColumnHeader
            backgroundColor={'gray-100'}
            minColumnWidth={'medium'}
          >
            Min. Balance
          </Sheet.ColumnHeader>
        </Sheet.Head>
        <Sheet.Body>
          {sheetData.snapshots.map((item) => {
            return (
              <Sheet.Row key={item.id} backgroundColor={'gray-200'}>
                <Sheet.TextCell value={formatDateString(item.month)} />
                <Sheet.TextCell value={formatCurrency(Number(item.deposits))} />
                <Sheet.TextCell
                  value={formatCurrency(Number(item.fundingAdjustment))}
                />
                <Sheet.TextCell
                  value={formatCurrency(Number(item.transferAdjustment))}
                />
                <Sheet.TextCell
                  value={formatCurrency(Number(item.otherAdjustment))}
                />
                <Sheet.TextCell backgroundColor={'gray-800'}>
                  <Flex justifyContent={'center'}>
                    <Text color={'white'}>
                      {formatCurrency(
                        getRevenue(
                          item.deposits,
                          item.fundingAdjustment,
                          item.transferAdjustment,
                          item.otherAdjustment
                        )
                      )}
                    </Text>
                  </Flex>
                </Sheet.TextCell>
                <Sheet.TextCell>
                  <Flex justifyContent={'center'}>
                    <Checkbox
                      label={''}
                      checked={item.includeInAutocalculate}
                    />
                  </Flex>
                </Sheet.TextCell>
                <Sheet.TextCell value={item.depositCountString} />
                <Sheet.TextCell value={item.negativeDaysCountString} />
                <Sheet.TextCell value={item.nsfCountString} />
                <Sheet.TextCell
                  value={formatCurrency(item.beginningBalanceCents / 100)}
                />
                <Sheet.TextCell
                  value={formatCurrency(item.endingBalanceCents / 100)}
                />
                <Sheet.TextCell backgroundColor={'gray-800'}>
                  <Flex justifyContent={'center'}>
                    <Text color={'white'}>
                      {formatCurrency(
                        getAdbCalc(
                          item.beginningBalanceCents,
                          item.endingBalanceCents
                        ) / 100
                      )}
                    </Text>
                  </Flex>
                </Sheet.TextCell>
                <Sheet.TextCell
                  value={formatCurrency(item.averageDailyBalanceCents / 100)}
                />
                <Sheet.TextCell backgroundColor={'gray-800'}>
                  <Flex justifyContent={'center'}>
                    <Text color={'white'}>
                      {displayPercentage(
                        getAdbPercentCalc(
                          item.averageDailyBalanceCents,
                          getAdbCalc(
                            item.beginningBalanceCents,
                            item.endingBalanceCents
                          ),
                          getRevenue(
                            item.deposits,
                            item.fundingAdjustment,
                            item.transferAdjustment,
                            item.otherAdjustment
                          )
                        )
                      )}
                    </Text>
                  </Flex>
                </Sheet.TextCell>
                <Sheet.TextCell
                  value={formatCurrency(item.minimumBalanceCents / 100)}
                />
              </Sheet.Row>
            );
          })}
          <FinancialDataAverageSection
            sheetData={sheetData}
            snapShotData={sheetData.snapshots}
            getRevenue={getRevenue}
            getAdbCalc={getAdbCalc}
            getAdbPercentCalc={getAdbPercentCalc}
          />
        </Sheet.Body>
      </Sheet>
    </Flex>
  );
};
