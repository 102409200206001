import {
  Sheet,
  IconButton,
  Flex,
  Combobox,
  formatCurrency,
  Checkbox,
  SelectableOption,
  Text,
  DatePicker,
  PlainDate,
  TextInput,
} from '@forward-financing/fast-forward';
import { useMemo } from 'react';
import { displayPercentage } from 'helpers/utils';
import { getSelectedOption } from '../../cashFlowUtils';
import { ExistingFinancing, FinancingFrequency } from '../../cashFlow.types';
import { FREQUENCY_OPTIONS } from '../existingFinancingConstants';
import {
  getMonthlyPaymentValue,
  getGrossPercentValue,
  getAdvancedAmountValue,
  isFinancingFrequency,
  toCents,
  parseCurrency,
  toDollars,
} from '../existingFinancingUtils';

type ExistingFinancingRowProps = {
  financing: ExistingFinancing;
  netDepositOverride: number;
  updateFinancingAttribute: (
    financingId: string,
    attributeUpdater: (
      attrs: ExistingFinancing['attributes']
    ) => Partial<ExistingFinancing['attributes']>
  ) => void;
  isReadOnly?: boolean;
};

export const ExistingFinancingRow = ({
  financing,
  netDepositOverride,
  updateFinancingAttribute,
  isReadOnly = false,
}: ExistingFinancingRowProps): JSX.Element => {
  const handleCompanyChange = (newValue: string): void => {
    updateFinancingAttribute(financing.id, () => ({ company: newValue }));
  };

  const frequencyValue = useMemo(
    () => getSelectedOption(FREQUENCY_OPTIONS, financing.attributes.frequency),
    [financing.attributes.frequency]
  );

  const handleFrequencyChange = (newValues: SelectableOption[]): void => {
    if (newValues.length === 0 || !isFinancingFrequency(newValues[0].value)) {
      return;
    }

    updateFinancingAttribute(financing.id, () => ({
      frequency: newValues[0].value as FinancingFrequency,
    }));
  };

  const amountValue = useMemo(
    () => formatCurrency(toDollars(financing.attributes.dailyAmountCents)),
    [financing.attributes.dailyAmountCents]
  );

  const handleAmountChange = (newValue: string): void => {
    const dailyAmountCents = toCents(parseCurrency(newValue));

    updateFinancingAttribute(financing.id, () => ({
      dailyAmountCents,
    }));
  };

  const monthlyPaymentValue = useMemo(
    () =>
      formatCurrency(
        getMonthlyPaymentValue(
          toDollars(financing.attributes.dailyAmountCents),
          financing.attributes.frequency
        )
      ),
    [financing.attributes.dailyAmountCents, financing.attributes.frequency]
  );

  const grossPercentValue = useMemo(
    () =>
      displayPercentage(
        getGrossPercentValue(netDepositOverride)(
          getMonthlyPaymentValue(
            toDollars(financing.attributes.dailyAmountCents),
            financing.attributes.frequency
          )
        )
      ),
    [
      financing.attributes.dailyAmountCents,
      financing.attributes.frequency,
      netDepositOverride,
    ]
  );

  const handleIncludedChange = (): void => {
    updateFinancingAttribute(financing.id, (prevAttrs) => ({
      isExcluded: !prevAttrs.isExcluded,
    }));
  };

  const fundedDateValue = useMemo(() => {
    if (!financing.attributes.fundedDate) {
      return undefined;
    }

    const dateObj = new Date(financing.attributes.fundedDate);

    return new PlainDate(
      dateObj.getFullYear(),
      dateObj.getMonth() + 1,
      dateObj.getDate()
    );
  }, [financing.attributes.fundedDate]);

  const handleFundedDateChange = (newValue: PlainDate | undefined): void => {
    updateFinancingAttribute(financing.id, () => ({
      fundedDate: newValue?.toString(),
    }));
  };

  const fundedAmountValue = useMemo(
    () => formatCurrency(toDollars(financing.attributes.fundedAmountCents)),
    [financing.attributes.fundedAmountCents]
  );

  const handleFundedAmountChange = (newValue: string): void => {
    const fundedAmountCents = toCents(parseCurrency(newValue));

    updateFinancingAttribute(financing.id, () => ({
      fundedAmountCents,
    }));
  };

  const handleTermChange = (newValue: string): void => {
    const term = parseInt(newValue, 10);

    updateFinancingAttribute(financing.id, () => ({
      term,
    }));
  };

  const handleFactorRateChange = (newValue: string): void => {
    const factorRate = parseFloat(newValue);

    updateFinancingAttribute(financing.id, () => ({
      factorRate,
    }));
  };

  const advanceAmountValue = useMemo(
    () =>
      formatCurrency(
        getAdvancedAmountValue(
          getMonthlyPaymentValue(
            toDollars(financing.attributes.dailyAmountCents),
            financing.attributes.frequency
          ),
          financing.attributes.term,
          financing.attributes.factorRate
        )
      ),
    [
      financing.attributes.dailyAmountCents,
      financing.attributes.factorRate,
      financing.attributes.frequency,
      financing.attributes.term,
    ]
  );

  const handleIsRenewalChange = (): void => {
    updateFinancingAttribute(financing.id, (prevAttrs) => ({
      isRenewal: !prevAttrs.isRenewal,
    }));
  };

  return (
    <Sheet.Row key={financing.id} backgroundColor={'white'}>
      <Sheet.TextCell>
        {/** TODO: do not forget to avoid deleting financing in read only mode */}
        <IconButton icon={'trash-can'} label={'Delete financing'} hiddenLabel />
      </Sheet.TextCell>

      <Sheet.TextCell>
        <Flex px={1}>
          <TextInput
            value={financing.attributes.company}
            onValueChange={handleCompanyChange}
            placeholder="Company name"
            label="Company name"
            hiddenLabel
            disabled={isReadOnly}
          />
        </Flex>
      </Sheet.TextCell>

      <Sheet.TextCell>
        <Flex px={1}>
          <Combobox
            label=""
            isFixed={true}
            onValueChange={handleFrequencyChange}
            options={FREQUENCY_OPTIONS}
            values={frequencyValue}
            disabled={isReadOnly}
          />
        </Flex>
      </Sheet.TextCell>

      <Sheet.TextCell
        value={amountValue}
        onValueChange={isReadOnly ? undefined : handleAmountChange}
      />

      <Sheet.TextCell backgroundColor={'gray-800'}>
        <Flex justifyContent={'center'}>
          <Text color={'white'}>{monthlyPaymentValue}</Text>
        </Flex>
      </Sheet.TextCell>

      <Sheet.TextCell backgroundColor={'gray-800'}>
        <Flex justifyContent={'center'}>
          <Text color={'white'}>{grossPercentValue}</Text>
        </Flex>
      </Sheet.TextCell>

      <Sheet.TextCell>
        <Flex justifyContent={'center'}>
          <Checkbox
            label={''}
            isLabelHidden
            checked={!financing.attributes.isExcluded}
            onCheckboxChange={handleIncludedChange}
            disabled={isReadOnly}
          />
        </Flex>
      </Sheet.TextCell>

      <Sheet.TextCell>
        <Flex justifyContent={'center'} px={1}>
          <DatePicker
            canSelectTime={false}
            label="Funded date"
            hiddenLabel
            selected={fundedDateValue}
            onChange={handleFundedDateChange}
            showDropdownCalendarPicker
            disabled={isReadOnly}
          />
        </Flex>
      </Sheet.TextCell>

      <Sheet.TextCell
        value={fundedAmountValue}
        onValueChange={isReadOnly ? undefined : handleFundedAmountChange}
      />

      <Sheet.TextCell
        value={financing.attributes.term?.toString()}
        onValueChange={isReadOnly ? undefined : handleTermChange}
      />

      <Sheet.TextCell
        value={financing.attributes.factorRate?.toString()}
        onValueChange={isReadOnly ? undefined : handleFactorRateChange}
      />

      <Sheet.TextCell backgroundColor={'gray-800'}>
        <Flex justifyContent={'center'}>
          <Text color={'white'}>{advanceAmountValue}</Text>
        </Flex>
      </Sheet.TextCell>

      <Sheet.TextCell>
        <Flex justifyContent={'center'}>
          <Checkbox
            label={''}
            isLabelHidden
            checked={financing.attributes.isRenewal}
            onCheckboxChange={handleIsRenewalChange}
            disabled={isReadOnly}
          />
        </Flex>
      </Sheet.TextCell>
    </Sheet.Row>
  );
};
