import { useCallback, useState } from 'react';
import {
  Banner,
  Card,
  Flex,
  formatDateString,
  Loading,
  Pagination,
} from '@forward-financing/fast-forward';
import { PaymentsIndexTable } from './PaymentsIndexTable';
import { useGetDetailedPayments } from './PaymentsDashboardHooks';

type PaymentsIndexProps = {
  status: string;
  date: string;
  provider: string;
  sameDay: boolean;
};

export const PaymentsIndex = ({
  status,
  date,
  provider,
  sameDay,
}: PaymentsIndexProps): JSX.Element => {
  const [page, setPage] = useState(1);

  const {
    data: paymentIndexData,
    loading: paymentIndexLoading,
    error: paymentIndexError,
  } = useGetDetailedPayments({ status, date, provider, sameDay, page });

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const cardTitle = `${
    sameDay ? 'Same-day' : 'Non-same-day'
  } ${status} payments for ${
    provider === 'null' ? 'Unknown' : provider //If we had a provider actually named "null" this could be a problem, but this is the same way the backend works(passing provider=null as a URL param)
  }, ${formatDateString(date)}`;

  return (
    <Card title={cardTitle}>
      {paymentIndexLoading && <Loading size="medium" />}
      {paymentIndexError && <Banner>{paymentIndexError.message}</Banner>}
      {!paymentIndexLoading && paymentIndexData && (
        <>
          <PaymentsIndexTable data={paymentIndexData.payments} />
          <Flex flexDirection="row-reverse">
            <Pagination
              numPages={paymentIndexData.totalPages}
              currentPage={paymentIndexData.currentPage}
              onPageClick={handlePageChange}
            />
          </Flex>
        </>
      )}
    </Card>
  );
};
