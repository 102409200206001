import { useCallback, useMemo } from 'react';
import {
  useGetApiOwner,
  useGetApiOwnerSimilarContacts,
  useLazyGetApiSimilarOwners,
} from 'apiHooks/underwriting/ownerFetchHooks';
import { useGetBankingSubmissionsData } from 'apiHooks/banking/bankingSubmissionHooks';
import { useApiIsos, UseApiIsosResponse } from 'apiHooks/funding/isoFetchHooks';
import {
  useGetApiCustomer,
  UseGetApiCustomerResponse,
  useGetCustomerSubmissions,
  useLazyGetApiCustomer,
} from 'apiHooks/underwriting/customerFetchHooks';
import {
  useApiRefreshOwners,
  useGetOwnerFicoScoresBatch,
} from 'apiHooks/underwriting/submissionFetchHooks';
import { BankingDataAttributesResponse } from 'types/api/banking/types';
import {
  CreditDataResponse,
  CustomerResponse,
  OwnerResponse,
  OwnerSimilarContactsResponse,
  SimilarOwnerResponse,
  SimilarOwnersSubmissionResponse,
  SubmissionResponse,
} from 'types/api/underwriting/types';
import { IsoResponse } from 'types/api/funding/types';
import {
  MutationResponse,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { useFetchExperianConsumer } from 'apiHooks/3pi/experianConsumerFetchHooks';
import { ExperianConsumerResponse } from 'types/api/3pi/types';
import {
  BankingAdditionalData,
  Customer,
  CustomerSubmission,
  ExperianConsumer,
  IndependentSalesOrganization,
  Owner,
  OwnerFicoScoresBatch,
  OwnerSimilarContact,
  SimilarOwner,
  SimilarOwnersObject,
  SimilarOwnerSubmission,
} from './matchedRecords.types';

interface UseCustomerResult extends Omit<UseGetApiCustomerResponse, 'data'> {
  customer: Customer | undefined;
}

const toCustomer = (customer?: CustomerResponse): Customer | undefined => {
  if (!customer) {
    return undefined;
  }

  return {
    id: customer.uuid,
    name: customer.name,
  };
};

export const useCustomer = (customerUuid?: string): UseCustomerResult => {
  const { data, error, ...rest } = useGetApiCustomer(customerUuid);

  const customer = toCustomer(data);

  return {
    customer,
    error,
    ...rest,
  };
};

type UseLazyCustomerResponse = [
  (customerUuid: string) => Promise<void>,
  { customer?: Customer; loading: boolean; error?: Error }
];

export const useLazyCustomer = (): UseLazyCustomerResponse => {
  const [fetcher, { data, loading, error }] = useLazyGetApiCustomer();

  const wrappedFetcher = useCallback(
    (customerUuid: string): Promise<void> => fetcher({ customerUuid }),
    [fetcher]
  );

  return [
    wrappedFetcher,
    {
      customer: toCustomer(data),
      loading,
      error,
    },
  ];
};

type UseOwnerResponse = {
  owner?: Owner;
  loading: boolean;
  error?: Error;
};

const toOwner = (response?: OwnerResponse): Owner | undefined => {
  if (!response) {
    return undefined;
  }

  return {
    id: response.uuid,
    fullName: `${response.first_name} ${response.last_name}`,
    ssnLastFour: response.ssn?.slice(-4),
    phoneNumber: response.home_phone?.toString(),
    updatedAt: response.updated_at,
    birthdate: response.born_on || undefined,
    address: {
      street1: response.address?.street1 || undefined,
      city: response.address?.city || undefined,
    },
  };
};

export const useOwner = (ownerUuid: string): UseOwnerResponse => {
  const { data, loading, error } = useGetApiOwner(ownerUuid);

  return {
    owner: toOwner(data),
    loading,
    error,
  };
};

interface UseCustomerSubmissionsResult
  extends Omit<UseGenericQueryResponse<SubmissionResponse[]>, 'data'> {
  customerSubmissions: CustomerSubmission[] | undefined;
}

const toCustomerSubmissions = (
  customerSubmission: SubmissionResponse
): CustomerSubmission => {
  return {
    submissionUuid: customerSubmission.uuid,
    submissionName: customerSubmission.name,
    owner1FullName: customerSubmission.owner_1_full_name,
    ownersUuid: customerSubmission.owner_uuids,
    owner2FullName: customerSubmission.owner_2_full_name,
    dateAppReceived: customerSubmission.date_app_received__c ?? undefined,
    stage: customerSubmission.stage_name,
    subStage: customerSubmission.sub_stage ?? undefined,
    status: customerSubmission.sub_stage,
    isoUuid: customerSubmission.partner_uuid ?? undefined,
    underwriter: customerSubmission.x2dc_underwriter__c,
    declineDrivers: customerSubmission.decline_drivers,
    declineDriverNotes: customerSubmission.decline_driver_notes,
    approvedDate: customerSubmission.approved_date__c || undefined,
    isRenewal: customerSubmission.type === 'Renewal',
  };
};

export const useCustomerSubmissions = (
  customerUuid?: string
): UseCustomerSubmissionsResult => {
  const { data, error, ...rest } = useGetCustomerSubmissions(customerUuid);
  const customerSubmissions = useMemo(
    () => data?.map(toCustomerSubmissions),
    [data]
  );

  return {
    customerSubmissions,
    error,
    ...rest,
  };
};

const toIso = (iso: IsoResponse): IndependentSalesOrganization => {
  return {
    uuid: iso.partner.uuid,
    name: iso.partner.name,
  };
};

interface UseCustomerSubmissionIsoResult
  extends Omit<UseApiIsosResponse, 'data'> {
  isos: IndependentSalesOrganization[] | undefined;
}

export const useCustomerSubmissionIsos = (
  isoUuids?: string[]
): UseCustomerSubmissionIsoResult => {
  const { data, error, ...rest } = useApiIsos(isoUuids);

  return {
    isos: data ? data.map(toIso) : [],
    error,
    ...rest,
  };
};

const toBankingSubmissionAdditionalData = (
  response: BankingDataAttributesResponse
): BankingAdditionalData => {
  return {
    submissionUuid: response.attributes.uuid,
    bankAccountNumbers: response.attributes.bank_account_numbers,
    revenueOverride: response.attributes.revenue_override,
    numberPositions: response.attributes.number_of_positions,
    grossPositions: response.attributes.gross_of_positions,
    ffPercentGross: response.attributes.ff_percent_of_gross,
    totalGrossPercent: response.attributes.total_gross_percent,
    program: response.attributes.program,
    termLength: response.attributes.term_length,
    maxApproval: response.attributes.max_approval,
    avrgNegativeDays: response.attributes.average_negative_days,
    avrgDailyBalance: response.attributes.average_daily_balance,
  };
};

type UseBankingSubmissionDataResult = {
  bankingSubmissionData: BankingAdditionalData[] | undefined;
  loading: boolean;
  error: Error | undefined;
};
export const useBankingSubmissionData = (
  submissionUuids?: string[]
): UseBankingSubmissionDataResult => {
  const { data, loading, error } =
    useGetBankingSubmissionsData(submissionUuids);

  const bankingSubmissionData = data?.data.map(
    toBankingSubmissionAdditionalData
  );
  return {
    bankingSubmissionData: bankingSubmissionData,
    loading,
    error,
  };
};

type UseOwnerSimilarContactsResponse = {
  similarContacts?: OwnerSimilarContact[];
  loading: boolean;
  error?: Error;
};

const toOwnerSimilarContact = (
  contact: OwnerSimilarContactsResponse
): OwnerSimilarContact => ({
  id: contact.contact_uuid,
  name: contact.name,
  customerName: contact.account_name,
  isoName: contact.partner_name,
  dealStage: contact.opportunity_stage,
  dealSubstage: contact.opportunity_substage,
  declineDrivers: contact.decline_drivers?.split(';'),
  ssnLastFour: contact.ssn_last_4,
  ssnMatch: contact.ssn_match,
  birthdate: contact.born_on,
  phoneNumber: contact.phone?.toString(),
  lastOpportunityDate: contact.most_recent_opportunity,
  lastOpportunityUuid: contact.opportunity_uuid,
  isRenewal: contact.is_renewal,
  street1: contact.street1,
  city: contact.city,
});

export const useOwnerSimilarContacts = (
  ownerUuid: string,
  submissionUuid: string
): UseOwnerSimilarContactsResponse => {
  const { data, loading, error } = useGetApiOwnerSimilarContacts(
    ownerUuid,
    submissionUuid
  );

  return {
    similarContacts: data ? data.map(toOwnerSimilarContact) : undefined,
    loading,
    error,
  };
};

const toSimilarOwnerSubmission = (
  submission: SimilarOwnersSubmissionResponse
): SimilarOwnerSubmission => ({
  uuid: submission.uuid,
  dateAppReceived: submission.date_app_received__c ?? undefined,
  stageName: submission.stage_name ?? undefined,
  subStage: submission.sub_stage ?? undefined,
  isRenewal: submission.is_renewal,
  declineDrivers: submission.decline_drivers__c ?? undefined,
  isoName: submission.partner_name ?? undefined,
});

const toSimilarOwner = (owner: SimilarOwnerResponse): SimilarOwner => ({
  firstName: owner.first_name,
  lastName: owner.last_name,
  bornOn: owner.born_on ?? undefined,
  homePhone: owner.home_phone ?? undefined,
  ssnLast4: owner.ssn_last_4 ?? undefined,
  street1: owner.street1 ?? undefined,
  city: owner.city ?? undefined,
  customerName: owner.customer_name ?? undefined,
  ssnMatch: owner.ssn_match,
  submissions: owner.submissions.map(toSimilarOwnerSubmission),
});

type UseLazySimilarOwnersResponse = [
  (ownerUuid: string, force: boolean) => Promise<void>,
  {
    similarOwnersObject: SimilarOwnersObject;
    loading: boolean;
    error?: Error;
  }
];

export const useLazySimilarOwners = (): UseLazySimilarOwnersResponse => {
  const [fetcher, { data, loading, error }] = useLazyGetApiSimilarOwners();

  const wrappedFetcher = useCallback(
    (ownerUuid: string, force: boolean): Promise<void> =>
      fetcher({ ownerUuid, force }),
    [fetcher]
  );

  return [
    wrappedFetcher,
    {
      similarOwnersObject: data
        ? {
            refreshedAt: data.refreshed_at ?? undefined,
            similarOwners: data.similar_owners?.map(toSimilarOwner) ?? [],
          }
        : { refreshedAt: undefined, similarOwners: [] },
      loading,
      error,
    },
  ];
};

const toOwnerFicoScores = (
  creditReport: CreditDataResponse[],
  uuid: string
): OwnerFicoScoresBatch[] => {
  return creditReport.map((report) => ({
    ownerUuid: report.owner_uuid,
    fico: report.fico_score ?? undefined,
    createdAt: report.created_at,
    submissionUuid: uuid,
  }));
};

type UseOwnerCreditDataResult = {
  ficoScores: OwnerFicoScoresBatch[] | undefined;
  loading: boolean;
  error: Error | undefined;
};
export const useOwnerCreditDataBatch = (
  submissionUuids?: string[]
): UseOwnerCreditDataResult => {
  const { data, loading, error } = useGetOwnerFicoScoresBatch(submissionUuids);

  const enrichedResponses =
    data &&
    submissionUuids?.map((uuid, index) => toOwnerFicoScores(data[index], uuid));
  const ownerFicoScores = enrichedResponses?.flat();

  return {
    ficoScores: ownerFicoScores,
    loading,
    error,
  };
};
type UseRefreshOwnersResult = [
  (
    ownersUuid: string | undefined,
    submissionUuid: string | undefined
  ) => Promise<MutationResponse>,
  { data?: MutationResponse; loading: boolean; error?: Error }
];

export const useRefreshOwners = (): UseRefreshOwnersResult => {
  const [baseFetchFunction, { data, loading, error }] = useApiRefreshOwners();

  const refreshOwners = async (
    ownerUuid?: string,
    submissionUuid?: string
  ): Promise<MutationResponse> => {
    return await baseFetchFunction({ ownerUuid, submissionUuid });
  };

  return [
    refreshOwners,
    {
      data,
      loading,
      error,
    },
  ];
};

const toExperianConsumer = (
  data?: ExperianConsumerResponse
): ExperianConsumer | undefined => {
  if (!data) {
    return undefined;
  }

  // There will only be one element for `consume_credits` when fetching with
  // submissionUuid and ownerUuid, so we can safely use element 0. Also ensure
  // that none of the properties are undefined before accessing them and that
  // any arrays are non-empty before using them for `find`.
  const riskModel =
    data.consumer_credits &&
    Array.isArray(data.consumer_credits) &&
    data.consumer_credits.length > 0 &&
    data.consumer_credits[0].risk_models
      ? data.consumer_credits[0].risk_models.find(
          ({ model_name }) => model_name === 'FICO'
        )
      : undefined;

  return {
    ficoScore: riskModel?.model_score,
  };
};

type ErrorWithStatusCode = Error & {
  statusCode?: number;
};

type UseExperianConsumerResult = UseGenericQueryResponse<ExperianConsumer> & {
  error?: ErrorWithStatusCode;
};

export const useExperianConsumer = (
  submissionUuid?: string,
  ownerUuid?: string
): UseExperianConsumerResult =>
  useGenericFeatureQuery(
    useFetchExperianConsumer,
    toExperianConsumer,
    submissionUuid,
    ownerUuid
  );
