import { Button, Flex } from '@forward-financing/fast-forward';

import { ExceptionRequestStatus } from 'components/ExceptionsRequest/exceptionsRequest.types';
import { ExceptionRequestDeclineModal } from '../DeclineModal/ExceptionRequestDeclineModal';
import { useExceptionRequestContext } from '../Context/ExceptionRequestContext';

type ExceptionRequestActionButtonsProps = {
  exceptionStatus: ExceptionRequestStatus;
  exceptionId: number;
  refetchExceptionRequests: () => void;
};

export const ExceptionRequestActionButtons = ({
  exceptionStatus,
  exceptionId,
  refetchExceptionRequests,
}: ExceptionRequestActionButtonsProps): JSX.Element | null => {
  const { modifyOfferHandler, isModifyButtonDisabled } =
    useExceptionRequestContext();

  if (exceptionStatus !== 'in_progress') return null;

  return (
    <Flex flexDirection="row" gap={3} mt={4}>
      <ExceptionRequestDeclineModal
        exceptionId={exceptionId}
        refetchExceptionRequests={refetchExceptionRequests}
      />

      <Button
        startIcon={isModifyButtonDisabled ? 'spinner' : undefined}
        variant="primary"
        onClick={modifyOfferHandler}
        disabled={isModifyButtonDisabled}
      >
        Modify offer
      </Button>
    </Flex>
  );
};
