import {
  Box,
  Subheading,
  List,
  Text,
  Popover,
  IconButton,
} from '@forward-financing/fast-forward';

export const IsoSalesRepPopover = (): JSX.Element => {
  return (
    <Popover
      header="ISO Sales Rep Email"
      position="right"
      content={
        <Box>
          <Subheading variant="subsection">
            ISO Information Central Sales Based Rules
          </Subheading>
          <List>
            <List.Item>
              <Text bold>Sales Rep Not Required</Text>
              <List>
                <List.Item>
                  A sales rep is not required for submissions from this ISO
                </List.Item>
              </List>
            </List.Item>
            <List.Item>
              <Text bold>Specific Email</Text>
              <List>
                <List.Item>
                  The sales rep is the same for all submissions from this ISO
                </List.Item>
              </List>
            </List.Item>
            <List.Item>
              <Text bold>Sales Rep Sender</Text>
              <List>
                <List.Item>
                  The sales rep will be set as the sender of the submission
                </List.Item>
              </List>
            </List.Item>
            <List.Item>
              <Text bold>Sales Rep From CC</Text>
              <List>
                <List.Item>
                  The sales rep will be set as the contact who is cc&apos;d on
                  the email submission
                </List.Item>
              </List>
            </List.Item>
            <List.Item>
              <Text bold>Signed Application</Text>
              <List>
                <List.Item>
                  The sales rep will need to be manually updated based on who is
                  included on the signed application
                </List.Item>
              </List>
            </List.Item>
            <List.Item>
              <Text bold>Email Content</Text>
              <List>
                <List.Item>
                  The sales rep will need to be manually updated based on who is
                  included in the body of the email submission
                </List.Item>
              </List>
            </List.Item>
          </List>
        </Box>
      }
      trigger={
        <IconButton
          label="ISO Sales Rep Email more info"
          icon="circle-question"
          hiddenLabel
        />
      }
    />
  );
};
