import { useApplicationSnapshot } from './applicationSnapshotFetchHooks';
import { ApplicationSnapshot } from './ApplicationSnapshot';
import { ApplicationSnapshotSubmission } from './applicationSnapshot.types';

export interface ApplicationSnapshotContainerProps {
  submission: ApplicationSnapshotSubmission;
  refetchExceptionRequests?: () => void;
}

export const ApplicationSnapshotContainer = ({
  submission,
  refetchExceptionRequests,
}: ApplicationSnapshotContainerProps): JSX.Element => {
  const applicationSnapshot = useApplicationSnapshot(submission);

  return (
    <ApplicationSnapshot
      {...applicationSnapshot}
      refetchExceptionRequests={refetchExceptionRequests}
    />
  );
};
