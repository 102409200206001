import {
  Banner,
  Button,
  Flex,
  Icon,
  Modal,
  Subheading,
  TextArea,
} from '@forward-financing/fast-forward';
import React, { useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import { useUpdateSubmission } from '../../Navbar/SubmissionNavbar/navbarFetchHooks';
import { ToolboxSubmission } from './Toolbox.types';

export interface ToolboxWaitingOnIsoModalProps {
  submission: ToolboxSubmission;
  refetchSubmission: () => void;
}
export const ToolboxWaitingOnIsoModal = ({
  submission,
  refetchSubmission,
}: ToolboxWaitingOnIsoModalProps): JSX.Element => {
  const { id: userId, sub_role: userSubRole } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);
  const [waitingOnIsoNotes, setWaitingOnIsoNotes] = useState('');

  const [
    updateSubmission,
    { error: errorUpdateSubmission, loading: loadingUpdateSubmission },
  ] = useUpdateSubmission(submission.uuid);

  const handleOpenChange = (): void => {
    setIsOpen((prevState) => !prevState);
  };

  const isSubmissionInPrequalStage =
    submission?.subStage === 'Pre-qualification';
  const isPrequalUser = userSubRole === 'prequal';
  const handleWaitingOnIsoChange = async (): Promise<void> => {
    const { success } = await updateSubmission({
      ...(isPrequalUser &&
        isSubmissionInPrequalStage && {
          decisionAnalystId: userId,
        }),
      subStage: 'Waiting on ISO',
      waitingOnIsoNote: waitingOnIsoNotes,
    });

    if (success) {
      setIsOpen(false);
      refetchSubmission();
    }
  };

  const isInAvailableStages =
    submission?.subStage &&
    [
      'Data Review',
      'Pre-qualification',
      'In Underwriting',
      'In Processing',
    ].includes(submission?.subStage);

  const isInWaitingOnIsoSubstage =
    submission && submission.subStage === 'Waiting on ISO';

  const shouldBeDisabled =
    !isInAvailableStages || isInWaitingOnIsoSubstage || loadingUpdateSubmission;

  return (
    <>
      <Modal
        title="Waiting on ISO"
        isOpen={isOpen}
        onOpenChange={handleOpenChange}
        body={
          <>
            {errorUpdateSubmission && (
              <Banner dismissable={false} variant="error">
                <Flex gap={3}>
                  <Icon size="lg" name="triangle-exclamation" />
                  {errorUpdateSubmission.message}
                </Flex>
              </Banner>
            )}
            <Flex flexDirection={'column'}>
              <Subheading variant="section">
                Please provide details to move to Waiting on ISO
              </Subheading>
              <TextArea
                required
                rows={5}
                label="Waiting on iso notes"
                value={waitingOnIsoNotes}
                onChange={(e) => setWaitingOnIsoNotes(e.target.value)}
              />

              <Flex justifyContent="center" gap={2}>
                <Button
                  disabled={waitingOnIsoNotes === ''}
                  startIcon={'user-clock'}
                  onClick={handleWaitingOnIsoChange}
                >
                  Proceed with Waiting on ISO
                </Button>
                <Button
                  startIcon="x"
                  variant="secondary"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </Button>
              </Flex>
            </Flex>
          </>
        }
        trigger={
          <Button
            disabled={shouldBeDisabled}
            key="waitingOnIso"
            startIcon="user-clock"
          >
            Waiting on ISO
          </Button>
        }
      />
    </>
  );
};
