import {
  Box,
  BoxProps,
  Calendar,
  Currency,
  Flex,
  Icon,
  PlainDate,
  Text,
} from '@forward-financing/fast-forward';
import {
  ScheduleDay,
  PaymentStatus,
  RejectedPayment,
  ExistingPayments,
} from '../paymentSchedule.types';
import { usePaymentScheduleContext } from '../PaymentScheduleContext';

export const getBackgroundColor = (
  status: PaymentStatus
): BoxProps['backgroundColor'] => {
  if (status === 'settled') {
    return 'green-200';
  }

  if (status === 'rejected') {
    return 'red-200';
  }

  if (status === 'pending') {
    return 'gray-200';
  }

  return 'white';
};

export interface ScheduleCalendarDayProps {
  date?: PlainDate;
  event: ScheduleDay;
}

const isRejectedPayment = (
  payment: ExistingPayments
): payment is RejectedPayment => payment.status === 'rejected';

export const ScheduleCalendarDay = ({
  date,
  event,
}: ScheduleCalendarDayProps): JSX.Element => {
  const { paymentSchedule } = usePaymentScheduleContext();
  const {
    reductionPercentageBucket,
    manual: isManual,
    stopped: isStopped,
    rollover: isRollover,
    scheduledSameDayAch: scheduledSameDayAchData,
    payments,
    provider,
  } = event;

  const hasScheduledSameDayAch = scheduledSameDayAchData !== undefined;
  const rejectionCode = payments.find(isRejectedPayment)?.code;

  const isLastDay =
    paymentSchedule &&
    date &&
    paymentSchedule[paymentSchedule.length - 1].date.equals(date);

  const hasReduction = !!reductionPercentageBucket;
  const hasNotes = !!event.notes;

  const hasTopRowContent = isLastDay || hasReduction || hasNotes;
  const hasBottomRowContent = isManual || isRollover || isStopped;

  const hasMultipleContentRows = hasTopRowContent && hasBottomRowContent;

  /* istanbul ignore next */
  let paddingTop: undefined | 1 | 2 | 3 | 4 = 3;
  /* istanbul ignore next */
  if (hasMultipleContentRows) {
    paddingTop = undefined;
    /* istanbul ignore next */
  } else if (hasTopRowContent || hasBottomRowContent) {
    paddingTop = 2;
  }

  return (
    <Calendar.Day bg={getBackgroundColor(event.status)}>
      <Box textAlign="center" pt={paddingTop}>
        {hasTopRowContent && (
          <Flex alignItems="center" justifyContent="space-around">
            {isLastDay && <Icon size="lg" name="check" color="success" />}
            {hasReduction && <Text bold>{reductionPercentageBucket}</Text>}
            {hasNotes && <Icon size="lg" name="comment" />}
          </Flex>
        )}
        {hasBottomRowContent && (
          <Flex alignItems="center" justifyContent="space-around">
            {isRollover && <Icon size="lg" name="arrows-rotate" />}
            {isManual && (
              <Text bold>
                {provider === 'manual_stripe' ? 'Card' : 'Check'}
              </Text>
            )}
            {isStopped && <Icon size="lg" name="stop" color="danger" />}
          </Flex>
        )}
        {(event.payments.length > 0 || event.amount !== 0) && (
          <Box>
            <Text size="l" bold>
              <Currency amount={event.amount} />
            </Text>
          </Box>
        )}
        {rejectionCode && (
          <Flex alignItems="center" justifyContent="space-around">
            <Text>{rejectionCode}</Text>
          </Flex>
        )}
        {hasScheduledSameDayAch && !rejectionCode && (
          <Flex alignItems="center" justifyContent="space-around">
            <Text>Same Day</Text>
          </Flex>
        )}
      </Box>
    </Calendar.Day>
  );
};
