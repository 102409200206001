import { useGetApiLexisNexisCustomerReports } from 'apiHooks/underwriting/lexisNexisCustomerFetchHooks';
import {
  BusinessPaynetResponse,
  LexisNexisCustomerReport,
  LexisNexisOwnerReport,
} from 'types/api/underwriting/types';
import { useGetApiBusinessPaynetReport } from 'apiHooks/underwriting/customerFetchHooks';
import { useGetApiPublicDocuments } from 'apiHooks/underwriting/ownerFetchHooks';

export type LatestPaynetReports = Record<string, BusinessPaynetResponse>;
export const useGetLatestPaynetReports = (
  submissionUuid: string
): LatestPaynetReports => {
  const { data } = useGetApiBusinessPaynetReport(submissionUuid);

  /*
    It's sorted from oldest to newest, because in reduce call
    we will get the latest report
  */
  const sorted =
    data?.sort((a, b) => a.created_at.localeCompare(b.created_at)) ?? [];

  return sorted.reduce<LatestPaynetReports>((acc, current) => {
    acc[current.full_payload.paynet_id] = current;
    return acc;
  }, {});
};

export type LatestLexisNexisCustomerReports = Record<
  string,
  LexisNexisCustomerReport
>;
export const useGetLatestLexisNexisCustomerReports = (
  submissionUuid: string
): LatestLexisNexisCustomerReports => {
  const { data } = useGetApiLexisNexisCustomerReports(submissionUuid);

  /*
    It's sorted from oldest to newest, because in reduce call
    we will get the latest report
  */
  const sorted =
    data?.documents?.sort((a, b) => a.created_at.localeCompare(b.created_at)) ??
    [];

  return sorted.reduce<LatestLexisNexisCustomerReports>((acc, current) => {
    if (!current.report_identifier) {
      return acc;
    }

    acc[current.report_identifier] = current;
    return acc;
  }, {});
};

export type LatestLexisNexisOwnerReports = Record<
  string,
  LexisNexisOwnerReport
>;
export const useGetLatestLexisNexisOwnerReports = (
  submissionUuid: string
): LatestLexisNexisOwnerReports => {
  const { data } = useGetApiPublicDocuments(submissionUuid);

  /*
    It's sorted from oldest to newest, because in reduce call
    we will get the latest report
  */
  const sorted =
    data?.documents?.sort((a, b) => a.created_at.localeCompare(b.created_at)) ??
    [];

  return sorted.reduce<LatestLexisNexisOwnerReports>((acc, current) => {
    if (!current.report_identifier) {
      return acc;
    }

    acc[current.report_identifier] = current;
    return acc;
  }, {});
};
