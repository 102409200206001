import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { AcceptedOfferResponse } from 'types/api/underwriting/types';

export const resetAcceptedOffer = async (
  submissionUuid: string,
  offerId: string
): Promise<{ success: boolean }> => {
  const url = new URL(
    `/decision/api/v1/submissions/${submissionUuid}/reset_accepted_offer`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest(url, 'POST', {
    accepted_offer_id: offerId,
  });

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to reset Accepted Offer');
  }

  return { success: true };
};

export const lowerPrepayments = async (
  submissionUuid: string
): Promise<{ success: boolean }> => {
  const url = new URL(
    `/decision/api/v1/submissions/${submissionUuid}/prepayment_schedule`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest(url, 'POST');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to lower prepayments');
  }

  return { success: true };
};

export const fetchAcceptedOffer = async (
  submissionUuid: string
): Promise<AcceptedOfferResponse> => {
  const url = new URL(
    `/decision/api/v1/submissions/${submissionUuid}/accepted_offer`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<AcceptedOfferResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Accepted Offer');
  }

  return response.json();
};
