import { HashMap } from 'api/codecs';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { Address } from 'api/UnderwritingClient/codecs';
import { SubmissionType } from '../../../components/SubmissionEditing/SubmissionEditing.types';

/**
 * Return type for GET '/api/v2/owners/:ownerUuid'
 */
export interface OwnerResponse {
  first_name: string;
  last_name: string;
  ssn: string | null;
  born_on: string | null;
  email: string;
  home_phone: string | null;
  cell_phone: string | null;
  title: string;
  primary: boolean;
  uuid: string;
  ownership_date: string | null;
  account_uuid: string;
  /**
   * This appears to occasionally be a string in the form "100.0".
   *
   * I've also seen it as a number occasionally.
   */
  ownership_percentage: number | string | null;
  id: number;
  ownership_ranking: number | null;
  owner_id: number;
  /**
   * The owners endpoint can return an Owner or a Contact,
   * depending on the state of the use_owner_not_contact
   * feature flag.
   */
  owner_uuid: string;
  customer_uuid: string;
  address: {
    street1: string | null;
    street2: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
  } | null;
  updated_at: string;
  deleted_at: string | null;
}

export interface OwnerSimilarContactsResponse {
  name: string;
  sfid: string;
  phone: string | null;
  born_on: string;
  street1: string;
  city: string;
  account_name: string;
  account_sfid: string;
  account_uuid: string;
  contact_uuid: string;
  partner_name: string;
  /**
   * This is a semicolon separated list of human readable
   * decline drivers, e.g.: "Inability to extend a minimum offer;Prohibited business type"
   */
  decline_drivers: string | null;
  opportunity_sfid: string;
  opportunity_uuid: string;
  opportunity_stage: string;
  opportunity_substage: string;
  most_recent_opportunity: string;
  ssn_last_4: string;
  ssn_match: boolean;
  is_renewal: boolean;
}

/**
 * Return type for GET '/api/v2/owners/:ownerUuid/similar_owners'
 */
export interface SimilarOwnersResponse {
  refreshed_at: string | null;
  similar_owners: SimilarOwnerResponse[];
}

export interface SimilarOwnerResponse {
  first_name: string;
  last_name: string;
  born_on: string | null;
  home_phone: string | null;
  ssn_last_4: string | null;
  street1: string | null;
  city: string | null;
  customer_name: string | null;
  ssn_match: boolean;
  submissions: SimilarOwnersSubmissionResponse[];
}

export interface SimilarOwnersSubmissionResponse {
  uuid: string;
  date_app_received__c: string | null;
  stage_name: string | null;
  sub_stage: string | null;
  is_renewal: boolean;
  decline_drivers__c: string | null;
  partner_name: string | null;
}

/**
 * Possible categories for submission
 * returned from GET '/api/v2/submissions/:submissionUuid'
 */
export enum SubmissionResponseCategories {
  PIF_ISO_EXCLUSIVITY = 'pif_iso_exclusivity',
  PIF_EXISTING_ISO = 'pif_existing_iso',
  PIF_NEW_ISO = 'pif_new_iso',
  RENEWAL = 'renewal',
  OTHER_ISO_RENEWAL = 'other_iso_renewal',
  NEW_SALES = 'new_sales',
  BUSINESS_DEVELOPMENT_PROGRAM = 'business_development_program',
}

/**
 * Return type for GET '/api/v2/submissions/:submissionUuid'
 */
export interface SubmissionResponse {
  application_uuid: string;
  approved_date__c: string | null;
  attachments_ready_for_processing: boolean;
  capital_needed: number | null;
  category: SubmissionResponseCategories | null;
  completed_automation_steps: boolean | null;
  credit_committee__c: string | null;
  customer_uuid: string;
  /**
   * A dateTime string in yyyy-mm-ddThh:mm:ss.sss-HH:mm
   *
   * Note: `-HH:mm` is the time zone offset, so could be
   * prefixed with a + instead of a -
   */
  date_declined: string | null;
  /**
   * A dateTime string in yyyy-mm-ddThh:mm:ss.sss-HH:mm
   *
   * Note: `-HH:mm` is the time zone offset, so could be
   * prefixed with a + instead of a -
   */
  date_app_received__c: string | null;
  /**
   * A dateTime string in yyyy-mm-ddThh:mm:ss.sss-HH:mm
   *
   * Note: `-HH:mm` is the time zone offset, so could be
   * prefixed with a + instead of a -
   */
  date_processing_complete__c: string | null;
  decision_analyst_id: number | null;
  partner_uuid: string | null;
  processing_analyst_id: number | null;
  decision_analyst_name: string | null;
  decline_driver_notes: string | null;
  decline_drivers: string | null;
  email_content: string | null;
  funded_date__c: string | null;
  funding_url: string | null;
  has_buy_out_position: boolean;
  has_previously_funded_customer: boolean;
  internal_renewal: boolean;
  is_new_deal_category: boolean | null;
  iso_competing_sub_message: string | null;
  name: string;
  oa_url: string;
  owner_uuids: string[];
  owner_2_full_name: string | null;
  owner_1_full_name: string | null;
  point_split_disabled: boolean | null;
  preferred_deal: boolean | null;
  prequal_analyst_name: string | null;
  /**
   * This is marked as unknown because I could not figure
   * out what it could be from looking at the UW codebase.
   *
   * In all actual payloads I looked at, it was set to null.
   * If/when we figure out what this is, we should update it.
   *
   * @tyrelosaur - Jan 12, 2023
   */
  pricing_risk_profile_at_approval: unknown | null;
  prime_deal: boolean | null;
  processor: string | null;
  renewal_submitter_email: string | null;
  revenue: number | null;
  sales_owner_id: number | null;
  sales_owner_name: string | null;
  sales_rep_email: string | null;
  stage_name: string;
  sub_stage: string | null;
  submission_source: string;
  /**
   * This should be either "New Deal" or "Renewal".
   */
  type: SubmissionType;
  uuid: string;
  // This submission will only be from the same ISO
  previous_funded_opportunity_uuid: string | null;
  // This submission can be associated with any ISO
  previous_funded_submission_uuid_from_customer: string | null;
  exception_request_underwriter_user_id: number | null;
  underwriter_sign_off_id: number | null;
  underwriter_sign_off_timestamp: string | null;
  use_of_funds: string | null;
  underwriter_sign_off_name: string | null;
  vca: string | null;
  waiting_on_iso_note__c: string | null;
  x2dc_underwriter__c: string | null;
  underwriter_id: number | null;
  account_uuid: string;
}

/**
 * Partial response from GET 'api/v2/customers/:customerUuid'
 *
 * See {@link CustomerResponse} for the full payload
 */
export interface CustomerAddress {
  street1: string;
  street2: string | null;
  city: string;
  state: string;
  zip: string;
}

/**
 * Response from GET 'api/v2/customers/:customerUuid'
 */
export interface CustomerResponse {
  uuid: string;
  legal_name: string;
  name: string;
  address: CustomerAddress | null;
  entity_type: string | null;
  industry_name: string | null;
  industry_id: number | null;
  started_on: string | null;
  fein: string | null;
  phone: string | null;
  risk_profile: string;
  monthly_revenue?: string;
  bank_account: BankAccountResponse | null;
  iso_product_id: string | null;
}

export interface BankAccountResponse {
  id: number | null;
  account_holder_full_name: string | null;
  bank_name: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  account_number: string | null;
  routing_number: string | null;
  bank_id: string | null;
}

export type UpdateCustomerRequestBody = {
  legal_name?: string;
  name?: string;
  current_address_attributes?: CustomerAddress | null;
  entity_type?: string | null;
  industry_id?: number | null;
  started_on?: string | null;
  fein?: string | null;
  phone?: string | null;
  bank_account_attributes?: BankAccountResponse | null;
};

export type UpdateOwnerRequestBody = {
  id?: number;
  uuid?: string;
  first_name: string;
  last_name: string;
  born_on: string | null;
  email: string;
  home_phone: string | null;
  cell_phone: string | null;
  ssn: string | null;
  title?: string;
  ownership_percentage?: number | string;
  current_address_attributes: CustomerAddress;
  deleted_at: string | null;
  _destroy: boolean | null;
};

/**
 * Return type for GET 'api/v2/applications/:applicationUuid'
 *
 * Note that only part of the response is currently documented.
 *
 * TODO: Document the rest of this response interface
 */
export interface ApplicationResponse {
  id: number;
  uuid: string;
  record_id: number;
}

/**
 * Return type for GET 'api/v2/accounts/:accountUuid'
 * Return type for GET 'api/v2/applications/:applicationUuid/accounts
 *
 * Note that only part of the response is currently documented.
 *
 * TODO: Document the rest of this response interface
 */
export interface AccountResponse {
  uuid: string;
  name: string;
}

/**
 * Return type for GET 'api/v2/customers/:customerUuid/owners'
 *
 * Note that the endpoint returns an array of this shape
 */
export interface CustomerOwnerResponse {
  first_name: string;
  last_name: string;
  ssn: string | null;
  born_on: string | null;
  email: string;
  home_phone: string | null;
  cell_phone: string | null;
  title: string;
  uuid: string;
  ownership_date: string | null;
  ownership_percentage: number | string | null;
  ownership_ranking: number | null;
  customer_uuid: string;
  replaced_by: string | null;
  id: number;
  deleted_at: string | null;
  address: {
    street1: string | null;
    street2: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
  };
}

/**
 * Response shape for GET '/api/v2/submissions/:submissionUuid/web_presences'
 */
export type SubmissionWebPresenceResponse = {
  business_website: string | null;
  facebook: string | null;
  instagram: string | null;
  yelp: string | null;
  other: string | null;
};

/**
 * Response from GET 'api/v2/customers/:customerUuid/established_dates'
 */
export interface CustomerEstablishedDatesResponse {
  customer_started_on: string;
  ln: {
    ln_date: string | null;
    ln_year_diff: number | null;
    ln_day_diff: number | null;
  };
}

/**
 * Return type for GET '/api/v2/submissions/:submissionUuid/risk_factors'
 */
export interface RiskFactorsResponse {
  success: boolean;
  risk_factors: {
    high_risk_qualities: {
      high_bankruptcy_score: string | null;
      low_vantage_score: string | null;
      home_based: string | null;
    };
    previous_auto_declines: {
      previously_prohibited_business: string | null;
      no_active_tradelines: string | null;
      recent_dismissed_bankruptcy: string | null;
      average_negative_days: string | null;
      percent_gross: string | null;
      average_deposit_override: string | null;
    };
    previous_advance_collection_status: {
      collection_status: string | null;
      default_history: string | null;
    };
    potential_fraud: string | null;
  };
  error: string | null;
}

/**
 * Return type for GET 'api/v2/submissions/:submissionUuid/inquiries'
 *
 * Endpoint returns an array of this response shape
 */
export interface InquiryOverview {
  id: number;
  inquired_on: string;
  subscriber: string;
  mca_lender_name: string | null;
  translated_inquiry_type_code: string;
  owner_uuid: string;
  owner_first_name: string;
  owner_last_name: string;
}

/**
 * Partial response from GET 'api/v2/submissions/:submissionUuid/credit_overview'
 *
 * Endpoint returns an array of this response shape
 */
export interface TradelinesResponse {
  owner_uuid: string;
  current_delinquencies_over_thirty_days_count: string;
  current_delinquencies_over_sixty_days_count: string;
  current_delinquencies_over_ninety_days_count: string;
  current_derog_count: string;
  delinquencies_over_thirty_days_count: string;
  delinquencies_over_sixty_days_count: string;
  delinquencies_over_ninety_days_count: string;
  now_delinquent_derog_count: string;
}

interface FraudScoreResults {
  score: number;
  uw_bucket: 'low' | 'medium' | 'high';
  bucket: 'low' | 'medium' | 'high';
}

/**
 * Partial response from GET 'api/v2/submissions/:submissionUuid/documents/fraud_scoring'
 *
 * See {@link FraudScoringDocumentResponse} for the full payload
 */
export interface FraudScoringDocument {
  owner_uuid: string;
  abuse_score: FraudScoreResults | null;
  first_party_synthetic_score: FraudScoreResults | null;
  third_party_synthetic_score: FraudScoreResults | null;
}

/**
 * Response from GET 'api/v2/submissions/:submissionUuid/documents/fraud_scoring'
 */
export interface FraudScoringDocumentResponse {
  documents: FraudScoringDocument[];
  success: boolean;
  error: string | null;
}

/**
 * Partial response from GET 'api/v2/submissions/:submissionUuid/documents/credit'
 *
 * See {@link CreditDocumentResponse} for the full payload
 */
export interface CreditDocument {
  owner_uuid: string;
  fico_score: number | null;
}

/**
 * Response from GET 'api/v2/submissions/:submissionUuid/documents/credit/lexis_nexis_owner_reports'
 */
export interface CreditDocumentResponse {
  documents: CreditDocument[];
  success: boolean;
  error: string | null;
}

/**
 * Partial response from GET 'api/v2/submissions/:submissionUuid/documents/lexis_nexis_owner_reports'
 *
 * See {@link DocumentsResponse} for the full payload
 */
export interface PublicRecordsUccRecord {
  secured_party: string[];
  date_filed:
    | {
        day?: string;
        year: string;
        month?: string;
      }
    | string
    | null;
  collateral_description: string | null;
  is_expired: boolean;
  is_released: boolean;
}

/**
 * Partial response from GET 'api/v2/submissions/:submissionUuid/documents/lexis_nexis_owner_reports'
 *
 * See {@link LexisNexisOwnerReportResponse} for the full payload
 */
export interface CriminalRecord {
  full_name: string;
  offense: string;
  offense_date: string | null;
  arrest_date: string | null;
  case_filing_date: string | null;
  state: string;
  admitted_dates: string[] | null;
  scheduled_release_dates: string[] | null;
  statuses: string[] | null;
  case_type_description: string | null;
}

export interface BankruptcyStatus {
  date: string;
  type: string;
}

/**
 * Partial response from GET 'api/v2/submissions/:submissionUuid/documents/lexis_nexis_owner_reports'
 *
 * See {@link LexisNexisOwnerReportResponse} for the full payload
 */
export type BankruptcyRecordResponse = {
  chapter: string;
  filing_date: string;
  exit_date: string;
  filed_as: string[];
  statuses: BankruptcyStatus[] | null;
};

/**
 * Partial response from GET 'api/v2/submissions/:submissionUuid/documents/lexis_nexis_owner_reports'
 *
 * See {@link LexisNexisOwnerReportResponse} for the full payload
 */
export interface LienJudgmentRecord {
  type: string[] | null;
  creditor: string[] | null;
  debtor: {
    name: string;
    address: LienJudgmentRecordAddress | LienJudgmentRecordAddress[] | null;
  }[];
  origin_filing_date: string;
  amount: string | null;
  status: string;
}

export interface LienJudgmentRecordAddress {
  street_number: string;
  street_name: string;
  street_suffix: string;
  city: string;
  county: string;
  state: string;
  zip5: string;
  zip4?: string;
}

/**
 * Partial response from GET 'api/v2/submissions/:submissionUuid/documents/lexis_nexis_owner_reports'
 *
 * See {@link LexisNexisOwnerReportResponse} for the full payload
 */
export interface LexisNexisOwnerReport {
  document_id: number;
  created_at: string;
  primary: boolean;
  individual_best_name: string | null;
  owner_uuid: string;
  uccs: PublicRecordsUccRecord[];
  criminals: CriminalRecord[];
  bankruptcies: BankruptcyRecordResponse[];
  lien_judgments: LienJudgmentRecord[];
  report_identifier: string | null;
}

export type OwnerLexisNexisSearchResultResponse = {
  full_name: string[];
  ssn: string[];
  date_of_birth:
    | {
        year: string;
        month: string;
      }[]
    | null;
  addresses: LexisNexisAddress[];
  report_identifier: string;
  created_at: string | null;
};

export type OwnerManualSearchResultsResponse = {
  owner_uuid: string;
  results: OwnerLexisNexisSearchResultResponse[];
};

export interface LexisNexisOwnerSearchResultsResponse {
  documents: {
    owner_uuid: string;
    results: OwnerLexisNexisSearchResultResponse[];
    mismatch:
      | {
          field: string;
          old_value: string | null;
          new_value: string | null;
        }[]
      | null;
  }[];
  success: boolean;
  error: string | null;
}

/**
 * Response from GET 'api/v2/submissions/:submissionUuid/documents/lexis_nexis_owner_reports'
 */
export interface LexisNexisOwnerReportResponse {
  documents: LexisNexisOwnerReport[];
  success: boolean;
  error: string | null;
}

/**
 * Partial response for GET 'api/v2/submissions/:submissionUuid/documents/lexis_nexis_customer_reports'
 *
 * See {@link LexisNexisCustomerReportResponse} for the full payload
 */
export interface LexisNexisCustomerExecutive {
  name?: {
    last: string;
    first: string;
  };
  unique_id: string;
  business_id: string;
  company_titles: {
    title: string | string[];
  };
  date_last_seen: {
    day: string;
    year: string;
    month: string;
  };
}

/**
 * Partial response for GET 'api/v2/submissions/:submissionUuid/documents/lexis_nexis_customer_reports'
 *
 * See {@link LexisNexisCustomerReportResponse} for the full payload
 */
export interface LexisNexisCustomerUcc {
  secured_party: string[];
  /**
   * If a string, date_filed takes the form "09/18/2018"
   */
  date_filed:
    | {
        day?: string;
        year: string;
        month?: string;
      }
    | string
    | null;
  collateral_description: string | null;
  is_expired: boolean;
  is_released: boolean;
}

/**
 * Partial response for GET 'api/v2/submissions/:submissionUuid/documents/lexis_nexis_customer_reports'
 *
 * See {@link LexisNexisCustomerReportResponse} for the full payload
 */
export type LexisNexisCustomerReport = {
  submission_uuid: string;
  /**
   * This ID will map to the document_id
   * key in the {@link LexisNexisCustomerOverview} type
   */
  document_id: number;
  company_name: string | null;
  business_address: null | {
    city: string;
    zip4: string;
    zip5: string;
    state: string;
    street_name: string;
    street_number: string;
    street_suffix: string;
  };
  company_phone: string | null;
  created_at: string;
  established: {
    day: string;
    year: string;
    month: string;
  } | null;
  executives: LexisNexisCustomerExecutive[];
  uccs: LexisNexisCustomerUcc[];
  lien_judgments: LienJudgmentRecord[];
  primary: boolean;
  report_identifier: string | null;
  bankruptcies: BankruptcyRecordResponse[];
};

/**
 * Return type for GET 'api/v2/submissions/:submissionUuid/documents/lexis_nexis_customer_reports'
 */
export interface LexisNexisCustomerReportResponse {
  documents: LexisNexisCustomerReport[];
  success: boolean;
  error: string | null;
}

export interface LexisNexisCustomerOverviewMismatch {
  field: string;
  old_value: string;
  new_value: string;
}

/**
 * Return type for GET 'api/v2/submissions/:submissionUuid/lexis_nexis_customer_overviews'
 *
 * API Endpoint returns an array of this shape
 */
export interface LexisNexisCustomerOverview {
  id: number;
  created_at: string;
  /**
   * This ID will map to the document_id
   * key in the {@link LexisNexisCustomerReport} type
   */
  document_id: number;
  document_type: string;
  open_bankruptcy_count: number;
  closed_bankruptcy_count: number;
  total_bankruptcy_count: number;
  open_lien_count: number;
  closed_lien_count: number;
  total_lien_count: number;
  open_lien_amount_cents: number;
  closed_lien_amount_cents: number;
  total_lien_amount_cents: number;
  open_judgment_count: number;
  closed_judgment_count: number;
  total_judgment_count: number;
  open_judgment_amount_cents: number;
  closed_judgment_amount_cents: number;
  total_judgment_amount_cents: number;
  lexis_nexis_customer_public_records_document_path: string;
  mismatch: LexisNexisCustomerOverviewMismatch[] | null;
}

export interface LexisNexisCustomerSearchResult {
  report_identifier: string;
  name: string;
  has_ucc: boolean;
  address?: {
    street_number?: string;
    street_name?: string;
    street_suffix?: string;
    city?: string;
    state?: string;
    zip5?: string;
    zip4?: string;
  };
}

export interface LexisNexisCustomerSearchResultsResponseDocument {
  submission_uuid: string;
  results: LexisNexisCustomerSearchResult[];
  created_at: string;
  query?: QueryObject | null;
}

export interface QueryObject {
  ssn?: string | null;
  zip?: string | null;
  city?: string | null;
  fein?: string | null;
  phone?: string | null;
  state?: string | null;
  street1?: string | null;
  last_name?: string | null;
  first_name?: string | null;
  company_name?: string | null;
}

/**
 * Return type for documents in {@link PaynetResultsResponse}
 */
export interface PaynetSearchResultsResponseDocument {
  results: PaynetResponse[];
  created_at: string;
}

/**
 * Return type for results as an array in {@link PaynetSearchResultsResponseDocument}
 */
export interface PaynetResponse {
  name: string;
  city: string;
  tax_id: string;
  state_code: string;
  paynet_id: string;
}
export interface LexisNexisCustomerSearchResultsResponse {
  documents:
    | LexisNexisCustomerSearchResultsResponseDocument
    | LexisNexisCustomerSearchResultsResponseDocument[];
  success: boolean;
  error: string | null;
}

/**
 * Return type for GET 'api/v2/submissions/:submissionUuid/documents/paynet_search_results'
 * Return type for GET 'api/v2/submissions/:submissionUuid/paynet_search'
 */
export interface PaynetResultsResponse {
  documents: PaynetSearchResultsResponseDocument | never[];
  success: boolean;
  error: string | null;
}

/**
 * Request body for POST '/api/v2/submissions/:submissionUuid/lexis_nexis_customer_reports'
 *
 * Each option is only used in particular instances. Please examine the fields
 * to determine which ones you need.
 */
export type PullLexisNexisReportRequestBody = {
  /**
   * Report ID is used when we want to associate an
   * existing {@link LexisNexisCustomerSearchResult} with
   * a submission. The `LexisNexisCustomerSearchResult.report_identifier`
   * is the expected key/value.
   *
   * In this case, you should _not_ pass the `force` param.
   */
  report_id?: string;
  /**
   * The `force` param is used to control the behavior of
   * a new pull.
   *
   * - When `false`, we pull a completely new LN report
   * - When `true`, we are refreshing the most recent existing report
   */
  force?: boolean;
};

/**
 * Request body for POST '/api/v2/submissions/:submissionUuid/paynet_report'
 */
export type PullPaynetReportRequestBody = {
  /**
   * Report ID is used when we want to associate an
   * existing {@link PaynetResponse} with
   * a submission. The `PaynetResponse.paynet_id`
   * is the expected key/value.
   *
   */
  paynet_id?: string;
};

export type PostRequestBody = {
  name: string;
  attributes: Record<string, unknown>;
};

/**
 * Return type for GET '/api/v2/submissions/:submissionUuid/credit_overviews'
 */
export interface CreditDataResponse {
  submission_uuid: string;
  id: string;
  created_at: string;
  owner_uuid: string;
  fico_score: number | null;
  bankruptcy_score: string;
  vantage_score: string;
  revolving_utilized_percent: string;
  trade_lines_count: string;
  satisfactory_accounts_count: string;
  now_delinquent_derog_count: string;
  active_paid_accounts_count: string;
  public_records_count: string;
  oldest_open_trade_line: string;
  current_delinquencies_over_thirty_days_count: string;
  current_delinquencies_over_sixty_days_count: string;
  current_delinquencies_over_ninety_days_count: string;
  current_derog_count: string;
  delinquencies_over_thirty_days_count: string;
  delinquencies_over_sixty_days_count: string;
  delinquencies_over_ninety_days_count: string;
  derog_count: string;
  experian_report_date?: string;
  total_unsecured_balance: string;
  credit_report_experian_path: string;
  experian_archive_path: string;
  trade_lines_path: string;
  mismatch:
    | {
        field: string;
        old_value: string | null;
        new_value: string | null;
      }[]
    | null;
  note: string | null;
}

/**
 * Return type for GET '/api/v2/submissions/:submissionUuid/lexis_nexis_owners'
 */
export interface LexisNexisResponse {
  id: string;
  owner_uuid: string;
  document_id: string;
  document_type: string;
  open_bankruptcy_count: string;
  closed_bankruptcy_count: string;
  total_bankruptcy_count: string;
  open_lien_count: string;
  closed_lien_count: string;
  total_lien_count: string;
  open_lien_amount_cents: string;
  closed_lien_amount_cents: string;
  total_lien_amount_cents: string;
  open_judgment_count: string;
  closed_judgment_count: string;
  total_judgment_count: string;
  open_judgment_amount_cents: string;
  closed_judgment_amount_cents: string;
  total_judgment_amount_cents: string;
  total_criminal_count: string;
  last_twelve_months_criminal_filing_count: string;
  one_to_three_year_criminal_filing_count: string;
  three_to_five_year_criminal_filing_count: string;
  five_to_ten_year_criminal_filing_count: string;
  ten_year_plus_criminal_filing_count: string;
  lexis_nexis_owner_public_records_document_path: string;
  lexis_nexis_owner_criminal_filings_document_path: string;
  created_at: string;
}

/**
 * Return type for GET '/api/v2/submissions/:submissionUuid/lexis_nexis_customer_search'
 */
export type LexisNexisCustomerSearchResponse = {
  submission_uuid: string;
  results: LexisNexisCustomerSearchResponseResults[];
  created_at: string | null;
  query: string | null;
};

/**
 * Partial return type for GET '/api/v2/submissions/:submissionUuid/lexis_nexis_customer_search'
 *
 * See {@link LexisNexisCustomerSearchResponse} for the full payload
 */
export type LexisNexisCustomerSearchResponseResults = {
  report_identifier: string;
  name: string;
  address: LexisNexisAddress;
  has_ucc: boolean;
};

type UccOwnerReportEntityResponse = {
  name: string;
  address: string;
};

export type UccOwnerResponse = {
  filing_date: string | null;
  secured_parties: UccOwnerReportEntityResponse[] | null;
  collateral_description: string | null;
  debtors: UccOwnerReportEntityResponse[] | null;
  status: string | null;
  file_type: string | null;
  file_number: string;
  state: string;
  closed: boolean;
};

export type OwnerUccReportResponse = {
  uccs: UccOwnerResponse[];
};

type UccBusinessReportEntity = {
  name: string;
  address: string;
};

export type UccBusinessFetchResponse = {
  filing_date: string | null;
  secured_parties: UccBusinessReportEntity[] | null;
  collateral_description: string | null;
  debtors: UccBusinessReportEntity[] | null;
  status: string | null;
  file_type: string | null;
  file_number: string;
  state: string;
  closed: boolean;
};

export type UccBusinessReportResponse = {
  document_id: number | null;
  submission_uuid: string;
  created_at: string;
  uccs: UccBusinessFetchResponse[];
};

/**
 * Partial response from GET '/api/v2/submissions/:submissionUuid/stipulations'
 *
 * See {@link StipulationResponse} for the full payload
 */
export type StipulationStatus =
  | 'not_received'
  | 'received'
  | 'pending'
  | 'accepted'
  | 'rejected'
  | 'uw_sign_off'
  | 'waived'
  | 'rejected_and_reprice'
  | 'manual_review';

export type BankVerificationType =
  | 'Manual'
  | 'Streaming'
  | 'Streaming via Plaid'
  | 'Streaming via Decision Logic';

/**
 * Return type for GET | POST | PATCH '/api/v2/submissions/:submissionUuid/stipulations'
 */
export interface StipulationResponse {
  name: string;
  status: StipulationStatus;
  /**
   * A dateTime string in yyyy-mm-ddThh:mm:ss.sss-HH:mm
   *
   * Note: `-HH:mm` is the time zone offset, so could be
   * prefixed with a + instead of a -
   */
  created_at: string;
  /**
   * A dateTime string in yyyy-mm-ddThh:mm:ss.sss-HH:mm
   *
   * Note: `-HH:mm` is the time zone offset, so could be
   * prefixed with a + instead of a -
   */
  updated_at: string;
  underwriter_id: number | null;
  underwriter_name: string | null;
  closing_analyst_id: number | null;
  closing_analyst_name: string | null;
  /**
   * External notes are included on external communication
   * to ISOs, and potentially to customers for reasons of rejection.
   */
  notes: string | null;
  /**
   * Additional information is included in internal communications between
   * Underwriting and Closing, as well as in external communications with ISOs,
   * and customers regarding the stipulation.
   */
  additional_info: string | null;
  /**
   * Whether this is one of the default stips that is created
   * when the deal reaches the approval stage.
   *
   * If it is one of the default stips, then is_additional
   * will be false.
   *
   * "Additional" stips can be selected in the ledger in BA.
   *
   * TODO: remove the bit about BA once we migrate that functionality
   * into IFE.
   */
  is_additional: boolean;
  uuid: string;
  opportunity_uuid: string;
  /**
   * Internal comments are intended for our internal
   * users, as the name suggests. These comments are not
   * included on any external communications.
   */
  internal_comments: string | null;
  /**
   * Whether or not a particular stipulation is used in
   * some email, I guess? On the backend, this is used to
   * reflect whether the stipulation name is one of several
   * stipulations marked as an "internal stipulation"
   */
  is_internal: boolean;
  received: boolean;
  /**
   * A dateTime string in yyyy-mm-ddThh:mm:ss.sss-HH:mm
   *
   * Note: `-HH:mm` is the time zone offset, so could be
   * prefixed with a + instead of a -
   */
  received_at: string | null;
  eligible_for_closing_assignment: boolean;
  bank_verification_type: BankVerificationType | null;
  reasons: string[];
}

/**
 * Input for POST '/api/v2/submissions/:submissionUuid/stipulations'
 *
 * This is the create endpoint for Stipulations.
 */
export type CreateStipulationRequestBody = {
  name: string;
  notes?: string;
  additional_info?: string;
  /**
   * Current usage always passes this as true.
   * In reality, it can probably be a boolean,
   * but I'm documenting current behavior, and we
   * can expand it if/when we find the need.
   *
   * @tyrelosaur - Jan 19, 2023
   */
  is_additional: true;
  /**
   * Current usage always passes this as not_received.
   * In reality, it can probably be a {@link StipulationStatus},
   * but I'm documenting current behavior, and we
   * can expand it if/when we find the need.
   *
   * @tyrelosaur - Jan 19, 2023
   */
  status: 'not_received';
};

/**
 * Input for PATCH '/api/v2/submissions/:submissionUuid/stipulations'
 *
 * This is the update endpoint/action for Stipulations.
 *
 * All fields are optional, but at least one should be passed.
 */
export type UpdateStipulationRequestBody = {
  name?: string;
  status?: StipulationStatus;
  closing_analyst_id?: number;
  underwriter_id?: number;
  notes?: string;
  additional_info?: string;
  is_additional?: boolean;
  internal_comments?: string;
  /**
   * A datetime string. It appears to accept most formats,
   * but the old implementation was using the result of
   * `new Date(...integerArgs).toString()`
   *
   * @tyrelosaur - Jan 19, 2023
   */
  received_at?: string;
};

export type UpdateSubmissionRequestBody = {
  underwriter_sign_off_id?: number | null;
  email_content?: string | null;
  stage_name?: string;
  sub_stage?: string | null;
  decision_analyst_id?: number;
  iso_competing_sub_message?: string;
  waiting_on_iso_note__c?: string;
  stage_change_notes?: string;
  x2dc_underwriter__c?: string;
  processing_analyst_id?: number;
  allow_decision_analyst_overwrite?: boolean;
  changed_by_move_deal_modal?: boolean;
  credit_committee__c?: string;
  capital_needed?: string;
  use_of_funds?: string;
  partner_uuid?: string;
  sales_rep_email?: string;
  prequal_analyst_name?: string;
  renewal_submitter_email?: string;
  exception_request_underwriter_user_id?: number | null;
  underwriter_id?: number | null;
  decline_drivers__c?: string | null;
  decline_driver_notes__c?: string | null;
  risk_score?: number;
};

export type DeclineSubmissionRequestBody = {
  is_previous_decline: boolean;
  decline_drivers: string;
  decline_driver_notes: string;
  declined_source: string;
  decision_analyst_id?: number;
};

export type AttachmentSource =
  | 'closing_documents'
  | 'original_submission'
  | 'other';

/**
 * Input for POST '/api/v1/applications/${submissionOrApplicationUuid}/attachments'
 *
 * This is the create endpoint for Attachments.
 */
export type CreateAttachmentRequestBody = {
  description: string;
  document_tags: string[];
  file: string;
  file_name: string;
  source: AttachmentSource;
};

/**
 * Input for PATCH '/api/v1/applications/:submissionOrApplicationUuid/attachments/:attachmentUuid'
 *
 * This is the update endpoint/action for Attachments.
 *
 * All fields are optional, but at least one should be passed.
 */
export type UpdateAttachmentRequestBody = {
  expires_on?: string;
  description?: string;
  document_tags?: string[];
  source?: AttachmentSource;
  file_name?: string;
};

/**
 * The shape of attachment document tags in many, but not all, places.
 * When calling `fetchDocumentTags`, this is the shape of the document tags.
 * In most places, the document tags are simply an array of strings.
 */
export type DocumentTags = {
  selectable: Record<string, string>;
  non_selectable: Record<string, string>;
};

/**
 * The shape of valid file extensions object.
 */
export type ValidFileExtensions = string[];

/**
 * The shape of featureFlag response
 */
export type FeatureFlagResponse = Record<string, boolean>;

/**
 * The shape of an Attachment.
 */
export interface AttachmentResponse {
  application_dba_name: string;
  application_uuid: string;
  byte_size: number;
  /**
   * This appears to be a MIME type.
   *
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
   */
  content_type: string;
  created_at: string;
  description: string | null;
  document_tags: string[];
  expires_on: string | null;
  file_deleted_at: string | null;
  file_name: string;
  source: AttachmentSource;
  submission_uuid: string | null;
  updated_at: string;
  uuid: string;
  uploaded_by: string | null;
}

/**
 * The below types are used for specific payload configurations that we use in the
 * code for increased type safety when we know certain sections of the payload will
 * be present.
 */
export interface RenewalComparisonRow {
  funding_data: FundingInfo;
  summary_data: SummaryInfo;
  approval_data: ApprovalInfo;
  cash_flow_data: CashFlowInfo;
  owner_risk_data: OwnerRiskInfo;
}
export interface RenewalComparisonData<T extends keyof RenewalComparisonRow> {
  current: Pick<RenewalComparisonRow, T>;
  previous_1: Pick<RenewalComparisonRow, T> | null;
  previous_2: Pick<RenewalComparisonRow, T> | null;
}

/**
 * The below 'Response' types represent the type of an incoming payload from the renewal
 * comparison endpoint, where certain sections may or may not be present.
 */
export interface RenewalComparisonResponseRow {
  funding_data?: FundingInfo;
  summary_data: SummaryInfo;
  approval_data?: ApprovalInfo;
  cash_flow_data?: CashFlowInfo;
  owner_risk_data?: OwnerRiskInfo;
}

export interface RenewalComparisonResponse {
  current: RenewalComparisonResponseRow;
  previous_1: RenewalComparisonResponseRow | null;
  previous_2: RenewalComparisonResponseRow | null;
}

export interface ApprovalInfo {
  success?: boolean;
  funded_amount?: number | null; // money
  term_months?: number | null;
  program?: string | null;
}

export interface CashFlowInfo {
  success?: boolean;
  revenue_override?: number | null; // money
  average_negative_days?: number | null;
  average_daily_balance?: number | null; // adb
  number_of_positions?: number | null;
  gross_of_positions?: number | null; // percentage
  ff_percent_of_gross?: number | null;
  total_gross_percent?: number | null;
}

export interface OwnerRiskInfo {
  success?: boolean;
  fico_score: number | null;
  bankruptcy_plus: number | null;
  vantage_score: number | null;
  total_delinquent_trade_lines: number | null;
  debt_to_high_credit: number | null;
  public_records_count: number | null;
  uccs_count: number | null;
  open_judgments_count: number | null;
  open_liens_count: number | null;
  criminal_filings_count: number | null;
}

export interface FundingInfo {
  success?: boolean;
  advance_number?: string | null;
  /** date string in YYYY-MM-DD format */
  date_of_advance?: null | string;
  /** float value, ranges from 0 to 100 */
  percentage_repaid?: number | null;
  /** float value, ranges from 0 to 1 */
  pace?: number | null;
  /** percentage value, range from 0 - 100 */
  thirty_day_dollar_pace: number | null;
  /** percentage value, range from 0 - 100 */
  sixty_day_dollar_pace: number | null;
  /** percentage value, range from 0 - 100 */
  overall_dollar_pace: number | null;
  /** percentage value, range from 0 - 100 */
  overall_payment_count_pace: number | null;
  most_recent_payback_status?: string | null;
  amount?: number | null; // money
  target_term?: number | null;
  balance?: number | null; // money
  minimum_to_approve?: number | null; // money
  is_add_on?: boolean | null;
  net_funded_amount?: number | null; // money
  missed_payment_count?: number | null;
  reduced_payment_count?: number | null;
  processing_fee_amount?: number | null; // money
  contractual_payback_frequency?: string | null;
  record_id?: number | null;
  commission?: number | null;
  last_payment_date?: null | string;
}

export interface SummaryInfo {
  business_name: string;
  underwriter_name: string | null;
  stage: string;
  sub_stage: string | null;
}

export interface EnqueuedDealResponse {
  submission_uuid: string;
  underwriting_app_url: string;
}

/**
 * Response will depend on whether we get a 200 with data or a 201 No Content
 *
 * With a 200 response, we expect submission_uuid and customer_uuid to be present.
 * With a 201 response, we expect success to be present.
 */
export type ProcessingQueueResponse =
  | AssignedProcessingDealResponse
  | MutationResponse;

export type AssignedProcessingDealResponse = {
  submission_uuid: string;
  customer_uuid: string;
};

export interface IndustryRiskProfile {
  id: number;
  name: string;
  risk_profile: string;
  pricing_risk_profile: string;
  /**
   * This appears to be a stringified float
   * value between 0 and 1
   */
  profit_target: string;
  favorite: boolean;
  uuid: string;
  archived: boolean;
}

/**
 * Input for POST '/api/v2/industries/:industryUuid'
 *
 * This is the create endpoint for Industries.
 */
export interface CreateIndustryRequestBody {
  name: string;
  risk_profile: string;
  pricing_risk_profile: string;
  profit_target: string;
  version_url: string;
  archived: boolean;
}

/**
 * Input for PATCH '/api/v2/industries/:industryUuid'
 *
 * This is the update endpoint/action for Industries.
 *
 * All fields are optional, but at least one should be passed.
 */
export interface UpdateIndustryRequestBody {
  name?: string;
  risk_profile?: string;
  pricing_risk_profile?: string;
  profit_target?: string;
  version_url?: string;
  archived?: boolean;
}

export interface ReportSearchLinkResponse {
  entity_uuid: string;
  manual_report_search_link: string;
}

/**
 * Return type for GET | POST | PATCH '/api/v2/submissions/:submissionUuid/legacy_report_links'
 */
export interface LegacyReportLinksResponse {
  owner_manual_report_search_links: ReportSearchLinkResponse[];
  customer_manual_report_search_link: ReportSearchLinkResponse;
  paynet_report_links: {
    primary_report_pulled_at: string | null;
    primary_report_link: string | null;
    archive_link: string | null;
    paynet_reports_count: number | null;
  };
  data_merch_link: string | null;
}

export interface SendToProcessingResponse {
  processing_status: { message: string };
  status: boolean;
}
/**
 * Return type for GET '/partner/api/v1/applications'
 */
export interface SearchResultResponse {
  meta: Meta;
  applications: SubmissionSearchResult[];
}

/**
 * Return type for GET '/api/v2/submissions/:submission_uuid/stage_history'
 */
export interface SubmissionStageHistoryResponse {
  date: string;
  updated_by: string;
  stage: string;
  sub_stage: string;
  assigned_underwriter: string | null;
  notes: string | null;
}

/**
 * Return type for GET '/api/v2/submissions/:submission_uuid/stage_transitions'
 * The API will return an array of objects using this interface.
 */
export interface SubmissionStageTransitionsResponse {
  stage: string;
  sub_stage: string | null;
}

export interface InternalRenewalCreationResponse {
  data: string;
}

export interface RenewalEligibleResponse {
  success: boolean;
  eligible: boolean;
}

export type SubmissionCompositeScoreResponse =
  | SubmissionRenewalCompositeScoreResponse
  | SubmissionNewDealCompositeScoreResponse;

export type SubmissionRenewalCompositeScoreResponse =
  // The old response format that should be removed once the feature flag update_renewal_composite_score is turned on
  OldRenewalCompositeScoreResponse | RenewalCompositeScoreV2Response;

type CommonRenewalCompositeScoreResponse = {
  industry_risk: {
    score?: number;
    value?: string;
    industry?: string;
  };
  times_funded: {
    score?: number;
    value?: number;
  };
  fico_change: {
    score?: number;
    value?: number;
  };
  revenue_change: {
    score?: number;
    value?: number;
  };
  payment_history: {
    score?: number;
    value?: number;
  };
  stacking: {
    score?: number;
    pre_ff_gross?: number;
  };
  composite_score?: {
    score?: number;
    tier?: string;
  };
  updated_at?: string;
};

export type OldRenewalCompositeScoreResponse =
  CommonRenewalCompositeScoreResponse & {
    artifact_case: 'renewal_composite_score';
    repaid_percentage: {
      score?: number;
      value?: number;
    };
  };

export type RenewalCompositeScoreV2Response =
  CommonRenewalCompositeScoreResponse & {
    artifact_case: 'renewal_composite_score_v2';
    bk_plus: {
      score?: number;
      value?: number;
    };
  };

export type SubmissionNewDealCompositeScoreResponse = {
  artifact_case: 'composite_score';
  industry_risk: {
    score?: number;
    value?: string;
    industry?: string;
  };
  position: {
    score?: number;
    value?: number;
  };
  fico: {
    score?: number;
    value?: number;
  };
  time_in_business: {
    score?: number;
    value?: number;
  };
  risk_decile?: {
    score?: number;
    value?: number;
  };
  risk_score?: {
    score?: number;
    value?: number;
  };
  true_revenue: {
    score?: number;
    value?: number;
  };
  composite_score?: {
    score?: number;
    tier?: string;
  };
  updated_at?: string;
};

export interface LivePipelineSearchParams {
  sort: string;
  order: 'asc' | 'desc';
  page: number;
  hits_per_page: number;
  stage_name: string;
  sub_stage: string;
}

export interface SubmissionSearchResult {
  objectID: string;
  partner_uuid: string;
  dba: string;
  legal_name: string;
  date_received: string;
  stage_name: string;
  sub_stage: string;
  approval_flag: boolean | null;
  ff_underwriter: string | null;
  credit_committee: string | null;
  exception_request_assignee: string | null;
  underwriter_sign_off_name: string | null;
  new_or_renewal: string;
  max_approval_issued: number | null;
  last_sub_stage_change_date: string | null;
  salesforce_opportunity_uuid: string;
  vca: string | null;
  processor: string | null;
  prequal_analyst_name: string | null;
  decision_analyst_id: number | null;
  industry_name: string | null;
  revenue_float: number | null;
  existing_financing_positions: number | null;
  iso_competing_sub_message: string | null;
  prime_deal_status?: number | null;
  last_stage_change_date?: string | null;
  sla_status: number | null;
  category_status: number | null;
}

/**
 * Return type for GET '/api/v2/submissions'
 */
export interface SubmissionSearchResultResponse {
  meta: Meta;
  applications: SubmissionSearchResult[];
}

export interface Meta {
  total_count: number;
  total_pages: number;
  current_page: number;
}

export type SubmissionNoteType = 'underwriter_scoring' | 'manager_scoring';

export interface SubmissionNotesResponse {
  id: number;
  submission_uuid: string;
  content: string;
  note_type: SubmissionNoteType;
  created_at: string;
  updated_at: string;
}

export type UpdateSubmissionNoteBody = {
  content: string;
};

export interface NewDealScoreResponse {
  online_presence: number | null;
  owner_risk: number | null;
  business_risk: number | null;
  quality_of_cash_flows: number | null;
  deal_specific_factors: number | null;
  average_score: number | null;
  deal_funded_on: string;
}

export interface RenewalScoreResponse {
  payment_history: number | null;
  stacking_considerations: number | null;
  revenue_trends: number | null;
  credit_trends: number | null;
  note: string | null;
  manager_feedback_score: boolean | null;
  manager_feedback_note: string | null;
  deal_funded_on: string | null;
  last_updated_at: string | null;
  last_updated_by: string | null;
}

export interface RenewalScoresFetchResponse {
  renewal_score: RenewalScoreResponse;
  previous_renewal_scores: RenewalScoreResponse[];
  original_deal_score: NewDealScoreResponse;
}

export type CreateRenewalScoreBody = {
  payment_history?: number;
  stacking_considerations?: number;
  revenue_trends?: number;
  credit_trends?: number;
  note?: string;
  manager_feedback_score?: boolean | null;
  manager_feedback_note?: string | null;
};

export type UpdateRenewalScoreBody = {
  payment_history?: number;
  stacking_considerations?: number;
  revenue_trends?: number;
  credit_trends?: number;
  note?: string;
  manager_feedback_score?: boolean | null;
  manager_feedback_note?: string | null;
};

export type FraudProtectionMatchesResponse = {
  success: boolean;
  data: FraudMatchResponse[];
};

export type FraudMatchResponse = {
  submission: FraudProtectionSubmissionResponse;
  alerts: FraudProtectionAlertResponse[];
  criteria: SubmissionMatchCriteriaResponse;
};

export type FraudProtectionSubmissionResponse = {
  uuid: string;
  account_uuid: string | null;
  received_at: string | null;
  dba: string | null;
  legal_name: string | null;
};

export type FraudStatus = 'unconfirmed' | 'confirmed' | 'disconfirmed';

export type FraudProtectionAlertResponse = {
  id: number;
  created_at: string;
  updated_at: string;
  type: string;
  status: FraudStatus;
  source: string;
  /**
   * This is a pretty ambiguous type since we don't currently know
   * all the various shapes for future categories of alerts.
   *
   * We do not currently use it in any features, but if we do in the
   * future, we will want to make this more specific, and potentially
   * define distinct types for each alert source.
   */

  data: HashMap<string>;
};

export type SubmissionMatchCriteriaResponse = {
  dba: boolean;
  legal_name: boolean;
  fein: boolean;
  business_address: boolean;
  business_phone: boolean;
  owner_name: boolean;
  owner_ssn: boolean;
  owner_email: boolean;
  owner_address: boolean;
  owner_phone: boolean;
};

export type CreateFraudProtectionDecisionResponse = {
  success: boolean;
  data: FraudDecisionResponse;
};

export type CreateFraudProtectionDecisionBody = {
  alert_id: number;
  user_id: number;
  status: FraudStatus;
};

export type FraudDecisionResponse = {
  id: number;
  created_at: string;
  updated_at: string;
  alert_id: number;
  user_id: number;
  status: FraudStatus;
};

export interface LexisNexisAddress {
  street_number?: string;
  street_pre_direction?: string;
  street_post_direction?: string;
  street_name?: string;
  street_suffix?: string;
  unit_designation?: string;
  unit_number?: string;
  city?: string;
  state?: string;
  zip5?: string;
  zip4?: string;
  county?: string;
}

export interface LexisNexisNameResponse {
  first: string;
  last: string;
  middle?: string;
  prefix?: string;
  suffix?: string;
}

export interface LexisNexisDateResponse {
  year: string;
  day?: string;
  month?: string;
}

type PhoneNumber = { phone_number: string };
export interface PhoneNumbersResponse {
  phones_plus: PhoneNumber[] | PhoneNumber;
}
export interface BestInfoResponse {
  name: LexisNexisNameResponse;
  address: LexisNexisAddress;
  ssn: string;
  dob: LexisNexisDateResponse;
}

export interface OwnerLexisNexisSsnInfoResponse {
  ssn: string;
  issued_end_date: LexisNexisDateResponse;
  issued_start_date: LexisNexisDateResponse;
  issued_location: string;
}

export interface NameVariationsReponse {
  name: LexisNexisNameResponse;
  ssn_info?: OwnerLexisNexisSsnInfoResponse;
}

type DefendantName = { name?: LexisNexisNameResponse };

export interface NoticesOfDefaultResponse {
  defendants: {
    defendant: DefendantName | DefendantName[];
  };
  deed_type: string;
  site_address: LexisNexisAddress;
  site2_address: LexisNexisAddress;
  recording_date: LexisNexisDateResponse;
}

export interface OwnerLexisNexisAddressSummaryResponse {
  phones?: {
    phone: { phone10: string } | { phone10: string }[];
  };
  address: LexisNexisAddress;
  subject_name: LexisNexisNameResponse;
  date_last_seen: LexisNexisDateResponse;
}

export type DebtorResponse = {
  parsed_parties: {
    party: {
      name: LexisNexisNameResponse;
      company_name?: string;
    };
  };
  addresses?: {
    address: LexisNexisAddress;
  };
};

export type CreditorResponse = {
  name: string;
  addresses?: {
    address: LexisNexisAddress;
  };
};

export type FilingResponse = {
  type: string;
  origin_filing_date: LexisNexisDateResponse;
  agency: string;
  agency_state: string;
  agency_county: string;
  number: string;
};

export type LiensJudgmentResponse = {
  debtors?: { debtor: DebtorResponse | DebtorResponse[] } | null;
  creditors?: { creditor: CreditorResponse | CreditorResponse[] } | null;
  filings?: { filing: FilingResponse | FilingResponse[] } | null;
  amount: string;
};

export type UccAddress = {
  address: LexisNexisAddress;
};

// both debtor and secure keys in the payload have the same nested fields
export type UccFilingEntity = {
  origin_name: string;
  addresses?: { address: LexisNexisAddress | LexisNexisAddress[] } | null;
};

export type FilingsResponse = {
  type?: string;
  filing_status?: string;
  expiration_date?: LexisNexisDateResponse;
};

export type UccFilingsResponse = {
  debtors?: {
    debtor: UccFilingEntity | UccFilingEntity[];
  };
  secureds?: {
    secured: UccFilingEntity | UccFilingEntity[];
  };
  origin_filing_date?: LexisNexisDateResponse;
  collaterals?: {
    collateral: { description: string } | { description: string }[];
  };
  release_date?: string;
  judge_satisfied_date?: string;
  judge_vacated_date?: string;
  filings?: { filing: FilingsResponse | FilingsResponse[] };
};

export type ForeclosureDefendant =
  | {
      name?: LexisNexisNameResponse;
      company_name: string;
    }
  | {
      name: LexisNexisNameResponse;
      company_name?: string;
    };

export type ForeclosuresResponse = {
  deed_type: string;
  defendants: {
    defendant: ForeclosureDefendant | ForeclosureDefendant[];
  };
  land_usage: string;

  site_address: LexisNexisAddress;

  recording_date: LexisNexisDateResponse;
};

/**
 * Per the parent type OwnerLexisNexisPayload, that uses this type, not all
 * fields are included. See more details in the OwnerLexisNexisPayload type.
 *
 */
export interface OwnerLexisNexisProfessionalLicenseResponse {
  profession_or_board: string;
  license_type: string;
  issued_date?: LexisNexisDateResponse;
  expiration_date?: LexisNexisDateResponse;
  status: string;
}

export type OwnerLexisNexisCriminalOffenseResponse = {
  court?: {
    offense: string;
    disposition: string;
  };
  arrest?: {
    date: LexisNexisDateResponse;
  };
  offense_date?: LexisNexisDateResponse;
};

export type OwnerLexisNexisCriminalResponse = {
  data_source: string;
  name: LexisNexisNameResponse;
  offenses: {
    offense:
      | OwnerLexisNexisCriminalOffenseResponse
      | OwnerLexisNexisCriminalOffenseResponse[];
  };
  case_filing_date: LexisNexisDateResponse;
  state_of_origin: string;
  unique_id: string;
};

export type CorporateAffiliationResponse = {
  company_name: string;
  address?: LexisNexisAddress;
  filing_date: LexisNexisDateResponse;
  state: string;
  status: string;
  record_type: string;
};

export type BankruptcyStatusHistory = {
  date: LexisNexisDateResponse;
  type: string;
};

export type BankruptcyResponse = {
  status_history: {
    status: BankruptcyStatusHistory | BankruptcyStatusHistory[];
  };
  original_chapter: string;
  original_filing_date: LexisNexisDateResponse;
};

/**
 * We are making an exception here to our usual rule of including all fields in
 * the API types.
 *
 * This type describes only part of the full LexisNexis payload for owners. The
 * full payload originates from a third party and it may change without us being
 * notified. It is also a very large payload, of which we only need a limited
 * subset of data for our UIs.
 *
 * Because of this, we have defined a subset of the payload that is relevant to
 * our UI such that other parts of the payload do not get misrepresented or out
 * of sync. This API type should not be treated as an absolute source of truth
 * for the FULL LexisNexis owner payload, but the data that is represented in
 * this type can be considered accurate and up to date.
 *
 * - @bradleyden 2024-01-26
 */
export interface OwnerLexisNexisPayload {
  owner_uuid: string;
  full_payload: {
    individual: {
      best_info?: BestInfoResponse;
      phones_pluses?: PhoneNumbersResponse;
      ak_as?: {
        identity: NameVariationsReponse[] | NameVariationsReponse;
      };
      bps_report_addresses?: {
        bps_report_address:
          | OwnerLexisNexisAddressSummaryResponse
          | OwnerLexisNexisAddressSummaryResponse[];
      };
      notices_of_defaults?: {
        notice_of_defaults:
          | NoticesOfDefaultResponse
          | NoticesOfDefaultResponse[];
      };
      professional_licenses?: {
        professional_license:
          | OwnerLexisNexisProfessionalLicenseResponse
          | OwnerLexisNexisProfessionalLicenseResponse[];
      };
      liens_judgments?: {
        lien_judgment: LiensJudgmentResponse | LiensJudgmentResponse[];
      };
      criminal_records?: {
        criminal:
          | OwnerLexisNexisCriminalResponse
          | OwnerLexisNexisCriminalResponse[];
      };
      ucc_filings?: {
        ucc_filing: UccFilingsResponse | UccFilingsResponse[];
      };
      foreclosures?: {
        foreclosure: ForeclosuresResponse | ForeclosuresResponse[];
      };
      corporate_affiliations?: {
        affiliation:
          | CorporateAffiliationResponse
          | CorporateAffiliationResponse[];
      };
      bankruptcies?: {
        bankruptcy: BankruptcyResponse | BankruptcyResponse[];
      };
      // keep adding the other key of the payload such as, ak_as, bankruptcies...
    };
  };
}

export interface OwnerLexisNexisReportResponse {
  documents: OwnerLexisNexisPayload[];
}

// The optional types may not be fully accurate here.
export type BusinessLexisNexisAddress = {
  city: string;
  zip4?: string;
  zip5: string;
  state: string;
  street_name?: string;
  unit_number?: string;
  street_number?: string;
  street_suffix?: string;
  street_address1?: string;
  street_address2?: string;
  unit_designation?: string;
  orig_street_address1?: string;
  street_pre_direction?: string;
  county?: string;
};

export type BusinessLexisNexisUccFilingEntity = {
  addresses?: {
    address: BusinessLexisNexisAddress | BusinessLexisNexisAddress[];
  } | null;
  origin_name: string;
};

export type BusinessLexisNexisCollateral = {
  description: string;
};

export type FilingOffice = {
  address: BusinessLexisNexisAddress;
  filing_agency: string;
};

export type BusinessLexisNexisExecutive = {
  name: LexisNexisNameResponse;
  address: BusinessLexisNexisAddress;
  company_titles: {
    title: string | string[];
  };
  date_last_seen: LexisNexisDateResponse;
};

export type BusinessLexisNexisDebtor = {
  addresses?: {
    address: LexisNexisAddress;
  };
  origin_name: string;
  parsed_parties: {
    party: {
      company_name?: string;
    };
  };
};

export type BusinessLexisNexisCreditor = {
  name: string;
  addresses?: {
    address: LexisNexisAddress;
  };
  parsed_parties: {
    party: {
      company_name?: string;
    };
  };
};

export type BusinessLexisNexisLiensJudgment = {
  debtors: { debtor: BusinessLexisNexisDebtor | BusinessLexisNexisDebtor[] };
  creditors: {
    creditor: BusinessLexisNexisCreditor | BusinessLexisNexisCreditor[];
  };
  filings?: { filing: FilingResponse | FilingResponse[] };
  amount?: string;
};

export type BusinessLexisNexisUcc = {
  debtors?: {
    debtor:
      | BusinessLexisNexisUccFilingEntity
      | BusinessLexisNexisUccFilingEntity[];
  };
  filings?: {
    filing: FilingsResponse | FilingsResponse[];
  };
  secureds?: {
    secured:
      | BusinessLexisNexisUccFilingEntity
      | BusinessLexisNexisUccFilingEntity[];
  };
  collaterals?: {
    collateral: BusinessLexisNexisCollateral | BusinessLexisNexisCollateral[];
  };
  filing_offices?: {
    office: FilingOffice | FilingOffice[];
  };
  release_date?: string;
  judge_satisfied_date?: string;
  judge_vacated_date?: string;
  origin_filing_date: LexisNexisDateResponse;
};

export type BusinessLexisNexisNameVariation = {
  company_name: string;
};

export type BusinessLexisNexisBusinessRegistration = {
  status: string;
  business_id: string;
  filing_code?: string;
  filing_type: string;
  company_name: string;
  company_phone: string | null;
  expiration_date: LexisNexisDateResponse;
  mailing_address: BusinessLexisNexisAddress;
  corporation_code: string;
};

export type BusinessLexisNexisCorporateHistory = {
  status: string;
  address?: BusinessLexisNexisAddress;
  incorporate_date?: LexisNexisDateResponse;
  company_name: string;
  company_type?: {
    entity_type?: string;
    business_type?: string;
    additional_info?: string;
  };
  record_date?: LexisNexisDateResponse;
  name_type: string;
  status_date?: LexisNexisDateResponse;
  registered_agent?: {
    name: LexisNexisNameResponse;
  };
  as_agent_date?: string;
};

export type PhoneInfo = {
  phone_info: {
    phone10: string;
  };
};

export type BusinessLexisNexisEvent = {
  date_type: string;
  filing_date: LexisNexisDateResponse;
  filing_type: string;
  filing_type_code: string;
};

export type ContactType = {
  type: string;
  title: string;
};

export type DebtorName = {
  type?: string;
  company_name?: string;
  original_name?: string;
};

export type DebtorNames = {
  name: DebtorName | DebtorName[];
};

type Debtor = {
  debtor: { names: DebtorNames } | { names: DebtorNames }[];
};

export type BusinessLexisNexisBankruptcy = {
  status_history: {
    status: BankruptcyStatusHistory | BankruptcyStatusHistory[];
  };
  original_chapter: string;
  original_filing_date: LexisNexisDateResponse;
  debtors: Debtor;
};

export type BusinessLexisNexisProfileContact = {
  names: {
    name: LexisNexisNameResponse | LexisNexisNameResponse[];
  };
  addresses: {
    address: LexisNexisAddress | LexisNexisAddress[];
  };
  as_of_date: LexisNexisDateResponse;
  company_name: string;
  contact_types: {
    contact_type: ContactType | ContactType[];
  };
};

export type BusinessLexisNexisProfile = {
  events?: {
    event: BusinessLexisNexisEvent | BusinessLexisNexisEvent[];
  };
  contacts?: {
    contact:
      | BusinessLexisNexisProfileContact
      | BusinessLexisNexisProfileContact[];
  };
  corporate_histories?: {
    corporate_history:
      | BusinessLexisNexisCorporateHistory
      | BusinessLexisNexisCorporateHistory[];
  };
};

/**
 * Partial response for GET 'api/v2/submissions/:submissionUuid/documents/lexis_nexis_customer_reports?full_payload=true'
 *
 * See {@link BusinessLexisNexisReportResponse} for the full payload
 */
export type BusinessLexisNexisPayload = {
  submission_uuid: string;
  document_id: string;
  created_at: string;
  primary: boolean;
  full_payload: {
    uc_cs?: {
      ucc: BusinessLexisNexisUcc | BusinessLexisNexisUcc[];
    };
    profiles?: {
      profile: BusinessLexisNexisProfile | BusinessLexisNexisProfile[];
    };
    executives?: {
      executive: BusinessLexisNexisExecutive | BusinessLexisNexisExecutive[];
    };
    report_for?: {
      address: BusinessLexisNexisAddress;
      company_name: string;
    };
    liens_judgments?: {
      lien_judgment:
        | BusinessLexisNexisLiensJudgment
        | BusinessLexisNexisLiensJudgment[];
    };
    name_variations?: {
      company_name_variation:
        | BusinessLexisNexisNameVariation
        | BusinessLexisNexisNameVariation[];
    };
    phone_variations?: {
      phone_variation: PhoneInfo | PhoneInfo[];
    };
    business_registrations?: {
      business_registration:
        | BusinessLexisNexisBusinessRegistration
        | BusinessLexisNexisBusinessRegistration[];
    };
    bankruptcies?: {
      bankruptcy: BusinessLexisNexisBankruptcy | BusinessLexisNexisBankruptcy[];
    };
  };
};

/**
 * Return type for GET 'api/v2/submissions/:submissionUuid/documents/lexis_nexis_customer_reports?full_payload=true'
 */
export type BusinessLexisNexisReportResponse = {
  documents: BusinessLexisNexisPayload[];
};

export type WebPresenceType =
  | 'business_website'
  | 'facebook'
  | 'instagram'
  | 'yelp'
  | 'other';

export type UpdateApiWebPresenceBody = {
  url: string;
};

export type BulkUpdateApiWebPresenceBody = {
  business_website?: string | null;
  facebook?: string | null;
  instagram?: string | null;
  yelp?: string | null;
  other?: string | null;
};

export type BulkUpdateWebPresenceResponse = {
  business_website: string | null;
  facebook: string | null;
  instagram: string | null;
  yelp: string | null;
  other: string | null;
};

export type WebPresencePatchResponse = {
  web_presence_type: WebPresenceType;
  url: string;
  urlChanged?: boolean;
};

export type StateListResponse = {
  name: string;
  abbreviation: string;
};

export interface BankNameResponse {
  id: string;
  name: string;
}

/**
 * This is the patch body for the V2 applications endpoint.
 * We are only defining the relevant fields needed in the
 * short term until we remove Applications from Underwriting.
 *
 * If more Application update fields are needed in the meantime,
 * we can add them here. But more likely, we will remove this type
 * before we ever need to expand it.
 */

export type UpdateApplicationRequestBody = {
  prequal_analyst_name?: string;
};

/**
 * Return type for GET '/api/v2/customers/:customerUuid/ineligibilities'
 *
 * This contains current_customer data, which does not require any summary or
 * match data, so it is just an array of ineligibilities for that customer.
 *
 * It also contains matched_customers, each of which contains match criteria
 * and customer summary data to identify the match, in addition to an array
 * of ineligibilities.
 */
export type IneligibilitiesResponse = {
  success: boolean;
  data: IneligibilitiesData;
};

export type IneligibilitiesData = {
  current_customer: {
    ineligibilities: IneligibilityResponse[];
  };
  matched_customers: MatchedCustomerIneligibilityResponse[];
};

export type IneligibilityResponse = {
  ineligibility_type: string;
  reason: string;
  created_at: string;
};

export type MatchedCustomerIneligibilityResponse = {
  customer: CustomerSummaryResponse;
  criteria: SubmissionMatchCriteriaResponse;
  ineligibilities: IneligibilityResponse[];
};

export type CustomerSummaryResponse = {
  uuid: string;
  dba: string;
  legal_name: string;
};

/**
 * Return type for GET '/api/v2/customers'
 *
 * Typing is a guess
 */
export type CustomersResponse = {
  name: string;
  fein?: string;
  legal_name: string;
  phone?: string;
  created_at?: string;
  updated_at?: string;
  uuid: string;
  has_open_renewal?: boolean;
  industry_id?: number;
  industry_name?: string;
  // YYYY-MM-DD
  started_on?: string | null;
  risk_profile?: string;
  entity_type?: string;
  fa_record_id?: number | null;
  funding_app_url?: string | null;
  industry_uuid?: string;
  industry_pricing_risk_profile?: string;
  is_streaming?: boolean;
  legal_hold?: boolean;
  monthly_revenue?: string | null;
  naics_code?: string | null;
  customer_uuid?: string | null;
  replaced_by?: string | null;
  active_submissions?: {
    iso_uuid?: string;
    url?: string;
    date?: string;
    uuid?: string;
    is_renewal?: boolean;
  }[];
  address: Address;
  bank_account?: BankAccountResponse | null;
}[];

/**
 * Return type for GET '/api/v2/decline_drivers'
 *
 * The success boolean will be in sync with the status code, which we check in our
 * fetch utils. For this reason, we do not need to explicitly use this boolean
 * for anything in our code.
 */
export type DeclineDriversResponse = {
  success: boolean;
  data: DeclineDriverData[];
};

export type DeclineDriverData = {
  id: number;
  value: string;
  created_at: string;
  updated_at: string;
};

export type SubmissionLogsResponse = {
  success: boolean;
  data: SubmissionLogResponse[];
};

export type SubmissionLogResponse =
  | UnderwriterAssignedLogResponse
  | CreditCommitteeAssignedLogResponse
  | ExceptionAssignedLogResponse
  | FinalUnderwriterSignOffAssignedLogResponse
  | DeclineDataSavedLogResponse;

export type SubmissionLogBase = {
  id: number;
  user_id: number;
  submission_uuid: string;
  event: string;
  stage_name: string;
  sub_stage: string;
  data: unknown;
  created_at: string;
  updated_at: string;
  log_type: 'underwriting';
};

export type UnderwriterAssignedLogResponse = SubmissionLogBase & {
  event: 'underwriter_assigned';
  data: {
    underwriter_id: number;
  };
};

export type CreditCommitteeAssignedLogResponse = SubmissionLogBase & {
  event: 'credit_committee_start';
  data: {
    credit_committee__c: string;
  };
};

export type ExceptionAssignedLogResponse = SubmissionLogBase & {
  event: 'underwriting_exception_request_start';
  data: {
    exception_request_underwriter_user_id: number;
  };
};

export type FinalUnderwriterSignOffAssignedLogResponse = SubmissionLogBase & {
  event: 'underwriting_final_signoff_start';
  data: {
    underwriter_sign_off_id: number;
  };
};

export type DeclineDataSavedLogResponse = SubmissionLogBase & {
  event: 'underwriting_decline_driver_saved';
};

type Result = {
  score: number;
  reasons: string[];
};

type StipulationRecommendationResult = {
  experian_consumer: Result;
  experian_commercial: Result;
  lexis_nexis_personal: Result;
  lexis_nexis_business: Result;
};

export type StipulationRecommendationsResponse = {
  submission_uuid: string;
  stipulation_type: string;
  bucket: string | undefined;
  overall_score: number | undefined;
  results: StipulationRecommendationResult | undefined;
  created_at: string;
};
export type PaynetNameAddressResponse = {
  primary_name: string | null;
  address_1: string | null;
  address_2: string | null;
  telephone: string | null;
  state_code: string | null;
  postal_code: string | null;
  city: string;
  tax_id: string;
};

export type PaynetContractResponse = {
  term: string;
  loss_amt: string;
  start_date: string;
  cur_bal_amt: string;
  // for this key in specific, paynet returns a string if there is a value to return,
  // if there is nothing they send an object like this { "xsi:nil": "true" }
  loss_status: string | { [key: string]: boolean };
  payment_amt: string;
  payment_freq: string;
  contract_type: string;
  equipment_type: string;
  orig_receivable_amt: string | null;
  past_due_0130_occurrences: string | null;
  past_due_3160_occurrences: string | null;
  past_due_6190_occurrences: string | null;
  past_due_91_plus_occurrences: string | null;
};

export type PaynetMemberLenderResponse = {
  contracts: { contract: PaynetContractResponse[] | PaynetContractResponse };
  as_of_date: string;
  primary_industry: string;
};

export type BusinessPaynetResponse = {
  full_payload: {
    paynet_id: string;
    report_id?: string;
    // Numeric string with two decimal places. E.g. '10.75'
    cur_bal_amt?: string;
    name_address?: PaynetNameAddressResponse;
    master_score: string | null;
    master_score_percentile?: string;
    member_lenders?: {
      member_lender: PaynetMemberLenderResponse[] | PaynetMemberLenderResponse;
    };
  };
  primary: boolean;
  id: number;
  created_at: string;
};

export type DataMerchNotesResponse = {
  note: string | null;
  category: string | null;
  created_at: string | null;
  added_by: string | null;
};

export type DataMerchMerchantResponse = {
  notes: { note: DataMerchNotesResponse }[];
  legal_name: string;
  fein: string;
  dba: string | null;
  city: string | null;
  state: string | null;
  address: string | null;
  street1: string | null;
  street2: string | null;
  industry: string | null;
  business_phone: string | null;
  business_startdate: string | null;
};

export type DataMerchBodyResponse = { merchant: DataMerchMerchantResponse };
export type DataMerchErrorResponse = { response: { error: string } };

export type DataMerchReportResponse = {
  documents: {
    full_payload: {
      body:
        | DataMerchBodyResponse
        | DataMerchBodyResponse[]
        | DataMerchErrorResponse;
    };
  } | null;
};

export type SOSResponseContactType = {
  type: string | null;
  title: string | null;
};

export type SOSResponseOwnershipInfo = {
  full_name: string | null;
  contact_type: SOSResponseContactType[] | null;
};

export type SOSFieldsResponse = {
  documents: {
    business_address: {
      city: string | null;
      type: string | null;
      zip4: string | null;
      zip5: string | null;
      state: string | null;
      street_name: string | null;
      street_number: string | null;
      street_suffix: string | null;
    } | null;
    company_name: string | null;
    federal_tax_id: string | null;
    incorporate_date: {
      day: string | null;
      month: string | null;
      year: string | null;
    } | null;
    message: string | null;
    organize_structure: string | null;
    ownership_info: SOSResponseOwnershipInfo[] | null;
    registered_agent: {
      full_name: string | null;
      first_name: string | null;
      last_name: string | null;
      title: string | null;
      ssn: string | null;
      address: {
        city: string | null;
        type: string | null;
        zip4: string | null;
        zip5: string | null;
        state: string | null;
        street_name: string | null;
        street_number: string | null;
        street_suffix: string | null;
      } | null;
    } | null;
    status: string | null;
  } | null;
  success: boolean | null;
  error: string | null;
};

export type LedgerUnderwritingPricingSuggestionsRaw = {
  // Line bellow is commented until BE confirms its usage
  // all_program_suggestions: PricingSuggestionResponse[]
  primary_suggestion: PricingSuggestionResponse;
  error?: string;
};

export type PricingSuggestionResponse = {
  // The type of program being suggested
  submission_uuid: string;
  program_type: string;
  inputs: {
    // The risk score associated with the ledger
    risk_score: string;
    // The industry risk associated with the ledger
    industry_risk: string;
    // The gross amount of financings associated with the ledger
    financings_gross: string;
  };
  // The maximum term for the suggested program
  max_term: number;
  // The rounded amount for the suggested program
  rounded_amount: number;
};

export type LedgerUnderwritingPricingSuggestionsGetParams = {
  // Ledger ID
  source_key: string;
  // "Underwriting::Ledger" (hard-coded string for now)
  source_type: string;
  // The ledger's 'net_deposit_override_cents' value, divided by 100,
  // converted to a float, and rounded to two decimal places
  revenue: string;
  // The count of financings associated with the ledger
  financings_count: string;
  // The gross amount of financings associated with the ledger
  financings_gross: string;
};

export type PullCredit = {
  experian_owner_report: {
    force: boolean;
  };
};

export type CreateSubmissionLogSkipResponse = {
  success: boolean;
  message: string;
};

export type CreateSubmissionLogCreatedResponse = {
  success: boolean;
  data: SubmissionLogResponse;
};

export type CreateSubmissionLogResponse =
  | CreateSubmissionLogCreatedResponse
  | CreateSubmissionLogSkipResponse;

export type UnderwriterAssignedLogBody = {
  event: 'underwriter_assigned';
  data: {
    underwriter_id: number;
  };
  log_type: 'underwriting';
};

export type CreditCommitteeAssignedLogBody = {
  event: 'credit_committee_start';
  data: {
    credit_committee__c: string;
  };
  log_type: 'underwriting';
};

export type ExceptionAssignedLogBody = {
  event: 'underwriting_exception_request_start';
  data: {
    exception_request_underwriter_user_id: number;
  };
  log_type: 'underwriting';
};

export type FinalUnderwriterSignOffAssignedLogBody = {
  event: 'underwriting_final_signoff_start';
  data: {
    underwriter_sign_off_id: number;
  };
  log_type: 'underwriting';
};

export type CreateApiSubmissionLogBody =
  | UnderwriterAssignedLogBody
  | CreditCommitteeAssignedLogBody
  | ExceptionAssignedLogBody
  | FinalUnderwriterSignOffAssignedLogBody;

/**
 * Response for GET /api/v2/submissions/:uuid/validation_overrides
 */
export type OverriddenValidationsResponse = {
  id: number;
  note: string | null;
  stage: string;
  created_by_id: number | null;
  created_at: string | null;
  missing_attributes: string[] | null;
};

/**
 * Input for POST '/api/v2/submissions/:uuid/validation_overrides'
 */
export type ValidationOverridePayload = {
  note: string;
  stage: string;
  missing_attributes: string[];
};

type Prediction = {
  vendor: string;
  naics_code: string | null;
  naics_title: string | null;
  confidence_score: number | null; // Value between [0, 100] (e.g. 75 or 75.5)
  industry: string | null; // Forward-specific industry, not NAICS
  industry_uuid: string | null;
  prohibited: boolean | null;
};

/**
 * Response for GET /api/v2/submissions/:submissionUuid/industry_recommendations
 */
export type IndustryRecommendationResponse = {
  submission_uuid: string;
  industry_uuid: string | null;
  industry: string | null; // Recommended Forward-specific industry, not NAICS
  prohibited: boolean | null;
  reason: string;
  predictions: Prediction[];
  created_at: string; // ISO 8601 date string (e.g. "2024-12-04T14:31:12.138-05:00")
};

/**
 * Return type for GET 'api/v2/live_pipeline/search'
 */
export type LivePipelineSubmissionSearchResultResponse = {
  meta: Meta;
  data: LivePipelineSubmissionResponse[];
};

/**
 * Partial response type for GET 'api/v2/live_pipeline/search'
 */
export type LivePipelineSubmissionResponse = {
  id: string;
  type: 'submissions';
  attributes: LivePipelineSubmissionAttributesResponse;
};

export type LivePipelineSubmissionAttributesResponse = {
  approval_flag: boolean | null;
  balance_outstanding: number | null;
  calculator_url: string | null;
  category_status: number | null;
  credit_committee: string | null;
  data_type: string;
  date_received: string;
  dba: string;
  decision_analyst_id: number | null;
  exception_request_assignee: string | null;
  existing_financing_positions: number | null;
  ff_underwriter: string | null;
  industry_name: string | null;
  iso_competing_sub_message: string | null;
  last_stage_change_date?: string | null;
  last_sub_stage_change_date: string | null;
  legal_name: string;
  max_approval_issued: number | null;
  merchant_partner_id: null; // No idea what the actual type is. All subs have null for this in preprod & prod
  new_or_renewal: string;
  offer_expires_at: string | null; // e.g. "02/28/2025  3:30 PM"
  'partner_action?': boolean; // Has the '?' in the key name, not to be confused with an optional key
  partner_renewal_eligible: boolean;
  partner_uuid: string;
  prequal_analyst_name: string | null;
  prime_deal_status?: number | null;
  processor: string | null;
  revenue_float: number | null;
  salesforce_opportunity_uuid: string; // submission uuid
  sla_status: number | null;
  stage: string;
  stage_name: string;
  sub_stage: string;
  submission_created_at: string; // e.g. "2020-11-25T14:55:51.000-05:00"
  submit_stips_eligible: boolean;
  underwriter_sign_off_name: string | null;
  vca: string | null;
  uuid: string; // Should be the same value as `salesforce_opportunity_uuid`
};

/**
 * Response for GET /decision/api/v1/submissions/:submissionUuid/accepted_offer
 */
export type AcceptedOfferResponse = {
  id: string;
  submission_uuid: string;
  amended_version: number | null;
  amount: number;
  buy_rate: number | null;
  commission: number | null;
  daily_payment: number | null;
  default_processing_fee: number;
  locked: boolean | null;
  payback_amount: number | null;
  processing_fee: number;
  rounded_holdback_percent: number | null;
  term: number | null;
  total_factor_rate: number | null;
  upsell: number | null;
  created_at: string;
  updated_at: string;
};

export type DigitalClosingDashboardSearchParams = {
  page: number;
  state: string;
};

export type OnlineCheckoutMetadata = {
  total_count: number;
  current_page: number;
  total_pages: number;
};

export type OnlineCheckoutResponse = {
  id: string; // UUID
  submission_uuid: string;
  // current_state can be any valid state for the online checkout model, defined here:
  // https://github.com/ForwardFinancing/underwriting/blob/41828c7d73f9cf09c7bfb1e68d5b37747b582bb6/app/models/online_checkout/states.rb#L9
  current_state: string;
  current_state_occurred_at: string; // ISO 8601 date string
  customer_legal_name: string | null;
  max_approval_amount: number | null; // Double precision float
};

export type OnlineCheckoutSearchResultResponse = {
  data: OnlineCheckoutResponse[];
  meta: OnlineCheckoutMetadata;
};
