import { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  formatDateTimeString,
  IconButton,
  Loading,
  Modal,
  Text,
} from '@forward-financing/fast-forward';
import { isEqual } from 'lodash';

import { useDebounceFn } from 'ahooks';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { featureFlags } from 'helpers/featureFlags';
import { useExceptionRequestContext } from '../../SubmissionUnderwriting/ExceptionRequest/Context/ExceptionRequestContext';
import { Ledger } from './ledger.types';
import { useDeleteLedger, useSendToCreditCommittee } from './ledgerHooks';
import { useLedgerContext } from './LedgerContext/LedgerContext';

const SEND_TO_COMMITTEE_SUCCESS_TEXT = 'Ledger sent to committee successfully';
const DELETE_SUCCESS_TEXT = 'Ledger deleted successfully';
export const SAVE_LEDGER_SUCCESS_TEXT = 'Ledger saved successfully';
export const SEND_LEDGER_SUCCESS_TEXT = 'Ledger sent successfully';

type LedgerToolboxProps = {
  ledger: Ledger;
  shouldDisableToolbox: boolean;
  submissionUuid: string;
  handleAction: (props: {
    action: () => Promise<MutationResponse>;
    successMessage: string;
    afterFetchAction?: () => void;
    shouldRefetchLedgers?: boolean;
  }) => Promise<void>;
  editIsEnabled: boolean;
  handleSendLedger: (
    successMessage: string,
    closeConfirmationModal: () => void
  ) => Promise<void>;
  setIsOverrideValidationModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  refetchLedgers: () => void;
  hasCloningError?: Error;
  handleLedgerClone: (submissionUuid: string) => void;
  isCloningLoading: boolean;
};

export const LedgerToolbox = ({
  handleAction,
  ledger,
  submissionUuid,
  editIsEnabled,
  handleSendLedger,
  shouldDisableToolbox,
  setIsOverrideValidationModalOpen,
  refetchLedgers,
  hasCloningError,
  handleLedgerClone,
  isCloningLoading,
}: LedgerToolboxProps): JSX.Element => {
  const { areAnyExceptionRequestsOpen } = useExceptionRequestContext();

  const {
    ledgerToUpdate,
    isProgramExplanationInvalid,
    saveLedger,
    isPatchLedgerLoading,
    errorFromPatchLedger,
  } = useLedgerContext();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [sendLedgerModalIsOpen, setSendLedgerModalIsOpen] = useState(false);

  const [isCalculatorLinkCopied, setIsCalculatorLinkCopied] = useState(false);

  const [deleteLedger, { error: hasDeleteError, loading: isDeleteLoading }] =
    useDeleteLedger();

  const [
    sendToCommittee,
    { error: hasSendToCommitteeError, loading: isSendToCommitteeLoading },
  ] = useSendToCreditCommittee();

  const { isSaveDisabled } = useLedgerContext();

  const handleDelete = async (): Promise<void> => {
    await handleAction({
      action: () => deleteLedger({ submissionUuid, ledgerId: ledger.id }),
      successMessage: DELETE_SUCCESS_TEXT,
      shouldRefetchLedgers: true,
    });
    setDeleteModalOpen(false);
  };

  const handleSendToCommittee = async (): Promise<void> => {
    const { success } = await saveLedger();

    success &&
      (await handleAction({
        action: () => sendToCommittee({ submissionUuid, ledgerId: ledger.id }),
        successMessage: SEND_TO_COMMITTEE_SUCCESS_TEXT,
      }));
  };

  const handleSaveLedger = async (): Promise<void> => {
    await handleAction({
      action: saveLedger,
      successMessage: SAVE_LEDGER_SUCCESS_TEXT,
    });
  };

  const handleConfirmationSendLedger = async (): Promise<void> => {
    await handleSendLedger(SEND_LEDGER_SUCCESS_TEXT, () =>
      setSendLedgerModalIsOpen(false)
    );
  };

  const { run: queueResetIsCalculatorLinkCopied } = useDebounceFn(
    () => {
      setIsCalculatorLinkCopied(false);
    },
    { wait: 2000 }
  );

  const handleCopyCalculatorLink = async (): Promise<void> => {
    if (!ledger.pricingCalculatorLink) return;
    await navigator.clipboard.writeText(ledger.pricingCalculatorLink);
    setIsCalculatorLinkCopied(true);
    queueResetIsCalculatorLinkCopied();
  };

  useEffect(() => {
    if (
      errorFromPatchLedger &&
      errorFromPatchLedger.message === '422: Validation override required'
    ) {
      refetchLedgers();

      setIsOverrideValidationModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFromPatchLedger, setIsOverrideValidationModalOpen]);

  const shouldDisableSaveButton =
    isSaveDisabled ||
    (!editIsEnabled && isEqual(ledgerToUpdate, ledger)) ||
    ledgerToUpdate.program === 0 ||
    ledger.lock ||
    (ledgerToUpdate.overrideBuyRates &&
      ledgerToUpdate.overrideBuyRatesReason.trim().length === 0);

  const shouldDisableSendButton =
    isSaveDisabled ||
    isPatchLedgerLoading ||
    ledger.lock ||
    isProgramExplanationInvalid;

  const shouldDisableCopyCalculatorButton =
    !ledger.lock || !ledger.pricingCalculatorLink;

  const patchLedgerErrorBanner = (): JSX.Element | null => {
    if (!errorFromPatchLedger || sendLedgerModalIsOpen) {
      return null;
    }

    const { message } = errorFromPatchLedger;

    if (message.startsWith('422:')) {
      return message.includes('Validation override required') ? (
        <></>
      ) : (
        <Banner dismissable>{message.split(': ')[1]}</Banner>
      );
    }

    return <Banner dismissable>{message}</Banner>;
  };

  const shouldDisableDecisionCommittee =
    isSendToCommitteeLoading ||
    ledger.lock ||
    shouldDisableToolbox ||
    isSaveDisabled ||
    ledgerToUpdate.program === 0 ||
    (ledgerToUpdate.overrideBuyRates &&
      ledgerToUpdate.overrideBuyRatesReason.trim().length === 0);

  return (
    <>
      {hasCloningError && (
        <Banner dismissable>{hasCloningError.message}</Banner>
      )}

      {hasDeleteError && <Banner dismissable>{hasDeleteError.message}</Banner>}

      {hasSendToCommitteeError && (
        <Banner dismissable>{hasSendToCommitteeError.message}</Banner>
      )}

      {patchLedgerErrorBanner()}

      <Flex justifyContent="space-between">
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          mx={2}
          justifySelf={'flex-start'}
          gap={3}
        >
          {ledger.approvedDate && (
            <Box>
              <Text bold>
                Saved and Sent On {formatDateTimeString(ledger.approvedDate)}
              </Text>
            </Box>
          )}
          <Button startIcon={'refresh'} onClick={refetchLedgers}>
            Refresh Ledger
          </Button>
        </Flex>

        <Flex>
          {featureFlags.show_pricing_calculator_link_in_ledger && (
            <IconButton
              icon={isCalculatorLinkCopied ? ['fas', 'check'] : ['fas', 'link']}
              label={
                isCalculatorLinkCopied ? 'Link Copied!' : 'Copy Calculator Link'
              }
              onClick={handleCopyCalculatorLink}
              disabled={shouldDisableCopyCalculatorButton}
            />
          )}

          <IconButton
            icon={['far', 'save']}
            label="Save"
            onClick={handleSaveLedger}
            disabled={shouldDisableSaveButton || shouldDisableToolbox}
          />
          <Modal
            trigger={
              <IconButton
                icon={['far', 'paper-plane']}
                label="Send ledger"
                disabled={shouldDisableSendButton || shouldDisableToolbox}
              />
            }
            isOpen={sendLedgerModalIsOpen}
            onOpenChange={() => setSendLedgerModalIsOpen((prev) => !prev)}
            title="Confirm Ledger Lock"
            size="auto"
            body={
              <>
                <Box mb={3}>
                  <Text bold>
                    After saving, the ledger will be locked, are you sure you
                    want to proceed?
                  </Text>
                  {isPatchLedgerLoading && <Loading />}
                </Box>

                {errorFromPatchLedger && (
                  <Banner mb={3}>
                    <Box mr={2}>{errorFromPatchLedger?.message}</Box>
                  </Banner>
                )}

                <Button
                  onClick={handleConfirmationSendLedger}
                  variant={'primary'}
                  disabled={
                    isPatchLedgerLoading || ledgerToUpdate.program === 0
                  }
                >
                  Save & send ledger
                </Button>
              </>
            }
          />
          <IconButton
            icon="people-line"
            label="Send to Decision Committee"
            onClick={handleSendToCommittee}
            disabled={shouldDisableDecisionCommittee}
          />
          <IconButton
            icon={['far', 'clone']}
            label="Clone"
            disabled={
              isCloningLoading ||
              shouldDisableToolbox ||
              areAnyExceptionRequestsOpen
            }
            onClick={() => handleLedgerClone(submissionUuid)}
          />
          <Modal
            trigger={
              <IconButton
                icon={['far', 'trash-can']}
                label="Delete"
                variant="danger"
                disabled={ledger.lock || shouldDisableToolbox}
              />
            }
            isOpen={deleteModalOpen}
            onOpenChange={() => setDeleteModalOpen((prev) => !prev)}
            title={'Delete ledger'}
            size={'auto'}
            body={
              <>
                <Box mb={3}>
                  <Text bold>Are you sure you want to delete this ledger?</Text>
                  {isDeleteLoading && <Loading />}
                </Box>

                <Button
                  onClick={handleDelete}
                  variant={'danger'}
                  disabled={isDeleteLoading}
                >
                  Delete
                </Button>
              </>
            }
          />
        </Flex>
      </Flex>
    </>
  );
};
