import { useEffect, useState } from 'react';
import {
  Address,
  DatePicker,
  Divider,
  Flex,
  formatDateString,
  formatDateTimeString,
  formatPhoneNumber,
  IconButton,
  Link,
  PlainDate,
  Subheading,
  Table,
  Tag,
  Text,
  TextInput,
} from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';
import { WebPresence } from '../ApplicationSnapshot/applicationSnapshot.types';
import { addressToString } from '../../../helpers/addressUtils';
import {
  buildGoogleSearchUrl,
  howLongAgo,
  isValidHttpUrl,
} from '../ApplicationSnapshot/applicationSnapshotUtils';
import { WebPresenceList } from '../ApplicationSnapshot/WebPresenceList';
import { useAllBusinessPaynetReports } from '../BusinessPaynetPage/businessPaynetHooks';
import { Submission } from '../SubmissionUnderwritingContainer.types';
import {
  CustomerBusinessSummary,
  ExperianMetaData,
  UpdateCustomerBody,
} from './businessOverview.types';
import {
  useBulkUpdateWebPresence,
  useIndustryRisks,
  useUpdateCustomer,
} from './businessOverviewHooks';
import { WebPresenceEditModal } from './WebPresenceEditModal';
import { IndustryEditModal } from './IndustryEditModal';
import { NATURAL_DISASTER_AFFECTED_ZIP_CODES } from './businessSummaryTableConstants';

export interface BusinessSummaryProps {
  customer: CustomerBusinessSummary | undefined;
  webPresence: WebPresence | undefined;
  useOfFunds: string | null | undefined;
  experianMetaData: ExperianMetaData | undefined;
  submission: Submission;
}

const checkForInsufficientWebPresenceUrls = (
  webPresences: WebPresence
): boolean => {
  const links = Object.values(webPresences).map((value) => !!value);

  return links.filter(Boolean).length < 3;
};

export const BusinessSummaryTable = ({
  customer,
  webPresence,
  useOfFunds,
  experianMetaData,
  submission,
}: BusinessSummaryProps): JSX.Element => {
  const [currentWebPresence, setCurrentWebPresence] = useState(
    webPresence || {}
  );

  // Stores keystroke changes to the TextInput.
  const [legalName, setLegalName] = useState(customer?.legalName || '');
  // Used to restore previous value upon canceling editing.
  const [legalNamePrevious, setLegalNamePrevious] = useState(
    customer?.legalName || ''
  );
  const [legalNameError, setLegalNameError] = useState('');
  const [legalNameEditing, setLegalNameEditing] = useState(false);
  const [legalNameCanceled, setLegalNameCanceled] = useState(false);

  const [dba, setDba] = useState(customer?.dba || '');
  const [dbaPrevious, setDbaPrevious] = useState(customer?.dba || '');
  const [dbaError, setDbaError] = useState('');
  const [dbaEditing, setDbaEditing] = useState(false);
  const [dbaCanceled, setDbaCanceled] = useState(false);

  const [businessWebsitePrevious, setBusinessWebsitePrevious] = useState(
    webPresence?.businessWebsite || ''
  );
  const [businessWebsiteEditing, setBusinessWebsiteEditing] = useState(false);
  const [businessWebsiteError, setBusinessWebsiteError] = useState('');
  const [businessWebsiteCanceled, setBusinessWebsiteCanceled] = useState(false);

  const [isUpdatingWebPresence, setIsUpdatingWebPresence] = useState(false);
  const [hasInsufficientWebPresenceUrls, setHasInsufficientWebPresenceUrls] =
    useState(false);

  const [businessStartDate, setBusinessStartDate] = useState(
    customer?.businessStartDate || ''
  );
  const [prevBusinessStartDate, setPrevBusinessStartDate] = useState(
    customer?.businessStartDate || ''
  );
  const [businessStartDateError, setBusinessStartDateError] = useState('');
  const [businessStartDateEditing, setBusinessStartDateEditing] =
    useState(false);
  const [businessStartDateCanceled, setBusinessStartDateCanceled] =
    useState(false);

  // We have to convert the number to a string because the EditableSelectCell
  // only handles string values.
  const [industry, setIndustry] = useState(`${customer?.industryId}`);

  // Used to store the current industry name for display purposes.
  const [currentIndustry, setCurrentIndustry] = useState(customer?.industry);

  const {
    data: industries,
    loading: industriesLoading,
    error: industriesError,
  } = useIndustryRisks();

  // Note we are using the same hook more than once. This is to make sure the
  // updates to legal name, dba and industry are independent of each other,
  // along with the respective loading, error, and responseReady states.
  const [
    updateCustomer,
    {
      data: updatedCustomerData,
      responseReady: customerResponseReady,
      loading: customerLoading,
      error: customerError,
    },
  ] = useUpdateCustomer(customer?.uuid || '');

  const [
    updateCustomerDba,
    {
      data: updatedCustomerDbaData,
      responseReady: customerDbaResponseReady,
      loading: customerDbaLoading,
      error: customerDbaError,
    },
  ] = useUpdateCustomer(customer?.uuid || '');

  const [
    updateWebPresence,
    {
      data: updatedWebPresenceResponse,
      responseReady: updatedWebPresenceResponseReady,
      loading: updatedWebPresenceLoading,
      error: updatedWebPresenceError,
    },
  ] = useBulkUpdateWebPresence(submission.uuid);

  const [
    updateIndustry,
    {
      data: updatedIndustry,
      responseReady: industryUpdateResponseReady,
      error: industryUpdateError,
      loading: industryUpdateLoading,
    },
  ] = useUpdateCustomer(customer?.uuid || '');

  const [
    updateBusinessStartDate,
    {
      data: updatedCustomerBusinessStartDateData,
      responseReady: customerBusinessStartDateResponseReady,
      loading: CustomerBusinessStartDateLoading,
      error: CustomerBusinessStartDateError,
    },
  ] = useUpdateCustomer(customer?.uuid || '');

  // Legal Name
  useEffect(() => {
    // When the update is successful, update the local state associated with the
    // updated customer.
    if (!legalNameEditing && customerResponseReady && !customerError) {
      setLegalName(updatedCustomerData?.legalName || '');
      setLegalNamePrevious(updatedCustomerData?.legalName || '');
    } else if (
      // If there is an error and the user did not cancel editing, then show the
      // error.
      legalNameEditing &&
      customerResponseReady &&
      customerError &&
      !legalNameCanceled
    ) {
      setLegalNameError(customerError.message);
    }
  }, [
    updatedCustomerData,
    customerResponseReady,
    customerError,
    legalNameEditing,
    legalNameCanceled,
  ]);

  // DBA
  useEffect(() => {
    if (!dbaEditing && customerDbaResponseReady && !customerDbaError) {
      setDba(updatedCustomerDbaData?.dba || '');
      setDbaPrevious(updatedCustomerDbaData?.dba || '');
    } else if (
      dbaEditing &&
      customerDbaResponseReady &&
      customerDbaError &&
      !dbaCanceled
    ) {
      setDbaError(customerDbaError.message);
    }
  }, [
    updatedCustomerDbaData,
    customerDbaResponseReady,
    customerDbaError,
    dbaEditing,
    dbaCanceled,
  ]);

  // Update web presences with the response from the server after update.
  useEffect(() => {
    if (!isUpdatingWebPresence) {
      return;
    }

    if (
      updatedWebPresenceResponse &&
      updatedWebPresenceResponseReady &&
      !updatedWebPresenceLoading &&
      !updatedWebPresenceError
    ) {
      setCurrentWebPresence(updatedWebPresenceResponse);
      setIsUpdatingWebPresence(false);
      setBusinessWebsitePrevious(
        updatedWebPresenceResponse.businessWebsite || ''
      );
    }
  }, [
    updatedWebPresenceResponseReady,
    updatedWebPresenceResponse,
    updatedWebPresenceError,
    isUpdatingWebPresence,
    updatedWebPresenceLoading,
  ]);

  // Update based on the number
  // of web presences. This will get triggered when the web presences are updated
  // or when the webPresence prop is initially loaded.
  useEffect(() => {
    if (updatedWebPresenceResponse) {
      setHasInsufficientWebPresenceUrls(
        checkForInsufficientWebPresenceUrls(updatedWebPresenceResponse)
      );
    } else if (webPresence) {
      setHasInsufficientWebPresenceUrls(
        checkForInsufficientWebPresenceUrls(webPresence)
      );
    }
  }, [updatedWebPresenceResponse, webPresence]);

  // Show update errors for business website.
  useEffect(() => {
    if (
      businessWebsiteEditing &&
      updatedWebPresenceError &&
      !businessWebsiteCanceled
    ) {
      setBusinessWebsiteError(updatedWebPresenceError.message);
    }
  }, [
    updatedWebPresenceError,
    businessWebsiteEditing,
    businessWebsiteCanceled,
  ]);

  const saveBusinessWebsite = async (): Promise<void> => {
    setIsUpdatingWebPresence(true);
    setBusinessWebsiteCanceled(false);

    const { success } = await updateWebPresence({
      ...currentWebPresence,
    });

    if (success) {
      setBusinessWebsiteEditing(false);
    }
  };

  // Industry
  // Note that industry has a more lightweight update process, because we
  // are using the EditableSelectCell component, which handles more for us.
  useEffect(() => {
    if (industryUpdateResponseReady && !industryUpdateError) {
      setIndustry(`${updatedIndustry?.industryId}` || '');
      setCurrentIndustry(updatedIndustry?.industry || '');
    }
  }, [updatedIndustry, industryUpdateResponseReady, industryUpdateError]);

  // Business Start Date
  useEffect(() => {
    if (
      !businessStartDateEditing &&
      customerBusinessStartDateResponseReady &&
      !CustomerBusinessStartDateError
    ) {
      setBusinessStartDate(
        updatedCustomerBusinessStartDateData?.businessStartDate || ''
      );
      setPrevBusinessStartDate(
        updatedCustomerBusinessStartDateData?.businessStartDate || ''
      );
    } else if (
      businessStartDateEditing &&
      customerBusinessStartDateResponseReady &&
      CustomerBusinessStartDateError &&
      !businessStartDateCanceled
    ) {
      setBusinessStartDateError(CustomerBusinessStartDateError.message);
    }
  }, [
    updatedCustomerBusinessStartDateData,
    customerBusinessStartDateResponseReady,
    CustomerBusinessStartDateError,
    businessStartDateEditing,
    businessStartDateCanceled,
  ]);

  const saveLegalName = async (body: UpdateCustomerBody): Promise<void> => {
    setLegalNameCanceled(false);
    const { success } = await updateCustomer(body);

    if (success) {
      setLegalNameEditing(false);
    }
  };

  const saveDbaName = async (body: UpdateCustomerBody): Promise<void> => {
    setDbaCanceled(false);
    const { success } = await updateCustomerDba(body);

    if (success) {
      setDbaEditing(false);
    }
  };

  const saveBusinessStartDate = async (
    body: UpdateCustomerBody
  ): Promise<void> => {
    setBusinessStartDateCanceled(false);
    const { success } = await updateBusinessStartDate(body);

    if (success) {
      setBusinessStartDateEditing(false);
    }
  };

  const editLegalName = (): void => {
    setLegalNameError('');
    setLegalNameEditing(true);
  };

  const editDbaName = (): void => {
    setDbaError('');
    setDbaEditing(true);
  };

  const editBusinessWebsite = (): void => {
    setBusinessWebsiteError('');
    setBusinessWebsiteEditing(true);
  };

  const editBusinessStartDate = (): void => {
    setBusinessStartDateError('');
    setBusinessStartDateEditing(true);
  };

  const cancelLegalNameEditing = (): void => {
    setLegalNameEditing(false);
    setLegalNameCanceled(true);
    setLegalName(legalNamePrevious);
    setLegalNameError('');
  };

  const cancelDbaEditing = (): void => {
    setDbaEditing(false);
    setDbaCanceled(true);
    setDba(dbaPrevious);
    setDbaError('');
  };

  const cancelBusinessWebsiteEditing = (): void => {
    setBusinessWebsiteEditing(false);
    setBusinessWebsiteCanceled(true);
    setCurrentWebPresence({
      ...currentWebPresence,
      businessWebsite: businessWebsitePrevious,
    });

    setBusinessWebsiteError('');
  };

  const cancelBusinessStartDateEditing = (): void => {
    setBusinessStartDateEditing(false);
    setBusinessStartDateCanceled(true);
    setBusinessStartDate(prevBusinessStartDate);
    setBusinessStartDateError('');
  };

  const industryOptions =
    (industries &&
      industries?.map((industryElem) => ({
        text: industryElem.name,
        // OptionShape Type requires string
        value: `${industryElem.id}`,
      }))) ||
    [];

  const showIndustryRow =
    industryOptions && industries && !industriesLoading && !industriesError;

  const { data: businessPaynetData } = useAllBusinessPaynetReports(
    submission.uuid
  );

  const primaryPaynetReport = businessPaynetData?.find(
    (report) => report.primary
  );

  const getOneYearAgoPlainDate = (): PlainDate => {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    return new PlainDate(
      oneYearAgo.getFullYear(),
      oneYearAgo.getMonth() + 1,
      oneYearAgo.getDate()
    );
  };

  const isClickToEditEnabled = !(
    submission.stageName === 'Digital Closing' &&
    submission.subStage === 'In Progress'
  );

  return (
    <>
      <Flex justifyContent="left" alignItems="center" gap={2}>
        <Subheading variant="section">Business Summary</Subheading>
        {featureFlags.show_new_natural_disaster_tag &&
          customer &&
          customer.fullAddress &&
          NATURAL_DISASTER_AFFECTED_ZIP_CODES.includes(
            customer.fullAddress.zip
          ) && (
            <Tag bgColor="danger">Potentially Impacted by Natural Disaster</Tag>
          )}
      </Flex>
      {customer ? (
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.RowHeader>Legal Name</Table.RowHeader>
              <Table.Cell>
                <Flex alignItems="center">
                  {legalNameEditing ? (
                    <Flex alignItems="center" flexBasis="100%">
                      <TextInput
                        type="text"
                        label="Legal Name"
                        hiddenLabel
                        name="legalName"
                        value={legalName}
                        onValueChange={setLegalName}
                        errorMessage={legalNameError}
                      />
                      <IconButton
                        icon={customerLoading ? 'spinner' : 'check'}
                        hiddenLabel
                        label="Save Legal Name"
                        onClick={() => saveLegalName({ legalName })}
                        disabled={customerLoading}
                      />
                      <IconButton
                        icon="x"
                        hiddenLabel
                        label="Cancel"
                        onClick={cancelLegalNameEditing}
                        disabled={customerLoading}
                      />
                    </Flex>
                  ) : (
                    <>
                      <Text>{legalName}</Text>
                      {isClickToEditEnabled && (
                        <IconButton
                          icon="pencil-alt"
                          hiddenLabel
                          label="Edit Legal Name"
                          onClick={editLegalName}
                        />
                      )}

                      <Divider orientation="vertical" />

                      <Link
                        newTab
                        href={buildGoogleSearchUrl(
                          customer.legalName,
                          customer.fullAddress?.city,
                          customer.fullAddress?.state
                        )}
                      >
                        Google
                      </Link>
                    </>
                  )}
                </Flex>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>DBA</Table.RowHeader>
              <Table.Cell>
                <Flex alignItems="center">
                  {dbaEditing ? (
                    <Flex alignItems="center" flexBasis="100%">
                      <TextInput
                        label="DBA"
                        hiddenLabel
                        name="dba"
                        value={dba}
                        onValueChange={setDba}
                        errorMessage={dbaError}
                      />
                      <IconButton
                        icon={customerDbaLoading ? 'spinner' : 'check'}
                        hiddenLabel
                        label="Save DBA"
                        onClick={() => saveDbaName({ dba })}
                      />
                      <IconButton
                        icon="x"
                        hiddenLabel
                        label="Cancel"
                        onClick={cancelDbaEditing}
                        disabled={customerDbaLoading}
                      />
                    </Flex>
                  ) : (
                    <>
                      <Text>{dba}</Text>
                      {isClickToEditEnabled && (
                        <IconButton
                          icon="pencil-alt"
                          hiddenLabel
                          label="Edit DBA"
                          onClick={editDbaName}
                        />
                      )}

                      <Divider orientation="vertical" />

                      <Link
                        newTab
                        href={buildGoogleSearchUrl(
                          customer.dba,
                          customer.fullAddress?.city,
                          customer.fullAddress?.state
                        )}
                      >
                        Google
                      </Link>
                    </>
                  )}
                </Flex>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Paynet Report</Table.RowHeader>
              <Table.Cell
                backgroundColor={businessPaynetData ? undefined : 'red-200'}
              >
                {primaryPaynetReport ? (
                  <Link
                    newTab
                    href={
                      new URL(
                        `/dashboard/submission/${submission.uuid}/business-paynet`,
                        window.location.origin
                      )
                    }
                  >
                    {formatDateString(primaryPaynetReport.createdAt)}
                  </Link>
                ) : (
                  'No'
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Experian Commercial Report</Table.RowHeader>
              <Table.Cell
                backgroundColor={
                  experianMetaData?.fetchedAt ? undefined : 'red-200'
                }
              >
                {experianMetaData?.fetchedAt ? (
                  <Link
                    newTab
                    href={
                      new URL(
                        `/business-credit/${submission.uuid}?uuidType=submission`,
                        window.location.origin
                      )
                    }
                  >
                    {experianMetaData?.fetchedAt &&
                      formatDateTimeString(experianMetaData?.fetchedAt)}
                  </Link>
                ) : (
                  'Not Pulled'
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>FEIN</Table.RowHeader>
              <Table.Cell>{customer.fein}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Business Address</Table.RowHeader>
              <Table.Cell>
                {customer.fullAddress && (
                  <Flex alignItems={'center'}>
                    <Link
                      newTab
                      href={
                        new URL(
                          `/maps/place/${addressToString(
                            customer.fullAddress
                          ).replace(/\s/g, '+')}`,
                          'https://www.google.com'
                        )
                      }
                    >
                      <Address
                        streetAddress={[
                          customer.fullAddress.street1,
                          customer.fullAddress.street2,
                        ]}
                        city={customer.fullAddress.city}
                        state={customer.fullAddress.state}
                        zipCode={customer.fullAddress.zip}
                        isSingleLine
                      />
                    </Link>
                    <Divider orientation="vertical" />

                    <Link
                      newTab
                      href={buildGoogleSearchUrl(
                        addressToString(customer.fullAddress)
                      )}
                    >
                      Google
                    </Link>
                  </Flex>
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Business Phone</Table.RowHeader>
              <Table.Cell>
                <Flex alignItems={'center'}>
                  {customer.businessPhone && (
                    <>
                      {formatPhoneNumber(customer.businessPhone)}
                      <Divider orientation="vertical" />
                      <Link
                        newTab
                        href={buildGoogleSearchUrl(
                          formatPhoneNumber(customer.businessPhone)
                        )}
                      >
                        Google
                      </Link>
                    </>
                  )}
                </Flex>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Website</Table.RowHeader>
              <Table.Cell>
                <Flex alignItems="center">
                  {businessWebsiteEditing ? (
                    <Flex alignItems="center" flexBasis="100%">
                      <TextInput
                        label="Website"
                        hiddenLabel
                        name="businessWebsite"
                        value={currentWebPresence.businessWebsite}
                        onValueChange={(newValue) => {
                          setCurrentWebPresence({
                            ...currentWebPresence,
                            businessWebsite: newValue,
                          });
                        }}
                        errorMessage={businessWebsiteError}
                      />
                      <IconButton
                        icon={updatedWebPresenceLoading ? 'spinner' : 'check'}
                        hiddenLabel
                        label="Save Website"
                        onClick={saveBusinessWebsite}
                      />
                      <IconButton
                        icon="x"
                        hiddenLabel
                        label="Cancel"
                        onClick={cancelBusinessWebsiteEditing}
                        disabled={updatedWebPresenceLoading}
                      />
                    </Flex>
                  ) : (
                    <>
                      {isValidHttpUrl(
                        currentWebPresence.businessWebsite || ''
                      ) ? (
                        <Link
                          newTab
                          href={
                            new URL(currentWebPresence.businessWebsite || '')
                          }
                        >
                          {currentWebPresence.businessWebsite}
                        </Link>
                      ) : (
                        <Text>
                          Invalid URL: {currentWebPresence.businessWebsite}
                        </Text>
                      )}

                      {isClickToEditEnabled && (
                        <IconButton
                          icon="pencil-alt"
                          hiddenLabel
                          label="Edit Website"
                          onClick={editBusinessWebsite}
                        />
                      )}
                    </>
                  )}
                </Flex>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>WHOIS Link</Table.RowHeader>
              <Table.Cell>
                {currentWebPresence.businessWebsite && (
                  <Link
                    newTab
                    href={
                      new URL(
                        `/whois/${currentWebPresence.businessWebsite}`,
                        'https://www.whois.com'
                      )
                    }
                  >
                    https://www.whois.com/whois/
                    {currentWebPresence.businessWebsite}
                  </Link>
                )}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Web Presences</Table.RowHeader>
              <Table.Cell
                backgroundColor={
                  hasInsufficientWebPresenceUrls ? 'red-200' : undefined
                }
                aria-label={
                  hasInsufficientWebPresenceUrls
                    ? 'Insufficient URL Warning'
                    : undefined
                }
              >
                <WebPresenceList webPresence={currentWebPresence} />
                {isClickToEditEnabled && (
                  <WebPresenceEditModal
                    webPresence={currentWebPresence}
                    updateWebPresence={updateWebPresence}
                    updatedWebPresenceError={updatedWebPresenceError}
                    updatedWebPresenceLoading={updatedWebPresenceLoading}
                    setWebPresence={setCurrentWebPresence}
                  />
                )}
              </Table.Cell>
            </Table.Row>

            {showIndustryRow && (
              <Table.Row>
                <Table.RowHeader>Industry</Table.RowHeader>
                <Table.Cell>
                  <Flex gap={2} alignItems={'center'}>
                    {currentIndustry}
                    {isClickToEditEnabled && (
                      <IndustryEditModal
                        industryId={industry}
                        options={industryOptions}
                        setIndustry={setIndustry}
                        submissionUuid={submission.uuid}
                        updatedIndustryError={industryUpdateError}
                        updatedIndustryLoading={industryUpdateLoading}
                        updateIndustry={updateIndustry}
                      />
                    )}
                  </Flex>
                </Table.Cell>
              </Table.Row>
            )}

            <Table.Row>
              <Table.RowHeader>Business Start Date</Table.RowHeader>
              <Table.Cell>
                {businessStartDateEditing ? (
                  <Flex alignItems="center" flexBasis="100%" gap={2}>
                    <DatePicker
                      label="Business Start Date"
                      onChange={(date: PlainDate | undefined) =>
                        date &&
                        setBusinessStartDate(
                          `${date.year}-${date.month}-${date.day}`
                        )
                      }
                      placeholder="MM/DD/YYYY"
                      hiddenLabel
                      minDate={new PlainDate(1800, 1, 1)}
                      maxDate={getOneYearAgoPlainDate()}
                      errorMessage={businessStartDateError}
                    />

                    <IconButton
                      icon={
                        CustomerBusinessStartDateLoading ? 'spinner' : 'check'
                      }
                      hiddenLabel
                      label="Save Business Start Date"
                      onClick={() =>
                        saveBusinessStartDate({ businessStartDate })
                      }
                      disabled={CustomerBusinessStartDateLoading}
                    />

                    <IconButton
                      icon="x"
                      hiddenLabel
                      label="Cancel"
                      onClick={cancelBusinessStartDateEditing}
                      disabled={CustomerBusinessStartDateLoading}
                    />
                  </Flex>
                ) : (
                  <Flex alignItems={'center'} flexBasis="100%" gap={2}>
                    {businessStartDate && formatDateString(businessStartDate)}
                    {isClickToEditEnabled && (
                      <IconButton
                        icon="pencil-alt"
                        hiddenLabel
                        label="Edit Business Start Date"
                        onClick={editBusinessStartDate}
                      />
                    )}
                  </Flex>
                )}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Time in Business</Table.RowHeader>
              <Table.Cell>
                {businessStartDate && howLongAgo(businessStartDate)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Use of Funds</Table.RowHeader>
              <Table.Cell>{useOfFunds}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Restricted</Table.RowHeader>
              <Table.Cell>{String(customer.restricted)} </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        <Text>No Customer Data</Text>
      )}
    </>
  );
};
