import * as React from 'react';
import { Sheet } from '@forward-financing/fast-forward';

import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';
import { useState } from 'react';
import { LedgerOffer } from './offers.types';
import { formatLedgerOfferField, isLedgerOfferKey } from './offers.utils';

type OfferCellProps = {
  fieldName: string;
  handleBlur?: (
    months: number,
    fieldName: keyof LedgerOffer,
    value: string,
    valid: boolean
  ) => void;
  isEditable?: boolean;
  isValid: boolean;
  key: number;
  months: number;
  setValue: (value: string | ((prev: string) => string)) => void;
  value: string;
  isHighlighted: boolean;
  handleFocus: () => void;
};

/**
 * Renders a cell in the offers table with optional editing capabilities.
 */
export const OffersTableCell = ({
  fieldName,
  handleBlur,
  isEditable,
  isValid,
  key,
  months,
  setValue,
  value,
  isHighlighted,
  handleFocus,
}: OfferCellProps): JSX.Element | null => {
  const [isFocused, setIsFocused] = useState(false);

  if (!isLedgerOfferKey(fieldName)) return null;

  const onFocusHandler = (): void => {
    // Remove any occurrences of %, $, or , from the previous value
    setValue((prev) => prev.replace(/%|\$|,/g, ''));
    handleFocus();
    setIsFocused(true);
  };

  const onBlurHandler = (): void => {
    if (isValid) {
      setValue((prev) =>
        formatLedgerOfferField({ [fieldName]: prev }, fieldName)
      );
    }
    handleBlur?.(months, fieldName, value?.replace(/%|\$|,/g, ''), isValid);
    setIsFocused(false);
  };

  const onKeyDownHandler: React.KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      onBlurHandler();
    }
  };

  let backgroundColor: ColorVariant | undefined;
  if (isHighlighted && !isFocused) {
    backgroundColor = 'green-200';
  }
  if (!isValid && isEditable) {
    backgroundColor = 'red-100';
  }

  return isEditable ? (
    <Sheet.TextCell
      backgroundColor={backgroundColor}
      key={`${key}-${months}`}
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
      onKeyDown={onKeyDownHandler}
      onValueChange={(val) => setValue(val)}
      value={String(value)}
    />
  ) : (
    <Sheet.TextCell
      backgroundColor={backgroundColor}
      key={`${key}-${months}`}
      value={value}
    />
  );
};
