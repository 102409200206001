import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  Loading,
  Modal,
  Subheading,
  Text,
  TextArea,
  Tooltip,
} from '@forward-financing/fast-forward';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { isAfterUnderwriting } from 'helpers/utils';
import { Submission } from '../DealScoringContainer.types';
import {
  useRefreshCompositeScore,
  useScoringComposite,
} from '../DealScoringFetchHooks';
import { StipulationManager } from '../../StipulationManager/StipulationManager';
import { useExceptionRequestContext } from '../../SubmissionUnderwriting/ExceptionRequest/Context/ExceptionRequestContext';
import { RevenueSummaryTable } from './RevenueSummaryTable';
import {
  LedgerToolbox,
  SAVE_LEDGER_SUCCESS_TEXT,
  SEND_LEDGER_SUCCESS_TEXT,
} from './LedgerToolbox';
import { OffersContainer } from './Offers/OffersContainer';
import { BuyRatesContainer } from './BuyRates/BuyRatesContainer';
import {
  useCloneLedger,
  useGetOverriddenValidations,
  useGetUser,
  useLedgerOffers,
  useValidationOverride,
} from './ledgerHooks';
import { useLedgerContext } from './LedgerContext/LedgerContext';
import { OverrideValidationCard } from './OverrideValidation/OverrideValidationCard';
import { LedgerCompositeScore } from './LedgerCompositeScore';

export type LedgerContainerProps = {
  currentSubmissionUuid: string;
  submission: Submission;
  refetchLedgers: () => void;
  isRenewal?: boolean;
  uwNotes?: string;
};

const CLONE_SUCCESS_TEXT = 'Ledger cloned successfully';

export const LedgerContainer = ({
  currentSubmissionUuid,
  submission,
  refetchLedgers,
  isRenewal,
  uwNotes,
}: LedgerContainerProps): JSX.Element => {
  const buyRateRef = useRef(null);
  const offerRef = useRef(null);

  const handleScrollBuyRate = (event: React.UIEvent<HTMLDivElement>): void => {
    if (offerRef.current) {
      // we are disabling ts and eslint since they throw error on scrollLeft as if it doesn't exist.
      // even if you try to use if checks still going to face the same issue.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      offerRef.current.scrollLeft = event.currentTarget.scrollLeft;
    }
  };

  const handleScrollOffer = (event: React.UIEvent<HTMLDivElement>): void => {
    if (buyRateRef.current) {
      // we are disabling ts and eslint since they throw error on scrollLeft as if it doesn't exist.
      // even if you try to use if checks still going to face the same issue.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      buyRateRef.current.scrollLeft = event.currentTarget.scrollLeft;
    }
  };

  const { isLedgerOpen, refetchExceptionRequests, setIsLedgerOpen } =
    useExceptionRequestContext();

  const {
    ledgerToUpdate,
    handleChangeLedger,
    isProgramExplanationInvalid,
    isProgramExplanationRequired,
    ledger,
    saveLedger,
  } = useLedgerContext();

  const ledgerRef = React.useRef(ledgerToUpdate);

  const [successText, setSuccessText] = useState<string | undefined>('');
  const [isOverrideValidationModalOpen, setIsOverrideValidationModalOpen] =
    useState(false);
  const [overrideNotes, setOverrideNotes] = useState('');

  const {
    data: compositeScore,
    loading: compositeScoreLoading,
    error: compositeScoreError,
  } = useScoringComposite(submission.uuid);

  const [cloneLedger, { error: hasCloningError, loading: isCloningLoading }] =
    useCloneLedger();

  const {
    data: overriddenValidations,
    loading: overriddenValidationsLoading,
    error: overriddenValidationsError,
    refetch: overriddenValidationsRefetch,
  } = useGetOverriddenValidations(submission.uuid);

  const [
    overrideValidations,
    { loading: overrideValidationsLoading, error: overrideValidationsError },
  ] = useValidationOverride();

  const {
    data: offersData,
    error: offersError,
    loading: offersLoading,
    refetch: refetchOffers,
  } = useLedgerOffers({
    submissionUuid: submission.uuid,
    ledgerId: ledger.id,
  });

  const handleAction = async ({
    action,
    successMessage,
    afterFetchAction,
    shouldRefetchLedgers,
  }: {
    action: () => Promise<MutationResponse>;
    successMessage: string;
    afterFetchAction?: () => void;
    shouldRefetchLedgers?: boolean;
  }): Promise<void> => {
    setSuccessText(undefined);

    const { success } = await action();

    if (success) {
      setSuccessText(successMessage);

      shouldRefetchLedgers && refetchLedgers?.();

      afterFetchAction?.();

      [SAVE_LEDGER_SUCCESS_TEXT, SEND_LEDGER_SUCCESS_TEXT].includes(
        successMessage
      ) && refetchOffers();
    }
  };

  const handleSendLedger = async (
    successMessage: string,
    closeConfirmationModal: () => void
  ): Promise<void> => {
    setSuccessText(undefined);

    const { success } = await saveLedger(true);

    if (success) {
      overriddenValidationsRefetch();
      closeConfirmationModal();
      setSuccessText(successMessage);

      if (isLedgerOpen) {
        refetchExceptionRequests();
        setIsLedgerOpen(false);
      }
    }
  };

  const handleOverrideReason = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void =>
    handleChangeLedger({
      overrideBuyRatesReason: event.target.value,
    });

  const editIsEnabled = useMemo(
    () => !!ledgerToUpdate.overrideBuyRates && ledgerToUpdate.program !== 0,
    [ledgerToUpdate]
  );

  const handleOverrideValidations = async (): Promise<void> => {
    if (!submission) {
      return;
    }

    const { success } = await overrideValidations({
      submissionUuid: submission.uuid,
      updateBody: {
        note: overrideNotes,
        stage: submission.stage,
        missing_attributes: ledger.validationErrorMessage || [],
      },
    });

    if (success) {
      setIsOverrideValidationModalOpen(false);
      overriddenValidationsRefetch();
    }
  };

  const latestOverriddenValidation =
    overriddenValidations?.[overriddenValidations.length - 1];

  const { data: underwriter } = useGetUser(
    latestOverriddenValidation?.createdById
  );

  const hasChangeInLedgerToUpdate = ledgerRef.current !== ledgerToUpdate;

  const shouldShowPersistedBuyRates =
    !submission.isNewDealCategory && !hasChangeInLedgerToUpdate;

  const [refreshScore, { data: refreshScoreData, error: refreshScoreError }] =
    useRefreshCompositeScore(submission.uuid);

  const isRenewalSubmission = submission.type === 'Renewal';
  const isInProcessingStage = submission?.stage === 'Processing';
  const isCurrentSubmission = submission?.uuid === currentSubmissionUuid;
  const shouldDisableToolbox =
    (submission && isAfterUnderwriting(submission.stage)) ||
    isInProcessingStage ||
    !isCurrentSubmission;
  const overrideValidationText = (): string =>
    ledger.validationErrorMessage && ledger.validationErrorMessage.length > 1
      ? 'Override validations'
      : 'Override validation';

  const refetchLedgersComplete = async (): Promise<void> => {
    refetchLedgers();
    await refreshScore();
  };

  const handleLedgerClone = async (submissionUuid: string): Promise<void> => {
    await handleAction({
      action: () => cloneLedger({ submissionUuid, ledgerId: ledger.id }),
      successMessage: CLONE_SUCCESS_TEXT,
      shouldRefetchLedgers: true,
    });
  };
  if (isCloningLoading) {
    return <Loading />;
  }

  return (
    <Flex flexDirection={'column'} gap={3}>
      {successText && (
        <Box m={3}>
          <Banner variant="success" autoDismissTime={5000} dismissable={'auto'}>
            {successText}
          </Banner>
        </Box>
      )}

      {compositeScoreError && <Banner>{compositeScoreError.message}</Banner>}
      {refreshScoreError && <Banner>{refreshScoreError.message}</Banner>}
      {overriddenValidationsError && (
        <Banner>{overriddenValidationsError.message}</Banner>
      )}

      <Modal
        trigger={<></>}
        isOpen={isOverrideValidationModalOpen}
        onOpenChange={() => {
          setIsOverrideValidationModalOpen((prev) => !prev);
        }}
        title={`${overrideValidationText()}?`}
        body={
          overriddenValidationsLoading ? (
            <Loading />
          ) : (
            <Flex gap={2} flexDirection="column" alignItems="flex-start">
              {overrideValidationsError && (
                <Banner>{overrideValidationsError.message}</Banner>
              )}

              <Icon name={'circle-exclamation'} color="danger" size="lg" />

              <Flex gap={1} flexDirection="column" alignItems="flex-start">
                <Text>
                  This submission cannot be sent until it has the following
                  data:
                </Text>

                {ledger.validationErrorMessage &&
                  ledger.validationErrorMessage.map((item) => (
                    <Text key={item}>- {item}</Text>
                  ))}
              </Flex>

              <TextArea
                label="Note"
                onChange={(e) => {
                  setOverrideNotes(e.target.value);
                }}
              />

              <Flex gap={2}>
                {overrideValidationsLoading ? (
                  <Loading />
                ) : (
                  <Button
                    disabled={!overrideNotes}
                    onClick={handleOverrideValidations}
                  >
                    {overrideValidationText()}
                  </Button>
                )}
                <Button
                  onClick={() => {
                    setIsOverrideValidationModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </Flex>
            </Flex>
          )
        }
      />

      <LedgerToolbox
        ledger={ledger}
        submissionUuid={submission.uuid}
        shouldDisableToolbox={shouldDisableToolbox}
        handleAction={handleAction}
        editIsEnabled={editIsEnabled}
        handleSendLedger={handleSendLedger}
        setIsOverrideValidationModalOpen={setIsOverrideValidationModalOpen}
        refetchLedgers={refetchLedgersComplete}
        hasCloningError={hasCloningError}
        handleLedgerClone={handleLedgerClone}
        isCloningLoading={isCloningLoading}
      />

      <Grid gutter>
        <Grid.Item xl={12}>
          <OverrideValidationCard
            latestOverriddenValidation={latestOverriddenValidation}
            underwriter={underwriter}
          />

          <LedgerCompositeScore
            compositeScoreLoading={compositeScoreLoading}
            compositeScore={compositeScore}
            refreshedComppositoScore={refreshScoreData}
          />
        </Grid.Item>
      </Grid>

      <Flex flexDirection={'column'}>
        <Subheading>Revenue Summary</Subheading>

        <Box>
          <RevenueSummaryTable
            ledger={ledgerToUpdate}
            amountRequested={submission?.amountRequested}
            isoUuid={submission?.isoUuid}
            onChange={handleChangeLedger}
            readOnly={ledger.lock}
            isNewSaleDealCategory={submission.isNewDealCategory}
          />
          <Divider orientation="horizontal" />
          <Grid gutter>
            <Grid.Item xl={4}>
              <Box backgroundColor="gray-100" p={2} rounded>
                <Grid gutter>
                  <Grid.Item xl={3}>
                    <Text bold>External ISO Notes</Text>
                  </Grid.Item>
                  <Grid.Item xl={9}>
                    <Text>
                      <TextArea
                        label="External ISO Notes"
                        hiddenLabel
                        rows={4}
                        name="externalISONotes"
                        value={ledgerToUpdate.uwNotes}
                        readOnly={ledger.lock}
                        onChange={(event) => {
                          handleChangeLedger({ uwNotes: event.target.value });
                        }}
                      />
                    </Text>
                  </Grid.Item>
                </Grid>
              </Box>
            </Grid.Item>

            <Grid.Item xl={4}>
              <Box backgroundColor="gray-100" p={2} rounded>
                <Grid gutter>
                  <Grid.Item xl={3}>
                    <Text bold>Program Explanation</Text>
                  </Grid.Item>
                  <Grid.Item xl={9}>
                    <Text>
                      <TextArea
                        label="Program Explanation"
                        hiddenLabel
                        rows={4}
                        name="programExplanation"
                        readOnly={ledger.lock}
                        value={ledgerToUpdate.programExplanation}
                        onChange={(event) =>
                          handleChangeLedger({
                            programExplanation: event.target.value,
                          })
                        }
                        required={
                          isProgramExplanationInvalid &&
                          isProgramExplanationRequired &&
                          !isRenewalSubmission
                        }
                        errorMessage={
                          isProgramExplanationInvalid &&
                          isProgramExplanationRequired
                            ? 'Required because the program is Starter/Core or does not match with suggested pricing.'
                            : undefined
                        }
                      />
                    </Text>
                  </Grid.Item>
                </Grid>
              </Box>
            </Grid.Item>
            <Grid.Item xl={4}>
              {ledgerToUpdate.overrideBuyRates && (
                <>
                  <Box backgroundColor="gray-100" p={2} rounded>
                    <Grid gutter>
                      <Grid.Item xl={3}>
                        <Text bold>Override Explanation</Text>
                      </Grid.Item>
                      <Grid.Item xl={9}>
                        <Text>
                          <TextArea
                            label="Override Explanation"
                            hiddenLabel
                            rows={4}
                            readOnly={ledger.lock}
                            name="overrideExplanation"
                            value={ledgerToUpdate.overrideBuyRatesReason}
                            onChange={handleOverrideReason}
                          />
                        </Text>
                      </Grid.Item>
                    </Grid>
                  </Box>
                </>
              )}
            </Grid.Item>
          </Grid>
          {uwNotes && (
            <Tooltip
              content={
                <Text preserveNewLines color="white">
                  {uwNotes}
                </Text>
              }
              position="right"
              trigger={
                <span>
                  UW score notes{' '}
                  <Icon name="info-circle" data-testid="uw-note-icon" />
                </span>
              }
            />
          )}

          <BuyRatesContainer
            ledger={ledgerToUpdate}
            readOnly={ledger.lock}
            submissionUuid={submission.uuid}
            handleChangeLedger={handleChangeLedger}
            persistedBuyRates={shouldShowPersistedBuyRates}
            buyRateRef={buyRateRef}
            handleScrollBuyRate={handleScrollBuyRate}
          />
        </Box>
      </Flex>

      <OffersContainer
        customerUuid={submission?.customerUuid}
        eligibleForWeekly={ledgerToUpdate.eligibleForWeekly}
        handleChangeLedger={handleChangeLedger}
        isRenewal={isRenewal}
        ledger={ledger}
        readOnly={ledger.lock}
        submissionUuid={submission.uuid}
        offersData={offersData}
        offersError={offersError}
        offersLoading={offersLoading}
        offerRef={offerRef}
        handleScrollOffer={handleScrollOffer}
      />

      <StipulationManager submissionUuid={submission.uuid} />
    </Flex>
  );
};
