import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { FUNDING_BASE_URL } from 'constants/globals';
import {
  PaybackSummariesResponse,
  PaybackSummaryDetailsResponse,
} from 'types/api/funding/types';

export const fetchSummaries = async (
  status: string,
  page = 1
): Promise<PaybackSummariesResponse> => {
  const url = new URL('/api/v2/payback/summaries', FUNDING_BASE_URL());
  url.searchParams.set('status', status);
  url.searchParams.set('page', page.toString());

  const response = await makeInternalAPIRequest<PaybackSummariesResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      `Failed to fetch payment summaries with status ${status}, page ${page}`
    );
  }

  return response.json();
};

export type FetchSummaryDetailsParams = {
  status: string;
  date: string;
  provider: string;
  sameDay: boolean;
  page: number;
};

export const fetchSummaryDetails = async ({
  status,
  date,
  provider,
  sameDay,
  page,
}: FetchSummaryDetailsParams): Promise<PaybackSummaryDetailsResponse> => {
  const url = new URL('/api/v2/payback/summaries/details', FUNDING_BASE_URL());
  url.searchParams.set('status', status);
  url.searchParams.set('date', date);
  url.searchParams.set('provider', provider);
  url.searchParams.set('same_day_ach', sameDay.toString());
  url.searchParams.set('page', page.toString());

  const response = await makeInternalAPIRequest<PaybackSummaryDetailsResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      `Failed to fetch payment summary details with status ${status}, date ${date}, provider ${provider}, page ${page}`
    );
  }

  return response.json();
};
