import {
  Address as AddressComponent,
  Divider,
  Flex,
  formatDateString,
  formatPhoneNumber,
  Link,
  Subheading,
  Table,
} from '@forward-financing/fast-forward';
import { differenceInYears } from 'date-fns';

import { gql } from '__generated__';
import {
  Address,
  BasicOwnerInfoTableFragmentFragment,
} from '__generated__/graphql';
import { InternalLink } from 'components/shared/InternalLink';
import { addressToString } from '../../../helpers/addressUtils';
import { SocialSecurityNumberDisplay } from './SocialSecurityNumberDisplay';

export const BasicOwnerInfoTableFragment = gql(`
  fragment BasicOwnerInfoTableFragment on Owner {
    id
    fullName
    ssn
    ssnLastFour
    birthdate
    address {
      street1
      street2
      city
      state
      zip
    }
    workPhone
    homePhone
    cellPhone
    email
    ownershipDate
    ownershipPercentage
  }
`);

export interface BasicOwnerInfoTableProps {
  currentUserCanSeePartialSsn: boolean;
  currentUserCanSeeDateOfBirth: boolean;
  owner?: BasicOwnerInfoTableFragmentFragment | null;
  lexisNexisOwnerPageHref?: string;
  setAddressSearchHref: (href: string) => void;
  setNameSearchHref: (href: string) => void;
  setCriminalSearchHref: (href: string) => void;
  setWorkPhoneSearchHref: (href: string | undefined) => void;
  setHomePhoneSearchHref: (href: string | undefined) => void;
  setCellPhoneSearchHref: (href: string | undefined) => void;
}

const buildGoogleSearchAddressUrl = (address: Address): URL => {
  const url = new URL('https://google.com/search');
  url.searchParams.append(
    'q',
    addressToString({
      street1: address.street1 ?? '',
      street2: address.street2 ?? '',
      city: address.city ?? '',
      state: address.state ?? '',
      zip: address.zip ?? '',
    })
  );

  return url;
};

const formatOwnerDob = (dob: string): string => {
  if (!dob) {
    return ''; // Return empty if no birthdate provided;
  }
  const birthDate = new Date(dob);
  const today = new Date();
  return `${formatDateString(dob)} (Age: ${differenceInYears(
    today,
    birthDate
  )})`;
};

const buildGoogleSearchNameUrl = (
  fullName: string,
  city?: string | null,
  state?: string | null,
  arrest?: boolean
): URL => {
  const url = new URL('https://google.com/search');
  url.searchParams.append(
    'q',
    `${fullName} ${arrest ? '' : city} ${state}${arrest ? ' Arrest' : ''}`
  );

  return url;
};

const buildGoogleSearchPhoneUrl = (phone: string): URL | undefined => {
  if (!phone) {
    return undefined;
  }

  const url = new URL('https://google.com/search');
  url.searchParams.append('q', formatPhoneNumber(phone));

  return url;
};

export const BasicOwnerInfoTable = (
  props: BasicOwnerInfoTableProps
): JSX.Element => {
  if (!props.owner) {
    return <></>;
  }

  const { owner } = props;
  const { address } = owner;

  const addressSearchUrl = buildGoogleSearchAddressUrl(address || {});
  props.setAddressSearchHref(addressSearchUrl.href);

  const workPhoneSearchUrl = buildGoogleSearchPhoneUrl(owner?.workPhone || '');
  props.setWorkPhoneSearchHref(workPhoneSearchUrl?.href);

  const homePhoneSearchUrl = buildGoogleSearchPhoneUrl(owner?.homePhone || '');
  props.setHomePhoneSearchHref(homePhoneSearchUrl?.href);

  const cellPhoneSearchUrl = buildGoogleSearchPhoneUrl(owner?.cellPhone || '');
  props.setCellPhoneSearchHref(cellPhoneSearchUrl?.href);

  const nameSearchUrl = buildGoogleSearchNameUrl(
    owner.fullName,
    owner.address?.city,
    owner.address?.state
  );
  props.setNameSearchHref(nameSearchUrl.href);

  const criminalSearchUrl = buildGoogleSearchNameUrl(
    owner.fullName,
    owner.address?.city,
    owner.address?.state,
    true
  );
  props.setCriminalSearchHref(criminalSearchUrl.href);

  return (
    <>
      <Flex gap={3} alignItems="center">
        <Subheading variant="section">Owner Basic Information</Subheading>
        <Flex>
          {props.lexisNexisOwnerPageHref && (
            <>
              <InternalLink target="_blank" to={props.lexisNexisOwnerPageHref}>
                Lexis Nexis
              </InternalLink>
              <Divider orientation="vertical" />
            </>
          )}

          <Link newTab href={nameSearchUrl}>
            Google
          </Link>
          <Divider orientation="vertical" />

          <Link newTab href={criminalSearchUrl}>
            Criminal
          </Link>
        </Flex>
      </Flex>
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.RowHeader>Name</Table.RowHeader>
            <Table.Cell>{owner.fullName}</Table.Cell>
          </Table.Row>
          {props.currentUserCanSeeDateOfBirth && (
            <Table.Row>
              <Table.RowHeader>Date of Birth</Table.RowHeader>
              <Table.Cell>
                {owner.birthdate && formatOwnerDob(owner.birthdate)}
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.RowHeader abbr="Social Security Number">SSN</Table.RowHeader>
            <Table.Cell>
              <SocialSecurityNumberDisplay
                ssn={props.owner.ssn}
                ssnLastFour={props.owner.ssnLastFour}
                canSeePartialSsn={props.currentUserCanSeePartialSsn}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Address</Table.RowHeader>
            <Table.Cell>
              {address && (
                <Link newTab href={addressSearchUrl}>
                  <AddressComponent
                    streetAddress={[address.street1, address.street2]}
                    city={address.city ?? ''}
                    state={address.state ?? ''}
                    zipCode={address.zip ?? ''}
                    isSingleLine
                  />
                </Link>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>State Resides</Table.RowHeader>
            <Table.Cell>{address?.state}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Work Phone</Table.RowHeader>
            <Table.Cell>
              {owner.workPhone && workPhoneSearchUrl && (
                <Link newTab href={workPhoneSearchUrl}>
                  {formatPhoneNumber(owner.workPhone)}
                </Link>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Home Phone</Table.RowHeader>
            <Table.Cell>
              {owner.homePhone && homePhoneSearchUrl && (
                <Link newTab href={homePhoneSearchUrl}>
                  {formatPhoneNumber(owner.homePhone)}
                </Link>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Cell Phone</Table.RowHeader>
            <Table.Cell>
              {owner.cellPhone && cellPhoneSearchUrl && (
                <Link newTab href={cellPhoneSearchUrl}>
                  {formatPhoneNumber(owner.cellPhone)}
                </Link>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Email Address</Table.RowHeader>
            <Table.Cell>{owner.email}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>Acquired Business</Table.RowHeader>
            <Table.Cell>
              {owner.ownershipDate &&
                formatDateString(props.owner.ownershipDate)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};
