import { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Combobox,
  Flex,
  Form,
  FormProps,
  Icon,
  IconButton,
  Modal,
  OptionShape,
  SelectableOption,
  Text,
} from '@forward-financing/fast-forward';
import { defaultTo } from 'lodash';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { useSubmissionIndustryRecommendations } from 'components/SuggestedIndustry/suggestedIndustryHooks';
import { SuggestedIndustry } from 'components/SuggestedIndustry/SuggestedIndustry';
import { featureFlags } from 'helpers/featureFlags';
import { UpdateCustomerBody } from './businessOverview.types';

export type EditIndustryModalProps = {
  industryId: string;
  options: OptionShape[];
  setIndustry: (industry: string) => void;
  submissionUuid: string;
  updateIndustry: (industry: UpdateCustomerBody) => Promise<MutationResponse>;
  updatedIndustryError?: Error;
  updatedIndustryLoading?: boolean;
};

export const IndustryEditModal = ({
  industryId,
  options,
  setIndustry,
  submissionUuid,
  updateIndustry,
  updatedIndustryError,
  updatedIndustryLoading,
}: EditIndustryModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [newIndustryId, setNewIndustryId] = useState<string>(industryId);

  const [industryEditing, setIndustryEditing] = useState(false);
  const [industryError, setIndustryError] = useState('');
  const [industryCanceled, setIndustryCanceled] = useState(false);

  const [updatingIndustry, setUpdatingIndustry] = useState(false);

  const hasUpdatedIndustry = industryId !== newIndustryId;

  const {
    data: industryRecommendationsData,
    error: industryRecommendationsError,
  } = useSubmissionIndustryRecommendations(submissionUuid);

  const suggestedIndustry = {
    industry: defaultTo(
      industryRecommendationsData?.suggestedIndustry.industry,
      null
    ),
    isProhibited: defaultTo(
      industryRecommendationsData?.suggestedIndustry.isProhibited,
      null
    ),
  };
  const otherSuggestions = industryRecommendationsData?.otherSuggestions;

  const suggestedIndustryId = options.find(
    (option) => option.text === suggestedIndustry.industry
  )?.value;

  const doesSelectedIndustryMatchSuggestedIndustry =
    suggestedIndustryId && suggestedIndustryId === newIndustryId;

  const shouldShowIndustryMismatchBanner =
    featureFlags.show_new_suggested_industry_predictions &&
    industryRecommendationsData?.suggestedIndustry &&
    !doesSelectedIndustryMatchSuggestedIndustry;

  const selectedOption = options.find((o) => o.value === newIndustryId);

  // Show errors.
  useEffect(() => {
    // 1. Don't show the error if we aren't editing (modal isn't open).
    // 2. Don't show the error if we previously canceled the changes. This one
    // is an edge case where we submit and get an error, and then cancel the
    // changes. Canceling closes the modal, and we don't want the same error to
    // appear again after re-opening.
    if (industryEditing && updatedIndustryError && !industryCanceled) {
      setIndustryError(updatedIndustryError.message);
    }
  }, [industryEditing, updatedIndustryError, industryCanceled]);

  const handleOpenChange = (): void => {
    // Reset any errors when opening the modal.
    if (!isModalOpen) {
      setIndustryError('');
      setIndustryEditing(true);
    }

    // Changes canceled.
    if (isModalOpen) {
      setNewIndustryId(industryId);
      setIndustryError('');
      setIndustryEditing(false);
      setIndustryCanceled(true);
    }

    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit: FormProps['onSubmit'] = async (_sourceInfo) => {
    if (!newIndustryId) {
      setIndustryError('Please select an industry.');
      return;
    }

    // No need to fire request if the industry hasn't changed.
    if (!hasUpdatedIndustry) {
      setIsModalOpen(false);
      return;
    }

    setUpdatingIndustry(true);
    setIndustryCanceled(false);

    const { success } = await updateIndustry({
      industryId: Number(newIndustryId),
    });

    setUpdatingIndustry(false);

    if (success) {
      // This updates the value in the parent component.
      setIndustry(newIndustryId);

      setIsModalOpen(false);
      setIndustryEditing(false);
    }
  };

  const handleValueChange = (values: SelectableOption[]): void => {
    const newValue = values[0] as SelectableOption | undefined;
    setNewIndustryId(newValue?.value ?? '');
  };

  return (
    <Modal
      trigger={
        <IconButton icon="pencil-alt" hiddenLabel label="Edit Industry" />
      }
      isOpen={isModalOpen}
      title="Edit Industry"
      onOpenChange={handleOpenChange}
      body={
        <Box>
          {industryError && (
            <Banner dismissable={false}>{industryError}</Banner>
          )}

          {featureFlags.show_new_suggested_industry_predictions &&
            industryRecommendationsError && (
              <Banner dismissable={false}>
                {industryRecommendationsError.message}
              </Banner>
            )}

          {featureFlags.show_new_suggested_industry_predictions &&
            industryRecommendationsData && (
              <SuggestedIndustry
                suggestedIndustry={suggestedIndustry}
                otherSuggestions={otherSuggestions}
              />
            )}

          <Form
            accessibleName="Edit Industry"
            onSubmit={handleSubmit}
            allowImplicitSubmission={true}
          >
            {({ fireSubmit }) => (
              <Flex flexDirection="column" gap={3}>
                <Combobox
                  label="Industry"
                  options={options}
                  values={selectedOption ? [selectedOption] : []}
                  onValueChange={handleValueChange}
                  isFixed
                  isClearable
                />

                {shouldShowIndustryMismatchBanner && (
                  <Banner dismissable={false} variant="warning">
                    <Flex gap={2} alignItems={'center'}>
                      <Icon name="circle-exclamation" />
                      <Text>
                        The selection in Industry does not match the Suggested
                        Industry.
                      </Text>
                    </Flex>
                  </Banner>
                )}

                <Box marginY={2}>
                  <Button
                    onClick={fireSubmit}
                    disabled={updatingIndustry || updatedIndustryLoading}
                  >
                    Submit
                  </Button>
                </Box>
              </Flex>
            )}
          </Form>
        </Box>
      }
    />
  );
};
